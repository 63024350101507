import { ENTRIES_LIST_KEY_TYPE, EntriesListReturnType } from '../entries/entriesList';

import Codefy from '../../../../codefy';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

export const UPLOAD_BATCH_ENTRIES_LIST_URL = '/api/v1/upload_batches/list_entries';

/* The same as ENTRIES_LIST so that all refresh code just works */
export const UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE = ENTRIES_LIST_KEY_TYPE;

export const uploadBatchEntriesListKey = (
  params: UploadBatchEntriesListParams,
): [string, UploadBatchEntriesListParams] => [UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE, params];

type UploadBatchEntriesListParams = {
  upload_batch_uuid: Codefy.Objects.UploadBatch['upload_batch_uuid'];
  document_processing_status: Codefy.Objects.Document['status'];
};

export const useUploadBatchEntriesList = (params: UploadBatchEntriesListParams) =>
  useCreateInfiniteQuery<EntriesListReturnType>(
    uploadBatchEntriesListKey(params),
    100,
    UPLOAD_BATCH_ENTRIES_LIST_URL,
    'GET',
    params,
    'entries',
    true,
  );
