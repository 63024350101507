import { CancelUploadBatchUploadsDialog } from './delete/cancelUploadsBatchUploads';
import { CreateDirectoryDialog } from './create/createDirectoryDialog';
import { CreateProjectDialog } from './create/createProjectDialog';
import { CreatePublicationDialog } from './create/createPublicationDialog';
import { CreateTagDialog } from './create/createTagDialog';
import { CreateTaglistDialog } from './create/createTaglistDialog';
import { DeleteAccountDialog } from './delete/deleteAccountDialog';
import { DeleteAnnotationDialog } from './delete/deleteAnnotationDialog';
import { DeleteEntryDialog } from './delete/deleteEntryDialog';
import { DeleteProjectDialog } from './delete/deleteProjectDialog';
import { DeleteTagDialog } from './delete/deleteTagDialog';
import { DeleteUploadBatchDialog } from './delete/deleteUploadBatchDialog';
import { EditTagCellDialog } from './edit/editTagCellDialog';
import { EditTagDialog } from './edit/editTagDialog';
import ExportPrettyWordDialog from './export/exportPrettyWordDialog';
import React from 'react';
import { RenameEntryDialog } from './rename/renameEntryDialog';
import { RenameProjectDialog } from './rename/renameProjectDialog';
import { UnshareProjectDialog } from './delete/unshareProjectDialog';

export default function DialogsRenderer() {
  return (
    <>
      <CreateProjectDialog />
      <CreateDirectoryDialog />
      <CreateTaglistDialog />
      <CreateTagDialog />
      <CreatePublicationDialog />

      <RenameProjectDialog />
      <RenameEntryDialog />

      <EditTagDialog />
      <EditTagCellDialog />

      <ExportPrettyWordDialog />

      <DeleteProjectDialog />
      <DeleteTagDialog />
      <DeleteEntryDialog />
      <DeleteAccountDialog />
      <DeleteAnnotationDialog />
      <UnshareProjectDialog />
      <DeleteUploadBatchDialog />
      <CancelUploadBatchUploadsDialog />
    </>
  );
}
