import Codefy from '../../../../../../codefy';
import { useCreateInfiniteQuery } from '../../../../subscriptionHelpers';

export const TAG_INSTANCES_LIST_URL = '/api/v1/taglists/tags/instances/list';

export const TAG_INSTANCES_LIST_KEY_TYPE = 'tagInstancesList';

export type TagInstancesListParams = {
  tag_ids?: Codefy.Objects.Tag['id'][];
  taglist_ids?: Codefy.Objects.Taglist['id'][];
  document_ids?: Codefy.Objects.Document['id'][];
  case_ids?: Codefy.Objects.Directory['id'][];
  types?: ('annotation' | 'entry' | 'annotation_tag')[];
  text_snippet_len?: number;
  offset?: number;
  limit?: number;
  /** 'created_at' | 'created_by' | 'position' | 'name' */
  order_by?: string;
  descending?: boolean;
};

export type TagInstancesReturnType = {
  tag_instances: Codefy.Objects.TagInstance[];
  num_tag_instances: number;
};

export const tagInstancesListKey = (
  params: TagInstancesListParams,
): [string, TagInstancesListParams] => [TAG_INSTANCES_LIST_KEY_TYPE, params];

export const useTagInstancesList = (params: TagInstancesListParams) =>
  useCreateInfiniteQuery<TagInstancesReturnType>(
    tagInstancesListKey(params),
    7,
    TAG_INSTANCES_LIST_URL,
    'GET',
    params,
    'tag_instances',
    false,
    (!params.tag_ids || params.tag_ids?.length === 0) &&
      (!params.taglist_ids || params.tag_ids?.length === 0),
  );
