import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiDataSerializer } from '../../subscriptionHelpers';
import { handleActionError } from '../actionHelpers';
import store from '../../../store';

export const userLogin = (data: Codefy.API.Users.Login.RequestData, callback: () => void) => {
  return async () => {
    try {
      const config: Codefy.API.Users.Login.RequestConfig = {
        url: '/api/v1/users/login',
        method: 'POST',
        data: fastApiDataSerializer(data),
      };

      const response = await axios(config);

      store.dispatch({
        type: 'UserGet',
        user: response.data,
      });

      store.dispatch({
        type: 'UserLoadingCompleted',
      });

      callback?.();
    } catch (error) {
      handleActionError(error?.response?.data);
      callback?.();
    }
  };
};
