import { COLORS } from '../../globalThemeSettings';
import React from 'react';

/** Icon used for annotation taglists */
export default function AnnotationTaglistIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path fill="none" d="M0,0H24V24H0ZM0,0H24V24H0Z" />
          <path
            fill={COLORS.primary}
            d="M12,15H6v2h6Zm5-4H6v2H17Zm0-4H6V9H17ZM5,7H3V9H5ZM5,17V15H3v2Zm0-6H3v2H5Zm16,4H15v2h6Zm-4,.14V22h2V15.14Z"
          />
        </g>
      </g>
    </svg>
  );
}
