export enum PaneKeys {
  'assistant' = 'assistant',
  'assistantResults' = 'assistantResults',
  'projectsList' = 'projectsList',
  'projectShareSettings' = 'projectShareSettings',
  'entriesList' = 'entriesList',
  'search' = 'search',
  'pdfViewer' = 'pdfViewer',
  'pdfViewer2' = 'pdfViewer2',
  'annotationTaglist' = 'annotationTaglist',
  'annotationTag' = 'annotationTag',
  'entryTaglist' = 'entryTaglist',
  'entryTag' = 'entryTag',
  'annotationsList' = 'annotationsList',
  'annotation' = 'annotation',
  'account' = 'account',
  'tutorials' = 'tutorials',
  'uploadBatches' = 'uploadBatches',
  'uploadBatch' = 'uploadBatch',
}
