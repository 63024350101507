import { Box, Chip, Grid, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import {
  COLORS,
  FONT_SIZES,
  componentTypeStyles,
  useGlobalStyles,
} from '../../globalThemeSettings';

import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import Codefy from '../../codefy';
import { PaneKeys } from '../panes/paneTypes/paneKeys';
import React from 'react';
import { tagInstancesDelete } from '../../controllers/api/actions/taglists/tags/tagInstances/tagInstancesDelete';
import { usePaneActions } from '../panes/usePaneActions';

// these styles created a warning when put into the Chip override classes above
const useStyles = makeStyles({
  labelIcon: {
    marginRight: '2px',
  },
  columnName: {
    maxWidth: '100%',
    fontSize: FONT_SIZES.default,
    color: '#333333',
  },
  // Importing into local classes here since we seem to be hitting this error:
  // https://github.com/mui-org/material-ui/issues/16374
  tagLabel: componentTypeStyles.tagLabel,
});

/** A component that show nice looking tag instance chips that can be displayed next to an
 * annotation or an entry */
export default function TagLabels({
  tagInstances,
  isHighlight,
  _paneActions,
  afterDelete,
  fullWidth,
  deleteOnClick,
  icon,
  lineBreaks,
  taglistType,
}: {
  tagInstances?: Codefy.Objects.TagInstanceInfo[];
  isHighlight?: boolean;
  // FIXME: Hack while annotationBoxes are rendered using React.render and we lose all connection to
  // the rest of the application
  _paneActions?: Function;
  afterDelete?: Function;
  /** Use false if displayed next to pdf document, true otherwise */
  fullWidth?: boolean;
  /** If true, deletes the label when clicked. Otherwise opens the label */
  deleteOnClick?: boolean;
  icon: 'add' | 'tag' | 'delete' | 'none';
  /** Should there be line breaks between the tagLabels or should they be on the same line? */
  lineBreaks: boolean;
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  // FIXME: Hack while annotationBoxes are rendered using React.render and we lose all connection to
  // the rest of the application
  const __paneActions = usePaneActions();
  const paneActions = _paneActions || __paneActions;

  // if this is not tagged, don't show anything
  if (isHighlight === true) return null;

  if (!tagInstances) return null;

  const onDelete = (instance: Codefy.Objects.TagInstanceInfo) => {
    tagInstancesDelete({ tag_instance_id: instance.id });
    afterDelete?.();
  };

  const onOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: Codefy.Objects.TagInstanceInfo,
  ) => {
    try {
      event.stopPropagation();

      if (taglistType === 'annotation') {
        //@ts-ignore FIXME
        paneActions?.addOrUpdatePane({
          paneKey: PaneKeys.annotationTaglist,
          params: {
            annotationTaglist_id: item.taglist_id,
          },
        });
        //@ts-ignore FIXME
        paneActions?.addOrUpdatePane({
          paneKey: PaneKeys.annotationTag,
          params: {
            annotationTag_taglistId: item.taglist_id,
            annotationTag_tagId: item.tag_id,
          },
        });
      } else if (taglistType === 'entry') {
        //@ts-ignore FIXME
        paneActions?.addOrUpdatePane({
          paneKey: PaneKeys.entryTaglist,
          params: {
            entryTaglist_id: item.taglist_id,
          },
        });
        //@ts-ignore FIXME
        paneActions?.addOrUpdatePane({
          paneKey: PaneKeys.entryTag,
          params: {
            entryTag_taglistId: item.taglist_id,
            entryTag_tagId: item.tag_id,
          },
        });
      }
      //@ts-ignore FIXME
      paneActions?.closePane(PaneKeys.annotationsList);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid container alignItems="center">
        {tagInstances.map((tagInstance) => (
          <Grid item key={tagInstance.id} md={lineBreaks ? 12 : undefined}>
            <Box
              /* Necessary for entry tagLabels so they're not stuck together */
              ml={0.5}
              mr={0.5}
              /* If the mb and mt margins are adjusted, make sure to also adjust
                useAnnotationPatchTagLabelPositions. We use no vertical margin so that the maximum
                of space is used. */
              mt={0}
              mb={lineBreaks ? 0 : 0.5}>
              <Chip
                style={{ backgroundColor: tagInstance?.tag_color || COLORS.defaultTagColor }}
                className={classes.tagLabel}
                size="small"
                key={tagInstance.id}
                onClick={(event) => {
                  if (deleteOnClick) {
                    onDelete(tagInstance);
                  } else {
                    onOpen(event, tagInstance);
                  }
                }}
                onDelete={
                  /* If icon is "delete", the entire label becomes a delete button, so we do not
                  need to show the little "x" sign */
                  icon === 'delete' ? undefined : () => onDelete(tagInstance)
                }
                deleteIcon={
                  <IconButton size="small">
                    <CancelIcon className={globalClasses.tagLabelDeleteIcon} fontSize="small" />
                  </IconButton>
                }
                label={
                  <Grid container alignItems="center" wrap="nowrap">
                    {icon === 'add' && <AddIcon className={classes.labelIcon} />}

                    {icon === 'delete' && <CloseIcon className={classes.labelIcon} />}

                    <Typography noWrap={true} className={classes.columnName}>
                      <Tooltip title={fullWidth ? '' : tagInstance.tag_name} enterDelay={1000}>
                        <Box ml={0.5} mr={0.3}>
                          <span className={globalClasses.tagLabelForegroundText}>
                            {tagInstance.tag_name}
                          </span>
                        </Box>
                      </Tooltip>
                    </Typography>
                  </Grid>
                }
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
