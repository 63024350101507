import useCurrentCase, { CurrentCaseEntry } from '../../../../hooks/useCurrentCase';
import {
  useUserSetting_taglist_descending,
  useUserSetting_taglist_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import Codefy from '../../../../codefy';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import { TagListTag } from './tagListTag';
import { TaglistVariants } from '../paneTypes';
import getTagsHierarchy from '../tag/getTagsHierarchy';
import { makeStyles } from '@material-ui/core';
import { useQueryParam_taglist_id } from '../../../../controllers/useGlobalQueryParams';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsList } from '../../../../controllers/api/subscriptions/taglists/tags/tagsList';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  /** The overflow is very important, otherwise you cannot have more entries than the height of the
   * screen! */
  root: { height: '100%', overflowY: 'auto' },
}));

export default function Taglist({
  variant,
  taglistType,
}: {
  variant: TaglistVariants;
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const { t } = useTranslation();
  const [taglist_orderBy] = useUserSetting_taglist_orderBy();
  const [taglist_descending] = useUserSetting_taglist_descending();
  const { id: currentCaseId } = useCurrentCase();

  const classes = useStyles();

  const [taglist_id] = useQueryParam_taglist_id({ variant, taglistType });

  const { data: taglist } = useTaglistsGet({ taglist_id, case_id: currentCaseId });

  const { data: tags, isFetching } = useTagsList({
    tag_ids: taglist?.tags.map((tag) => tag.id),
    order_by: taglist_orderBy,
    descending: taglist_descending,
    /* If the pane is the assistant pane, tag instances from all sources should be considered,
    because later we hide those tags that do not have any instances (and thus no training data) and
    training data will usually be from other cases */
    case_id: variant === TaglistVariants.TAGLIST ? currentCaseId : undefined,
  });

  if (isFetching && !tags?.tags) return <PaneContentLoading />;

  if (!tags?.tags || !taglist)
    return (
      <PaneContentEmpty
        text={
          taglistType === 'annotation' ? t('taglist.noAnnotationTags') : t('taglist.noEntryTags')
        }
      />
    );

  return (
    <div className={classes.root}>
      <CurrentCaseEntry />
      {getTagsHierarchy(tags.tags).map((tag) => (
        <TagListTag
          key={tag.id}
          project_id={taglist?.path.project_id}
          tag={tag}
          variant={variant}
        />
      ))}
    </div>
  );
}
