import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';
import { useQuery } from 'react-query';

export const DIRECTORIES_GET_KEY_TYPE = 'directoriesGet';

export const directoriesGetKey = (
  id?: Codefy.Objects.Directory['id'],
): [string, Codefy.Objects.Directory['id'] | undefined] => [DIRECTORIES_GET_KEY_TYPE, id];

export const useDirectoriesGet = (directory_id?: Codefy.Objects.Directory['id'] | string) => {
  if (typeof directory_id === 'string') {
    directory_id = parseInt(directory_id);
  }
  return useQuery(
    directoriesGetKey(directory_id),
    async (key: string, directory_id?: Codefy.Objects.Directory['id']) => {
      if (!directory_id) return undefined;
      const { data } = await axios.get('/api/v1/directories/get', { params: { directory_id } });
      return data as Codefy.Objects.Directory;
    },
  );
};
