import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';

import Codefy from '../../../../codefy';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import TaglistSelector from './taglistSelector';
import { TaglistVariants } from '../paneTypes';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenRenameEntryDialog } from '../../../dialogs/rename/renameEntryDialog';
import { usePaneActions } from '../../usePaneActions';
import { useQueryParam_taglist_id } from '../../../../controllers/useGlobalQueryParams';
import useTaglistMenuEntries from './useTaglistMenuEntries';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';

export default function TaglistPaneTitleBar({
  onlyShowPart,
  variant,
  taglistType,
}: {
  onlyShowPart?: 'icon' | 'title';
  variant: TaglistVariants;
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const [taglist_id] = useQueryParam_taglist_id({ variant, taglistType });
  const isEveryone = useIsEveryone();
  const { data: taglist, isError } = useTaglistsGet({ taglist_id });
  const paneActions = usePaneActions();
  const openRenameEntryDialog = useOpenRenameEntryDialog();

  /** Close on error, e.g. after deleting */
  useEffect(() => {
    if (!isError) return;
    paneActions.closePane(
      taglistType === 'annotation' ? PaneKeys.annotationTaglist : PaneKeys.entryTaglist,
    );
  }, [isError]);

  const taglistMenuEntries = useTaglistMenuEntries({ taglist });

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={
        taglist &&
        (taglist?.type === 'annotation' ? (
          <EntryFileTypeIcon entryMimetype="application/codefy.annotation-taglist" />
        ) : (
          <EntryFileTypeIcon entryMimetype="application/codefy.entry-taglist" />
        ))
      }
      onRenameCallback={taglist && openRenameEntryDialog({ entryId: taglist?.path.entry_id })}
      title={
        <span>
          <Grid container alignItems="baseline" spacing={1} wrap="nowrap">
            <Grid item>{taglist?.path.entry_name}</Grid>
          </Grid>
        </span>
      }
      paneKey={taglistType === 'annotation' ? PaneKeys.annotationTaglist : PaneKeys.entryTaglist}
      menuEntries={taglistMenuEntries}
      statusBar={
        variant === TaglistVariants.ASSISTANT && taglist?.path.project_id && taglist ? (
          <Box mt={-0.8}>
            <TaglistSelector
              project_id={taglist.path.project_id}
              variant={variant}
              taglistType={taglist.type}
            />
          </Box>
        ) : (
          ''
        )
      }
      readonly={isEveryone}
      onCollapseRightPane={() => {
        paneActions.closePane(
          taglistType === 'annotation' ? PaneKeys.annotationTag : PaneKeys.entryTag,
        );
      }}
    />
  );
}
