import Codefy from '../../../../codefy';
import { Grid } from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { TaglistVariants } from '../paneTypes';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenEditTagDialog } from '../../../dialogs/edit/editTagDialog';
import { useQueryParam_tag_tagId } from '../../../../controllers/useGlobalQueryParams';
import useTagMenuEntries from './useTagMenuEntries';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';

export default function TagPaneTitleBar({
  onlyShowPart,
  taglistType,
}: {
  onlyShowPart?: 'icon' | 'title';
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const isEveryone = useIsEveryone();
  const [tag_tagId] = useQueryParam_tag_tagId({ taglistType });

  const { data: tag } = useTagsGet(tag_tagId);

  const { data: taglist } = useTaglistsGet({ taglist_id: tag?.taglist_id });

  const tagMenuEntries = useTagMenuEntries({
    project_id: taglist?.path.project_id,
    tag,
    full: false,
    variant: TaglistVariants.TAGLIST,
  });

  const openEditTagDialog = useOpenEditTagDialog();

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={taglistType === 'annotation' ? <LabelOutlinedIcon /> : <LabelIcon />}
      onRenameCallback={tag && openEditTagDialog({ tagId: tag?.id })}
      title={
        <span>
          <Grid container alignItems="baseline" spacing={1}>
            <Grid item>
              <small style={{ opacity: 0.7 }}>{tag?.cell}</small>
            </Grid>

            <Grid item>{tag?.name}</Grid>
          </Grid>
        </span>
      }
      paneKey={taglistType === 'annotation' ? PaneKeys.annotationTag : PaneKeys.entryTag}
      menuEntries={tagMenuEntries}
      readonly={isEveryone}
    />
  );
}
