import { bindContextMenu, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';

import Codefy from '../../../../codefy';
import CopyToClipboard from 'react-copy-to-clipboard';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { PaneKeys } from '../paneKeys';
import PdfViewerLeftIcon from '../../../icons/pdfViewerLeft';
import PdfViewerRightAddIcon from '../../../icons/pdfViewerRightAdd';
import PdfViewerRightIcon from '../../../icons/pdfViewerRight';
import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import { documentsDownload } from '../../../../controllers/api/actions/documents/documentsDownload';
import getSearchResultShareUrl from './getSearchResultShareUrl';
import { toast } from 'react-toastify';
import { useIsPaneOpen } from '../../../../controllers/useGlobalQueryParams';
import { usePaneActions } from '../../usePaneActions';
import { useTranslation } from 'react-i18next';

/** Whatever is inside this wrapper will open a search result context menu (star, share, ...) when
 * right-clicked */
export default function SearchResultContextMenuWrapper({
  searchResult,
  children,
  openOnLeftClick,
}: {
  searchResult?: Codefy.Objects.SearchResult;
  children: React.ReactChild | React.ReactChildren;
  /** Used when we are wrapping a button that is supposed to open the context menu when clicked
   * (with the left mouse button) */
  openOnLeftClick?: boolean;
}) {
  const { t } = useTranslation();
  const paneActions = usePaneActions();
  const isPdfViewerOpen = useIsPaneOpen(PaneKeys.pdfViewer);
  const isPdfViewer2Open = useIsPaneOpen(PaneKeys.pdfViewer2);

  const contextMenuState = usePopupState({
    variant: 'popover',
    popupId: 'searchResultContextMenu',
  });
  const [contextState, setContextState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  if (!searchResult) return <>{children}</>;

  const onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    contextMenuState.close();
  };

  const onOpenSearchResultLeft = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: searchResult.document.id,
        pdfViewer_page: searchResult.highlight.boxes[0].page,
        pdfViewer_boxes: JSON.stringify(searchResult.highlight.boxes),
        search_selectedResultPartId: searchResult.part_id,
      },
    });
  };

  const onOpenSearchResultRight = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer2,
      params: {
        pdfViewer2_documentId: searchResult.document.id,
        pdfViewer2_page: searchResult.highlight.boxes[0].page,
        pdfViewer2_boxes: JSON.stringify(searchResult.highlight.boxes),
        search_selectedResultPartId: searchResult.part_id,
      },
    });
  };

  const onDownloadDocument = async () => {
    if (!searchResult.document) return;
    documentsDownload(searchResult.document);
  };

  /** The share URL that gets copied into the clipboard when the user clicks on the share button */
  const shareUrl = getSearchResultShareUrl(searchResult);

  /** Show popup that the share url has been successfully copied to the clipboard */
  const onCopyShareUrlToClipboardSuccess = () => {
    toast.success(t('panes.copyLinkSuccess'));
  };

  const menuAnchorPosition =
    contextState.mouseY !== null && contextState.mouseX !== null
      ? { top: contextState.mouseY, left: contextState.mouseX }
      : undefined;

  const menu = (
    <Menu
      {...bindMenu(contextMenuState)}
      anchorReference="anchorPosition"
      anchorPosition={menuAnchorPosition}
      onClick={onMenuClick}>
      {isPdfViewerOpen && !isPdfViewer2Open && (
        <MenuItem onClick={onOpenSearchResultRight}>
          <ListItemIcon>
            <IconButton size="small">
              <PdfViewerRightAddIcon />
            </IconButton>
          </ListItemIcon>
          {t('pdfViewer2.openRightAdd')}
        </MenuItem>
      )}
      {isPdfViewerOpen && isPdfViewer2Open && (
        <div>
          <MenuItem onClick={onOpenSearchResultLeft}>
            <ListItemIcon>
              <IconButton size="small">
                <PdfViewerLeftIcon />
              </IconButton>
            </ListItemIcon>
            {t('pdfViewer2.openLeft')}
          </MenuItem>
          <MenuItem onClick={onOpenSearchResultRight}>
            <ListItemIcon>
              <IconButton size="small">
                <PdfViewerRightIcon />
              </IconButton>
            </ListItemIcon>
            {t('pdfViewer2.openRight')}
          </MenuItem>
        </div>
      )}

      <CopyToClipboard text={shareUrl} onCopy={onCopyShareUrlToClipboardSuccess}>
        <MenuItem>
          <ListItemIcon>
            <IconButton size="small" data-e2e-id="share-annotation">
              <ShareIcon />
            </IconButton>
          </ListItemIcon>
          {t('searchResultContextMenuWrapper.copyShareLink')}
        </MenuItem>
      </CopyToClipboard>

      <MenuItem onClick={onDownloadDocument}>
        <ListItemIcon>
          <IconButton size="small">
            <GetAppIcon />
          </IconButton>
        </ListItemIcon>
        {t('searchResultContextMenuWrapper.download')}
      </MenuItem>
    </Menu>
  );

  return (
    <div
      {...bindContextMenu(contextMenuState)}
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setContextState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        bindContextMenu(contextMenuState).onContextMenu(event);
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!openOnLeftClick) return;

        event.stopPropagation();
        event.preventDefault();
        setContextState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        contextMenuState.open();
      }}>
      {children}
      {menu}
    </div>
  );
}
