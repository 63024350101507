import { Box, Button, FilledInputProps, TextField } from '@material-ui/core';
import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';

import { GenericDialog } from './genericDialog';
import { useGlobalStyles } from '../../globalThemeSettings';
import { useTranslation } from 'react-i18next';

export default function GenericTextFieldDialog({
  open,
  title,
  icon,
  currentName,
  onRename,
  onClose,
}: {
  open: boolean;
  title: string;
  icon: JSX.Element;
  currentName: string;
  onRename: (newName: string) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const [name, setName] = useState(currentName);

  const onSubmit = () => {
    if (!name) return;

    onRename(name);
    onClose();
  };

  const onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) =>
    setName(event.target.value);

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const InputProps: Partial<FilledInputProps> = {
    startAdornment: <Box mr={1}>{icon}</Box>,
    endAdornment: (
      <Box ml={1}>
        <Button
          onClick={onSubmit}
          disabled={!name}
          color="primary"
          className={globalClasses.dialogButton}>
          {t('renameDialog.save')}
        </Button>
      </Box>
    ),
    disableUnderline: true,
  };

  return (
    <GenericDialog title={title} onClose={onClose} open={open}>
      <TextField
        placeholder={t('renameDialog.enterName')}
        value={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        fullWidth
        autoFocus
        focused
        InputProps={InputProps}
      />
    </GenericDialog>
  );
}
