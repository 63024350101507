import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AccountPaneTitleBar({ onlyShowPart }: { onlyShowPart?: 'icon' | 'title' }) {
  const { t } = useTranslation();
  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={<AccountCircleIcon />}
      title={t('account.account')}
      paneKey={PaneKeys.account}
    />
  );
}
