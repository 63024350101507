import { Box, Button, ButtonGroup, Tooltip } from '@material-ui/core';
import { DataFunc, Mention, MentionsInput, OnChangeHandlerFunc } from 'react-mentions';
import React, { useState } from 'react';

import Codefy from '../../../../../codefy';
import CreateIcon from '@material-ui/icons/Create';
import EmailIcon from '@material-ui/icons/Email';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import classNames from './mentions.module.css';
import { commentsCreate } from '../../../../../controllers/api/actions/annotations/comments/commentsCreate';
import { projectsShare } from '../../../../../controllers/api/actions/projects/projectsShare';
import useHover from '../../../../../hooks/useHover';
import useKeyPress from '../../../../../hooks/useKeyPress';
import { useProjectsGet } from '../../../../../controllers/api/subscriptions/projects/projectsGet';
import { useSelector } from 'react-redux';
import { useStyles } from './index';
import { useTranslation } from 'react-i18next';

// TODO: Perhaps this can be refactored to be written together with the AnnotationComment component?
// Seems there is some redundant code
// TODO: Add tests for the feature of notifying/sharing after writing a comment

export function CreateAnnotationComment({ annotation }: { annotation: Codefy.Objects.Annotation }) {
  const classes = useStyles({});
  const user = useSelector((state: Codefy.State) => state.user);
  const [hoverRef, isHovered] = useHover();
  const { t } = useTranslation();
  const useCtrl = useKeyPress(17);

  const { data: project } = useProjectsGet(annotation.path.project_id);

  const [saving, setSaving] = useState(false);
  const [focused, setFocused] = useState(false);

  /** A representation of the comment that includes mentions syntax (for highlighting the selected
   * mentions) in the comment input area after the user selects them */
  const [commentWithMentions, setCommentWithMentions] = useState('');
  /** A plain text representation of the comment */
  const [comment, setComment] = useState('');
  /** A list of emails that will receive notifications. New emails are added when the user selects
   * an email from the mentions suggestion menu, but they can be manually removed by the user */
  const [mentions, setMentions] = useState<
    {
      /** The email address of the user */
      email: string;
      /** Should the user get read-only access to the project? */
      shareWith: boolean;
      /** Should the user get read-write access to the project? */
      shareReadWrite: boolean;
      /** Should the user get an email notification about the comment? */
      notify: boolean;
    }[]
  >([]);

  /** Retrieves users given a search term for the mentions functionality */
  const mentionSearch: DataFunc = (search, callback) => {
    axios.get('/api/v1/users/list', { params: { limit: 5, email: search } }).then((response) => {
      if (response?.data?.users) {
        callback(
          response?.data?.users.map((user: Codefy.Objects.User) => ({
            id: user.email,
            display: user.email,
          })),
        );
      } else {
        callback([]);
      }
    });
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onSave = async () => {
    setSaving(true);

    /** Share project, if necessary */
    for (const mention of mentions) {
      /** Skip if user already has accesses to project */
      if (project?.permissions.map((user) => user.user.email).includes(mention.email)) continue;

      /** Skip if 'Give read access' was not checked */
      if (!mention.shareWith) continue;

      /** Shares the project */
      projectsShare({
        project_ids: [annotation.path.project_id],
        email: mention.email,
        write: mention.shareReadWrite,
      });
    }

    /** Creates the comment, optionally notifying selected users */
    await commentsCreate({
      annotation_id: annotation.id,
      text: comment,
      /** Notifies users */
      emails: mentions
        .filter((mention) => mention.notify && mention.shareWith)
        .map((mention) => mention.email),
    });
    setComment('');
    setCommentWithMentions('');
    setMentions([]);
    setSaving(false);
    setFocused(false);
  };

  const onCancel = () => {
    setComment('');
    setCommentWithMentions('');
    setMentions([]);
    setFocused(false);
  };

  /** Every time a key is pressed, check if it happens to be the enter key AND ctrl is held down, if
   * yes, save the comment */
  const onKeyDownSaveIfCtrlEnter = (
    event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const keyCodeForTheEnterKey = 13;
    if (event.keyCode === keyCodeForTheEnterKey) {
      if (useCtrl || event.metaKey /* for Mac OSX */) {
        onSave();
      }
    }
  };

  /** When the user types in the comment field */
  const onChange: OnChangeHandlerFunc = (event, newValue, newPlainTextValue) => {
    setCommentWithMentions(newValue);
    setComment(newPlainTextValue);
  };

  /** When the user selects from the mention suggestions popup */
  const onAdd = (id: React.ReactText) => {
    /** Skip if email already exists */
    if (mentions.find((entry) => entry.email === id)) return;

    setMentions([
      ...mentions,
      { email: id.toString(), shareWith: true, shareReadWrite: true, notify: true },
    ]);
  };

  if (!user) return null;

  const showFull = comment || focused;

  return (
    <div
      className={isHovered || focused ? classes.createRoot : classes.createRootInactive}
      ref={hoverRef}>
      <MentionsInput
        placeholder={t('annotations.comments.mentionInputPlaceholder')}
        disabled={saving}
        onKeyDown={onKeyDownSaveIfCtrlEnter}
        onFocus={onFocus}
        data-e2e-id="add-comment-to-annotation-field"
        value={commentWithMentions}
        onChange={onChange}
        className="mentions"
        classNames={classNames}>
        <Mention
          className={classNames.mentions__mention}
          trigger="@"
          data={mentionSearch}
          appendSpaceOnAdd
          onAdd={onAdd}
        />
      </MentionsInput>

      {!saving && (
        <div style={{ marginTop: '10px' }}>
          {mentions.map((mention) => (
            <FormGroup key={mention.email} row className={classes.mentionFormGroup}>
              <FormControlLabel
                control={<span></span>}
                label={<span className={classes.mentionEmail}>{mention.email}</span>}
              />

              {/* Only shown if the user will receive at least read access to the project, otherwise
               * we would be sending an email and the user wouldn't be able to open the link!
               */}
              {mention.shareWith && (
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      size="small"
                      checked={mention.notify}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        const newMentions = mentions.map((m) => {
                          if (m.email === mention.email) {
                            m.notify = checked;
                          }
                          return m;
                        });
                        setMentions(newMentions);
                      }}
                      name={'notify-' + mention.email}
                    />
                  }
                  label={
                    <Tooltip title={t('annotations.comments.mentionNotifyViaEmail') || ''}>
                      <EmailIcon className={classes.mentionIcon} />
                    </Tooltip>
                  }
                />
              )}

              {/* Don't show if the user already has access to the project. */}
              {!project?.permissions.map((user) => user.user.email).includes(mention.email) && (
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      size="small"
                      checked={mention.shareWith}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        const newMentions = mentions.map((m) => {
                          if (m.email === mention.email) {
                            m.shareWith = checked;
                          }
                          return m;
                        });
                        setMentions(newMentions);
                      }}
                      name={'share-' + mention.email}
                    />
                  }
                  label={
                    <Tooltip title={t('annotations.comments.mentionGiveReadAccess') || ''}>
                      <VisibilityIcon className={classes.mentionIcon} />
                    </Tooltip>
                  }
                />
              )}

              {/* Don't show if the user will not receive at least read-only access of it the user
              already has access to the project. */}
              {mention.shareWith &&
                !project?.permissions.map((user) => user.user.email).includes(mention.email) && (
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        size="small"
                        checked={mention.shareReadWrite}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean,
                        ) => {
                          const newMentions = mentions.map((m) => {
                            if (m.email === mention.email) {
                              m.shareReadWrite = checked;
                            }
                            return m;
                          });
                          setMentions(newMentions);
                        }}
                        name={'share-' + mention.email}
                      />
                    }
                    label={
                      <Tooltip title={t('annotations.comments.mentionGiveWriteAccess') || ''}>
                        <CreateIcon className={classes.mentionIcon} />
                      </Tooltip>
                    }
                  />
                )}
            </FormGroup>
          ))}
        </div>
      )}

      {showFull && (
        <Box display="flex" justifyContent="flex-end" m={1}>
          <ButtonGroup aria-label="small outlined button group">
            <Button
              variant="outlined"
              color="default"
              onClick={onCancel}
              className={classes.cancelButton}>
              {t('annotations.comments.cancel')}
            </Button>
            <Button
              data-e2e-id="add-comment-to-annotation-button"
              variant="outlined"
              color="primary"
              disabled={comment.length === 0 || saving}
              onClick={onSave}>
              {t('annotations.comments.addComment')}
            </Button>
          </ButtonGroup>
        </Box>
      )}
    </div>
  );
}
