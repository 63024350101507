import { Button, TextField, withStyles } from '@material-ui/core';
import React, { useState } from 'react';

import Codefy from '../../../../../codefy';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useOpenDeleteAccountDialog } from '../../../../dialogs/delete/deleteAccountDialog';

// TODO: Add documentation

// eslint-disable-next-line react/display-name
export const DeleteAccountSettingContent = (): JSX.Element => {
  const user = useSelector((state: Codefy.State) => state.user);
  const { t } = useTranslation();
  const history = useHistory();
  const openDeleteAccountDialog = useOpenDeleteAccountDialog();

  const [email, setEmail] = useState('');
  const isAgreeButtonEnabled = email === user?.email;

  const cancelDeleteAccount = () => {
    history.push('/');
  };

  const DeleteButton = withStyles(() => ({
    root: {
      color: 'white',
      '&:disabled': {
        backgroundColor: 'white',
      },
      backgroundColor: red[500],
      '&:hover': {
        color: 'white',

        backgroundColor: red[700],
      },
    },
  }))(Button);

  return (
    <div>
      <Typography variant="subtitle1">
        {t('account.deleteAccount.dialogContent', {
          postProcess: 'markdown-jsx',
          email: user?.email || '',
        })}
      </Typography>

      <br />

      <TextField
        id="email"
        label={t('authScreen.email')}
        name="email"
        variant="outlined"
        autoComplete="email"
        fullWidth
        autoFocus
        error={!isAgreeButtonEnabled}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />

      <br />
      <br />

      <DeleteButton
        disabled={!isAgreeButtonEnabled}
        onClick={openDeleteAccountDialog({})}
        variant="outlined">
        {t('account.deleteAccount.confirmButton')}
      </DeleteButton>

      <Button
        onClick={cancelDeleteAccount}
        color="primary"
        variant="outlined"
        style={{ marginLeft: '5px' }}>
        {t('account.deleteAccount.disagreeButton')}
      </Button>
    </div>
  );
};
