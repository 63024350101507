import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import { useProjectsGet } from '../../../../controllers/api/subscriptions/projects/projectsGet';
import { useQueryParam_projectShare_projectId } from '../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

export default function ProjectsShareSettingsPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const { t } = useTranslation();
  const [projectShare_projectId] = useQueryParam_projectShare_projectId();
  const { data: project } = useProjectsGet(projectShare_projectId);

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={<ShareIcon />}
      title={t('projectShareSettingsPaneTitleBar.usersWithAccessToProject', {
        name: project?.name || '...',
      })}
      paneKey={PaneKeys.projectShareSettings}
    />
  );
}
