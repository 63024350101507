// TODO @jakub add documentation

import {
  ANNOTATIONS_LIST_KEY_TYPE,
  AnnotationsListRequestParams,
} from '../api/subscriptions/annotations/annotationsList';
import { AnyAction, Dispatch } from 'redux';

import { CACHE_INVALIDATE } from './websocketEventTypes';
import Codefy from '../../codefy';
import { UPLOAD_BATCHES_LIST_KEY_TYPE } from '../api/subscriptions/uploadBatches/uploadBatchesListBatches';
import { UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE } from '../api/subscriptions/uploadBatches/uploadBatchesListBatchEntries';
import { USERS_QUOTA_KEY_TYPE } from '../api/subscriptions/users/usersQuota';
import { documentsGetKey } from '../api/subscriptions/documents/documentsGet';
import { entriesListKey } from '../api/subscriptions/entries/entriesList';
import { projectsGetKey } from '../api/subscriptions/projects/projectsGet';
import { projectsListKey } from '../api/subscriptions/projects/projectsList';
import { queryCache } from 'react-query';

const processInvalidatedProjectIds = (
  cacheInvalidationAction: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (cacheInvalidationAction.project_ids) {
    /* Refresh projects list */
    queryCache.invalidateQueries(projectsListKey({}));

    cacheInvalidationAction.project_ids.forEach((projectId) => {
      /* Refresh project */
      queryCache.invalidateQueries(projectsGetKey(projectId));
    });

    /* Refresh quota information */
    queryCache.invalidateQueries(USERS_QUOTA_KEY_TYPE);
  }
};

const processInvalidatedDirectoryIds = (
  cacheInvalidateHandler: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (!cacheInvalidateHandler.directory_ids) return;

  /* Refresh entries list */
  for (const directory_id of cacheInvalidateHandler.directory_ids) {
    queryCache.invalidateQueries(entriesListKey({ directory_ids: [directory_id] }));
  }
};

const processInvalidatedEntryIds = (
  cacheInvalidateHandler: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (!cacheInvalidateHandler.entry_ids) return;

  /* Refresh entries list */
  // TODO: Optimize
  queryCache.invalidateQueries(entriesListKey({}));
};

const processInvalidatedDocumentIds = (
  cacheInvalidationAction: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (cacheInvalidationAction.document_ids) {
    cacheInvalidationAction.document_ids.forEach((documentId) => {
      /* Refresh document */
      queryCache.invalidateQueries(documentsGetKey(documentId));
      queryCache.invalidateQueries(UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE);
      queryCache.invalidateQueries(UPLOAD_BATCHES_LIST_KEY_TYPE);
    });
  }
};

const processInvalidatedDocumentPages = (
  cacheInvalidationAction: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (cacheInvalidationAction.document_pages) {
    cacheInvalidationAction.document_pages.forEach((documentPage) => {
      /* Refresh annotations */
      try {
        queryCache.invalidateQueries((query) => {
          if (query.queryKey[0] !== ANNOTATIONS_LIST_KEY_TYPE) return false;
          // TODO: Add comments

          const params = query.queryKey[1] as AnnotationsListRequestParams;
          if (!params.pages) return true;
          if (!params.document_ids) return true;
          if (params.document_ids && params.document_ids.includes(documentPage[0])) {
            return params.pages && params.pages.includes(documentPage[1]);
          } else {
            return false;
          }
        });
      } catch {
        /* we are using try since we are casting types which is dangerous */
      }
    });
  }
};

const processInvalidatedAnnotationIds = (
  cacheInvalidationAction: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (cacheInvalidationAction.annotation_ids) {
    /* Refresh annotation lists */
    const annotationsListQueries = queryCache.getQueries([ANNOTATIONS_LIST_KEY_TYPE]);
    annotationsListQueries.forEach((query) => {
      let shouldUpdate = false;
      try {
        const data = query.state.data as { annotations: Codefy.Objects.Annotation[] };
        data.annotations.forEach((annotation) => {
          if (cacheInvalidationAction.annotation_ids?.includes(annotation.id)) {
            shouldUpdate = true;
          }
        });
        if (shouldUpdate) {
          queryCache.invalidateQueries(query.queryKey);
        }
      } catch {
        /* we are using try since we are casting types which is dangerous */
      }
    });
  }
};

/** Listens for cache invalidate notifications and invalidates the relevant caches so
 * that they are refreshed. */
export const cacheInvalidateHandler = () => (next: Dispatch) => (action: AnyAction): AnyAction => {
  /* Ignore redux action if not a websocket message */
  if (action?.type !== 'REDUX_WEBSOCKET::MESSAGE') {
    return next(action);
  }

  /* Ignore websocket message if not a 'cache_invalide' message */
  const parsed = JSON.parse(action.payload.message) as Codefy.Objects.Notification;
  if (parsed.type !== CACHE_INVALIDATE) {
    return next(action);
  }

  const cacheInvalidationAction = parsed.action as Codefy.Notifications.Payloads.CacheInvalidate;

  /* The following functions all execute actions necessary to refresh the local cached data,
  depending on what has been marked as invalidated (= stale/old) */
  [
    processInvalidatedProjectIds,
    processInvalidatedDirectoryIds,
    processInvalidatedEntryIds,
    processInvalidatedDocumentIds,
    processInvalidatedDocumentPages,
    processInvalidatedAnnotationIds,
  ].forEach((invalidationProcessor) => invalidationProcessor(cacheInvalidationAction));

  return next(action);
};
