import { Typography, makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { UsersQuotaResponse } from '../../../../controllers/api/subscriptions/users/usersQuota';
//@ts-ignore
import humanFormat from 'human-format';
import { useTranslation } from 'react-i18next';

export const hoverPointerstyle: React.CSSProperties = {
  cursor: 'pointer',
  color: 'black',
  opacity: 0.7,
};

export const listItemIconStyle: React.CSSProperties = {
  marginRight: '-15px',
};

export const useStyles = makeStyles((theme) => ({
  num_entries: {
    opacity: 0.5,
  },
  hiddenList: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  divider: {},
  indented: {
    marginLeft: '20px',
    marginTop: '10px',
  },
}));

export function QuotaBars({ quota }: { quota: UsersQuotaResponse }) {
  const { t } = useTranslation();
  const filesPercentage = quota ? quota?.file / quota?.file_quota : 0;
  const diskPercentage = quota ? quota?.disk / quota?.disk_quota : 0;
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Typography noWrap variant="subtitle2">
          {quota?.file_quota && (
            <>
              {t('account.quota.Files')}
              <br />
            </>
          )}
          {quota.disk_quota && t('account.quota.disk')}
        </Typography>
      </Box>
      <Box width="100%" mr={1}>
        {quota?.file_quota && (
          <>
            <LinearProgress variant="determinate" value={filesPercentage * 100} />
            <br />{' '}
          </>
        )}
        {quota?.disk_quota && <LinearProgress variant="determinate" value={diskPercentage * 100} />}
      </Box>
      <Box mr={1}>
        <Typography noWrap variant="subtitle2">
          {quota?.file_quota && (
            <>
              {quota ? `${quota.file}/${quota.file_quota}` : <Skeleton variant="rect" width={50} />}{' '}
              {t('account.quota.files')}
              <br />
            </>
          )}
          {quota?.disk_quota && (
            <>
              {quota ? (
                `${Math.round(humanFormat.raw(quota.disk, { unit: 'B' }).value)}
                 ${humanFormat.raw(quota.disk, { unit: 'B' }).prefix}B
                 ${' /'}
                 ${Math.round(humanFormat.raw(quota.disk_quota, { unit: 'B' }).value)}`
              ) : (
                <Skeleton variant="rect" width={50} />
              )}{' '}
              {quota
                ? humanFormat.raw(quota.disk_quota, {
                    unit: 'B',
                  }).prefix
                : ''}
              B
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
}
