import { GenericTagDialog, GenericTagDialogOnDone } from '../genericTagDialog';
import React, { useState } from 'react';

import Codefy from '../../../codefy';
import { tagsCreate } from '../../../controllers/api/actions/taglists/tags/tagsCreate';
import { useBetween } from 'use-between';
import { useTaglistsGet } from '../../../controllers/api/subscriptions/taglists/taglistsGet';

type DialogCreateTagStateType = {
  taglistId: Codefy.Objects.Taglist['id'];
};

const useDialogCreateTagState = () => useState<DialogCreateTagStateType>();

const useSharedDialogCreateTagState = () => useBetween(useDialogCreateTagState);

export const CreateTagDialog = () => {
  const [state, setState] = useSharedDialogCreateTagState();

  const { data: taglist } = useTaglistsGet({ taglist_id: state?.taglistId });

  const onClose = () => setState(undefined);

  const onCreate: GenericTagDialogOnDone = (props) => {
    if (!state?.taglistId) return;
    tagsCreate({ ...props, taglist_id: state?.taglistId });
  };

  if (!taglist) return null;

  return (
    <GenericTagDialog
      taglistType={taglist.type}
      onDone={onCreate}
      onClose={onClose}
      possibleParents={taglist.tags}
    />
  );
};

export const useOpenCreateTagDialog = () => {
  const [, setState] = useSharedDialogCreateTagState();
  return (state: DialogCreateTagStateType) => () => setState(state);
};
