import Annotation from './annotation';
import React from 'react';
import { useAnnotationsGet } from '../../../../controllers/api/subscriptions/annotations/annotationsGet';
import { useQueryParam_annotation_id } from '../../../../controllers/useGlobalQueryParams';

/** A wrapper that loads the annotation from the backend and provides it to the <Annotation>
 * component, which does not by itself load anything from the backend */
export default function AnnotationPaneContentWrapper() {
  const [annotation_id] = useQueryParam_annotation_id();
  const { data: annotation } = useAnnotationsGet({ annotation_id: annotation_id });

  if (!annotation) return null;

  return <Annotation annotation={annotation} groupContinuation={false} />;
}
