import Codefy from '../../../../codefy';
import { fastApiParamsSerializer } from '../../../../controllers/api/subscriptionHelpers';

/** A short and pretty url that the user can share with others that redirect to the annotation.
 * Looks like this: https://test.codefy.de/share/annotation/123 */
export default function getSearchResultShareUrl(searchResult: Codefy.Objects.SearchResult): string {
  /** The server part of the URL, where the frontend is running, e.g. https://test.codefy.de/ */
  const baseUrl = window.location.protocol + '//' + window.location.host;

  const params = {
    panes: ['pdfViewer'],
    pdfViewer_documentId: searchResult.document.id,
    pdfViewer_page: searchResult.highlight.boxes[0].page,
    pdfViewer_boxes: JSON.stringify(searchResult.highlight.boxes),
    search_selectedResultPartId: searchResult.part_id,
  };

  /** The final url that the user can share with others */
  const shareUrl = baseUrl + '/?' + fastApiParamsSerializer(params);
  return shareUrl;
}
