import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../../subscriptions/annotations/annotationsList';

import Codefy from '../../../../../codefy';
import { TAGLISTS_GET_KEY_TYPE } from '../../../subscriptions/taglists/taglistsGet';
import { TAGS_GET_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { executeAction } from '../../actionHelpers';

export const tagsRename = (data: { tag_id: Codefy.Objects.Tag['id']; name: string }) =>
  executeAction<Codefy.Objects.Tag>({
    config: { url: '/api/v1/taglists/tags/rename', method: 'POST' },
    data,
    queryKeysToRefresh: [
      TAGLISTS_GET_KEY_TYPE,
      TAGS_LIST_KEY_TYPE,
      TAG_INSTANCES_LIST_KEY_TYPE,
      TAGS_GET_KEY_TYPE,
      ANNOTATIONS_LIST_KEY_TYPE,
      ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
    ],
  });
