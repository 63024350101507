import React, { useCallback } from 'react';

import { Dialog } from '@material-ui/core';
import { modalTypes } from './modalTypes';
import { useQueryParam_modal } from '../../controllers/useGlobalQueryParams';

export function Modal() {
  const [modal, set_modal] = useQueryParam_modal();

  const onCloseModal = useCallback(() => {
    set_modal(undefined);
  }, []);

  return (
    <Dialog hideBackdrop fullWidth open={!!modal} onClose={onCloseModal} disablePortal>
      {modal && modalTypes?.[modal]}
    </Dialog>
  );
}
