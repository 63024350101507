import { deDE, enUS } from '@material-ui/core/locale';

export const langMUMapping = {
  eng: enUS,
  deu: deDE,
};

export const langMomentMapping = {
  eng: 'en',
  deu: 'de',
};
