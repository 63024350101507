import {
  useUserSetting_tag_descending,
  useUserSetting_tag_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import { Alert } from '@material-ui/lab';
import { COLORS } from '../../../../globalThemeSettings';
import Codefy from '../../../../codefy';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import TagInstance from './tagInstance';
import { makeStyles } from '@material-ui/core';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import { useMeasure } from 'react-use';
import { useQueryParam_tag_tagId } from '../../../../controllers/useGlobalQueryParams';
import { useTagInstancesList } from '../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';

const useStyles = makeStyles(() => ({
  /** The overflow is very important, otherwise you cannot have more entries than the height of the
   * screen! */
  root: { height: '100%' },
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
}));

export default function Tag({ taglistType }: { taglistType: Codefy.Objects.Taglist['type'] }) {
  const classes = useStyles();

  const [tag_tagId] = useQueryParam_tag_tagId({ taglistType });
  const [tag_descending] = useUserSetting_tag_descending();
  const [tag_orderBy] = useUserSetting_tag_orderBy();
  const { id: currentCaseId } = useCurrentCase();

  const { data: tag } = useTagsGet(tag_tagId);

  const { data: taglist } = useTaglistsGet({ taglist_id: tag?.taglist_id });

  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [refAlert, { height: heightAlert }] = useMeasure<HTMLDivElement>();

  const {
    data: tagInstancesPages,
    fetchMore,
    canFetchMore,
    isFetchedAfterMount,
  } = useTagInstancesList({
    tag_ids: tag_tagId ? [tag_tagId] : undefined,
    case_ids: currentCaseId ? [currentCaseId] : undefined,
    order_by: tag_orderBy,
    descending: tag_descending,
  });

  const tagInstances =
    tagInstancesPages?.reduce((acc: Codefy.Objects.TagInstance[], cur) => {
      for (const tagInstance of cur.tag_instances) {
        acc.push(tagInstance);
      }
      return acc;
    }, []) || [];

  if (!tag) return null;

  const renderTagInstance = (index: number) => {
    if (!tagInstances || !tagInstances[index]) return <span></span>;

    return (
      <TagInstance
        project_id={taglist?.path.project_id}
        key={tagInstances[index].id}
        tag={tag}
        tagInstance={tagInstances[index]}
      />
    );
  };

  if (!isFetchedAfterMount && tagInstances.length === 0) return <PaneContentLoading />;

  return (
    <div className={classes.root} ref={ref}>
      {tag.description && (
        <Alert
          ref={refAlert}
          severity="info"
          style={{ backgroundColor: COLORS.backgroundLight, borderRadius: '0px' }}>
          {tag.description}
        </Alert>
      )}

      <InfiniteScroll
        className={classes.virtuoso}
        dataLength={tagInstances.length || 0}
        next={fetchMore}
        height={height - (tag.description ? heightAlert : 0) - 10}
        hasMore={!!canFetchMore}
        loader={<PaneContentLoading />}>
        {tagInstances.map((_, index) => renderTagInstance(index))}
      </InfiniteScroll>
    </div>
  );
}
