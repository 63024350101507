import Codefy from '../codefy';
import { useSelector } from 'react-redux';

/** Check if the  user is currently an anonymous viewer of a publicly shared project. */
const useIsEveryone = (): boolean => {
  const userId = useSelector((state: Codefy.State) => state.user?.id);

  const isEveryone = !userId;

  return isEveryone;
};

export default useIsEveryone;
