import Codefy from '../../../../codefy';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { executeAction } from '../actionHelpers';

export const taglistsTagTemplate = (data: {
  name: Codefy.Objects.Entry['name'];
  template: File;
  directory_id: Codefy.Objects.Directory['id'];
}) =>
  executeAction<Codefy.Objects.Taglist>({
    config: { url: '/api/v1/taglists/tag_template', method: 'POST' },
    data,
    queryKeysToRefresh: [ENTRIES_LIST_KEY_TYPE],
  });
