import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';
import { QueryKey, useQuery } from 'react-query';

type LayoutsGetRequestParams = {
  document_id: Codefy.Objects.Document['id'];
  page: number;
};

export type LayoutsGetReturnType = Codefy.Objects.LayoutPart[];

export const LAYOUTS_GET_KEY_TYPE = 'layoutsGet';

export const layoutsGetKey = (params: LayoutsGetRequestParams): QueryKey => [
  LAYOUTS_GET_KEY_TYPE,
  params.document_id,
  params.page,
];

export const useLayoutsGet = (params: LayoutsGetRequestParams) =>
  useQuery(
    layoutsGetKey(params),
    async (key: string, document_id: Codefy.Objects.Document['id'], page: number) => {
      const { data } = await axios.get('/api/v1/parts/layout', {
        params: { page: page, document_id: document_id },
      });
      return data as LayoutsGetReturnType;
    },
  );
