import { createStyles, makeStyles } from '@material-ui/core';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() =>
  createStyles({
    listItemIcon: { marginRight: '-23px', marginBottom: '-3px' },
  }),
);
// eslint-disable-next-line react/display-name
const AutocompletePlaceholder = () => {
  const classes = useStyles();

  return (
    <ListItem button>
      <ListItemIcon className={classes.listItemIcon}>
        <SearchIcon />
      </ListItemIcon>

      <ListItemText primary={<Skeleton variant="text" width={250} />} />
    </ListItem>
  );
};

export default AutocompletePlaceholder;
