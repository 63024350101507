import { BOX_SHADOWS, COLORS, componentTypeStyles } from '../globalThemeSettings';
import { List, ListItem, ListItemIcon, Slide, Tooltip, makeStyles } from '@material-ui/core';
import {
  useQueryParam_focusedPane,
  useQueryParam_panes,
} from '../controllers/useGlobalQueryParams';

import { PaneKeys } from './panes/paneTypes/paneKeys';
import React from 'react';
import { connectedPanes } from './panes/usePaneConnected';
import { paneTypes } from './panes/paneTypes/paneTypes';
import useIsEveryone from '../hooks/useIsEveryone';
import { usePaneActions } from './panes/usePaneActions';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: '5px',
    width: '60px',
    zIndex: 10,
    position: 'fixed',
    height: 'calc(100vh - 77px)',

    boxShadow: BOX_SHADOWS.small,
    borderRadius: '0px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    backgroundColor: COLORS.background,
    border: componentTypeStyles.uiElement.border,
    maxHeight: 'calc(100vh - 77px)',
  },
  root: {},
  listItem: {
    height: '60px',
  },
  listItemIcon: {
    marginLeft: '0px',
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const paneActions = usePaneActions();

  const [panes] = useQueryParam_panes();
  const [focusedPane] = useQueryParam_focusedPane();
  const isEveryone = useIsEveryone();

  const openPaneTypes = paneTypes
    .filter((pane) => panes?.includes(pane.key))
    .filter((pane) => pane.key !== PaneKeys.tutorials)
    .filter((pane) => !connectedPanes.map((p) => p[1]).includes(pane.key))
    .filter((pane) => !(pane.needsLogin && isEveryone));
  return (
    <Slide in direction="right">
      <div className={classes.paper}>
        <div className={classes.root}>
          <List disablePadding>
            {openPaneTypes.map((pane) => (
              <Tooltip title={pane.title} enterDelay={1000} key={pane.key}>
                <ListItem
                  key={pane.key}
                  className={classes.listItem}
                  button
                  dense
                  divider
                  selected={focusedPane === pane.key}
                  onClick={() => paneActions.focusPane(pane.key)}>
                  <ListItemIcon className={classes.listItemIcon}>{pane.icon}</ListItemIcon>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </div>
      </div>
    </Slide>
  );
}
