import axios, { AxiosResponse } from 'axios';

import Codefy from '../../../../codefy';
import { ThunkDispatch } from 'redux-thunk';
import { fastApiDataSerializer } from '../../subscriptionHelpers';
import store from '../../../store';

export const userChangeSettings = (
  settingsChanges: Codefy.API.Users.ChangeSettings.RequestData['settings'],
) => {
  return async (
    dispatch: ThunkDispatch<Codefy.State, undefined, Codefy.API.Users.ChangeSettings.Action>,
  ) => {
    const user = store.getState().user;

    const currentSettings = user?.settings ?? {};
    const newSettings = { ...currentSettings, ...settingsChanges };

    const config: Codefy.API.Users.ChangeSettings.RequestConfig = {
      url: '/api/v1/users/settings',
      method: 'POST',
      data: fastApiDataSerializer({ settings: newSettings }),
    };

    type Response = Codefy.API.Users.ChangeSettings.Response;
    const response: AxiosResponse<Response> = await axios(config);

    dispatch({
      type: 'ChangeSettings',
      user: response.data,
    });
  };
};
