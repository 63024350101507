import { applyMiddleware, compose, createStore } from 'redux';

import Codefy from '../codefy';
import { cacheInvalidateHandler } from './websocket/cacheInvalidateHandler';
import createRootReducer from './reducers';
import errorHandler from './websocket/errorHandler';
import history from './history';
import logger from 'redux-logger';
import { popupHandler } from './websocket/popupHandler';
import reduxWebsocket from '@giantmachines/redux-websocket';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

/** The initial state, when the application starts */
export const initialState: Codefy.State = {
  search: {
    open: false,
    query: '',
    searchbarArrowKeyPosition: 0,
  },
  uploads: [],
  userLoadingCompleted: false,
};

const middlewares = [
  /* For dispatching history actions */
  routerMiddleware(history),
  /* For dispatching async actions */
  thunk,
  /* For dispatching websocket messages */
  reduxWebsocket({ reconnectOnClose: false /* seems to be broken, so we handle it ourselves */ }),
  popupHandler,
  cacheInvalidateHandler,
  errorHandler,
];

/* Only log in development */
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const store = createStore(
  /* Root reducer with router state */
  createRootReducer(history),
  initialState,
  compose(applyMiddleware(...middlewares)),
);

export default store;
