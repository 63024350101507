import Codefy from '../../../../codefy';
import { DIRECTORIES_GET_KEY_TYPE } from '../../subscriptions/directories/directoriesGet';
import { DIRECTORIES_PARENTS_KEY_TYPE } from '../../subscriptions/directories/directoriesParents';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { executeAction } from '../actionHelpers';

export const projectsRename = (data: {
  project_id: Codefy.Objects.Project['id'];
  name: Codefy.Objects.Project['name'];
}) =>
  executeAction<Codefy.Objects.Project>({
    config: { url: '/api/v1/projects/rename', method: 'POST' },
    data,
    queryKeysToRefresh: [
      PROJECTS_LIST_KEY_TYPE,
      DIRECTORIES_GET_KEY_TYPE,
      DIRECTORIES_PARENTS_KEY_TYPE,
    ],
  });
