/** This file contains global design-related constants */

import { ThemeOptions, makeStyles } from '@material-ui/core';

import { red } from '@material-ui/core/colors';

export const FONT_SIZES = {
  /** all text should use this!*/
  default: '11pt',
  /** ...expect for very very few elements if there is a very good reason, like the search bar text,
   * since UI research shows this is better? */
  large: '12pt',
};

export const COLORS = {
  /** "Normal" background */
  background: 'white',
  /** The furthermost background, i.e. the "desk" where everything lies on */
  backgroundLight: '#F8F8F8',
  primary: '#003366',
  primaryLight: '#d8e4ed',
  /** Things like the search icon in the search bar, dividers */
  uiSkeleton: '#E9E9E9',
  uiSkeletonLight: '#E9E9E9AA',
  /** Default text, such as search result text */
  text: '#131415',
  /** Secondary text that is not supposed to be that visible, e.g. search result headings */
  textLight: '#616161AA',
  /** Used e.g. for boxShadow */
  shadow: '#00000044',
  /** Used for highlighting certain parts of words, e.g. in the searchDropdown */
  textHighlightBackground: '#BDD0F7',
  /** Color used for text or graphics on top of tag labels (which have a dark color) */
  tagLabelForegroundColor: '#003366',
  defaultTagColor: '#FDF59D',
  delete: red['200'],
};

export const BOX_SHADOWS = {
  small: `0px 0px 5px ${COLORS.shadow}`,
  medium: `2px 2px 10px ${COLORS.shadow}`,
};

export const BORDER_RADIUSES = {
  tiny: '5px',
  small: '10px',
  huge: '25px',
};

const baseTypographyStyle = {
  fontSize: FONT_SIZES.default,
  lineHeight: 1.5,
  color: COLORS.text,
  fontWeight: 'normal',
  textAlign: 'left',
} as const;

export const TAG_LABEL_HEIGHT = 22;

export const componentTypeStyles = {
  text: {
    ...baseTypographyStyle,
  },
  textLight: {
    ...baseTypographyStyle,
    color: COLORS.textLight,
  },
  textPrimary: {
    ...baseTypographyStyle,
    color: COLORS.primary,
  },
  heading: {
    ...baseTypographyStyle,
    color: COLORS.primary,
    fontWeight: 'bold',
  },
  /** Used for special text part such as the search term */
  accent: {
    ...baseTypographyStyle,
    fontStyle: 'italic',
  },
  /** Used e.g. for separating searchDropdown parts ("search suggestions", "folders", ...) */
  subheading: {
    ...baseTypographyStyle,
    color: COLORS.textLight,
    textTransform: 'uppercase',
  },
  unselectable: {
    userSelect: 'none',
  },

  listEntry: {
    ...baseTypographyStyle,
    color: COLORS.primary,
  },
  listEntryLight: {
    ...baseTypographyStyle,
    color: COLORS.textLight,
  },
  icon: {
    color: COLORS.primary,
  },
  /** Discernible UI component, e.g. a pane, a menu */
  uiElement: {
    background: COLORS.background,
    borderRadius: BORDER_RADIUSES.small,
    border: undefined,
    boxShadow: BOX_SHADOWS.small,
  },
  /** A UI component that is supposed to look higher than others, e.g. the opened searchDropdown */
  uiElementHigh: {
    background: COLORS.background,
    borderRadius: BORDER_RADIUSES.small,
    boxShadow: BOX_SHADOWS.medium,
  },
  uiElementBackground: {
    background: COLORS.backgroundLight,
  },
  cancelButton: {
    color: COLORS.textLight,
  },
  verticalIconDivider: {
    width: '1px',
    height: '20px',
    marginRight: '5px',
    marginLeft: '5px',
    backgroundColor: COLORS.uiSkeleton,
  },
  visibleDivider: {
    backgroundColor: COLORS.uiSkeleton,
    height: '1px',
    width: '100%',
  },
  /** To override MUIs margin to the right of list icons (when necessary, not always, therefore it
   * is not an ovverride in GLOBAL_MUI_THEME) */
  narrowListItemIcon: {
    minWidth: '40px',
    width: '40px',
  },
  /** Used for some icons */
  icon22: {
    fontSize: '22px',
  },
  icon24: {
    fontSize: '24px',
  },
  /** Used for context menus that should only appear when hovered over, and should float to the right */
  floatRightVisible: {
    float: 'right',
  },
  floatRightHidden: {
    float: 'right',
    opacity: 0,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  noUppercase: {
    textTransform: 'none',
  },
  link: {
    color: COLORS.primary,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  /** Used for the "..." button in the pdfViewer */
  largeText: { fontSize: '120%' },
  invisible: { opacity: 0 },
  /** The box that is shown in the pdf viewer above a text that has a highlight */
  annotationBox: {
    cursor: 'pointer',
    position: 'absolute',
    mixBlendMode: 'darken',
  },
  tagLabel: {
    zIndex: 501,
    height: TAG_LABEL_HEIGHT,
    border: '1px solid #00336600',
    '&:hover': {
      border: '1px solid #003366',
    },
    borderRadius: BORDER_RADIUSES.tiny,
  },
  tagLabelSelected: {
    border: '1px solid #003366',
  },
  /** Used for text written on tagLabels */
  tagLabelForegroundText: {
    color: COLORS.tagLabelForegroundColor,
  },
  tagLabelDeleteIcon: {
    color: '#00000038',
    '&:hover': {
      color: '#00000055',
    },
  },
  dialogButton: {
    ...baseTypographyStyle,
    color: COLORS.primary,
    fontWeight: 'bold',
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
} as const;

export const useGlobalStyles = makeStyles(componentTypeStyles);

export const GLOBAL_MUI_THEME: ThemeOptions = {
  typography: {
    body1: {
      fontSize: FONT_SIZES.default,
    },
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: { main: COLORS.primary },
    action: {
      selected: COLORS.primaryLight,
      hover: COLORS.primaryLight,
    },
    divider: COLORS.uiSkeletonLight,
  },

  shape: {
    borderRadius: 5,
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '18pt',
        color: COLORS.primary,
      },
    },

    MuiListItemText: {
      primary: componentTypeStyles.listEntry,
    },
    MuiPopover: {
      root: {
        // override popover z-index to show it in front of pdf viewer toolbar
        // @ts-ignore
        zIndex: '10001 !important',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: COLORS.backgroundLight,
        },
      },
    },
  },
};
