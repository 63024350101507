import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../../subscriptions/annotations/annotationsList';
import { ANNOTATIONS_GET_KEY_TYPE } from '../../../subscriptions/annotations/annotationsGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { TAGS_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsList';
import Codefy from '../../../../../codefy';
import { executeAction } from '../../actionHelpers';

export const commentsCreate = async (data: {
  annotation_id: Codefy.Objects.Annotation['id'];
  text: string;
  /** Notify these users via email about the comment. Users must have access to the project */
  emails?: string[];
}) =>
  executeAction<Codefy.Objects.AnnotationComment>({
    config: { url: '/api/v1/comments/create', method: 'POST' },
    data,
    queryKeysToRefresh: [
      ANNOTATIONS_LIST_KEY_TYPE,
      ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
      ANNOTATIONS_GET_KEY_TYPE,
      TAG_INSTANCES_LIST_KEY_TYPE,
      TAGS_LIST_KEY_TYPE,
    ],
  });
