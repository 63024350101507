import React, { useEffect } from 'react';

import { Beforeunload } from 'react-beforeunload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Codefy from '../../../../codefy';
import { DocumentStatus } from '../../../../controllers/api/actions/documents/documentsUpload';
import { IconButton } from '@material-ui/core';
import { PaneKeys } from '../paneKeys';
import { usePaneActions } from '../../usePaneActions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function UploadsButton() {
  const { t } = useTranslation();
  const paneActions = usePaneActions();
  const uploads = useSelector((state: Codefy.State) => state.uploads);
  const uploading = uploads.filter((upload) => upload.status === DocumentStatus.uploading);
  const isPreparingUploads = useSelector((state: Codefy.State) => state.isPreparingUploads);

  const openUploadsPane = () => {
    if (uploading) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.uploadBatches,
      });
    }
  };

  useEffect(() => {
    if (uploads.length > 0 || isPreparingUploads) {
      openUploadsPane();
    }
  }, [uploads.length, isPreparingUploads]);

  const button = (
    <IconButton aria-label="uploads" edge="end" color="inherit" onClick={openUploadsPane}>
      <CloudUploadIcon />
    </IconButton>
  );

  if (uploading.length > 0) {
    return (
      <Beforeunload onBeforeunload={() => t('uploadsBar.stillUploading')}>{button}</Beforeunload>
    );
  } else {
    return button;
  }
}
