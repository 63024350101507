import {
  useQueryParam_entriesList_directoryId,
  useQueryParam_searchBar_directoryId,
  useQueryParam_searchBar_documentId,
} from '../../controllers/useGlobalQueryParams';

import { useEffect } from 'react';

/** Adjusts the search scope to follow the latest action of the user, i.e. if the user opens a
 * document, set the search scope to that document, if the user switches to another directory, show
 * search results from that directory */
export function FollowSearchScopeListener() {
  const [, set_searchBar_directoryId] = useQueryParam_searchBar_directoryId();
  const [, set_searchBar_documentId] = useQueryParam_searchBar_documentId();
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();

  useEffect(() => {
    if (!entriesList_directoryId) return;
    set_searchBar_documentId(undefined, 'pushIn');
    set_searchBar_directoryId(entriesList_directoryId, 'pushIn');
  }, [entriesList_directoryId]);

  // Note that we only follow when the search directory changes, and not when the user opens a pdf
  // document, as that was deemed annoying most of the time.

  return null;
}
