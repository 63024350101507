import { QueryKey, queryCache } from 'react-query';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Cancel } from 'axios';
import { fastApiDataSerializer, handleAuthError } from '../subscriptionHelpers';

import Codefy from '../../../codefy';
import i18 from '../../../i18n/i18n';
import { toast } from 'react-toastify';

/** Some actions might cause an error in the backend, for example trying to rename
 * a project in a way that's not allowed. We should show these error messages to the
 * user so he gets feedback about his actions. */
export const getErrorText = (errorMessage: Codefy.API.ErrorMessage | undefined) => {
  if (!errorMessage) return;
  // translation key, fallback message and params for the error message
  const { detail, fallback, params } = errorMessage;
  // in case translation key for error message exists set to that message
  // otherwise use fallback alternative with or without params,
  // and add that translation (english) in case a fallback message exists
  // finally, if none of above is the case set to generic error message
  if (!i18.exists(`backendErrorCodes.${detail}`) && fallback) {
    i18.addResource('eng', 'translation', `backendErrorCodes.${detail}`, fallback);
  }
  return i18.t([`backendErrorCodes.${detail}`, 'backendErrorCodes.somethingWentWrong'], params);
};

export const handleActionError = (errorMessage: Codefy.API.ErrorMessage | undefined) => {
  // extract the error message
  const errorText = getErrorText(errorMessage);
  // show the error to the user
  toast.error(errorText);
};

/** Helper function that returns the response.data returned from an axios call, checked
 * for auth errors, and serializes data on the second parameter before passing it to
 * the axios `config` object */
export async function executeAction<T>({
  config,
  data,
  queryKeysToRefresh,
  showToastOnError = true,
  onError,
}: {
  config: AxiosRequestConfig;
  /** Data is a separate parameter (instead of just putting it in the config) to make
   * clearer that we are not just passing the `config` object directly to axios, but
   * instead are doing the `data` serialization */
  data: any;
  /** Which querykeys to refresh after a successful action */
  queryKeysToRefresh?: QueryKey[];
  /** Should an error notification be shown if an error occurs? */
  showToastOnError?: boolean;
  /** An optional error handler */
  onError?: (error: AxiosError | Cancel) => void;
}) {
  try {
    const response: AxiosResponse<T> = await axios({
      ...config,
      data: fastApiDataSerializer(data),
    });

    queryKeysToRefresh?.forEach((queryKey) =>
      queryCache.invalidateQueries(
        queryKey,
        /** This is the default, but we still write it to be more explicit. Since these days we are
         * invalidating very broadly (mostly without filtering whether e.g. the directory ID matches
         * the current directory ID, instead we just reload without caring if it's really necessary;
         * all this is to simplify the code and have less bugs), at least we want to only invalidate
         * what's actually being shown to the user. Otherwise, if the user has clicked around a lot
         * (and a lot of cached queries exist), we could DDOS the backend. */
        { refetchActive: true, refetchInactive: false },
      ),
    );

    return response.data;
  } catch (error) {
    handleAuthError(error?.response?.status);
    if (showToastOnError) {
      handleActionError(error?.response?.data);
    }
    if (onError) {
      onError(error);
    }
  }
}
