export default `
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

html {
  font-size: 1.3em;
  color: #000000dd;

  padding: 3em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  line-height: 2;
  text-align: justify;
  letter-spacing: 0.003em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Crimson Text";
  color: #000000dd;
}
body {
  font-family: "Open Sans";
  margin: "5cm 3cm 5cm 3cm";
}
`;
