import { QueryKey, useInfiniteQuery } from 'react-query';
import axios, { AxiosRequestConfig } from 'axios';

import Codefy from '../../../../codefy';
import { fastApiDataSerializer } from '../../subscriptionHelpers';
import stringify from 'json-stable-stringify';

export type SearchSearchRequestParams = {
  query: string;
  directory_ids?: Codefy.Objects.Directory['id'][];
  recursive?: boolean;
  document_ids?: Codefy.Objects.Document['id'][];
  languages?: Codefy.Objects.LanguageType[];
  text_snippet_len?: number;
  offset?: number;
  limit?: number;
  attributes?: any;
  /** Used by the backend for statistics */
  autocomplete?: boolean;
  method?: 'insta' | 'trigram';
};

export type SearchSearchReturnType = {
  query: string;
  results: Codefy.Objects.SearchResult[];
  facets_key?: string;
  method: Codefy.Objects.SearchMethod;
  debug?: Codefy.Objects.SearchDebug;
  offset: number;
};

export const SEARCH_SEARCH_KEY_TYPE = 'searchSearch';

export const searchSearchKey = (params: any): QueryKey => [
  SEARCH_SEARCH_KEY_TYPE,
  stringify(params),
];

export const useSearchSearch = (params: SearchSearchRequestParams, keepPreviousData?: boolean) => {
  const limit = params.limit || 20;

  const queryFn = async (
    _: any,
    __: any,
    offset = 0,
  ): Promise<SearchSearchReturnType & { offset: number }> => {
    if (!params.query) {
      return {
        query: '',
        results: [],
        method: params.method || 'insta',
        offset,
      };
    }

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/api/v1/search/search',
      data: fastApiDataSerializer({
        ...params,
        /** Trim space (e.g. "term "), to reduce requests sent to backend and fix some weird
         * inconsistencies the backend has between "term" and "term " (it gives slightly different results) */
        query: params.query.trim(),
        offset,
        limit,
      }),
    };

    const { data } = await axios(config);
    return { ...data, offset } as SearchSearchReturnType & { offset: number };
  };

  const getFetchMore = (lastPage: SearchSearchReturnType & { offset: number }) => {
    try {
      if (lastPage.results.length === 0) return false;
      return lastPage.offset + limit;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return useInfiniteQuery(searchSearchKey(params), queryFn, { getFetchMore, keepPreviousData });
};
