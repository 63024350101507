import Codefy from '../../../../codefy';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export function useUploadBatchesName(uploadBatch: Codefy.Objects.UploadBatch): string {
  const { t } = useTranslation();

  return uploadBatch.created_at
    ? t('uploadBatch.uploadBatch', {
        count: uploadBatch.num_documents,
        when: moment(uploadBatch.created_at).fromNow(),
      })
    : t('uploadBatch.uploadBatchJustNow', {
        count: uploadBatch.num_documents,
      });
}
