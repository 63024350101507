import Codefy from '../../../../codefy';
import { DIRECTORIES_PARENTS_KEY_TYPE } from '../../subscriptions/directories/directoriesParents';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { executeAction } from '../actionHelpers';

export const entriesMove = (data: {
  entry_ids: Codefy.Objects.Entry['id'][];
  directory_id: Codefy.Objects.Directory['id'];
}) =>
  executeAction<Codefy.Objects.Entry>({
    config: { url: '/api/v1/entries/move', method: 'POST' },
    data,
    queryKeysToRefresh: [
      ENTRIES_LIST_KEY_TYPE,
      PROJECTS_LIST_KEY_TYPE,
      DIRECTORIES_PARENTS_KEY_TYPE,
    ],
  });
