import { DocumentStatus } from '../../../../controllers/api/actions/documents/documentsUpload';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { useQueryParam_uploadBatch_status } from '../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

const useGetUploadBatchStatusExplanation = (status?: DocumentStatus): string => {
  const { t } = useTranslation();
  if (status === undefined) return '';

  switch (status) {
    case DocumentStatus.error_format_not_supported:
      return t('uploadBatch.statusExplanations.errorFormatNotSupported');
    case DocumentStatus.error_quota_exceeded:
      return t('uploadBatch.statusExplanations.errorQuotaExceeded');
    case DocumentStatus.error_upload_failed:
      return t('uploadBatch.statusExplanations.uploadFailed');
    case DocumentStatus.failed:
      return t('uploadBatch.statusExplanations.failed');
    case DocumentStatus.processed:
      return t('uploadBatch.statusExplanations.processed');
    case DocumentStatus.unprocessed:
      return t('uploadBatch.statusExplanations.unprocessed');
    case DocumentStatus.uploading:
      return t('uploadBatch.statusExplanations.uploading');
    case DocumentStatus.uploading_cancelled:
      return t('uploadBatch.statusExplanations.uploading_cancelled');
    case DocumentStatus.storing:
      return t('uploadBatch.statusExplanations.storing');
    default:
      return '';
  }
};

export default function UploadBatchPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const [uploadBatch_status] = useQueryParam_uploadBatch_status();
  const statusExplanation = useGetUploadBatchStatusExplanation(uploadBatch_status);

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={<span></span>}
      title={''}
      paneKey={PaneKeys.uploadBatch}
      statusBar={statusExplanation}
    />
  );
}
