import { COLORS } from '../../globalThemeSettings';
import React from 'react';

export default function PdfViewerRightIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path fill={COLORS.primary} d="M 9,19 H 19 V 4 H 9 Z M 4,17 H 8 V 6 H 4 Z" id="path49" />
    </svg>
  );
}
