import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import DirectoryFileUploadWrapper, { acceptedFiles } from './directoryFileUploadWrapper';
import { FaFileExcel, FaFilePdf, FaFileWord } from 'react-icons/fa';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  useQueryParam_entriesList_directoryId,
  useQueryParam_panes,
} from '../../../../controllers/useGlobalQueryParams';
import {
  useUserSetting_entries_descending,
  useUserSetting_entries_filterByMimetype,
  useUserSetting_entries_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Codefy from '../../../../codefy';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import EntryDragDropWrapper from './directoryDropWrapper';
import EntryFileTypeIcon from './entryFileTypeIcon';
import FilterListIcon from '@material-ui/icons/FilterList';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { Icon } from '@iconify/react';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import PasteTextDialog from './pasteTextDialog';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { RiFolderUploadLine } from 'react-icons/ri';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SortIcon from '@material-ui/icons/Sort';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import baselineFactCheck from '@iconify-icons/ic/baseline-fact-check';
import clsx from 'clsx';
import createGenericNestableMenuGroupTitle from '../../../menus/createGenericNestableMenuGroupTitle';
import { documentsUpload } from '../../../../controllers/api/actions/documents/documentsUpload';
import fastStringify from 'fast-stringify';
import outlineArticle from '@iconify-icons/ic/outline-article';
import { taglistsTagTemplate } from '../../../../controllers/api/actions/taglists/taglistsTagTemplate';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenCreateDirectoryDialog } from '../../../dialogs/create/createDirectoryDialog';
import { useOpenCreateTaglistDialog } from '../../../dialogs/create/createTaglistDialog';
import { useOpenRenameEntryDialog } from '../../../dialogs/rename/renameEntryDialog';
import { useOpenRenameProjectDialog } from '../../../dialogs/rename/renameProjectDialog';
import { usePaneActions } from '../../usePaneActions';
import useSetAssistantParams from '../assistant/useSetAssistantParams';
import useSetSearchParams from '../../../navbar/searchBar/useSetSearchParams';
import { useTranslation } from 'react-i18next';

/* Used to simulate a click on the file upload dropzone if the user clicks on the "Upload
 * documents" or "Upload folder" buttons */
const UPLOAD_DOCUMENTS_BUTTON_ID = 'uploadDocumentsButtonId';
const UPLOAD_FOLDER_BUTTON_ID = 'uploadFolderButtonId';

export const Documents: Codefy.Objects.DocumentMimetype[] = [
  'application/pdf',
  'application/vnd.oasis.opendocument.text',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.ms-outlook',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/html',
  'text/plain',
  'application/rtf',
  'text/rtf',
];

export const DocumentsWord: Codefy.Objects.WordMimetype[] = [
  'application/vnd.oasis.opendocument.text',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const DocumentsExcel: Codefy.Objects.ExcelMimetype[] = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const goUpIconStyle: React.CSSProperties = {
  transform: 'rotate(180deg)',
  marginLeft: '-2px',
  marginBottom: '-2px',
};

export default function EntriesListPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const paneActions = usePaneActions();
  const dispatch = useDispatch();

  const operationsTagsEnabled = useFeatureFlag('operations_tags');
  const taglistFromTemplateEnabled = useFeatureFlag('taglist_from_template');

  const openRenameEntryDialog = useOpenRenameEntryDialog();
  const openRenameProjectDialog = useOpenRenameProjectDialog();

  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();
  const [entriesList_descending, set_entriesList_descending] = useUserSetting_entries_descending();
  const [entriesList_orderBy, set_entriesList_orderBy] = useUserSetting_entries_orderBy();
  const [
    entriesList_filterByMimetype,
    set_entriesList_filterByMimetype,
  ] = useUserSetting_entries_filterByMimetype();

  const [panes] = useQueryParam_panes();

  const openCreateDirectoryDialog = useOpenCreateDirectoryDialog();
  const openCreateTaglistDialog = useOpenCreateTaglistDialog();

  const isEveryone = useIsEveryone();

  const checkMimetypeFilter = (arr: Codefy.Objects.EntryMimeType[]) =>
    fastStringify(entriesList_filterByMimetype) === fastStringify(arr);

  const [pasteTextDialogOpen, setPasteTextDialogOpen] = useState(false);
  const setPasteTextDialogOpenTrue = () => setPasteTextDialogOpen(true);
  const setPasteTextDialogOpenFalse = () => setPasteTextDialogOpen(false);

  const {
    data: directory,
    isLoading: directoryIsLoading,
    error: directoryError,
  } = useDirectoriesGet(entriesList_directoryId);

  const onSetSearchScopeDirectory = useSetSearchParams('directory', entriesList_directoryId);
  const onSetAssistantScopeDirectory = useSetAssistantParams({
    scope: 'directory',
    id: entriesList_directoryId,
    project_id: directory?.path?.project_id,
  });

  const tagTemplateInputRef = useRef(null);

  const onClickUpload = (id: string) => {
    //@ts-ignore
    document.getElementById(id)?.click?.();
    /** true = keep menu open (so that the <input> element still exists when the user selects files) */
    return true;
  };

  const onClickTagTemplate = () => {
    //@ts-ignore
    tagTemplateInputRef?.current?.click?.();
    /** true = keep menu open (so that the <input> element still exists when the user selects files) */
    return true;
  };

  const userHasWriteAccess = directory?.path?.entry_write;

  const icon = useMemo(
    () =>
      directory?.path?.entry_mimetype === 'inode/case' ? (
        <WorkOutlineIcon />
      ) : directory?.path?.entry_name ? (
        <FolderOpenIcon />
      ) : (
        <FolderIcon />
      ),
    [directory?.path?.entry_mimetype],
  );

  /** When the user uploads documents */
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (!entriesList_directoryId) return;

      const files = [...acceptedFiles, ...rejectedFiles];

      dispatch(documentsUpload(files, entriesList_directoryId));
    },
    [entriesList_directoryId],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    /** Needs to be true since we have sometimes two drop areas above each other, e.g. the
     * entriesList folder pane and another folder inside this pane */
    noDragEventsBubbling: true,
    multiple: true,
    accept: acceptedFiles,
  });

  const menuEntries = useMemo(
    () =>
      !entriesList_directoryId
        ? undefined
        : [
            {
              key: 'goUp',
              text: t('entriesListPaneTitleBar.goUp'),
              icon: <SubdirectoryArrowRightIcon style={goUpIconStyle} />,
              disabled: !directory?.path?.directory_id,
              onClick: () => {
                if (!directory?.path?.directory_id) return;
                paneActions.addOrUpdatePane({
                  paneKey: PaneKeys.entriesList,
                  params: { entriesList_directoryId: directory?.path?.directory_id },
                });
              },
            },
            {
              key: 'new',
              text: t('entriesListPaneTitleBar.new'),
              icon: <AddIcon />,
              disabled: isEveryone || !userHasWriteAccess,
              hideMenuEntriesGroupTitle: true,
              menuEntries: [
                ...createGenericNestableMenuGroupTitle({ groupTitle: 'Upload' }),
                {
                  key: 'upload',
                  text: t('entriesListPaneTitleBar.uploadDocuments'),
                  onClick: () => onClickUpload(UPLOAD_DOCUMENTS_BUTTON_ID),
                  icon: (
                    <Box mb={-0.75} {...getRootProps()}>
                      <CloudUploadOutlinedIcon />
                      <input id={UPLOAD_DOCUMENTS_BUTTON_ID} {...getInputProps()} />
                    </Box>
                  ),
                  disabled: isEveryone || !userHasWriteAccess,
                },
                {
                  key: 'uploadFolder',
                  text: t('entriesListPaneTitleBar.uploadFolder'),
                  onClick: () => onClickUpload(UPLOAD_FOLDER_BUTTON_ID),
                  icon: (
                    <Box mb={-0.75} {...getRootProps()}>
                      <RiFolderUploadLine
                        className={clsx(globalClasses.icon, globalClasses.icon24)}
                      />
                      <input
                        id={UPLOAD_FOLDER_BUTTON_ID}
                        {...getInputProps()}
                        /** For handling uploads of directories */
                        //@ts-ignore
                        webkitdirectory=""
                        //@ts-ignore
                        directory=""
                      />
                    </Box>
                  ),
                  disabled: isEveryone || !userHasWriteAccess,
                },
                ...createGenericNestableMenuGroupTitle({ groupTitle: 'Create', topDivider: true }),
                {
                  key: 'newFolder',
                  text: t('entriesListPaneTitleBar.createFolder'),
                  icon: <CreateNewFolderOutlinedIcon />,
                  onClick: openCreateDirectoryDialog({
                    directoryId: entriesList_directoryId,
                    isCase: false,
                  }),
                  disabled: isEveryone || !userHasWriteAccess,
                },
                {
                  key: 'newCase',
                  text: t('entriesListPaneTitleBar.createCase'),
                  icon: <WorkOutlineIcon />,
                  onClick: openCreateDirectoryDialog({
                    directoryId: entriesList_directoryId,
                    isCase: true,
                  }),
                  disabled: isEveryone || !userHasWriteAccess,
                },
                {
                  key: 'paste',
                  text: t('entriesListPaneTitleBar.createDocument'),
                  icon: <PostAddIcon />,
                  onClick: setPasteTextDialogOpenTrue,
                  disabled: isEveryone || !userHasWriteAccess,
                },
                {
                  key: 'createContentTaglist',
                  text: t('entriesListPaneTitleBar.createContentTaglist'),
                  icon: <EntryFileTypeIcon entryMimetype="application/codefy.annotation-taglist" />,
                  disabled: isEveryone || !userHasWriteAccess,
                  onClick: openCreateTaglistDialog({
                    directoryId: entriesList_directoryId,
                    taglistType: 'annotation',
                  }),
                },
                {
                  key: 'createOperationsTaglist',
                  text: t('entriesListPaneTitleBar.createOperationsTaglist'),
                  icon: <EntryFileTypeIcon entryMimetype="application/codefy.entry-taglist" />,
                  disabled: isEveryone || !userHasWriteAccess || !operationsTagsEnabled,
                  onClick: openCreateTaglistDialog({
                    directoryId: entriesList_directoryId,
                    taglistType: 'entry',
                  }),
                },
                ...createGenericNestableMenuGroupTitle({
                  groupTitle: 'Advanced',
                  topDivider: true,
                  disabled: isEveryone || !userHasWriteAccess || !taglistFromTemplateEnabled,
                }),
                {
                  key: 'uploadTagTemplate',
                  text: t('entriesListPaneTitleBar.uploadTagTemplate'),
                  onClick: onClickTagTemplate,
                  icon: (
                    <Box mb={-0.5}>
                      <EntryFileTypeIcon entryMimetype="application/codefy.annotation-taglist" />
                      <input
                        ref={tagTemplateInputRef}
                        type="file"
                        multiple={false}
                        accept={[
                          '.xls',
                          'application/vnd.ms-excel',
                          '.xlsx',
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        ].join(', ')}
                        style={{ display: 'none' }}
                        onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                          const name = window.prompt(
                            t('entriesListPaneTitleBar.contentTaglistName'),
                          );

                          if (name && event.target.files && directory?.id) {
                            const files = Array.from(event.target.files);

                            /** The dispatches are not just so the user sees something is being
                             * uploaded, but also because we have a "hack" that closes the menu
                             * after the user uploads the Excel template */
                            dispatch({
                              type: 'DocumentUploadIncreaseTotalCount',
                              increaseBy: 1,
                            });
                            await taglistsTagTemplate({
                              name: name,
                              template: files[0],
                              directory_id: directory.id,
                            });
                            dispatch({
                              type: 'DocumentUploadIncreaseDoneCount',
                              increaseBy: 1,
                            });
                          }

                          // TODO: Reset file so that if the user re-selects the same file, the
                          // function does not mysteriously do nothing (since onChange would not be
                          // triggered  */
                        }}
                      />
                    </Box>
                  ),
                  disabled: isEveryone || !userHasWriteAccess || !taglistFromTemplateEnabled,
                },
              ],
            },

            {
              key: 'sort',
              text: t('entriesListPaneTitleBar.sortBy'),
              icon: <SortIcon />,
              menuEntries: [
                {
                  key: 'sortName',
                  text: t('entriesListPaneTitleBar.name'),
                  icon: <SortByAlphaIcon />,
                  selected: entriesList_orderBy === 'name',
                  menuEntries: [
                    {
                      key: 'sortNameAscending',
                      text: t('entriesListPaneTitleBar.AZName'),
                      selected: !entriesList_descending && entriesList_orderBy === 'name',
                      onClick: async () => {
                        await set_entriesList_descending(false);
                        await set_entriesList_orderBy('name');
                      },
                    },
                    {
                      key: 'sortNameDescending',
                      text: t('entriesListPaneTitleBar.ZAName'),
                      selected: entriesList_descending && entriesList_orderBy === 'name',
                      onClick: async () => {
                        await set_entriesList_descending(true);
                        await set_entriesList_orderBy('name');
                      },
                    },
                  ],
                },
                {
                  key: 'sortCreationDate',
                  text: t('entriesListPaneTitleBar.creationDate'),
                  icon: <ScheduleIcon />,
                  selected: entriesList_orderBy === 'created_at',
                  menuEntries: [
                    {
                      key: 'sortNameAscending',
                      text: t('entriesListPaneTitleBar.oldestFirst'),
                      selected: !entriesList_descending && entriesList_orderBy === 'created_at',
                      onClick: async () => {
                        await set_entriesList_descending(false);
                        await set_entriesList_orderBy('created_at');
                      },
                    },
                    {
                      key: 'sortNameDescending',
                      text: t('entriesListPaneTitleBar.newestFirst'),
                      selected: entriesList_descending && entriesList_orderBy === 'created_at',
                      onClick: async () => {
                        await set_entriesList_descending(true);
                        await set_entriesList_orderBy('created_at');
                      },
                    },
                  ],
                },
                {
                  key: 'sortMimetype',
                  text: t('entriesListPaneTitleBar.mimetype'),
                  icon: <CategoryIcon />,
                  selected: entriesList_orderBy === 'mimetype',
                  onClick: () => {
                    set_entriesList_orderBy('mimetype');
                  },
                },
              ],
            },
            {
              key: 'filter',
              text: t('entriesListPaneTitleBar.filterBy'),
              icon: <FilterListIcon />,
              selected: !!entriesList_filterByMimetype,
              menuEntries: [
                {
                  key: 'filterType',
                  text: t('entriesListPaneTitleBar.filterByType'),
                  icon: <CategoryIcon />,
                  selected: !!entriesList_filterByMimetype,
                  menuEntries: [
                    {
                      key: 'entriesList_filterType_clear',
                      text: t('entriesListPaneTitleBar.filterByClear'),
                      icon: <ClearIcon />,
                      onClick: () => {
                        set_entriesList_filterByMimetype(undefined);
                      },
                    },
                    {
                      key: 'entriesList_filterType_taglists',
                      text: t('entriesListPaneTitleBar.filterByTags'),
                      icon: (
                        <Icon
                          icon={baselineFactCheck}
                          color={COLORS.primary}
                          className={globalClasses.icon22}
                        />
                      ),
                      onClick: () => {
                        set_entriesList_filterByMimetype([
                          'application/codefy.annotation-taglist',
                          'application/codefy.entry-taglist',
                        ]);
                      },
                      selected: checkMimetypeFilter([
                        'application/codefy.annotation-taglist',
                        'application/codefy.entry-taglist',
                      ]),
                    },
                    {
                      key: 'entriesList_filterType_directories',
                      text: t('entriesListPaneTitleBar.filterByFolders'),
                      icon: <FolderOpenIcon />,
                      onClick: () => {
                        set_entriesList_filterByMimetype(['inode/directory']);
                      },
                      selected: checkMimetypeFilter(['inode/directory']),
                    },
                    {
                      key: 'entriesList_filterType_documents',
                      text: t('entriesListPaneTitleBar.filterByDocuments'),
                      icon: (
                        <Icon
                          icon={outlineArticle}
                          color={COLORS.primary}
                          className={globalClasses.icon22}
                        />
                      ),
                      onClick: () => {
                        set_entriesList_filterByMimetype(Documents);
                      },
                      selected:
                        checkMimetypeFilter(Documents) ||
                        checkMimetypeFilter(['application/pdf']) ||
                        checkMimetypeFilter(DocumentsWord) ||
                        checkMimetypeFilter(DocumentsExcel),
                      menuEntries: [
                        {
                          key: 'entriesList_filterType_documents_pdf',
                          text: t('entriesListPaneTitleBar.filterByDocumentsPDF'),
                          icon: <FaFilePdf color="#660600" />,
                          onClick: () => {
                            set_entriesList_filterByMimetype(['application/pdf']);
                          },
                          selected: checkMimetypeFilter(['application/pdf']),
                        },
                        {
                          key: 'entriesList_filterType_documents_word',
                          text: t('entriesListPaneTitleBar.filterByDocumentsWord'),
                          icon: <FaFileWord color="#1262B3" />,
                          onClick: () => {
                            set_entriesList_filterByMimetype(DocumentsWord);
                          },
                          selected: checkMimetypeFilter(DocumentsWord),
                        },
                        {
                          key: 'entriesList_filterType_documents_excel',
                          text: t('entriesListPaneTitleBar.filterByDocumentsExcel'),
                          icon: <FaFileExcel />,
                          onClick: () => {
                            set_entriesList_filterByMimetype(DocumentsExcel);
                          },
                          selected: checkMimetypeFilter(DocumentsExcel),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
    [
      entriesList_directoryId,
      isEveryone,
      userHasWriteAccess,
      entriesList_descending,
      entriesList_orderBy,
      entriesList_filterByMimetype,
      panes.length,
    ],
  );

  const title = directory?.path?.entry_name || directory?.path?.project_name;

  if (onlyShowPart === 'icon' && icon) return icon;
  if (onlyShowPart === 'title' && title) return <span>{title}</span>;

  return (
    <>
      <EntryDragDropWrapper directory_id={directory?.id}>
        <>
          <DirectoryFileUploadWrapper directory_id={directory?.id}>
            <PaneTitleBar
              onlyShowPart={onlyShowPart}
              icon={icon}
              title={title}
              titleCopyable
              paneKey={PaneKeys.entriesList}
              onSearchCallback={onSetSearchScopeDirectory}
              onAssistantCallback={onSetAssistantScopeDirectory}
              onRenameCallback={
                directory?.path?.entry_id
                  ? openRenameEntryDialog({ entryId: directory?.path?.entry_id })
                  : openRenameProjectDialog({ projectId: directory?.path?.project_id })
              }
              menuEntries={menuEntries}
              loading={directoryIsLoading}
              error={directoryError}
              readonly={isEveryone || !userHasWriteAccess}
            />
          </DirectoryFileUploadWrapper>
          {directory ? (
            <PasteTextDialog
              open={pasteTextDialogOpen}
              directory_id={directory?.id}
              onCloseCallback={setPasteTextDialogOpenFalse}
            />
          ) : null}
        </>
      </EntryDragDropWrapper>
    </>
  );
}
