import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import Codefy from '../../../codefy';
import { Documents } from '../../panes/paneTypes/entriesList/entriesListPaneTitleBar';
import EntryFileTypeIcon from '../../panes/paneTypes/entriesList/entryFileTypeIcon';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GenericTextFieldDialog from '../genericTextFieldDialog';
import { entriesRename } from '../../../controllers/api/actions/entries/entriesRename';
import { useBetween } from 'use-between';
import { useEntriesGet } from '../../../controllers/api/subscriptions/entries/entriesGet';
import { useTranslation } from 'react-i18next';

type DialogRenameEntryStateType = { entryId?: Codefy.Objects.Entry['id'] };

const useDialogRenameEntryState = () => useState<DialogRenameEntryStateType>();

const useSharedDialogRenameEntryState = () => useBetween(useDialogRenameEntryState);

export const RenameEntryDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogRenameEntryState();

  const { data: entry, isFetching } = useEntriesGet(state?.entryId);

  const onClose = () => setState(undefined);

  const onRename = async (name: string) => {
    if (!name || !state?.entryId) return;
    entriesRename({ entry_id: state.entryId, name });
  };

  if (
    !entry?.name ||
    /* Make sure to refresh the GenericTextFieldDialog component when the user renames the same
thing twice */
    isFetching
  )
    return null;

  const icon = (() => {
    switch (entry.mimetype) {
      case 'inode/directory':
        return <FolderOpenIcon />;
      case 'inode/case':
        return <EntryFileTypeIcon entryMimetype={entry.mimetype} size={22} />;
      default:
        return (
          <Box ml={'1px'}>
            <EntryFileTypeIcon entryMimetype={entry.mimetype} size={22} />
          </Box>
        );
    }
  })();

  return (
    <GenericTextFieldDialog
      open={!!state}
      title={
        entry.mimetype == 'inode/directory'
          ? t('renameDialog.renameDirectory')
          : entry.mimetype == 'inode/case'
          ? t('renameDialog.renameCase')
          : entry.mimetype == 'application/codefy.annotation-taglist' ||
            entry.mimetype == 'application/codefy.entry-taglist'
          ? t('renameDialog.renameTaglist')
          : // @ts-ignore
          Documents.includes(entry.mimetype)
          ? t('renameDialog.renameDocument')
          : t('renameDialog.renameEntry')
      }
      icon={icon}
      onClose={onClose}
      onRename={onRename}
      currentName={entry.name}
    />
  );
};

export const useOpenRenameEntryDialog = () => {
  const [, setState] = useSharedDialogRenameEntryState();
  return (state: DialogRenameEntryStateType) => () => setState(state);
};
