import Codefy from '../../../../codefy';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { executeAction } from '../actionHelpers';

export const projectsUnshare = (data: {
  project_ids: Codefy.Objects.Project['id'][];
  email: Codefy.Objects.User['email'];
}) =>
  executeAction<Codefy.Objects.Project>({
    config: { url: '/api/v1/projects/unshare', method: 'POST' },
    data,
    queryKeysToRefresh: [PROJECTS_LIST_KEY_TYPE],
  });
