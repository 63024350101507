import Codefy from '../../../../codefy';
import { TAGLISTS_GET_KEY_TYPE } from '../../subscriptions/taglists/taglistsGet';
import { executeAction } from '../actionHelpers';

export const taglistsCreate = (data: {
  directory_id: Codefy.Objects.Directory['id'];
  name: string;
  type: Codefy.Objects.Taglist['type'];
}) =>
  executeAction<Codefy.Objects.Taglist>({
    config: { url: '/api/v1/taglists/create', method: 'POST' },
    data,
    queryKeysToRefresh: [TAGLISTS_GET_KEY_TYPE],
  });
