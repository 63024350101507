import FolderIcon from '@material-ui/icons/Folder';
import { GenericNestableMenuEntry } from '../../../../../menus/genericNestableMenuEntry';
import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useQueryParam_search_recursive } from '../../../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

export default function useRecursiveFacet(): GenericNestableMenuEntry {
  const { t } = useTranslation();
  const [search_recursive, set_search_recursive] = useQueryParam_search_recursive();

  return {
    key: 'subfolders',
    text: t('searchFilter.subfolders'),
    icon: <FolderIcon />,
    menuEntries: [
      {
        key: 'includeSubfolders',
        text: t('searchFilter.includeSubfolders'),
        onClick: () => set_search_recursive(true, 'pushIn'),
        selected: search_recursive,
        icon: <VisibilityIcon />,
      },
      {
        key: 'dontIncludeSubfolders',
        text: t('searchFilter.dontIncludeSubfolders'),
        onClick: () => set_search_recursive(false, 'pushIn'),
        selected: !search_recursive,
        icon: <VisibilityOffIcon />,
      },
    ],
  };
}
