import { TAGLISTS_GET_KEY_TYPE } from '../../../subscriptions/taglists/taglistsGet';
import { TAGS_GET_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsList';
import Codefy from '../../../../../codefy';
import { executeAction } from '../../actionHelpers';

export const tagsExportTextTemplates = (data: {
  tag_id: Codefy.Objects.Tag['id'];
  export_text_templates: Codefy.Objects.Tag['export_text_templates'];
}) =>
  executeAction<Codefy.Objects.Tag>({
    config: { url: '/api/v1/taglists/tags/export_text_templates', method: 'POST' },
    data,
    queryKeysToRefresh: [TAGLISTS_GET_KEY_TYPE, TAGS_GET_KEY_TYPE, TAGS_LIST_KEY_TYPE],
  });
