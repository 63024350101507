import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { CircularProgress } from '@material-ui/core';
import Codefy from '../../codefy';
import { PaneKeys } from '../../components/panes/paneTypes/paneKeys';
import { Redirect } from 'react-router';
import { axios } from '@use-hooks/axios';
import { fastApiParamsSerializer } from '../api/subscriptionHelpers';
import { getAnnotationLinkUrl } from '../../components/panes/paneTypes/annotation/annotation';

/** This has abbreviated key names to save a few characters and shorten the resulting link */
export type SharedSelection = {
  /** Boxes */
  b: Codefy.Objects.Box[];
  /** Document_id */
  d: Codefy.Objects.Document['id'];
};

/** A component that redirects the user from a shortened resource link (e.g. `/share/annotation/5`)
 * to the actual resource */
const ShareLinkForwarder = () => {
  const [redirectTo, setRedirectTo] = useState<string>();
  const [query] = useQueryParams({ s: StringParam });

  /* Run once to determine the link we need to redirect to */
  useEffect(() => {
    /* Needs to be extracted into a function since we are using async */
    const run = async () => {
      /** What was shared, e.g. `annotation` */
      const type = window.location.pathname.split('/')[2];
      /** The id of the item shared, e.g. 5 */
      const id = parseInt(window.location.pathname.split('/')[3]);

      if (type === 'annotation') {
        const params = { annotation_id: id };
        const response = await axios.get('/api/v1/annotations/get', { params });
        const annotation = response.data as Codefy.Objects.Annotation;
        const linkUrl = getAnnotationLinkUrl(annotation);
        setRedirectTo(linkUrl);
      } else if (type === 'selection') {
        const selectionZip = query.s;
        if (selectionZip) {
          const selection = JSON.parse(atob(selectionZip)) as SharedSelection;

          setRedirectTo(
            `/?` +
              fastApiParamsSerializer({
                panes: [PaneKeys.projectsList, PaneKeys.pdfViewer],
                pdfViewer_documentId: selection.d,
                pdfViewer_boxes: JSON.stringify(selection.b),
                pdfViewer_page: selection.b[0].page,
              }),
          );
        } else {
          setRedirectTo('/');
        }
      } else {
        /** This should not happen ^^ */
        setRedirectTo('/');
      }
    };
    run();
    // eslint-disable-next-line
  }, []);

  /* As soon as the `redirectTo` is set, we redirect the user */
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  /* Meanwhile, show a loading indicator. This should usually be at most a few hundred ms. */
  return <CircularProgress />;
};

export default ShareLinkForwarder;
