import { axios } from '@use-hooks/axios';
import { useQuery } from 'react-query';

export const ENTRIES_GET_KEY_TYPE = 'entriesGet';

export const entriesGetKey = (): [string] => [ENTRIES_GET_KEY_TYPE];

export const useHelpUrlsGet = () => {
  return useQuery(entriesGetKey(), async () => {
    const { data } = await axios.get('/api/v1/help_urls');
    return data as [string, string][];
  });
};
