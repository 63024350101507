import 'moment/locale/de';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './i18n/i18n';

import { BrowserRouter, Route } from 'react-router-dom';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { ThemeProvider, createMuiTheme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';

import AppLayout from './components/layout';
import Codefy from './codefy';
import CssBaseline from '@material-ui/core/CssBaseline';
import Favicon from './components/favicon/favicon';
import { GLOBAL_MUI_THEME } from './globalThemeSettings';
import { IsDraggingFileProvider } from './hooks/useIsDraggingFile';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import React from 'react';
import { ReactQueryDevtools } from 'react-query-devtools';
import { langMUMapping } from './i18n/localeMappings';
import moment from 'moment';
import store from './controllers/store';
import { useTranslation } from 'react-i18next';

const queryConfig: ReactQueryConfig = {
  queries: {
    /** Don't retry failed queries so that we can act after something has been deleted */
    retry: false,
    /** Disable automatic refresh of all queries on window refocus. This was causing a lot
     * of unecessary requests because we are already refetching in the background thanks to
     * the Websocket connection. The only missing case (user closes laptop for a few hours)
     * is taken care by listening to the wake-event and refetching then. */
    refetchOnWindowFocus: false,
    /** If, immediately after having reloaded the data, another reload request comes in, ignore it.
     This is because after performing an action, we already reload the data, and then a
     notification might come in to request the data being reloaded yet another time.  */
    // Depreciated on 2020-12-19 let's see if this improves the tagging experience
    // staleTime: 1000,
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    toastContent: {
      margin: '20px',
      fontWeight: 'bold',
      cursor: 'default',
    },
  }),
);

/** The entry point for our application is just a wrapper that provides acess to the
 * store */
export const App = () => {
  /** Sets the Material Design theme settings for the entire application */
  const { i18n } = useTranslation();
  const classes = useStyles();
  const theme = createMuiTheme(
    GLOBAL_MUI_THEME,
    langMUMapping[i18n.language as Codefy.Objects.LanguageType],
  );

  moment.locale(i18n.language as Codefy.Objects.LanguageType);
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      {/* Listens to whether the user is dragging a file from his system into the browser */}
      <IsDraggingFileProvider>
        <Provider store={store}>
          {/* Provides the theme settings to the Material Design components */}
          <ThemeProvider theme={theme}>
            {/* Sets favicon */}
            <Favicon />
            {/* Resets the many different default CSS settings that vary by browser to a common baseline */}
            <CssBaseline />
            {/* Provides routing features (history and location) */}
            <BrowserRouter>
              {/* Allows to read and manipulate the URL query, such as /project?project_id=5 */}
              <QueryParamProvider ReactRouterRoute={Route}>
                <ToastContainer
                  bodyClassName={classes.toastContent}
                  position={toast.POSITION.TOP_RIGHT}
                  hideProgressBar
                  pauseOnHover={false}
                  autoClose={5000}
                  closeButton={false}
                />

                <AppLayout />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryParamProvider>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </IsDraggingFileProvider>
    </ReactQueryConfigProvider>
  );
};
