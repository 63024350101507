import Codefy from '../../../../../../codefy';
import { GenericNestableMenuEntry } from '../../../../../menus/genericNestableMenuEntry';
import React from 'react';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import TranslateIcon from '@material-ui/icons/Translate';
import { useQueryParam_search_language } from '../../../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

export default function useLanguageFacet(
  facets?: Codefy.Objects.SearchFacets | null,
): GenericNestableMenuEntry | null {
  const { t } = useTranslation();
  const [search_language, set_search_language] = useQueryParam_search_language();

  /** This looks redundant, but it serves a purpose: It allows our i18n checker to verify that we
   * have all the translations for the names of the languages */
  const languageMap = {
    deu: t('searchFilter.languages.deu'),
    eng: t('searchFilter.languages.eng'),
    zho: t('searchFilter.languages.zho'),
    rus: t('searchFilter.languages.rus'),
    fra: t('searchFilter.languages.fra'),
  } as { [key: string]: string };

  if (!facets) return null;

  if (facets.languages.length === 1 && !search_language) return null;

  if (search_language) {
    return {
      key: 'language',
      text: languageMap?.[search_language] || search_language,
      icon: <TranslateIcon />,
      menuEntries: [
        {
          key: 'reset',
          text: t('searchPane.reset'),
          onClick: () => set_search_language(undefined, 'pushIn'),
          icon: <RotateLeftIcon />,
        },
      ],
    };
  }

  return {
    key: 'language',
    text: t('searchFilter.language'),
    icon: <TranslateIcon />,
    menuEntries: facets.languages.map((language) => ({
      key: language,
      text: languageMap?.[language] || language,
      onClick: () => set_search_language(language, 'pushIn'),
    })),
  };
}
