import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../../../subscriptions/annotations/annotationsList';

import { ANNOTATIONS_GET_KEY_TYPE } from '../../../../subscriptions/annotations/annotationsGet';
import { TAG_INSTANCES_GET_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import Codefy from '../../../../../../codefy';
import { executeAction } from '../../../actionHelpers';

export const tagInstancesExportText = async (data: {
  tag_instance_id: Codefy.Objects.TagInstance['id'];
  export_text: string;
}) =>
  executeAction<Codefy.Objects.TagInstance>({
    config: { url: '/api/v1/taglists/tags/instances/export_text', method: 'POST' },
    data,
    queryKeysToRefresh: [
      ANNOTATIONS_LIST_KEY_TYPE,
      ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
      ANNOTATIONS_GET_KEY_TYPE,
      TAG_INSTANCES_LIST_KEY_TYPE,
      TAG_INSTANCES_GET_KEY_TYPE,
    ],
  });
