import useCustomAttributesFacets from './facets/useCustomAttributesFacets';
import useLanguageFacet from './facets/useLanguageFacet';
import useRecursiveFacet from './facets/userRecursiveFacet';
import { useSearchFacets } from '../../../../../controllers/api/subscriptions/search/searchFacets';

export default function useFacets(facets_key?: string) {
  const { data: facets } = useSearchFacets({ facets_key });

  let facetmenuEntries = [];

  const recursiveFacet = useRecursiveFacet();
  const languageFacet = useLanguageFacet(facets);
  const customAttributesFacets = useCustomAttributesFacets(facets);

  facetmenuEntries.push(recursiveFacet);
  if (languageFacet) facetmenuEntries.push(languageFacet);
  if (customAttributesFacets.length > 0) {
    facetmenuEntries = [...facetmenuEntries, ...customAttributesFacets];
  }

  if (!facets_key) return [];
  if (!(facets?.num_parts !== 0 && facets?.num_parts && facets?.num_parts > 0)) return [];

  // TODO: Add narrow down button

  return facetmenuEntries;
}
