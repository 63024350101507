import 'react-resizable/css/styles.css';

import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  useQueryParam_focusedPane,
  useQueryParam_panes,
} from '../../controllers/useGlobalQueryParams';

import { ClosePdfViewerOnCaseIdChangeListener } from '../listeners/ClosePdfViewerOnCaseIdChangeListener';
import DialogsRenderer from '../dialogs/dialogsRenderer';
import { FollowSearchScopeListener } from '../listeners/FollowSearchScopeListener.tsx';
import { Modal } from '../old_modals/Modal';
import { Navbar } from '../navbar/navbar';
import Pane from './pane';
import { PaneKeys } from './paneTypes/paneKeys';
import { ROUTE_LOGIN } from '../../controllers/routes/routes';
import { Redirect } from 'react-router';
import Sidebar from '../sidebar';
import { paneTypes } from './paneTypes/paneTypes';
import useIsEveryone from '../../hooks/useIsEveryone';
import { usePaneActions } from './usePaneActions';
import useSessionRecorder from '../../hooks/useSessionRecorder';
import { useUserSetting_tutorials_closed } from '../../controllers/api/subscriptions/users/userSettings';

export const DEFAULT_PANES: PaneKeys[] = [PaneKeys.projectsList, PaneKeys.tutorials];

export const getPaneElementId = (paneKey: PaneKeys) => `pane-${paneKey}`;

export const useStyles = makeStyles(() => ({
  paneViewport: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    paddingTop: '5px',
    paddingBottom: '10px',
    marginLeft: '60px',
    paddingRight: '25px',
  },
  firstPane: {
    marginLeft: '6px',
  },
  paneContent: {
    height: 'calc(100vh - 190px)',
    overflow: 'hidden',
  },
  dragHandle: {
    flex: '0 0 auto',
    height: '100%',
    width: '3px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '3px',
    paddingRight: '3px',
    marginRight: '-3px',
    marginLeft: '-3px',
    backgroundColor: '#00000000',
    cursor: 'col-resize',
    '&:hover': {
      backgroundColor: '#00000040',
    },
    borderRadius: '200px',
  },
  toolbar: {
    // necessary for content to be below app bar
    height: '55px',
  },
}));

/** Scroll to focused pane (once, on startup) */
const useScrollToFocusedPaneOnStartup = () => {
  const paneActions = usePaneActions();
  const [focusedPane] = useQueryParam_focusedPane();
  useEffect(() => {
    if (focusedPane) paneActions.focusPane(focusedPane as PaneKeys);
  }, []);
};

export default function PanesViewport() {
  const [panes, set_panes] = useQueryParam_panes();
  const classes = useStyles();
  const [tutorials_closed] = useUserSetting_tutorials_closed();
  const isEveryone = useIsEveryone();

  useSessionRecorder();
  useScrollToFocusedPaneOnStartup();

  /* Set default panes if no panes */
  useEffect(() => {
    if (!panes || (panes?.length === 0 && !isEveryone)) {
      set_panes(DEFAULT_PANES);
    }
  }, [panes]);

  if (!panes && isEveryone) return <Redirect to={ROUTE_LOGIN} />;

  if (!panes) return null;

  return (
    <>
      <Modal />

      <DialogsRenderer />

      <Navbar />

      <ClosePdfViewerOnCaseIdChangeListener />
      <FollowSearchScopeListener />

      <div className={classes.toolbar} />

      <Grid container spacing={0}>
        <Sidebar />
        <div className={classes.paneViewport} id="panesViewport">
          {paneTypes
            .filter((pane) => panes.includes(pane.key))
            .filter((pane) => !(pane.needsLogin && isEveryone))
            .filter((pane) => {
              if (pane.key !== PaneKeys.tutorials) {
                return true;
              } else {
                return !tutorials_closed;
              }
            })
            .map((pane) => (
              <Pane key={pane.key} pane={pane} />
            ))}
          <div style={{ flex: '0 0 auto', width: '50px' }}></div>
        </div>
      </Grid>
    </>
  );
}
