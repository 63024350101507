import { Box, Select } from '@material-ui/core';
import React, { useEffect } from 'react';

import Codefy from '../../../../codefy';
import { TaglistVariants } from '../paneTypes';
import { t } from '../../../../controllers/websocket/popupHandler';
import { taglistsCreate } from '../../../../controllers/api/actions/taglists/taglistsCreate';
import { useEntriesList } from '../../../../controllers/api/subscriptions/entries/entriesList';
import { useProjectsGet } from '../../../../controllers/api/subscriptions/projects/projectsGet';
import { useQueryParam_taglist_id } from '../../../../controllers/useGlobalQueryParams';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';

export default function TaglistSelector({
  project_id,
  variant,
  taglistType,
  showWhenEmpty,
}: {
  project_id: Codefy.Objects.Project['id'];
  variant: TaglistVariants;
  taglistType: Codefy.Objects.Taglist['type'];
  showWhenEmpty?: string;
}) {
  const { data: project } = useProjectsGet(project_id);

  const mimetype: Codefy.Objects.EntryMimeType =
    taglistType === 'annotation'
      ? 'application/codefy.annotation-taglist'
      : 'application/codefy.entry-taglist';

  const { data: projectTaglistPages } = useEntriesList({
    directory_ids: project?.directory_id ? [project.directory_id] : [],
    mimetypes: [mimetype],
    recursive: true,
  });
  const projectTaglists = projectTaglistPages?.[0].entries;

  const [raw_taglist_id, set_taglist_id] = useQueryParam_taglist_id({ variant, taglistType });
  const taglist_id = raw_taglist_id || projectTaglists?.[0]?.taglist?.id;

  const { data: taglist } = useTaglistsGet({ taglist_id });

  useEffect(() => {
    if (!raw_taglist_id && taglist_id) {
      set_taglist_id(taglist_id);
    }
  }, [taglist_id]);

  if (projectTaglists?.length === 0) return showWhenEmpty ? <span>{showWhenEmpty}</span> : null;

  return (
    <Box p={0} pb={0} pt={0} flexWrap="nowrap" width="100%">
      <Select
        native
        style={{ maxHeight: '23px' }}
        variant="outlined"
        value={taglist?.id}
        onChange={async (event) => {
          if (event.target.value) {
            if (event.target.value === 'new') {
              /* User wants to create a new taglist */
              const newName = prompt(t('tagLabelsEditor.newTaglistName'));
              if (newName && project?.directory_id) {
                const newTaglist = await taglistsCreate({
                  directory_id: project?.directory_id,
                  name: newName,
                  type: taglistType,
                });
                if (newTaglist) {
                  set_taglist_id(newTaglist.id);
                }
              }
            } else {
              set_taglist_id((event.target.value as unknown) as number);
            }
          }
        }}>
        {projectTaglists?.map((taglist) => (
          <option
            key={taglist.taglist?.id}
            value={taglist.taglist?.id}
            onClick={(event) => {
              event.stopPropagation();
            }}>
            {taglist.name}
          </option>
        ))}
        <option key={0} value={'new'}>
          {t('tagLabelsEditor.newTaglist')}
        </option>
      </Select>
    </Box>
  );
}
