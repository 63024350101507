import { Box, Typography, makeStyles } from '@material-ui/core';

import { PaneKeys } from '../paneKeys';
import React from 'react';
import Youtube from 'react-youtube';
import { paneTypes } from '../paneTypes';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useHelpUrlsGet } from '../../../../controllers/api/subscriptions/help_urls';

const useStyles = makeStyles({
  root: { height: '100%', overflow: 'auto' },
});

export default function Tutorials() {
  const classes = useStyles();
  const { data } = useHelpUrlsGet();
  const globalClasses = useGlobalStyles();

  return (
    <div className={classes.root}>
      {data?.map((entry) => (
        <div key={entry[1]}>
          <Box m={2}>
            <Box mb={1}>
              <Typography className={globalClasses.heading}>{entry[0]}</Typography>
            </Box>
            <Youtube
              videoId={entry[1]}
              opts={{
                width: (
                  (paneTypes.find((pane) => pane.key === PaneKeys.tutorials)?.width || 500) - 50
                ).toString(),
                height: '310',
              }}
            />
          </Box>
        </div>
      ))}
    </div>
  );
}
