import React, { useState } from 'react';

import Codefy from '../../../codefy';
import FolderIcon from '@material-ui/icons/Folder';
import GenericTextFieldDialog from '../genericTextFieldDialog';
import { projectsRename } from '../../../controllers/api/actions/projects/projectsRename';
import { useBetween } from 'use-between';
import { useProjectsGet } from '../../../controllers/api/subscriptions/projects/projectsGet';
import { useTranslation } from 'react-i18next';

type DialogRenameProjectStateType = { projectId?: Codefy.Objects.Project['id'] };

const useDialogRenameProjectState = () => useState<DialogRenameProjectStateType>();

const useSharedDialogRenameProjectState = () => useBetween(useDialogRenameProjectState);

export const RenameProjectDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogRenameProjectState();

  const { data: project, isFetching } = useProjectsGet(state?.projectId);

  const onClose = () => setState(undefined);

  const onRename = async (name: string) => {
    if (!name || !state?.projectId) return;
    projectsRename({ project_id: state?.projectId, name });
  };

  if (
    !project?.name ||
    /* Make sure to refresh the GenericTextFieldDialog component when the user renames the same
thing twice */
    isFetching
  )
    return null;

  return (
    <GenericTextFieldDialog
      open={!!state}
      title={t('renameDialog.renameWorkspace')}
      icon={<FolderIcon />}
      onClose={onClose}
      onRename={onRename}
      currentName={project.name}
    />
  );
};

export const useOpenRenameProjectDialog = () => {
  const [, setState] = useSharedDialogRenameProjectState();
  return (state: DialogRenameProjectStateType) => () => setState(state);
};
