import Account from './account';
import AccountPaneTitleBar from './account/accountPaneTitleBar';
import AnnotationPaneContentWrapper from './annotation/annotationPaneContentWrapper';
import AnnotationPaneTitleBar from './annotation/annotationPaneTitleBar';
import AnnotationsListContent from './annotationsList/annotationsList';
import AnnotationsListPaneTitleBar from './annotationsList/annotationsListPaneTitleBar';
import AssistantPaneTitleBar from './assistant/assistantPaneTitleBar';
import AssistantResults from './assistantResults/assistantResults';
import AssistantResultsPaneTitleBar from './assistantResults/assistantResultsPaneTitleBar';
import Codefy from '../../../codefy';
import EntriesList from './entriesList/entriesList';
import EntriesListPaneTitleBar from './entriesList/entriesListPaneTitleBar';
import { PaneKeys } from './paneKeys';
import PdfViewer from './pdfViewer/pdfViewer';
import PdfViewerPaneTitleBar from './pdfViewer/pdfViewerPaneTitleBar';
import ProjectShareSettings from './projectShareSettings/projectShareSettings';
import ProjectsListContent from './projectsList/projectsList';
import ProjectsListPaneTitleBar from './projectsList/projectsListPaneTitleBar';
import ProjectsShareSettingsPaneTitleBar from './projectShareSettings/projectShareSettingsPaneTitleBar';
import React from 'react';
import SearchPaneTitleBar from './search/searchPaneTitleBar/searchPaneTitleBar';
import SearchResultsPaneContent from './search/searchResultsPaneContent';
import Tag from './tag/tag';
import TagPaneTitleBar from './tag/tagPaneTitleBar';
import Taglist from './taglist/taglist';
import TaglistPaneTitleBar from './taglist/taglistPaneTitleBar';
import Tutorials from './tutorials/tutorials';
import TutorialsPaneTitleBar from './tutorials/tutorialsPaneTitleBar';
import UploadBatch from './uploadBatch/uploadBatch';
import UploadBatchPaneTitleBar from './uploadBatch/uploadBatchPaneTitleBar';
import UploadBatches from './uploadBatches/uploadBatches';
import UploadBatchesPaneTitleBar from './uploadBatches/uploadBatchesPaneTitleBar';

export type PaneType = {
  key: PaneKeys;
  content: React.ReactElement;
  paneTitleBar: React.ReactElement;
  width: number;
  icon: React.ReactElement;
  title: React.ReactElement;
  /** Is the user is missing this feature flag, hide the pane */
  needsFeatureFlag?: Codefy.Objects.FeatureFlags;
  /** If the user is not logged in, hide the pane */
  needsLogin?: boolean;
  closable?: boolean;
  minWidth?: number;
};

export enum TaglistVariants {
  'TAGLIST',
  'ASSISTANT',
}

export enum PANE_WIDTHS {
  'SMALL' = 300,
  'MEDIUM' = 400,
  'LARGE' = 550,
  /* Note that many users have screens with 1280px width, so the default should be somewhere below
  that to also account for sidebar and giving the visual impression that there are more panes than
  the pdfViewer */
  'HUGE' = 1150,
}

export const paneTypes: PaneType[] = [
  {
    key: PaneKeys.projectsList,
    content: <ProjectsListContent />,
    paneTitleBar: <ProjectsListPaneTitleBar />,
    icon: <ProjectsListPaneTitleBar onlyShowPart="icon" />,
    title: <ProjectsListPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.SMALL,
    closable: false,
    needsLogin: true,
  },
  {
    key: PaneKeys.projectShareSettings,
    content: <ProjectShareSettings />,
    paneTitleBar: <ProjectsShareSettingsPaneTitleBar />,
    icon: <ProjectsShareSettingsPaneTitleBar onlyShowPart="icon" />,
    title: <ProjectsShareSettingsPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.MEDIUM,
    needsLogin: true,
  },
  {
    key: PaneKeys.tutorials,
    content: <Tutorials />,
    paneTitleBar: <TutorialsPaneTitleBar />,
    icon: <TutorialsPaneTitleBar onlyShowPart="icon" />,
    title: <TutorialsPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.LARGE,
  },
  {
    key: PaneKeys.entriesList,
    content: <EntriesList />,
    paneTitleBar: <EntriesListPaneTitleBar />,
    icon: <EntriesListPaneTitleBar onlyShowPart="icon" />,
    title: <EntriesListPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.MEDIUM,
  },
  {
    key: PaneKeys.uploadBatches,
    content: <UploadBatches />,
    paneTitleBar: <UploadBatchesPaneTitleBar />,
    icon: <UploadBatchesPaneTitleBar onlyShowPart="icon" />,
    title: <UploadBatchesPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.MEDIUM,
    needsLogin: true,
  },
  {
    key: PaneKeys.uploadBatch,
    content: <UploadBatch />,
    paneTitleBar: <UploadBatchPaneTitleBar />,
    icon: <UploadBatchPaneTitleBar onlyShowPart="icon" />,
    title: <UploadBatchPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.LARGE,
    needsLogin: true,
  },
  {
    key: PaneKeys.entryTaglist,
    content: <Taglist variant={TaglistVariants.TAGLIST} taglistType="entry" />,
    paneTitleBar: <TaglistPaneTitleBar variant={TaglistVariants.TAGLIST} taglistType="entry" />,
    icon: (
      <TaglistPaneTitleBar
        onlyShowPart="icon"
        variant={TaglistVariants.TAGLIST}
        taglistType="entry"
      />
    ),
    title: (
      <TaglistPaneTitleBar
        onlyShowPart="title"
        variant={TaglistVariants.TAGLIST}
        taglistType="entry"
      />
    ),
    width: PANE_WIDTHS.SMALL,
    needsFeatureFlag: 'operations_tags',
  },
  {
    key: PaneKeys.entryTag,
    content: <Tag taglistType="entry" />,
    paneTitleBar: <TagPaneTitleBar taglistType="entry" />,
    icon: <TagPaneTitleBar onlyShowPart="icon" taglistType="entry" />,
    title: <TagPaneTitleBar onlyShowPart="title" taglistType="entry" />,
    width: PANE_WIDTHS.LARGE,
    needsFeatureFlag: 'operations_tags',
  },
  {
    key: PaneKeys.search,
    content: <SearchResultsPaneContent />,
    paneTitleBar: <SearchPaneTitleBar />,
    icon: <SearchPaneTitleBar onlyShowPart="icon" />,
    title: <SearchPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.LARGE,
  },
  {
    key: PaneKeys.pdfViewer,
    content: <PdfViewer pdfViewerVariant={1} />,
    paneTitleBar: <PdfViewerPaneTitleBar pdfViewerVariant={1} />,
    width: PANE_WIDTHS.HUGE,
    icon: <PdfViewerPaneTitleBar pdfViewerVariant={1} onlyShowPart="icon" />,
    title: <PdfViewerPaneTitleBar pdfViewerVariant={1} onlyShowPart="title" />,
    minWidth: PANE_WIDTHS.MEDIUM,
  },
  {
    key: PaneKeys.pdfViewer2,
    content: <PdfViewer pdfViewerVariant={2} />,
    paneTitleBar: <PdfViewerPaneTitleBar pdfViewerVariant={2} />,
    width: PANE_WIDTHS.HUGE,
    icon: <PdfViewerPaneTitleBar pdfViewerVariant={2} onlyShowPart="icon" />,
    title: <PdfViewerPaneTitleBar pdfViewerVariant={2} onlyShowPart="title" />,
    minWidth: PANE_WIDTHS.MEDIUM,
  },
  {
    key: PaneKeys.annotationTaglist,
    content: <Taglist variant={TaglistVariants.TAGLIST} taglistType="annotation" />,
    paneTitleBar: (
      <TaglistPaneTitleBar variant={TaglistVariants.TAGLIST} taglistType="annotation" />
    ),
    icon: (
      <TaglistPaneTitleBar
        onlyShowPart="icon"
        variant={TaglistVariants.TAGLIST}
        taglistType="annotation"
      />
    ),
    title: (
      <TaglistPaneTitleBar
        onlyShowPart="title"
        variant={TaglistVariants.TAGLIST}
        taglistType="annotation"
      />
    ),
    width: PANE_WIDTHS.SMALL,
  },
  {
    key: PaneKeys.annotationTag,
    content: <Tag taglistType="annotation" />,
    paneTitleBar: <TagPaneTitleBar taglistType="annotation" />,
    icon: <TagPaneTitleBar onlyShowPart="icon" taglistType="annotation" />,
    title: <TagPaneTitleBar onlyShowPart="title" taglistType="annotation" />,
    width: PANE_WIDTHS.LARGE,
  },
  {
    key: PaneKeys.assistant,
    content: <Taglist variant={TaglistVariants.ASSISTANT} taglistType="annotation" />,
    paneTitleBar: <AssistantPaneTitleBar />,
    icon: <AssistantPaneTitleBar onlyShowPart="icon" />,
    title: <AssistantPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.SMALL,
  },
  {
    key: PaneKeys.assistantResults,
    content: <AssistantResults />,
    paneTitleBar: <AssistantResultsPaneTitleBar />,
    icon: <AssistantResultsPaneTitleBar onlyShowPart="icon" />,
    title: <AssistantResultsPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.LARGE,
  },
  {
    key: PaneKeys.annotationsList,
    content: <AnnotationsListContent />,
    paneTitleBar: <AnnotationsListPaneTitleBar />,
    icon: <AnnotationsListPaneTitleBar onlyShowPart="icon" />,
    title: <AnnotationsListPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.LARGE,
  },
  {
    key: PaneKeys.annotation,
    content: <AnnotationPaneContentWrapper />,
    paneTitleBar: <AnnotationPaneTitleBar />,
    icon: <AnnotationPaneTitleBar onlyShowPart="icon" />,
    title: <AnnotationPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.LARGE,
  },
  {
    key: PaneKeys.account,
    content: <Account />,
    paneTitleBar: <AccountPaneTitleBar />,
    icon: <AccountPaneTitleBar onlyShowPart="icon" />,
    title: <AccountPaneTitleBar onlyShowPart="title" />,
    width: PANE_WIDTHS.LARGE,
    needsLogin: true,
  },
];
