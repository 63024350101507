import { eventWithTime } from 'rrweb/typings/types';
import { executeAction } from '../actionHelpers';

export const sessionsStoreEventsChunk = (data: {
  session_key?: string;
  events_chunk: eventWithTime[];
}) =>
  executeAction({
    config: { url: '/api/v1/sessions/store_events_chunk', method: 'POST' },
    data,
    queryKeysToRefresh: [],
    showToastOnError: false,
  });
