import Codefy from '../../../../codefy';
import { DIRECTORIES_GET_KEY_TYPE } from '../../subscriptions/directories/directoriesGet';
import { ENTRIES_GET_KEY_TYPE } from '../../subscriptions/entries/entriesGet';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { executeAction } from '../actionHelpers';

export const directoriesConvert = (data: { directory_id: Codefy.Objects.Directory['id'] }) =>
  executeAction<Codefy.Objects.Directory>({
    config: { url: '/api/v1/directories/case', method: 'POST' },
    data,
    queryKeysToRefresh: [ENTRIES_LIST_KEY_TYPE, ENTRIES_GET_KEY_TYPE, DIRECTORIES_GET_KEY_TYPE],
  });
