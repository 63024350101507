import { Route, Switch } from 'react-router-dom';

import AfterRegister from '../../components/auth/afterRegister';
import Codefy from '../../codefy';
import ConfirmChangeEmail from '../../components/auth/confirmChangeEmail';
import ConfirmRegister from '../../components/auth/confirmRegister';
import ConfirmResetPassword from '../../components/auth/confirmResetPassword';
import { LoginAndRegister } from '../../components/auth/loginAndRegister';
import PanesViewport from '../../components/panes/panesViewport';
import React from 'react';
import ResetPassword from '../../components/auth/resetPassword';
import SessionsPlayer from '../../components/sessions/sessionsPlayer';
import ShareLinkForwarder from './shareUrlForwarder';
import { useSelector } from 'react-redux';

export const ROUTE_LOGIN = '/login';

export default function Routes() {
  const userIsAdmin = useSelector((state: Codefy.State) => state.user?.admin);

  return (
    <Switch>
      <Route path="/after-register" component={AfterRegister} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/confirm-reset-password" component={ConfirmResetPassword} />
      <Route path="/confirm-change-email" component={ConfirmChangeEmail} />
      <Route path="/confirm-register" component={ConfirmRegister} />
      <Route path="/share" component={ShareLinkForwarder} />
      <Route path={ROUTE_LOGIN} component={LoginAndRegister} />
      {userIsAdmin && <Route path="/replay" exact component={SessionsPlayer} />}
      <Route path="/" exact component={PanesViewport} />
    </Switch>
  );
}
