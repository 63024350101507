import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import { useAnnotationsGet } from '../../../../controllers/api/subscriptions/annotations/annotationsGet';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenDeleteAnnotationDialog } from '../../../dialogs/delete/deleteAnnotationDialog';
import { usePaneActions } from '../../usePaneActions';
import { useQueryParam_annotation_id } from '../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

export default function AnnotationPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const [annotation_id] = useQueryParam_annotation_id();
  const paneActions = usePaneActions();
  const isEveryone = useIsEveryone();
  const { data: annotation } = useAnnotationsGet({ annotation_id: annotation_id });
  const { t } = useTranslation();
  const openDeleteAnnotationDialog = useOpenDeleteAnnotationDialog();

  const onOpenAnnotation = () => {
    if (!annotation) return;
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: annotation.path.entry_id,
        pdfViewer_boxes: JSON.stringify(annotation.boxes),
        pdfViewer_page: annotation.boxes[0].page,
        pdfViewer_highlightAnnotationWithId: annotation.id,
      },
      reset: true,
    });
  };

  const userHasWriteAccess = annotation?.path.entry_write;

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={<StarIcon />}
      title={t('annotationPaneTitleBar.title', {
        page: annotation?.boxes[0].page || '...',
        name: annotation?.path.entry_name || '...',
      })}
      paneKey={PaneKeys.annotation}
      menuEntries={[
        {
          key: 'showInDocument',
          text: t('annotationPaneTitleBar.showInDocument'),
          icon: <OpenInNewIcon />,
          onClick: onOpenAnnotation,
        },
        { key: 'deleteDivider', divider: true },
        {
          key: 'delete',
          text: t('annotationPaneTitleBar.delete'),
          icon: <DeleteIcon />,
          disabled: isEveryone || !userHasWriteAccess,
          onClick: openDeleteAnnotationDialog({ annotationId: annotation?.id }),
        },
      ]}
      readonly={isEveryone || !userHasWriteAccess}
    />
  );
}
