import { Box, Button, ButtonGroup, IconButton, TextField, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';

import Codefy from '../../../../../codefy';
import EditIcon from '@material-ui/icons/Edit';
import Linkify from 'react-linkify';
import RemoveIcon from '@material-ui/icons/Remove';
import { commentsDelete } from '../../../../../controllers/api/actions/annotations/comments/commentsDelete';
import { commentsEdit } from '../../../../../controllers/api/actions/annotations/comments/commentsEdit';
import moment from 'moment';
import useHover from '../../../../../hooks/useHover';
import useKeyPress from '../../../../../hooks/useKeyPress';
import { useSelector } from 'react-redux';
import { useStyles } from './index';
import { useTranslation } from 'react-i18next';

export function AnnotationComment({
  annotationComment,
}: {
  annotationComment: Codefy.Objects.AnnotationComment;
}) {
  const classes = useStyles({});
  const currentUser = useSelector((state: Codefy.State) => state.user);
  const [hoverRef, isHovered] = useHover();
  const { t } = useTranslation();
  const useCtrl = useKeyPress(17);

  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [comment, setComment] = useState(annotationComment.text);
  const [saving, setSaving] = useState(false);

  const onDelete = async () => {
    if (deleting) return;
    setDeleting(true);
    await commentsDelete({ comment_id: annotationComment.id });
  };

  const onSave = async () => {
    setSaving(true);
    await commentsEdit({
      comment_id: annotationComment.id,
      text: comment,
    });
    setSaving(false);
    setEditing(false);
  };

  const onCancel = () => {
    setComment(annotationComment.text);
    setEditing(false);
  };

  /** Every time a key is pressed, check if it happens to be the enter key AND ctrl is held down, if
   * yes, save the comment */
  const onKeyDownSaveIfCtrlEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const keyCodeForTheEnterKey = 13;
    if (event.keyCode === keyCodeForTheEnterKey) {
      if (useCtrl || event.metaKey /* for Mac OSX */) {
        onSave();
      }
    }
  };

  const commentIsFromCurrentUser = currentUser?.email === annotationComment.created_by.email;

  return (
    <div className={classes.commentRoot} ref={hoverRef} data-e2e-id="annotation-comment">
      {commentIsFromCurrentUser && (
        <Tooltip enterDelay={1000} title={t('annotations.comments.delete') || ''}>
          <IconButton
            data-e2e-id="delete-annotation"
            onClick={onDelete}
            size="small"
            className={isHovered ? classes.commentDeleteIcon : classes.commentDeleteIconInactive}>
            <RemoveIcon />
          </IconButton>
        </Tooltip>
      )}
      {commentIsFromCurrentUser && (
        <Tooltip enterDelay={1000} title={t('annotations.comments.edit') || ''}>
          <IconButton
            data-e2e-id="edit-annotation"
            onClick={() => setEditing(true)}
            size="small"
            className={isHovered ? classes.commentDeleteIcon : classes.commentDeleteIconInactive}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      <b>{annotationComment.created_by.email}</b> ·{' '}
      <span className={classes.commentCreatedAt}>
        <Tooltip enterDelay={500} title={moment(annotationComment.created_at).format('LLLL')}>
          <span>{moment(annotationComment.created_at).fromNow()}</span>
        </Tooltip>
      </span>
      <br />
      {editing ? (
        <>
          <TextField
            multiline
            inputProps={{
              'data-e2e-id': 'edit-comment-to-annotation-field',
            }}
            disabled={saving}
            placeholder={t('annotations.comments.editComment') + '…'}
            onChange={(event) => setComment(event.target.value)}
            value={comment}
            fullWidth
            variant="standard"
            size="small"
            className={classes.createTextField}
            onKeyDown={onKeyDownSaveIfCtrlEnter}
          />
          <Box display="flex" justifyContent="flex-end" m={1}>
            <ButtonGroup aria-label="small outlined button group">
              <Button
                variant="outlined"
                color="default"
                onClick={onCancel}
                className={classes.cancelButton}>
                {t('annotations.comments.cancel')}
              </Button>
              <Button
                data-e2e-id="edit-comment-to-annotation-button"
                variant="outlined"
                color="primary"
                disabled={comment.length === 0 || saving}
                onClick={onSave}>
                {t('annotations.comments.saveChanges')}
              </Button>
            </ButtonGroup>
          </Box>
        </>
      ) : (
        <span className={classes.text}>
          {/** TODO: Make link style pretty (might need to use another module since this seems to be broken) */}
          <Linkify>{annotationComment.text}</Linkify>
        </span>
      )}
    </div>
  );
}
