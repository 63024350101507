import { Box, Slide, Tooltip, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParam_nextUrl, useQueryParam_panes } from '../../controllers/useGlobalQueryParams';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import AppLogo from '../appLogo/AppLogo';
import { COLORS } from '../../globalThemeSettings';
import Codefy from '../../codefy';
import CreatePublicationButton from './createPublicationButton';
import { E2E } from '../../testing/common/data-e2e-ids';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import { PaneKeys } from '../panes/paneTypes/paneKeys';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { ROUTE_LOGIN } from '../../controllers/routes/routes';
import SearchBar from './searchBar/searchBar';
import Toolbar from '@material-ui/core/Toolbar';
import UploadsButton from '../panes/paneTypes/uploadBatches/uploadsButton';
import { setSearchBarInputValue } from './searchBar/setSearchBarInputValue';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import useIsEveryone from '../../hooks/useIsEveryone';
import { usePaneActions } from '../panes/usePaneActions';
import { useTranslation } from 'react-i18next';
import { useUserSetting_tutorials_closed } from '../../controllers/api/subscriptions/users/userSettings';

const useStyles = makeStyles({
  appBar: {
    display: 'flex',
    backgroundColor: COLORS.backgroundLight,
    color: COLORS.primary,
    marginTop: '3px',
    marginBottom: '-3px',
  },
  logo: {
    cursor: 'pointer',
  },
  flexGrow: { flexGrow: 1 },
});

export function Navbar() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [panes] = useQueryParam_panes();
  const history = useHistory();
  const paneActions = usePaneActions();
  const isEveryone = useIsEveryone();
  const dispatch = useDispatch();
  const userEmail = useSelector((state: Codefy.State) => state.user?.email);
  const userIsAdmin = useSelector((state: Codefy.State) => state.user?.admin);
  const [, set_tutorials_closed] = useUserSetting_tutorials_closed();
  const [, set_nextUrl] = useQueryParam_nextUrl();

  const publicationsFeatureFlagEnabled = useFeatureFlag('publications');

  const onOpenAccount = useCallback(() => {
    if (isEveryone) {
      const nextUrl = window.location.href;
      history.push(ROUTE_LOGIN);
      set_nextUrl(nextUrl);
    } else {
      paneActions.addOrUpdatePane({ paneKey: PaneKeys.account });
      dispatch({ type: 'setSearch', open: false });
    }
  }, [panes]);

  const onShowHelp = useCallback(() => {
    set_tutorials_closed(false);
    paneActions.addOrUpdatePane({ paneKey: PaneKeys.tutorials });
    dispatch({ type: 'setSearch', open: false });
  }, [panes]);

  const onClickLogo = useCallback(() => {
    dispatch({ type: 'setSearch', query: '' });
    setSearchBarInputValue('');
    if (isEveryone) return;
    history.push('/');
  }, []);

  return (
    <Slide in direction="down">
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar variant="dense">
          <div onClick={onClickLogo}>
            <Box mb={-1} ml={0} mr={1.5} className={classes.logo}>
              <AppLogo width="125px" />
            </Box>
          </div>

          <Box ml={1.5} width="100%">
            <SearchBar />
          </Box>

          {publicationsFeatureFlagEnabled && !isEveryone && <CreatePublicationButton />}

          {userIsAdmin && (
            <Link to="/replay">
              <IconButton>
                <PlayCircleFilledIcon />
              </IconButton>
            </Link>
          )}

          {!isEveryone && <UploadsButton />}

          <IconButton aria-label="show help" edge="end" color="inherit" onClick={onShowHelp}>
            <HelpOutlineIcon />
          </IconButton>

          <Tooltip
            title={
              isEveryone
                ? t('account.loginOrRegister') || ''
                : (t('account.title') || '') + ': ' + userEmail
            }>
            <span>
              <IconButton
                aria-label="user account"
                edge="end"
                color="inherit"
                onClick={onOpenAccount}
                data-e2e-id={E2E.account_open_account_pane_button}>
                <AccountCircleIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </Slide>
  );
}
