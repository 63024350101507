import { Box, Grid } from '@material-ui/core';
import {
  useQueryParam_assistant_projectId,
  useQueryParam_taglist_id,
} from '../../../../controllers/useGlobalQueryParams';

import CodefyIcon from '../../../appLogo/codefyIcon';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { TaglistVariants } from '../paneTypes';
import { taglistsExportPrettyExcel } from '../../../../controllers/api/actions/taglists/taglistsExportPrettyExcel';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { usePaneActions } from '../../usePaneActions';
import { useTranslation } from 'react-i18next';
import TaglistSelector from '../taglist/taglistSelector';

export default function AssistantPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const { t } = useTranslation();
  const [assistant_projectId] = useQueryParam_assistant_projectId();
  const [taglist_id] = useQueryParam_taglist_id({
    variant: TaglistVariants.ASSISTANT,
    taglistType: 'annotation',
  });
  const isEveryone = useIsEveryone();
  const paneActions = usePaneActions();
  const { id: currentCaseId, project_directory_id } = useCurrentCase();

  const directory_id = currentCaseId || project_directory_id;

  const onExportPrettyExcel = () => {
    if (directory_id && taglist_id) {
      taglistsExportPrettyExcel({ taglist_id, directory_id });
    }
  };

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={<CodefyIcon />}
      title={
        <span>
          <Grid container alignItems="baseline" spacing={1} wrap="nowrap">
            <Grid item>{t('assistantPane.reviewAssistant')}</Grid>
          </Grid>
        </span>
      }
      paneKey={PaneKeys.assistant}
      menuEntries={[
        {
          key: 'export',
          text: 'Download',
          icon: <GetAppIcon />,
          onClick: onExportPrettyExcel,
          disabled: !directory_id || !taglist_id,
        },
      ]}
      statusBar={
        assistant_projectId ? (
          <Box mt={-0.3}>
            <TaglistSelector
              project_id={assistant_projectId}
              variant={TaglistVariants.ASSISTANT}
              taglistType="annotation"
              showWhenEmpty={t('assistantPane.noTaglists')}
            />
          </Box>
        ) : (
          ''
        )
      }
      readonly={isEveryone}
      onCollapseRightPane={() => {
        paneActions.closePane(PaneKeys.assistantResults);
      }}
    />
  );
}
