import Codefy from '../codefy';
import { useSelector } from 'react-redux';

/** Helper hook that returns if a feature flag is enabled for the current user */
const useFeatureFlag = (
  featureFlag: Codefy.Objects.FeatureFlags,
): /* Currently just boolean, might need to be adjusted in the future if we change the backend */ boolean => {
  const flag = useSelector((state: Codefy.State) => {
    if (!state.user?.feature_flags) return false;

    if (featureFlag in state.user?.feature_flags) {
      return state.user?.feature_flags[featureFlag];
    }
  });

  return !!flag;
};

export default useFeatureFlag;
