import { QueryKey, useInfiniteQuery, useQuery } from 'react-query';
import axios, { AxiosRequestConfig } from 'axios';

import Cookies from 'universal-cookie';

/** Helper function that builds the params in a way that our FastAPI backend
 * understands. This is relevant when submitting arrays. Supports both params and
 * formdata */
export const fastApiSerializer = (
  input: {
    [key: string]: string | number | undefined | string[] | number[] | undefined[] | any;
  },
  resultType: 'params' | 'form' = 'params',
) => {
  const result = resultType === 'params' ? new URLSearchParams() : new FormData();
  for (const [key, value] of Object.entries(input)) {
    if (Array.isArray(value)) {
      for (const element of value) {
        if (element) {
          if (value instanceof File && resultType === 'form') {
            result.append(key, value);
          } else if (typeof element === 'object') {
            result.append(key, JSON.stringify(element));
          } else {
            result.append(key, element.toString());
          }
        }
      }
    } else {
      if (value || value === false || value === 0) {
        if (value instanceof File && resultType === 'form') {
          result.append(key, value);
        } else if (typeof value === 'object') {
          result.append(key, JSON.stringify(value));
        } else {
          result.append(key, value.toString());
        }
      }
    }
  }
  return resultType === 'params' ? result.toString() : result;
};

export const fastApiParamsSerializer = (input: {
  [key: string]: string | number | undefined | string[] | number[] | undefined[] | any;
}): string => fastApiSerializer(input, 'params') as string; // TODO: why does TypeScript throw an error if its not casted?

export const fastApiDataSerializer = (input: {
  [key: string]: string | number | undefined | string[] | number[] | undefined[] | any;
}): FormData => fastApiSerializer(input, 'form') as FormData; // TODO: why does TypeScript throw an error if its not casted?

/** If an API call fails with 401 or 403, it means that the session cookie is not valid,
 * even though it's still saved on the users computer. Therefore, we remove the outdated
 * cookie and reload the window for good measure, then the user can log in again. */
export const handleAuthError = (
  statusCode: number | undefined,
  /** Usually we want to reload, so that the user sees the login screen, but if we
   * already are at the login screen and the get-user API call fires, we would end up
   * in an infinite loop of reloading, so there needs to be an option to not reload.
   * We still want to remove the cookie, since it's outdated if we get a 401/403
   * response. */
  reload = true,
) => {
  /** Only 401 is used when we are actually not authenticated. If we are authenticated,
   * but do something that is forbidden (e.g. try to rename the INBOX), we get a 403
   * (FORBIDDEN), as should be. */
  const notAuthenticatedStatusCode = 401;
  if (statusCode === notAuthenticatedStatusCode) {
    const cookies = new Cookies();
    ['session', 'session-unsafe'].forEach((cookieName) => cookies.remove(cookieName));
    if (reload) {
      window.location.reload();
    }
  }
};

/** Helper function that creates a useQuery hook that makes useQuery work in a way that allows
 * react-query to cancel the outgoing request when the user abandons the component that needs the
 * query */
export function useCreateCancellableQueryFn<RequestParams, ReturnType>(
  queryKey: string,
  axiosUrl: string,
  axiosConfig: (AxiosRequestConfig & { params: RequestParams }) | { data: FormData },
  disabled?: boolean,
) {
  const queryFn = () => {
    const source = axios.CancelToken.source();

    const promise = new Promise((resolve, reject) => {
      axios(axiosUrl, { ...axiosConfig, cancelToken: source.token })
        /* We do this since we only care about the data and just want to work with it right away and
      not have to write `foo.data` in our components */
        .then(({ data }) => resolve(data))
        .catch(reject);
    }) as Promise<ReturnType> & { cancel: () => void };

    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };

  return useQuery(queryKey, queryFn, { keepPreviousData: true, enabled: !disabled });
}

export function useCreateInfiniteQuery<ReturnType>(
  queryKey: QueryKey,
  limit: number,
  axiosUrl: string,
  axiosMethod: AxiosRequestConfig['method'],
  axiosParams: Record<string, any>,
  pageAttribute: string,
  keepPreviousData?: boolean,
  disabled?: boolean,
) {
  limit = limit || axiosParams.limit;

  const queryFn = async (_: any, params: any, offset = 0) => {
    const config =
      axiosMethod === 'GET'
        ? {
            method: axiosMethod,
            params: { ...axiosParams, offset, limit },
            paramsSerializer: fastApiParamsSerializer,
          }
        : {
            method: axiosMethod,
            data: fastApiDataSerializer({ ...axiosParams, offset, limit }),
          };
    const { data } = await axios(axiosUrl, config);
    return { ...data, offset } as ReturnType & { offset: number };
  };

  const getFetchMore = (lastPage: ReturnType & { offset: number }) => {
    try {
      //@ts-ignore
      if (lastPage[pageAttribute].length === 0) return false;
      return lastPage.offset + limit;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return useInfiniteQuery(queryKey, queryFn, {
    getFetchMore,
    keepPreviousData,
    enabled: !disabled,
  });
}
