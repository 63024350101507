import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiDataSerializer } from '../../subscriptionHelpers';
import stringify from 'json-stable-stringify';
import { useQuery } from 'react-query';

type SearchAutocompleteRequestParams = {
  query: string;
  document_ids?: Codefy.Objects.Document['id'][];
  entry_ids?: Codefy.Objects.Entry['id'][];
  directory_ids?: Codefy.Objects.Directory['id'][];
  min_num_pages?: number;
  max_num_pages?: number;
  min_created_at?: string;
  max_created_at?: string;
  languages?: string[]; // actually Language[] but useQueryParams only thinks of it as "StringParam"
  limit?: number;
};

type SearchAutocompleteReturnType = {
  query: string;
  results: Codefy.Objects.SearchAutocomplete[];
  debug?: Codefy.Objects.SearchDebug;
};

export const searchAutocompleteKey = (params: SearchAutocompleteRequestParams): string =>
  'searchAutocomplete' + stringify(params);

export const useSearchAutocomplete = (params: SearchAutocompleteRequestParams) => {
  const limit = params.limit || 10;

  const queryFn = () => {
    const source = axios.CancelToken.source();

    const promise = new Promise((resolve, reject) => {
      if (!params.query) {
        return resolve({
          query: '',
          results: [],
        });
      }

      axios({
        url: '/api/v1/search/autocomplete',
        method: 'POST',
        data: fastApiDataSerializer({
          ...params,
          limit,
          /** Trim space (e.g. "term "), to reduce requests sent to backend and fix some weird
           * inconsistencies the backend has between "term" and "term " (it gives slightly different results) */
          query: params.query.trim(),
        }),
        cancelToken: source.token,
      })
        /* We do this since we only care about the data and just want to work with it right away and
      not have to write `foo.data` in our components */
        .then(({ data }) => resolve(data))
        .catch(reject);
    }) as Promise<SearchAutocompleteReturnType> & { cancel: () => void };

    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };

  return useQuery(searchAutocompleteKey(params), queryFn, { keepPreviousData: true });
};
