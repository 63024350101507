import './droppableAreasWhileDraggingStyle.css';

import { Container, DropResult } from 'react-smooth-dnd';
import React, { useCallback, useState } from 'react';

import Codefy from '../../../../codefy';
import { entriesMove } from '../../../../controllers/api/actions/entries/entriesMove';

export const DIRECTORY_DROP_GROUP_NAME = 'directoryDrop';

export type DropPayload = { type: 'moveEntry'; data?: Codefy.Objects.Entry };

/** Allows dragging entries and search queries into directories. Entries will be moved, search
 * queries will be saved (as new entries of type 'saved search' in that directory) */
export default function DirectoryDropWrapper({
  directory_id,
  children,
}: {
  directory_id?: Codefy.Objects.Directory['id'];
  children: React.ReactChild | React.ReactChild[];
}) {
  const [dragStarted, setDragStarted] = useState(false);
  const [dragEntered, setDragEntered] = useState(false);

  const onDragStart = useCallback(() => setDragStarted(true), []);
  const onDragEnd = useCallback(() => setDragStarted(false), []);
  const onDragEnter = useCallback(() => setDragEntered(true), []);
  const onDragLeave = useCallback(() => setDragEntered(false), []);

  const dragStartedClassName = 'blinking_droppable';
  const dragEnteredClassName = 'blinking_droppable_hover';
  const defaultClassName = '';

  const onDropEntry = useCallback(
    (props: DropResult) => {
      setDragStarted(false);
      setDragEntered(false);

      /** This is very important because every target project is a container and every project will
       * receive this props so we need to make sure to only actually execute the move on the actual
       * projec the user dropped the entry on */
      if (props.addedIndex !== 0) return;

      const payload = props.payload as DropPayload;
      if (!directory_id) return;

      switch (payload.type) {
        case 'moveEntry':
          if (payload.data?.id) entriesMove({ entry_ids: [payload.data.id], directory_id });
          break;
      }
    },
    [directory_id],
  );

  const shouldAcceptDrop = useCallback(() => !!directory_id, [directory_id]);

  return (
    <div
      className={
        shouldAcceptDrop()
          ? dragEntered
            ? dragEnteredClassName
            : dragStarted
            ? dragStartedClassName
            : defaultClassName
          : defaultClassName
      }>
      <Container
        groupName={DIRECTORY_DROP_GROUP_NAME}
        behaviour="drop-zone"
        shouldAcceptDrop={shouldAcceptDrop}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDropEntry}>
        {children}
      </Container>
    </div>
  );
}
