/* Websocket event types as they come in from the backend */

export const PROJECT_RENAMED = 'project_renamed';
export const PROJECT_DELETED = 'project_deleted';
export const PROJECT_SHARED = 'project_shared';
export const PROJECT_UNSHARED = 'project_unshared';
export const ENTRY_RENAMED = 'entry_renamed';
export const ENTRY_MOVED_TO = 'entry_moved_to';
export const ENTRY_DELETED = 'entry_deleted';
export const CACHE_INVALIDATE = 'cache_invalidate';
export const ANNOTATION_DELETED = 'annotation_deleted';
export const ANNOTATION_CREATED = 'annotation_created';
export const ANNOTATION_UPDATED = 'annotation_updated';
export const TAGLIST_CREATED = 'taglist_created';
export const TAGLIST_DELETED = 'taglist_deleted';
export const TAGLIST_UPDATED = 'taglist_updated';
export const TAG_CREATED = 'tag_created';
export const TAG_DELETED = 'tag_deleted';
export const TAG_UPDATED = 'tag_updated';
export const USER_QUOTA_ALMOST_EXCEEDED = 'user_quota_almost_exceeded';
