import { DEFAULT_PANES, getPaneElementId } from './panesViewport';
import {
  GLOBAL_QUERY_PARAMS,
  useQueryParam_focusedPane,
  useQueryParam_panes,
} from '../../controllers/useGlobalQueryParams';

import { ModalKeys } from '../old_modals/modalTypes';
import { PaneKeys } from './paneTypes/paneKeys';
import { connectedPanes } from './usePaneConnected';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useUpdateUrlParams } from '../../hooks/useUpdateUrlParams';
import { useUserSetting_tutorials_closed } from '../../controllers/api/subscriptions/users/userSettings';

export function usePaneActions() {
  /*
   Note that the setQueries should be one atomic operation (i.e. don't write several `setQuery`s throughout a
   function) so that the users back button works.

   Note also that the order of the spreads is essential when resetting params!
   */

  const history = useHistory();
  const updateUrlParams = useUpdateUrlParams();
  const [focusedPane, set_focusedPane] = useQueryParam_focusedPane();
  const [panes] = useQueryParam_panes();
  const [, set_tutorials_closed] = useUserSetting_tutorials_closed();

  const getPanes = () => panes || DEFAULT_PANES;

  /** Returns the params object necessary to remove all params for a pane. This should ALWAYS be the
   * first parameter when spreading, i.e. {...getParamsToResetPane(pane), ...otherParams}, because
   * otherwise we will overwrite the other params! */
  const getParamsToResetPane = (
    pane: PaneKeys | ModalKeys,
  ): { [key in GLOBAL_QUERY_PARAMS]?: undefined } => {
    const resetParams: { [key: string]: undefined } = {};

    const urlParams = queryString.parse(history.location.search);

    Object.keys(urlParams).forEach((key) => {
      if (key.startsWith(pane + '_')) {
        resetParams[key] = undefined;
      }
    });

    return resetParams;
  };

  const focusPane = (paneToFocus: PaneKeys) => {
    if (focusedPane !== paneToFocus) set_focusedPane(paneToFocus);
    document
      .getElementById(getPaneElementId(paneToFocus))
      ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
  };

  const addOrUpdatePane = ({
    paneKey,
    params,
    reset,
    inBackground,
  }: {
    paneKey: PaneKeys;
    params?: {
      [key in GLOBAL_QUERY_PARAMS]?: any;
    };
    /** Should all other params that are currently set for this pane be removed first? */
    reset?: boolean;
    /** Position the pane should appear in */
    position?: number;
    /** If true, the pane is not focused after adding/updating */
    inBackground?: boolean;
  }) => {
    const panes = getPanes();

    if (reset) {
      params = { ...getParamsToResetPane(paneKey), ...params };
    }

    if (!panes?.includes(paneKey)) panes.push(paneKey);

    /* This should be one atomic operation (i.e. don't write several `setQuery`s throughout a
    function) so that the users back button works */
    /* The order of the spreads is essential! */

    updateUrlParams({ ...params, panes, focusedPane: paneKey }, 'pushIn');

    if (!inBackground) {
      const checkExist = setInterval(function () {
        /** Since React is async, there might be a race condition resulting in the scroll-into-view
         * being executed before the pane gets rendered, therefore we need this workaround */
        if (document.getElementById(`pane-${paneKey}`)) {
          focusPane(paneKey);
          clearInterval(checkExist);
        }
      }, 100); // check every 100ms
    }
  };

  const maximizePane = (paneKeyToMaximize: PaneKeys) => {
    const panes = getPanes();

    updateUrlParams({ panes: panes?.filter((pane) => pane === paneKeyToMaximize) }, 'pushIn');
  };

  const closePane = (paneKeyToClose: PaneKeys) => {
    /** Connected panes always close themselves together */
    let connectedPanesCloseParams = {};
    let connectedPanesClosePaneKey: PaneKeys[] = [];
    connectedPanes.map(([left, right]) => {
      if (paneKeyToClose === left) {
        connectedPanesCloseParams = {
          ...connectedPanesCloseParams,
          ...getParamsToResetPane(right),
        };
        connectedPanesClosePaneKey = [...connectedPanesClosePaneKey, right];
      }
    });

    const panes = getPanes();
    const params = {
      /* The order of the spreads is essential! */
      ...getParamsToResetPane(paneKeyToClose),
      ...connectedPanesCloseParams,
      panes: panes?.filter(
        (pane) => pane !== paneKeyToClose && !connectedPanesClosePaneKey.includes(pane),
      ),
    };

    /** Closing the tutorials closes them forever */
    if (paneKeyToClose === PaneKeys.tutorials) {
      set_tutorials_closed(true);
    }

    /* This should be one atomic operation (i.e. don't write several `setQuery`s throughout a
    function) so that the users back button works */
    updateUrlParams(params, 'pushIn');
  };

  return {
    addOrUpdatePane,
    maximizePane,
    closePane,
    focusPane,
  };
}
