/** From https://github.com/azmenak/material-ui-nested-menu-item with some modifications, mostly
 * style*/

import { COLORS, FONT_SIZES, useGlobalStyles } from '../../globalThemeSettings';
import { ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import React, { useImperativeHandle, useRef, useState } from 'react';

import ArrowRight from '@material-ui/icons/ArrowRight';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import { MenuProps } from '@material-ui/core/Menu';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

export interface NestedMenuItemProps extends Omit<MenuItemProps, 'button'> {
  /**
   * Open state of parent `<Menu />`, used to close decendent menus when the
   * root menu is closed.
   */
  parentMenuOpen: boolean;
  /**
   * Component for the container element.
   * @default 'div'
   */
  component?: React.ElementType;
  /**
   * Effectively becomes the `children` prop passed to the `<MenuItem/>`
   * element.
   */
  label?: React.ReactNode;
  /**
   * @default <ArrowRight />
   */
  rightIcon?: React.ReactNode;
  /**
   * Props passed to container element.
   */
  ContainerProps?: React.HTMLAttributes<HTMLElement> & React.RefAttributes<HTMLElement | null>;
  /**
   * Props passed to sub `<Menu/>` element
   */
  MenuProps?: Omit<MenuProps, 'children'>;
  /**
   * @see https://material-ui.com/api/list-item/
   */
  button?: true | undefined;
  /**
   * Icon shown to the left
   */
  icon?: React.ReactNode;
}

const TRANSPARENT = 'rgba(0,0,0,0)';
const useMenuItemsStyle = makeStyles((theme) => ({
  root: (props: any) => ({
    backgroundColor: props.open ? theme.palette.action.hover : TRANSPARENT,
    width: '100%',
    color: COLORS.primary,
    fontSize: FONT_SIZES.default,
  }),
  space: {
    flexGrow: 1,
  },
}));

/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
const NestedMenuItem = React.forwardRef<HTMLLIElement | null, NestedMenuItemProps>(
  function NestedMenuItem(props, ref) {
    const {
      parentMenuOpen,
      label,
      rightIcon = <ArrowRight />,
      children,
      icon,
      className,
      tabIndex: tabIndexProp,
      ContainerProps: ContainerPropsProp = {},
      ...MenuItemProps
    } = props;

    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

    /** A nested menus are only opened after a delay, to avoid accidental openings when flying over
     * them with the mouse */
    const [delayTimer, setDelayTimer] = useState<NodeJS.Timeout>();

    const menuItemRef = useRef<HTMLLIElement>(null);
    //@ts-ignore
    useImperativeHandle(ref, () => menuItemRef.current);

    const containerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = useRef<HTMLDivElement>(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
      setDelayTimer(
        setTimeout(() => {
          setIsSubMenuOpen(true);

          if (ContainerProps?.onMouseEnter) {
            ContainerProps.onMouseEnter(event);
          }
          /** https://www.nngroup.com/articles/timing-exposing-content/ suggests at least 0.3s, but
           * I find 0.25s to be better in combination with the fact that clicking the nested menu
           * does not open it for some reason (the user still has to wait the full 0.25s). TODO ? */
        }, 250),
      );
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(true);

      if (ContainerProps?.onMouseEnter) {
        ContainerProps.onMouseEnter(event);
      }
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(false);

      /* Cancels a timer, this is important or else the nested menu will open even though the user's
     mouse has moved on! */
      if (delayTimer) {
        clearTimeout(delayTimer);
        setDelayTimer(undefined);
      }

      if (ContainerProps?.onMouseLeave) {
        ContainerProps.onMouseLeave(event);
      }
    };

    // Check if any immediate children are active
    const isSubmenuFocused = () => {
      const active = containerRef.current?.ownerDocument?.activeElement;
      //@ts-ignore
      for (const child of menuContainerRef.current?.children ?? []) {
        if (child === active) {
          return true;
        }
      }
      return false;
    };

    const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
      if (event.target === containerRef.current) {
        setIsSubMenuOpen(true);
      }

      if (ContainerProps?.onFocus) {
        ContainerProps.onFocus(event);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        return;
      }

      if (isSubmenuFocused()) {
        event.stopPropagation();
      }

      const active = containerRef.current?.ownerDocument?.activeElement;

      if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
        containerRef.current?.focus();
      }

      if (
        event.key === 'ArrowRight' &&
        event.target === containerRef.current &&
        event.target === active
      ) {
        const firstChild = menuContainerRef.current?.children[0] as HTMLElement | undefined;
        firstChild?.focus();
      }
    };

    const open = isSubMenuOpen && parentMenuOpen;
    const menuItemClasses = useMenuItemsStyle({ open });
    const globalClasses = useGlobalStyles();

    // Root element must have a `tabIndex` attribute for keyboard navigation
    let tabIndex;
    if (!props.disabled) {
      tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return (
      <div
        {...ContainerProps}
        ref={containerRef}
        onFocus={handleFocus}
        tabIndex={tabIndex}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        style={{ width: '100%' }}>
        <MenuItem
          {...MenuItemProps}
          className={clsx(menuItemClasses.root, className)}
          ref={menuItemRef}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <span className={globalClasses.listEntry}>{label}</span>
          <div className={menuItemClasses.space} />
          {rightIcon}
        </MenuItem>
        <Menu
          // Set pointer events to 'none' to prevent the invisible Popover div
          // from capturing events for clicks and hovers
          style={{ pointerEvents: 'none' }}
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => {
            setIsSubMenuOpen(false);
          }}>
          <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
            {children}
          </div>
        </Menu>
      </div>
    );
  },
);

export default NestedMenuItem;
