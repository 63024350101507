import { Box, ListItemSecondaryAction } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  useIsPaneOpen,
  useQueryParam_pdfViewer_documentId,
  useQueryParam_taglist_id,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import DirectoryDropWrapper from './directoryDropWrapper';
import DirectoryFileUploadWrapper from './directoryFileUploadWrapper';
import EntryContextMenuWrapper from './entryContextMenuWrapper';
import EntryFileTypeIcon from './entryFileTypeIcon';
import EntryMoveDragWrapper from './entryMoveDragWrapper';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaneKeys } from '../paneKeys';
import StarIcon from '@material-ui/icons/Star';
import TagLabels from '../../../tagLabels/tagLabelsRenderer';
import { TaglistVariants } from '../paneTypes';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import useHover from '../../../../hooks/useHover';
import { usePaneActions } from '../../usePaneActions';

// TODO: Refactor/Cleanup

export function EntriesListItem({
  entry,
  hideOpenInParentDirectory,
}: {
  entry: Codefy.Objects.Entry;
  hideOpenInParentDirectory?: boolean;
}) {
  const globalClasses = useGlobalStyles();

  const operationsTagsEnabled = useFeatureFlag('operations_tags');

  const paneActions = usePaneActions();

  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(1);
  const [taglist_id] = useQueryParam_taglist_id({
    variant: TaglistVariants.TAGLIST,
    taglistType: 'entry',
  });
  const [annotationTaglist_id] = useQueryParam_taglist_id({
    variant: TaglistVariants.TAGLIST,
    taglistType: 'annotation',
  });

  const annotationTaglistOpen = useIsPaneOpen(PaneKeys.annotationTaglist);
  const entryTaglistOpen = useIsPaneOpen(PaneKeys.entryTaglist);

  const [hoverRef, isHovered] = useHover();

  const onOpenEntry = () => {
    if (entry.directory) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.entriesList,
        params: { entriesList_directoryId: entry.directory.id },
      });
    } else if (entry.document) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.pdfViewer,
        params: {
          pdfViewer_documentId: entry.document.id,
          /* Opening a document from the file list should always open on the first page, even if
          a "last read page" value is available */
          pdfViewer_page: 1,
        },
        reset: true,
      });
    } else if (entry.taglist) {
      if (entry.taglist.type === 'annotation') {
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.annotationTaglist,
          params: { annotationTaglist_id: entry.taglist.id },
        });
        paneActions.closePane(PaneKeys.annotationTag);
      } else if (entry.taglist.type === 'entry') {
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.entryTaglist,
          params: { entryTaglist_id: entry.taglist.id },
        });
        paneActions.closePane(PaneKeys.entryTag);
      }
    }
  };
  const name = useMemo(() => {
    switch (entry.mimetype) {
      case 'inode/directory':
        return entry.name;
      default:
        return entry.name;
    }
  }, [entry.name, entry.mimetype]);

  const primaryText = (
    <span>
      <span className={globalClasses.listEntry}>{name}</span>{' '}
      {entry.starred && (
        <StarIcon
          fontSize="small"
          color="inherit"
          style={{ color: '#003366', marginBottom: '-4px', marginLeft: '3px' }}
        />
      )}
      {entry.tag_instances && entry.tag_instances.length > 0 && operationsTagsEnabled && (
        <>
          <br />
          <TagLabels
            tagInstances={entry.tag_instances}
            icon="tag"
            lineBreaks={false}
            fullWidth
            taglistType="entry"
          />
        </>
      )}
    </span>
  );

  const icon = useMemo(() => {
    switch (entry.mimetype) {
      case 'inode/directory':
        return <FolderOpenIcon />;
      case 'inode/case':
        return <EntryFileTypeIcon entryMimetype={entry.mimetype} size={22} />;
      default:
        return (
          <Box ml={'1px'}>
            <EntryFileTypeIcon entryMimetype={entry.mimetype} size={22} />
          </Box>
        );
    }
  }, [!!entry.directory, entry.id, entry.directory?.id, entry.mimetype]);

  return (
    <EntryContextMenuWrapper entry={entry} hideOpenInParentDirectory={hideOpenInParentDirectory}>
      <DirectoryDropWrapper directory_id={entry.directory?.id}>
        <EntryMoveDragWrapper entry={entry}>
          <DirectoryFileUploadWrapper directory_id={entry.directory?.id}>
            <div ref={hoverRef}>
              <ListItem
                button
                divider
                selected={
                  (entry.document && pdfViewer_documentId === entry.document?.id) ||
                  (entryTaglistOpen && entry.taglist && taglist_id === entry.taglist.id) ||
                  (annotationTaglistOpen &&
                    entry.taglist &&
                    annotationTaglist_id == entry.taglist.id)
                }
                disabled={entry.document && entry.document?.status !== 1}
                onClick={onOpenEntry}>
                <ListItemIcon className={globalClasses.narrowListItemIcon}>{icon}</ListItemIcon>
                <Box pr={5}>
                  <ListItemText primary={primaryText} />
                </Box>
              </ListItem>
              <ListItemSecondaryAction style={isHovered ? {} : { opacity: 0 }}>
                <EntryContextMenuWrapper
                  entry={entry}
                  openOnLeftClick
                  hideOpenInParentDirectory={hideOpenInParentDirectory}>
                  <IconButton edge="end" aria-label="delete" size="small">
                    <MoreVertIcon />
                  </IconButton>
                </EntryContextMenuWrapper>
              </ListItemSecondaryAction>
            </div>
          </DirectoryFileUploadWrapper>
        </EntryMoveDragWrapper>
      </DirectoryDropWrapper>
    </EntryContextMenuWrapper>
  );
}
