import { ListRange, Virtuoso } from 'react-virtuoso';
import React, { useCallback } from 'react';
import {
  useUserSetting_entries_descending,
  useUserSetting_entries_filterByMimetype,
  useUserSetting_entries_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import Codefy from '../../../../codefy';
import DirectoryFileUploadWrapper from './directoryFileUploadWrapper';
import DirectoryParents from './directoryParents';
import { EntriesListItem } from './entriesListItem';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
//@ts-ignore
import useDimensions from 'react-use-dimensions';
import { useEntriesList } from '../../../../controllers/api/subscriptions/entries/entriesList';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import { useQueryParam_entriesList_directoryId } from '../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

export const DEFAULT_SORTED_BY = 'mimetype';
export const DEFAULT_DESCENDING = true;

// TODO: Add ability to act on multiple items

export default function EntriesListContent() {
  const { t } = useTranslation();
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();

  const [ref, { height }] = useDimensions();

  const operationsTagsEnabled = useFeatureFlag('operations_tags');

  const [entriesList_orderBy] = useUserSetting_entries_orderBy();
  const [entriesList_descending] = useUserSetting_entries_descending();
  const [entriesList_filterByMimetype] = useUserSetting_entries_filterByMimetype();

  const { data: entriesPages, fetchMore, canFetchMore, isLoading } = useEntriesList({
    directory_ids: entriesList_directoryId ? [entriesList_directoryId] : undefined,
    order_by: entriesList_orderBy,
    descending: entriesList_descending,
    mimetypes: entriesList_filterByMimetype,
  });

  const entriesList =
    entriesPages?.reduce((acc: Codefy.Objects.Entry[], cur) => {
      for (const entry of cur.entries) {
        if (entry.mimetype === 'application/codefy.entry-taglist' && !operationsTagsEnabled) {
          continue;
        }

        acc.push(entry);
      }
      return acc;
    }, []) || [];

  const renderDocument = (index: number) => {
    if (!entriesList[index]) return <span></span>;
    return <EntriesListItem entry={entriesList[index]} hideOpenInParentDirectory={true} />;
  };

  /** Triggered when the user scrolls in the list */
  const onRangeChanged = useCallback(
    (range: ListRange) => {
      /** If user didn't scroll far enough yet */
      if (range.endIndex < entriesList.length - 50) return;

      /** If there is nothing more to load */
      if (!canFetchMore) return;

      fetchMore();
    },
    [entriesList.length, canFetchMore],
  );

  if (isLoading && entriesList.length === 0) {
    return (
      <div>
        {<DirectoryParents />}
        <PaneContentLoading />
      </div>
    );
  }

  if (entriesList.length === 0)
    return (
      <>
        {<DirectoryParents />}
        <DirectoryFileUploadWrapper directory_id={entriesList_directoryId}>
          <PaneContentEmpty text={t('entriesList.noEntries')} />
        </DirectoryFileUploadWrapper>
      </>
    );

  return (
    <>
      <div ref={ref}>{<DirectoryParents />}</div>
      <DirectoryFileUploadWrapper directory_id={entriesList_directoryId}>
        {/* This is a "hack" so that the last elements are not hidden because the
        DirectoryParents components moves them out of the visible area.
        This should be reworked if the virtualization component is ever reworked/updated */}
        <div style={{ height: `calc(100% - ${height}px)` }}>
          <Virtuoso
            totalCount={entriesList?.length || 0}
            itemContent={renderDocument}
            rangeChanged={onRangeChanged}
          />
        </div>
      </DirectoryFileUploadWrapper>
    </>
  );
}
