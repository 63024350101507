import React, { useState } from 'react';

import GenericDeleteDialog from '../genericDeleteDialog';
import { toast } from 'react-toastify';
import { useBetween } from 'use-between';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { userDeleteAccount } from '../../../controllers/api/actions/user/userDeleteAccount';

type DialogDeleteAccountStateType = {};

const useDialogDeleteAccountState = () => useState<DialogDeleteAccountStateType>();

const useSharedDialogDeleteAccountState = () => useBetween(useDialogDeleteAccountState);

export const DeleteAccountDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogDeleteAccountState();

  const history = useHistory();

  const onClose = () => setState(undefined);

  const onDelete = async () => {
    await userDeleteAccount()();
    toast.success(t('account.deleteAccount.success'));
    history.push('/');
  };

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted deletions. */
  if (!state) return null;

  return (
    <GenericDeleteDialog
      open={!!state}
      title={t('account.deleteAccount.reallySure')}
      onDelete={onDelete}
      onClose={onClose}
    />
  );
};

export const useOpenDeleteAccountDialog = () => {
  const [, setState] = useSharedDialogDeleteAccountState();
  return (state: DialogDeleteAccountStateType) => () => setState(state);
};
