import Codefy from '../../../../../codefy';
import { axios } from '@use-hooks/axios';
import { useQuery } from 'react-query';

export const TAGS_GET_URL = '/api/v1/taglists/tags/get';

export const TAGS_GET_KEY_TYPE = 'tagsGet';

export const tagsGetKey = (
  id?: Codefy.Objects.Tag['id'],
): [string, Codefy.Objects.Tag['id'] | undefined] => [TAGS_GET_KEY_TYPE, id];

export const useTagsGet = (tag_id?: Codefy.Objects.Tag['id']) => {
  return useQuery(tagsGetKey(tag_id), async (key: string, tag_id?: Codefy.Objects.Tag['id']) => {
    if (!tag_id) return undefined;
    const { data } = await axios.get(TAGS_GET_URL, { params: { tag_id: tag_id } });
    return data as Codefy.Objects.Tag;
  });
};
