import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import React, { ChangeEventHandler, useEffect, useState } from 'react';

import Codefy from '../../codefy';
import { GenericDialog } from './genericDialog';
import { PublicationsCreateRequestType } from '../../controllers/api/actions/publications/publicationsCreate';
import { projectsShare } from '../../controllers/api/actions/projects/projectsShare';
import { projectsUnshare } from '../../controllers/api/actions/projects/projectsUnshare';
import { useCopyToClipboard } from 'react-use';
import { useGlobalStyles } from '../../globalThemeSettings';
import { useProjectsGet } from '../../controllers/api/subscriptions/projects/projectsGet';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export type GenericPublicationDialogOnDoneCreate = (props: {
  title: PublicationsCreateRequestType['title'];
  description: PublicationsCreateRequestType['description'];
}) => Promise<Codefy.Objects.Publication | undefined>;

export type GenericPublicationDialogOnDoneSave = (props: {
  publication_id: Codefy.Objects.Publication['id'];
  title: PublicationsCreateRequestType['title'];
  description: PublicationsCreateRequestType['description'];
}) => Promise<Codefy.Objects.Publication | undefined>;

export const GenericPublicationDialog = ({
  currentPublication,
  projectName,
  imageDataUrl,
  onDoneCreate,
  onDoneSave,
  onClose,
}: {
  currentPublication?: Codefy.Objects.Publication;
  projectName: Codefy.Objects.Project['name'];
  imageDataUrl: string;
  onDoneCreate: GenericPublicationDialogOnDoneCreate;
  onDoneSave: GenericPublicationDialogOnDoneSave;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const userEmail = useSelector((state: Codefy.State) => state.user?.email);

  const [copied, copyToClipboard] = useCopyToClipboard();

  const [publication, setPublication] = useState<Codefy.Objects.Publication | undefined>(
    currentPublication,
  );

  const { data: project } = useProjectsGet(publication?.project_id);
  const projectIsPublic = !!project?.permissions.find(
    (permission) => permission.user.email === 'everyone',
  );

  const [title, setTitle] = useState(currentPublication?.title || projectName);
  const [description, setDescription] = useState(
    currentPublication?.description || 'A Codefy workspace created by ' + userEmail,
  );

  const onCreate = async () => {
    if (!title || !description) return;
    const publicationResponse = await onDoneCreate({ title, description });
    setPublication(publicationResponse);
  };

  const onSave = async () => {
    if (!title || !description || !publication?.id) return;
    const publicationResponse = await onDoneSave({
      publication_id: publication?.id,
      title,
      description,
    });
    setPublication(publicationResponse);
  };

  const onChangeTitle: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) =>
    setTitle(event.target.value.replace('\n', ''));

  const onChangeDescription: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) =>
    setDescription(event.target.value.replace('\n', ''));

  const link: string | undefined =
    publication && `${window.location.protocol}//${window.location.hostname}/p/${publication.uuid}`;

  /* Automatically copy the link to the clipboard */
  useEffect(() => {
    if (!link) return;
    copyToClipboard(link);
  }, [link]);

  return (
    <GenericDialog
      title={
        currentPublication
          ? t('publicationDialog.editPublication')
          : t('publicationDialog.createNewPublication')
      }
      onClose={onClose}
      open
      maxWidth="sm">
      <Box>
        <Box p={2} maxWidth={500} ml="auto" mr="auto">
          <Paper elevation={3}>
            <Box maxHeight={250} overflow="hidden">
              <img src={imageDataUrl} style={{ width: '100%' }} />
            </Box>

            <Box p={2}>
              <TextField
                InputProps={{
                  className: globalClasses.heading,
                  style: { fontSize: '130%' },
                  disableUnderline: true,
                  autoFocus: true,
                }}
                multiline
                placeholder={t('publicationDialog.title')}
                value={title}
                onChange={onChangeTitle}
                fullWidth
                autoFocus
              />
              <TextField
                InputProps={{ className: globalClasses.text, disableUnderline: true }}
                multiline
                placeholder={t('publicationDialog.description')}
                value={description}
                onChange={onChangeDescription}
                fullWidth
                autoFocus
              />
            </Box>
          </Paper>
        </Box>
        <Box mt={2} ml="auto" mr="auto">
          {!publication && (
            <Button fullWidth className={globalClasses.dialogButton} onClick={onCreate}>
              {t('publicationDialog.createShareLink')}
            </Button>
          )}
          {publication && (publication.title !== title || publication.description !== description) && (
            <Button fullWidth hidden className={globalClasses.dialogButton} onClick={onSave}>
              {t('publicationDialog.save')}
            </Button>
          )}
          {publication && link && (
            <>
              <Box m={2}>
                <TextField
                  value={link}
                  fullWidth
                  onClick={() => copyToClipboard(link)}
                  InputProps={{
                    style: { fontSize: '130%', alignSelf: 'center' },
                    disableUnderline: true,
                    className: globalClasses.heading,
                    endAdornment: copied.value === link && (
                      <Box ml={1}>
                        <span className={globalClasses.textLight}>
                          {t('publicationDialog.copied')}
                        </span>
                      </Box>
                    ),
                  }}
                />
                <Box mt={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedA"
                        color="primary"
                        checked={projectIsPublic}
                        onChange={(event, checked) => {
                          if (checked) {
                            projectsShare({
                              project_ids: [publication.project_id],
                              email: 'everyone',
                              write: false,
                            });
                          } else {
                            projectsUnshare({
                              project_ids: [publication.project_id],
                              email: 'everyone',
                            });
                          }
                        }}
                      />
                    }
                    label={<span>{t('publicationDialog.publishHelperText', { projectName })}</span>}
                  />
                </Box>
              </Box>
              {projectIsPublic && (
                <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                  <Grid container spacing={2} alignItems="center" justify="center">
                    <Grid item>
                      <LinkedinShareButton
                        url={link}
                        title={title}
                        summary={description}
                        source={window.location.hostname}>
                        <LinkedinIcon size={24} round />
                      </LinkedinShareButton>
                    </Grid>
                    <Grid item>
                      <TwitterShareButton url={link} title={title} via={window.location.hostname}>
                        <TwitterIcon size={24} round />
                      </TwitterShareButton>
                    </Grid>
                    <Grid item>
                      <WhatsappShareButton url={link} title={title}>
                        <WhatsappIcon size={24} round />
                      </WhatsappShareButton>
                    </Grid>
                    <Grid item>
                      <FacebookShareButton url={link} quote={`${title}: ${description}`}>
                        <FacebookIcon size={24} round />
                      </FacebookShareButton>
                    </Grid>
                    <Grid item>
                      <EmailShareButton url={link} subject={title} body={description}>
                        <EmailIcon size={24} round />
                      </EmailShareButton>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </GenericDialog>
  );
};
