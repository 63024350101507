import Codefy from '../../../../codefy';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { UPLOAD_BATCHES_GET_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesGet';
import { UPLOAD_BATCHES_LIST_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesListBatches';
import { UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesListBatchEntries';
import { executeAction } from '../actionHelpers';

export const projectsDelete = (data: { project_ids: Codefy.Objects.Project['id'][] }) =>
  executeAction<Codefy.Objects.Project>({
    config: { url: '/api/v1/projects/delete', method: 'POST' },
    data,
    queryKeysToRefresh: [
      PROJECTS_LIST_KEY_TYPE,
      UPLOAD_BATCHES_LIST_KEY_TYPE,
      UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE,
      UPLOAD_BATCHES_GET_KEY_TYPE,
    ],
  });
