import React from 'react';

/** The Codefy application logo as an SVG vector, in full, rectangular form, that is: <C>odefy */
const AppLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 439.45 118.25"
    width="105px"
    height="35px"
    {...props}>
    <defs>
      <style>
        {`.cls-1{fill:#036;}`}
        {`.cls-2{fill:#36c;}`}
      </style>
    </defs>
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Layer_1" data-name="Layer 1">
        <path
          className="cls-1"
          d="M104.58,89.69a31.56,31.56,0,0,1-12-11.94A34,34,0,0,1,88.21,60.5a34,34,0,0,1,4.37-17.25,31,31,0,0,1,12-11.87,36.63,36.63,0,0,1,34.25,0,30.62,30.62,0,0,1,11.94,11.87,34.45,34.45,0,0,1,4.31,17.25,34.45,34.45,0,0,1-4.31,17.25,31.13,31.13,0,0,1-11.94,11.94,36.18,36.18,0,0,1-34.25,0Zm29.69-6.75A22.29,22.29,0,0,0,143,73.87a28,28,0,0,0,3.12-13.37A28,28,0,0,0,143,47.13a22.29,22.29,0,0,0-8.69-9.07,26.41,26.41,0,0,0-25.13,0,22.76,22.76,0,0,0-8.75,9.07A27.61,27.61,0,0,0,97.21,60.5a27.61,27.61,0,0,0,3.18,13.37,22.76,22.76,0,0,0,8.75,9.07,26.41,26.41,0,0,0,25.13,0Z"
        />
        <path
          className="cls-1"
          d="M232.58.63V93.37h-8.5v-13A27.08,27.08,0,0,1,213.52,90.5,31,31,0,0,1,198.71,94,33.4,33.4,0,0,1,182,89.75a30.3,30.3,0,0,1-11.75-11.88A35,35,0,0,1,166,60.5a35,35,0,0,1,4.25-17.37A30,30,0,0,1,182,31.31a33.77,33.77,0,0,1,16.75-4.18,31,31,0,0,1,14.43,3.31,27.34,27.34,0,0,1,10.57,9.69V.63ZM211.89,82.94a22.62,22.62,0,0,0,8.75-9.07,27.5,27.5,0,0,0,3.19-13.37,27.5,27.5,0,0,0-3.19-13.37,22.62,22.62,0,0,0-8.75-9.07,24.57,24.57,0,0,0-12.43-3.18,24.88,24.88,0,0,0-12.57,3.18,22.76,22.76,0,0,0-8.75,9.07A27.61,27.61,0,0,0,175,60.5a27.61,27.61,0,0,0,3.18,13.37,22.76,22.76,0,0,0,8.75,9.07,24.88,24.88,0,0,0,12.57,3.18A24.57,24.57,0,0,0,211.89,82.94Z"
        />
        <path
          className="cls-1"
          d="M314,63.25H259a23.5,23.5,0,0,0,7.87,16.56q7.13,6.31,18,6.31a28.35,28.35,0,0,0,11.25-2.18A24.63,24.63,0,0,0,305,77.5l5,5.75a28.19,28.19,0,0,1-10.94,8A37.08,37.08,0,0,1,284.58,94a36.57,36.57,0,0,1-17.94-4.31,31.08,31.08,0,0,1-12.18-11.94,34,34,0,0,1-4.38-17.25,35.24,35.24,0,0,1,4.19-17.25,30.26,30.26,0,0,1,11.5-11.87,32,32,0,0,1,16.44-4.25,31.69,31.69,0,0,1,16.37,4.25A30.15,30.15,0,0,1,310,43.19a35.55,35.55,0,0,1,4.12,17.31ZM266.27,40.81A23.84,23.84,0,0,0,259,56.63h46.62a23.84,23.84,0,0,0-7.31-15.82,24.15,24.15,0,0,0-32,0Z"
        />
        <path
          className="cls-1"
          d="M344.64,10.63q-2.94,3.12-2.93,9.25v7.75H362v7.5H342V93.37h-8.88V35.13H321.33v-7.5h11.75V19.5q0-9,5.19-14.25T353,0a25.13,25.13,0,0,1,7.25,1.06,16.29,16.29,0,0,1,5.87,3.07l-3,6.62a14.62,14.62,0,0,0-9.75-3.25Q347.59,7.5,344.64,10.63Z"
        />
        <path
          className="cls-1"
          d="M439.45,27.63l-32.87,73.62q-4,9.25-9.25,13.12a20.64,20.64,0,0,1-12.63,3.88,25.77,25.77,0,0,1-8.87-1.5,19.25,19.25,0,0,1-7.13-4.5l4.13-6.63a16.33,16.33,0,0,0,12,5,12.07,12.07,0,0,0,7.69-2.5q3.18-2.49,5.93-8.5l2.88-6.37L372,27.63h9.25L406,83.5,430.7,27.63Z"
        />
        <polygon
          className="cls-2"
          points="79.32 82.64 36.07 59.89 0 80.34 40.34 104.86 79.32 82.64"
        />
        <polygon className="cls-1" points="77.59 36.36 40.24 15.01 0 39.74 0 80.34 77.59 36.36" />
      </g>
    </g>
  </svg>
);

export default AppLogo;
