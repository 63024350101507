import i18 from '../../i18n/i18n';

export const passwordRules = [
  { text: i18.t('authScreen.passwordRules.oneUpperCase'), regex: /(?=.*[A-Z])/ },
  { text: i18.t('authScreen.passwordRules.oneLowerCase'), regex: /(?=.*[a-z])/ },
  { text: i18.t('authScreen.passwordRules.oneNumber'), regex: /(?=.*[0-9])/ },
  { text: i18.t('authScreen.passwordRules.oneSpecial'), regex: /(?=.*[^A-Za-z0-9])/ },
  { text: i18.t('authScreen.passwordRules.minEight'), regex: /.{8,}/ },
];

export const isEmailValid = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isPasswordValid = (password: string) =>
  passwordRules.every((rule) => rule.regex.test(password));
