import { Box, Typography } from '@material-ui/core';

import { EntriesListItem } from '../components/panes/paneTypes/entriesList/entriesListItem';
import React from 'react';
import { useDirectoriesGet } from '../controllers/api/subscriptions/directories/directoriesGet';
import { useEntriesGet } from '../controllers/api/subscriptions/entries/entriesGet';
import { useGlobalStyles } from '../globalThemeSettings';
import { useProjectsGet } from '../controllers/api/subscriptions/projects/projectsGet';
import { useQueryParam_entriesList_directoryId } from '../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

/** A helper function that returns the currently opened case based on what is currently displayed to
 * the user. The complexity arises from the fact that the user can theoretically open a document
 * from case A, and then navigate the entriesList pane to a folder from case B. We have to decide
 * which case is supposed to be the active one. */
export default function useCurrentCase() {
  const [directoryId] = useQueryParam_entriesList_directoryId();

  const { data: directory } = useDirectoriesGet(directoryId);
  const directoryCaseId =
    directory?.path?.entry_mimetype === 'inode/case' ? directory.id : directory?.path?.case_id;

  const { data: currentCase } = useDirectoriesGet(directoryCaseId);

  const { data: project } = useProjectsGet(directory?.path?.project_id);

  return {
    id: currentCase?.id,
    name: currentCase?.path?.entry_name,
    project_directory_id: project?.directory_id,
    entry_id: currentCase?.path?.entry_id,
  };
}

/** Shows the current case as a clickable entry that can be shown at the top of components that
 * exhibit a different behaviour based on the currently active case */
export function CurrentCaseEntry() {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const { entry_id } = useCurrentCase();
  const { data: entry } = useEntriesGet(entry_id);

  if (!entry) return null;

  return (
    <div className={globalClasses.uiElementBackground}>
      <Box pl={2} pt={1}>
        <Typography className={globalClasses.subheading}>{t('entriesList.currentCase')}</Typography>
      </Box>
      <EntriesListItem entry={entry} />
    </div>
  );
}
