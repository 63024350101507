import React, { useState } from 'react';

import Codefy from '../../../codefy';
import EntryFileTypeIcon from '../../panes/paneTypes/entriesList/entryFileTypeIcon';
import GenericCreateDialog from './../genericCreateDialog';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import { taglistsCreate } from '../../../controllers/api/actions/taglists/taglistsCreate';
import { useBetween } from 'use-between';
import { usePaneActions } from '../../panes/usePaneActions';
import { useTranslation } from 'react-i18next';

type DialogCreateTaglistStateType = {
  directoryId: Codefy.Objects.Directory['id'];
  taglistType: Codefy.Objects.Taglist['type'];
};

const useDialogCreateTaglistState = () => useState<DialogCreateTaglistStateType>();

const useSharedDialogCreateTaglistState = () => useBetween(useDialogCreateTaglistState);

export const CreateTaglistDialog = () => {
  const { t } = useTranslation();
  const paneActions = usePaneActions();

  const [state, setState] = useSharedDialogCreateTaglistState();

  const onClose = () => setState(undefined);

  const onCreate = async (name: string) => {
    if (!name || !state?.directoryId || !state.taglistType) return;
    const taglist = await taglistsCreate({
      directory_id: state.directoryId,
      name,
      type: state.taglistType,
    });

    if (taglist && taglist.id) {
      if (state.taglistType === 'annotation') {
        /** Switch to the newly created taglist */
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.annotationTaglist,
          params: { annotationTaglist_id: taglist.id },
          reset: true,
        });
      } else if (state.taglistType === 'entry') {
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.entryTaglist,
          params: { entryTaglist_id: taglist.id },
          reset: true,
        });
      }
    }
  };

  return (
    <GenericCreateDialog
      open={!!state}
      title={
        state?.taglistType === 'annotation'
          ? t('createDialog.newAnnotationsTaglist')
          : t('createDialog.newEntryTaglist')
      }
      icon={
        state?.taglistType === 'annotation' ? (
          <EntryFileTypeIcon entryMimetype="application/codefy.annotation-taglist" />
        ) : (
          <EntryFileTypeIcon entryMimetype="application/codefy.entry-taglist" />
        )
      }
      onClose={onClose}
      onCreate={onCreate}
    />
  );
};

export const useOpenCreateTaglistDialog = () => {
  const [, setState] = useSharedDialogCreateTaglistState();
  return (state: DialogCreateTaglistStateType) => () => setState(state);
};
