import './sessionsPlayerStyle.css';

import { Box, MenuItem, Select, SelectProps } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

import PaneContentLoading from '../panes/paneContentLoading';
import { STORE_CHUNK_AFTER_SECONDS } from '../../hooks/useSessionRecorder';
import { eventWithTime } from 'rrweb/typings/types';
import moment from 'moment';
import rrwebPlayer from 'rrweb-player';
import { useQueryParam_sessionsPlayer_sessionKey } from '../../controllers/useGlobalQueryParams';
import { useSessionsGet } from '../../controllers/api/subscriptions/sessions/sessionsGet';
import { useSessionsList } from '../../controllers/api/subscriptions/sessions/sessionsList';

export default function SessionsPlayer() {
  const [session_key, set_session_key] = useQueryParam_sessionsPlayer_sessionKey();

  const { data: sessions } = useSessionsList({});
  const { data: session, isFetching } = useSessionsGet({ session_key });

  const onChange: SelectProps['onChange'] = (event) => {
    if (!event.target.value) return;
    set_session_key(event.target.value as string);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Box m={2}>
        <Select
          defaultValue={0}
          fullWidth
          variant="outlined"
          value={session_key}
          onChange={onChange}>
          <MenuItem value={0}>
            <b>Select a session</b>
          </MenuItem>
          {sessions?.sessions.map((session) => (
            <MenuItem key={session.session_key} value={session.session_key}>
              {session.count * STORE_CHUNK_AFTER_SECONDS} second session recorded{' '}
              {moment(session.created_at).fromNow()}{' '}
              <i style={{ marginLeft: '5px' }}>(recording started at: {session.created_at})</i>
            </MenuItem>
          ))}
        </Select>

        {isFetching && <PaneContentLoading />}

        <Box pt={2} width="100%" height="70vh">
          {session?.events && !isFetching && <Player events={session.events} />}
        </Box>
      </Box>
    </div>
  );
}

function Player({ events }: { events: eventWithTime[] }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = document.getElementById('rrweb_player');
    if (!target) return;

    const player = new rrwebPlayer({
      target,
      props: {
        events,
        width: ref.current?.getBoundingClientRect().width,
        height: ref.current?.getBoundingClientRect().height,
      },
    });

    return player.pause;
  });

  return (
    <div
      id="rrweb_player"
      ref={ref}
      style={{ width: '100%', height: '100%', backgroundColor: '#00336633' }}
    />
  );
}
