import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { usePaneActions } from '../../usePaneActions';
import { useTranslation } from 'react-i18next';

export default function UploadBatchesPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const paneActions = usePaneActions();
  const { t } = useTranslation();

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={<CloudUploadIcon />}
      title={t('uploadBatch.uploadManager')}
      paneKey={PaneKeys.uploadBatches}
      onCollapseRightPane={() => {
        paneActions.closePane(PaneKeys.uploadBatch);
      }}
    />
  );
}
