import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';
import { useQuery } from 'react-query';

export const PROJECTS_GET_KEY_TYPE = 'projectsGet';

export const projectsGetKey = (
  id?: Codefy.Objects.Project['id'],
): [string, Codefy.Objects.Project['id'] | undefined] => [PROJECTS_GET_KEY_TYPE, id];

export const useProjectsGet = (project_id?: Codefy.Objects.Project['id'] | string) => {
  if (typeof project_id === 'string') {
    project_id = parseInt(project_id);
  }
  return useQuery(
    projectsGetKey(project_id),
    async (key: string, project_id?: Codefy.Objects.Project['id']) => {
      if (!project_id) return undefined;
      const { data } = await axios.get('/api/v1/projects/get', {
        params: { project_id },
        paramsSerializer: fastApiParamsSerializer,
      });
      return data as Codefy.Objects.Project;
    },
  );
};
