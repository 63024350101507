import Codefy from '../../../../../../codefy';
import FilterListIcon from '@material-ui/icons/FilterList';
import { GenericNestableMenuEntry } from '../../../../../menus/genericNestableMenuEntry';
import React from 'react';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useQueryParam_search_attributes } from '../../../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

/** Allows displaying filter facets arbitrarily, depending on whatever attributes the backend
 * provides. Each attribute gets it's own button! */
export default function useCustomAttributesFacets(
  facets?: Codefy.Objects.SearchFacets | null,
): GenericNestableMenuEntry[] {
  const { t } = useTranslation();
  const [search_attributes, set_search_attributes] = useQueryParam_search_attributes();

  if (!facets) return [];

  /* Convert array of attributes into buttons with menus */
  const menus =
    facets.attributes?.reduce(
      (acc: { [key: string]: (GenericNestableMenuEntry & { isResetButton?: boolean })[] }, cur) => {
        if (!acc?.[cur.key]) acc[cur.key] = [];

        const menuItem = {
          key: `${cur.key}-${cur.value} (${cur.count.toString()})`,
          text: cur.value,
          onClick: () => {
            set_search_attributes({ ...search_attributes, [cur.key]: cur.value }, 'pushIn');
          },
        };

        acc[cur.key].push(menuItem);

        return acc;
      },
      {},
    ) || {};

  /* For those attributes that have been set by the user, update buttonText to show the value
  without having to open the menu and add a reset button */
  if (search_attributes) {
    Object.keys(search_attributes).forEach((key) => {
      const newName = `${key}: ${search_attributes[key]}`;
      menus[newName] = [
        {
          key: 'reset',
          text: t('searchFilter.reset'),
          /** So that we can distinguish later on when we filter out facets which only have one
           * option */
          isResetButton: true,
          onClick: () => {
            const newAttributes = { ...search_attributes };
            delete newAttributes[key];
            set_search_attributes(newAttributes, 'pushIn');
          },
          icon: <RotateLeftIcon />,
        } as GenericNestableMenuEntry & { isResetButton?: boolean },
      ];
      delete menus[key];
    });
  }

  const sortAlphabetically = (a: string, b: string) => a.localeCompare(b);

  const finalMenus = Object.keys(menus)
    .sort(sortAlphabetically)
    .map((menuName) => {
      /** Don't show if there is only one option, but show if that option is resetting */
      if (menus[menuName].length <= 1 && !menus[menuName]?.[0]?.isResetButton) return null;
      return {
        key: menuName,
        text: menuName,
        icon: <FilterListIcon />,
        menuEntries: menus[menuName],
      };
    });

  const finalFilteredMenus = finalMenus.filter((menu) => menu !== null);
  //@ts-ignore
  return finalFilteredMenus;
}
