import React, { FormEvent, useState } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import NoAuthScreenWrapper from './noAuthScreenWrapper';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { getErrorText } from '../../controllers/api/actions/actionHelpers';
import { isAxiosError } from './axiosHelper';
import { isEmailValid } from './validation';
import useAxios from '@use-hooks/axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    textBlock: {
      margin: theme.spacing(4, 0),
    },
    alternativeLink: {
      paddingTop: theme.spacing(3),
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': { textDecoration: 'underline' },
    },
  }),
);

/** A screen allowing the user to reset password */

const ResetPassword = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');

  const formData = new FormData();
  formData.append('email', email);

  const { response, loading, error, reFetch } = useAxios({
    url: '/api/v1/users/reset_password',
    method: 'POST',
    options: {
      data: formData,
    },
    forceDispatchEffect: () => isEmailValid(email),
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    reFetch();
  };

  const commonTextFieldProps: TextFieldProps = {
    variant: 'outlined',
    margin: 'normal',
    required: true,
    fullWidth: true,
  };

  return (
    <NoAuthScreenWrapper title={t('resetPassword.resetPassword')}>
      {!loading && !response && (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            {...commonTextFieldProps}
            id="email"
            label={t('authScreen.email')}
            name="email"
            autoComplete="email"
            autoFocus
            error={email !== '' && !isEmailValid(email)}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!isEmailValid(email)}>
                {t('resetPassword.reset')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      {loading && (
        <div className={classes.textBlock}>
          <Skeleton variant="rect" />
        </div>
      )}

      {isAxiosError(error) && (
        <div className={classes.textBlock}>
          <Typography color="error" variant="body1" align="center">
            {getErrorText(error?.response?.data) || error?.name}
          </Typography>
        </div>
      )}
      {response && !loading && (
        <div className={classes.textBlock}>
          <Typography variant="body1" align="center">
            {t('resetPassword.successMessage')}
          </Typography>
        </div>
      )}
      <Grid container className={classes.alternativeLink} justify="flex-end">
        <Grid item>
          <Link style={{ textDecoration: 'none' }} component={RouterLink} to="/">
            {t('authScreen.existingAccount')}
          </Link>
        </Grid>
      </Grid>
    </NoAuthScreenWrapper>
  );
};

export default ResetPassword;
