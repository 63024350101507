import { QuotaBars } from '../userQuota';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../index';
import { useTranslation } from 'react-i18next';
import { useUsersQuota } from '../../../../../controllers/api/subscriptions/users/usersQuota';

// eslint-disable-next-line react/display-name
export const UserQuotaSettingContent = (): JSX.Element | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: quota } = useUsersQuota();

  if (!quota?.file_quota && !quota?.disk_quota) {
    return null;
  }

  return (
    <div>
      <Typography className={classes.secondaryHeading} variant="caption">
        {t('account.storageHelp')}
      </Typography>
      <br />
      <br />
      <QuotaBars quota={quota} />
    </div>
  );
};
