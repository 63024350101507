import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';
import { useQuery } from 'react-query';

export const UPLOAD_BATCHES_GET_KEY_TYPE = 'uploadBatchesGet';

export const uploadBatchesGetKey = (
  upload_batch_uuid?: Codefy.Objects.UploadBatch['upload_batch_uuid'],
): [string, Codefy.Objects.UploadBatch['upload_batch_uuid'] | undefined] => [
  UPLOAD_BATCHES_GET_KEY_TYPE,
  upload_batch_uuid,
];

export const useUploadBatchesGet = ({
  upload_batch_uuid,
}: {
  upload_batch_uuid?: Codefy.Objects.UploadBatch['upload_batch_uuid'];
}) => {
  return useQuery(
    uploadBatchesGetKey(upload_batch_uuid),
    async (key: string, upload_batch_uuid?: Codefy.Objects.UploadBatch['upload_batch_uuid']) => {
      if (!upload_batch_uuid) return undefined;
      const { data } = await axios.get<Codefy.Objects.UploadBatch>('/api/v1/upload_batches/get', {
        params: { upload_batch_uuid },
      });

      data.num_documents =
        data.num_documents_failed + data.num_documents_processed + data.num_documents_unprocessed;

      return data;
    },
  );
};
