import * as websocketEventTypes from '../websocketEventTypes';

import { AnyAction, Dispatch } from 'redux';
import { ToastId, toast } from 'react-toastify';

import Codefy from '../../../codefy';
import { Link } from 'react-router-dom';
import React from 'react';
import i18n from '../../../i18n/i18n';
import popups from './popups';

export const t = i18n.t.bind(i18n);

type ToastListItem = {
  toastId: ToastId;
  notificationType: string;
  notificationMergeKey: string;
  count: number;
};
const toastsList: ToastListItem[] = [];

const onToastClose = (toastId: ToastId) => {
  const index = toastsList.findIndex((t) => t.toastId === toastId);
  if (index !== 1) {
    toastsList.splice(index, 1);
  }
};
/** Get unique group key for notification type  */
const getNotificationMergeKey = (notification: Codefy.Objects.Notification) => {
  const projectId = notification.action.project_id;

  if (!projectId) {
    return notification.type;
  }

  switch (notification.type) {
    case websocketEventTypes.ENTRY_DELETED:
    case websocketEventTypes.ENTRY_MOVED_TO:
    case websocketEventTypes.ENTRY_RENAMED:
    case websocketEventTypes.PROJECT_RENAMED: {
      return `${notification.type}_${projectId}`;
    }

    case websocketEventTypes.PROJECT_SHARED:
    case websocketEventTypes.PROJECT_UNSHARED:
      return `${notification.type}_${notification.action.permission_email}`;

    default:
      return notification.type;
  }
};

/** Listens for notifications and shows popups when they come in. */
/** Used for combine similar WebSocket notifications into one and just show a count of the same notifications in the toast message  */
export const popupHandler = (store: any) => (next: Dispatch) => (action: AnyAction): AnyAction => {
  const state: Codefy.State = store.getState();

  if (action.type === 'REDUX_WEBSOCKET::MESSAGE') {
    const parsed: Codefy.Objects.Notification = JSON.parse(action.payload.message);
    const notificationType = parsed.type;
    const notificationAction = parsed.action;

    const handlePopup = (popup: Codefy.Notifications.Popup): void => {
      if (popup[0] === notificationType) {
        /* Get the content of the popup */
        const content = popup[1](notificationAction);
        const notificationMergeKey = getNotificationMergeKey(parsed);
        /* find existing notification  */
        const existingToastIndex = toastsList.findIndex(
          (t) => t.notificationMergeKey === notificationMergeKey,
        );
        /* if exists, add notification to existing list and update existing toast */
        if (existingToastIndex > -1) {
          const existingToast = toastsList[existingToastIndex];
          toastsList[existingToastIndex].count++;
          if (content.multipleText) {
            toast.update(existingToast.toastId, {
              render: content.multipleText(existingToast.count),
            });
          }
          /* if not exists, show new toast */
        } else {
          /** We display different text depending if the action was taken by the user
           that sees the popup or somebody else */
          const currentUser =
            notificationAction?.user_id && state.user?.id === notificationAction?.user_id;

          const text = currentUser ? content.textIfCurrentUser : content.textIfOtherUser;

          if (text) {
            const toastContent = content.url ? (
              <span>
                {text}{' '}
                <Link to={content.url} style={{ color: 'white' }}>
                  <br />
                  <br />
                  Open
                </Link>
              </span>
            ) : (
              text
            );
            const toastFunction = currentUser ? toast.success : toast.info;

            const toastId = toastFunction(toastContent, {
              autoClose: content.timeout || 6000,
              onClose: () => {
                onToastClose(toastId);
              },
            });

            toastsList.push({
              toastId,
              notificationType,
              count: 1,
              notificationMergeKey,
            });
          }
        }
      }
    };

    popups.forEach(handlePopup);
  }
  return next(action);
};
