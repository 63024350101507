import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiDataSerializer } from '../../subscriptionHelpers';

export const userChangeLanguage = (data: Codefy.API.Users.ChangeLanguage.RequestData) => {
  return async () => {
    const config: Codefy.API.Users.ChangeLanguage.RequestConfig = {
      url: '/api/v1/users/change_language',
      method: 'POST',
      data: fastApiDataSerializer(data),
    };

    return await axios(config);
  };
};
