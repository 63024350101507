import { TAGLISTS_GET_KEY_TYPE } from '../../../subscriptions/taglists/taglistsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsList';
import Codefy from '../../../../../codefy';
import { executeAction } from '../../actionHelpers';

export const tagsCell = (data: {
  tag_id: Codefy.Objects.Tag['id'];
  cell: Codefy.Objects.Tag['cell'];
}) =>
  executeAction<Codefy.Objects.Tag>({
    config: { url: '/api/v1/taglists/tags/cell', method: 'POST' },
    data: { ...data, cell: data.cell?.toUpperCase() },
    queryKeysToRefresh: [TAGLISTS_GET_KEY_TYPE, TAGS_LIST_KEY_TYPE],
  });
