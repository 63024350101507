import { Box, Button, DialogActions, DialogProps, Grid } from '@material-ui/core';

import { COLORS } from '../../globalThemeSettings';
import { E2E } from '../../testing/common/data-e2e-ids';
import { GenericDialog } from './genericDialog';
import React from 'react';
//@ts-ignore
import useKeyPress from 'react-use-keypress';
import { useTranslation } from 'react-i18next';

export default function GenericDeleteDialog({
  open,
  title,
  icon,
  content,
  onDelete,
  onClose,
  dialogProps,
  deleteButtonText,
}: {
  open: boolean;
  title: string;
  icon?: JSX.Element;
  content?: JSX.Element;
  onDelete: () => void;
  onClose: () => void;
  dialogProps?: DialogProps;
  deleteButtonText?: string;
}) {
  const { t } = useTranslation();

  const onSubmit = () => {
    onDelete();
    onClose();
  };

  /* Allow pressing "Enter" to confirm the deletion. */
  useKeyPress('Enter', () => {
    /* This `if` is EXTREMELY important! Otherwise, when pressing Enter, the user would delete
    everything potentially deletable, which is most likely all projects and even his account */
    if (!open) return;
    onSubmit();
  });

  return (
    <GenericDialog title={title} onClose={onClose} open={open} {...dialogProps}>
      <Box>
        <Grid container alignItems="center" spacing={1}>
          {icon && (
            <Grid item>
              <Box mb={-0.5}>{icon}</Box>
            </Grid>
          )}
          {content && <Grid item>{content}</Grid>}
        </Grid>
        <Box mt={1}>
          <DialogActions>
            <Button size="small" onClick={onClose}>
              {t('deleteDialog.cancel')}
            </Button>
            <Button
              data-e2e-id={E2E.dialog_generic_delete_confirm}
              size="small"
              onClick={onSubmit}
              variant="contained"
              style={{ backgroundColor: COLORS.delete }}>
              {deleteButtonText || t('deleteDialog.delete')}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </GenericDialog>
  );
}
