import Codefy from '../../../../codefy';
import axios from 'axios';
import { useQuery } from 'react-query';

export const searchFacetsKey = (facets_key?: string): [string, string | undefined] => [
  'searchFacets',
  facets_key,
];

export const useSearchFacets = (params: { facets_key?: string }) =>
  useQuery(searchFacetsKey(params.facets_key), async () => {
    if (!params.facets_key) return null;
    const { data } = await axios.get('/api/v1/search/facets', {
      params: { key: params.facets_key },
    });
    return data as Codefy.Objects.SearchFacets;
  });
