/** Class to work with selection. It saves full boxes list, selected boxes list.
 * Allows to get currently selected boxes and closest element to provided coordinates (x, y) */

import { pointDistanceToPoint, rectDistanceToPoint } from './distance';

import Codefy from '../../../../../codefy';
import minBy from './minBy';

export type Position = {
  x: number;
  y: number;
};

export const VISIBLE_SELECTION_CLASS_NAME = 'visible';
export const MIN_SELECTION_DISTANCE = 10;
export function isDistinct<T>(mapper: (value: T) => string): (value: T) => boolean {
  const keys: { [index: string]: boolean } = {};

  return (entry: T) => {
    const key = mapper(entry);

    if (keys[key] !== undefined) {
      return false;
    }

    return (keys[key] = true);
  };
}

const getRelativeCanvasCoordinates = (e: Event, pageview?: any) => {
  let x = (e as MouseEvent).offsetX;
  let y = (e as MouseEvent).offsetY;
  const targetEl = e.target as HTMLElement;

  if (targetEl.classList.contains('selection') || targetEl.classList.contains('Annotation__part')) {
    const top = parseFloat(targetEl.style.top.replace('px', ''));
    const left = parseFloat(targetEl.style.left.replace('px', ''));
    x += left;
    y += top;
  }

  /* Rotate coordinates if the underlying page is also rotated */
  if (pageview) {
    const { width, height } = pageview.viewport;
    let totalRotation = (pageview.rotation + pageview.pdfPageRotate) % 360;

    x /= width;
    y /= height;

    while (totalRotation > 0) {
      [x, y] = [y, 1.0 - x];
      totalRotation -= 90;
    }

    x *= width;
    y *= height;
  }

  return { x, y };
};

export type Box = {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
  orderNumber: number;
};
class SelectionHelper {
  public selectedBoxes: Codefy.Objects.LayoutPart[] = [];
  public boxesList: Codefy.Objects.LayoutPart[] = [];
  public uniqueId? = '';
  public selectionStartElement: number | null = null;
  public selectionEndElement: number | null = null;
  public startSelectionPosition: Position = {
    x: 0,
    y: 0,
  };
  public lastSelectionPosition: Position = {
    x: 0,
    y: 0,
  };
  public getClosestElement(x: number, y: number) {
    const withDistance = this.boxesList.map((lp) => {
      return {
        ...lp,
        distance: rectDistanceToPoint({ x, y }, lp),
      };
    });
    const min = minBy(withDistance, (wd: Codefy.Objects.LayoutPart) => wd.distance);
    if (min) {
      return min.orderNumber;
    }
    return 0;
  }
  public getSelectedElementsList() {
    const distance = pointDistanceToPoint(this.startSelectionPosition, this.lastSelectionPosition);
    if (distance > MIN_SELECTION_DISTANCE) {
      const elementsList = this.boxesList.filter(
        (ll) =>
          this.selectionStartElement &&
          this.selectionEndElement &&
          ((ll.orderNumber >= this.selectionStartElement &&
            ll.orderNumber <= this.selectionEndElement) ||
            (ll.orderNumber >= this.selectionEndElement &&
              ll.orderNumber <= this.selectionStartElement)),
      );

      /** Prevent text from being selected twice like thislike this */
      const uniqueElementsList = elementsList.filter(
        isDistinct((i) => `${i.uniqueId}-${i.page}-${i.orderNumber}`),
      );

      return uniqueElementsList;
    }
    return [];
  }

  public getRelativeCoordinates = (e: Event) => {
    return getRelativeCanvasCoordinates(e);
  };

  public static clearSelection() {
    const selector = `.textLayer .selection.${VISIBLE_SELECTION_CLASS_NAME}`;
    document.querySelectorAll(selector).forEach((el) => {
      el.classList.remove(VISIBLE_SELECTION_CLASS_NAME);
    });
  }
}

export default SelectionHelper;
