import { Box, Breadcrumbs, Divider, Typography } from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';

import DirectoryDropWrapper from './directoryDropWrapper';
import DirectoryFileUploadWrapper from './directoryFileUploadWrapper';
import React from 'react';
import clsx from 'clsx';
import { useDirectoriesParents } from '../../../../controllers/api/subscriptions/directories/directoriesParents';
import { useQueryParam_entriesList_directoryId } from '../../../../controllers/useGlobalQueryParams';

export default function DirectoryParents() {
  const globalClasses = useGlobalStyles();
  const [
    entriesList_directoryId,
    set_entriesList_directoryId,
  ] = useQueryParam_entriesList_directoryId();
  const { data: parentDirectories } = useDirectoriesParents(entriesList_directoryId);

  if (parentDirectories?.num_directories === 1) return null;

  return (
    <>
      <Box p={1} pl={2} pb={2} bgcolor={COLORS.backgroundLight}>
        <Breadcrumbs separator="›">
          {parentDirectories?.directories.map((parentDirectory) => (
            <Box mb={-1} key={parentDirectory.id} className={globalClasses.link}>
              <DirectoryFileUploadWrapper directory_id={parentDirectory.id}>
                <DirectoryDropWrapper directory_id={parentDirectory.id}>
                  <Box onClick={() => set_entriesList_directoryId(parentDirectory.id)}>
                    <Typography
                      className={clsx(globalClasses.pointer, globalClasses.underlineOnHover)}>
                      {parentDirectory.path?.entry_name || parentDirectory.path?.project_name}
                    </Typography>
                  </Box>
                </DirectoryDropWrapper>
              </DirectoryFileUploadWrapper>
            </Box>
          ))}
        </Breadcrumbs>
      </Box>
      <Divider />
    </>
  );
}
