import {
  useQueryParam_assistantResults_tagId,
  useQueryParam_assistant_directoryId,
  useQueryParam_assistant_documentId,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import TextSearchResult from '../search/searchResultsItem';
import { makeStyles } from '@material-ui/core';
import { useMeasure } from 'react-use';
import { useSelector } from 'react-redux';
import { useTagInstancePredictionsList } from '../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesPredictionsList';

const useStyles = makeStyles(() => ({
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
}));

export default function AssistantResults() {
  const classes = useStyles();
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const searchOpen = useSelector((state: Codefy.State) => state.search.open);

  const [assistantResults_tagId] = useQueryParam_assistantResults_tagId();
  const [assistant_documentId] = useQueryParam_assistant_documentId();
  const [assistant_directoryId] = useQueryParam_assistant_directoryId();

  const {
    data: resultsPages,
    isLoading: predictionsLoading,
    fetchMore: predictionsFetchMore,
    canFetchMore,
  } = useTagInstancePredictionsList({
    tag_id: assistantResults_tagId,
    document_ids: assistant_documentId ? [assistant_documentId] : undefined,
    directory_ids: assistant_directoryId ? [assistant_directoryId] : undefined,
  });

  const results =
    resultsPages?.reduce((acc: Codefy.Objects.SearchResult[], cur) => {
      for (const result of cur.tag_instance_predictions) {
        acc.push(result);
      }
      return acc;
    }, []) || [];

  if (predictionsLoading && results.length === 0) {
    return <PaneContentLoading />;
  }

  const renderResult = (index: number) => {
    if (!results[index]) return <span></span>;
    const searchResult = results[index];
    return (
      <TextSearchResult
        source="assistantResults"
        searchResult={searchResult}
        key={'pane-document-' + searchResult.document.id + 'part-' + searchResult.part_id}
        uniqueId={'pane-document-' + searchResult.document.id + 'part-' + searchResult.part_id}
        isInstasearchResult={false}
        groupContinuation={!!(results[index].document.id === results[index - 1]?.document.id)}
        renderAnnotationBoxes={!searchOpen}
      />
    );
  };

  return (
    <div className={classes.virtuoso} ref={ref}>
      <InfiniteScroll
        className={classes.virtuoso}
        dataLength={results.length || 0}
        next={predictionsFetchMore}
        height={height - 1}
        hasMore={!!canFetchMore}
        loader={<PaneContentLoading />}>
        {results.map((_, index) => renderResult(index))}
      </InfiniteScroll>
    </div>
  );
}
