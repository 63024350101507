import { useEffect, useState } from 'react';

// // Usage
// function App() {
//   // Call our hook for each key that we'd like to monitor
//   const happyPress = useKeyPress('h');
//   const sadPress = useKeyPress('s');
//   const robotPress = useKeyPress('r');
//   const foxPress = useKeyPress('f');

//   return (
//     <div>
//       <div>h, s, r, f</div>
//       <div>
//         {happyPress && '😊'}
//         {sadPress && '😢'}
//         {robotPress && '🤖'}
//         {foxPress && '🦊'}
//       </div>
//     </div>
//   );
// }

// Hook
function useKeyPress(targetKeyCode: number) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    // If pressed key is our target key then set to true
    const downHandler = ({ keyCode }: { keyCode: number }) => {
      if (keyCode === targetKeyCode) {
        setKeyPressed(true);
      }
    };

    // If released key is our target key then set to false
    const upHandler = ({ keyCode }: { keyCode: number }) => {
      if (keyCode === targetKeyCode) {
        setKeyPressed(false);
      }
    };

    // Add event listeners
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKeyCode]); // Ensures that effect is only run on mount and unmount

  return keyPressed;
}

export default useKeyPress;
