import { ListItemSecondaryAction, ListItemText } from '@material-ui/core';

import Codefy from '../../../../codefy';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import SharedWithUserContextMenuWrapper from './sharedWithUserContextMenuWrapper';
import useHover from '../../../../hooks/useHover';
import { useTranslation } from 'react-i18next';

/** Renders the users that a project is shared with */
export function SharedWithUser({
  project,
  permission,
}: {
  project: Codefy.Objects.Project;
  permission: Codefy.Objects.ProjectPermission;
}) {
  const { t } = useTranslation();
  const [hoverRef, isHovered] = useHover();

  return (
    <SharedWithUserContextMenuWrapper project={project} permission={permission}>
      <div ref={hoverRef}>
        <ListItem key={permission.user.id} dense divider>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>

          <ListItemText
            primary={<b>{permission.user.email}</b>}
            secondary={
              permission.user.id === project.created_by?.id
                ? t('sharedWithUser.creator')
                : permission.write
                ? t('sharedWithUser.writeAccess')
                : t('sharedWithUser.readAccess')
            }
          />

          <ListItemSecondaryAction style={isHovered ? {} : { opacity: 0 }}>
            <SharedWithUserContextMenuWrapper
              project={project}
              permission={permission}
              openOnLeftClick>
              <IconButton edge="end" aria-label="delete" size="small">
                <MoreVertIcon />
              </IconButton>
            </SharedWithUserContextMenuWrapper>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    </SharedWithUserContextMenuWrapper>
  );
}
