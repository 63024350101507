import Codefy from '../../../../codefy';

export default function getTagsHierarchy(dataset: Codefy.Objects.Tag[]): Codefy.Objects.Tag[] {
  const hashTable = Object.create(null);
  dataset.forEach((aData) => (hashTable[aData.id] = { ...aData, childTags: [] }));
  const dataTree: Codefy.Objects.Tag[] = [];
  dataset.forEach((aData) => {
    if (aData.parent) hashTable[aData.parent].childTags.push(hashTable[aData.id]);
    else dataTree.push(hashTable[aData.id]);
  });
  return dataTree;
}
