import {
  DEFAULT_PDF_SCALE,
  useUserSetting_pdf_scale,
} from '../../../../controllers/api/subscriptions/users/userSettings';
//@ts-ignore
import { Document as PdfDocumentLoader, pdfjs } from 'react-pdf';
import React, { createContext, useMemo, useRef, useState } from 'react';
import {
  useQueryParam_pdfViewer_documentId,
  useQueryParam_pdfViewer_page,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import { PANE_WIDTHS } from '../paneTypes';
import PaneContentLoading from '../../paneContentLoading';
import { PaneKeys } from '../paneKeys';
import { PdfPage } from './pdfPage';
import SelectionArea from './pdfPageTextSelectionLayer/selectionArea';
import { debounce } from 'throttle-debounce';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import { usePaneWidth } from '../../pane';
import { usePdfCurrentPageSaver } from './usePdfCurrentPageSaver';
import { usePdfScrollSyncAfterScaleChange } from './usePdfScrollSyncAfterScaleChange';
import { usePdfScrollToPageOnExternalTrigger } from './usePdfScrollToPageOnExternalTrigger';

/** Enables showing two pdfViewers next to each other */
export type PdfViewerVariantType = 1 | 2;

// TODO: Load this dynamically from the pdfjs-dist module files or something. I just always keep
// getting an error that the pdfjs API versions (between react-pdf and pdfjs-dist?) mismatch.
pdfjs.GlobalWorkerOptions.workerSrc =
  process.env.PUBLIC_URL + '/pdfViewer/pdf.worker.2.5.207.min.js';

export const DocumentIdContext = createContext<Codefy.Objects.Document['id']>(0);
export const PdfPageIndexContext = createContext<number>(-1);
export const PdfPagePageWidthContext = createContext<number>(0);
export const PdfPagePageHeightContext = createContext<number>(0);

export const PDF_PAGE_MARGIN_BOTTOM_PERCENTAGE_OF_PAGE_HEIGHT = 0.015;
export const RECTANGLE_HIGHLIGHT_MARGIN_BOTTOM = 3;

export const getPdfPageElementId = (
  pdfViewerVariant: PdfViewerVariantType,
  documentId: Codefy.Objects.Document['id'],
  pageIndex: number,
) => `pdfViewer-${pdfViewerVariant}-document-${documentId}-page-${pageIndex + 1}`;

export const scrollToPage = (
  pdfViewerVariant: PdfViewerVariantType,
  documentId: Codefy.Objects.Document['id'],
  pageIndex: number,
) => {
  const pageElement = document.getElementById(
    getPdfPageElementId(pdfViewerVariant, documentId, pageIndex),
  );
  if (!pageElement) return;
  pageElement.scrollIntoView();
};

const getCurrentPageIndex = (
  _document: Codefy.Objects.Document | undefined,
  containerScrollPosition: number,
  scale: number,
) => {
  if (!_document || !_document.page_sizes) return 0;

  let scrollPosition = 0;
  let page = 0;

  for (const pageSize of _document.page_sizes) {
    /** The actual height of the page, taking into account scaling that might have been applied */
    const realPageHeight = (pageSize[1] / pageSize[0]) * PANE_WIDTHS.HUGE;

    /* Keep track of how many pages we have already passed */
    scrollPosition +=
      realPageHeight *
      scale *
      /* Don't forget to also add the "padding" between the pages that is there so pages look like
      actual pages */
      (1 + PDF_PAGE_MARGIN_BOTTOM_PERCENTAGE_OF_PAGE_HEIGHT);

    /* When comparing with the scroll position of the container, subtract a bit. This has
      the effect that page numbers change more "naturally", i.e. not at the extreme edges of the
      page, but rather, which page is "mostly" visible on the screen. */
    if (scrollPosition - realPageHeight / 3 > containerScrollPosition) {
      return page;
    }

    page += 1;
  }

  return page;
};

export default function PdfViewer({
  pdfViewerVariant,
}: {
  pdfViewerVariant: PdfViewerVariantType;
}) {
  const [userSetting_pdf_scale] = useUserSetting_pdf_scale();
  const scale = userSetting_pdf_scale || DEFAULT_PDF_SCALE;

  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(pdfViewerVariant);
  const [pdfViewer_page] = useQueryParam_pdfViewer_page(pdfViewerVariant);
  const { data: _document } = useDocumentsGet(pdfViewer_documentId);

  const pdfViewerContainerRef = useRef<HTMLDivElement>(null);

  const [containerScrollPosition, setContainerScrollPosition] = useState<number>(0);

  const onScrollContainer = debounce(200, false, () => {
    const scrollTop = pdfViewerContainerRef.current?.scrollTop;
    /* This needs to be !== undefined, otherwise it won't work if the viewer is at the very top,
    since 0 is falsy... */
    if (scrollTop !== undefined) {
      setContainerScrollPosition(scrollTop);
    }
  });

  const currentPageIndex = getCurrentPageIndex(_document, containerScrollPosition, scale);

  usePdfCurrentPageSaver({ pdfViewerVariant, currentPageIndex });
  usePdfScrollToPageOnExternalTrigger({ pdfViewerVariant, pdfViewer_documentId, pdfViewer_page });
  usePdfScrollSyncAfterScaleChange({ pdfViewerContainerRef });

  const [pdfViewerPaneWidth] = usePaneWidth(
    pdfViewerVariant === 1 ? PaneKeys.pdfViewer : PaneKeys.pdfViewer2,
  );

  const style = useMemo(
    () => ({
      overflow: 'auto',
      width: pdfViewerPaneWidth,
      height: '100%',
      background: '#EEEEEE',
    }),
    [pdfViewerPaneWidth],
  );

  if (!pdfViewer_documentId || !_document) return <PaneContentLoading />;

  return (
    <DocumentIdContext.Provider value={pdfViewer_documentId}>
      <SelectionArea
        _document={_document}
        selectionAreaId={`pdf-${pdfViewer_documentId}`}
        style={style}>
        <div ref={pdfViewerContainerRef} onScroll={onScrollContainer} style={style}>
          <PdfDocumentLoader
            file={`/api/v1/documents/pdf?document_id=${pdfViewer_documentId}`}
            loading={PaneContentLoading}
            /** Make sure the current page is updated */
            onLoadSuccess={onScrollContainer}>
            {Array.from({ length: _document.num_pages }).map((_, pageIndex) => {
              const pageSize = _document.page_sizes?.[pageIndex];

              /* This should never happen but better show an error message than make the user think
              pages are missing. */
              if (!pageSize)
                return (
                  <div>
                    Error: No page size available for page {pageIndex + 1}. Reprocess document.
                  </div>
                );

              return (
                <div key={pageIndex}>
                  <PdfPageIndexContext.Provider value={pageIndex}>
                    <PdfPagePageWidthContext.Provider value={PANE_WIDTHS.HUGE * scale}>
                      <PdfPagePageHeightContext.Provider
                        value={(pageSize[1] / pageSize[0]) * PANE_WIDTHS.HUGE * scale}>
                        <PdfPage
                          pdfViewerVariant={pdfViewerVariant}
                          visible={
                            /* Always render first three pages, makes sure first pages always load and
                          don't have to wait for any position calculator */
                            pageIndex < 2 || Math.abs(pageIndex - currentPageIndex) < 3
                          }
                        />
                      </PdfPagePageHeightContext.Provider>
                    </PdfPagePageWidthContext.Provider>
                  </PdfPageIndexContext.Provider>
                </div>
              );
            })}
          </PdfDocumentLoader>
        </div>
      </SelectionArea>
    </DocumentIdContext.Provider>
  );
}
