import { PaneKeys } from '../panes/paneTypes/paneKeys';
import useCurrentCase from '../../hooks/useCurrentCase';
import { useDocumentsGet } from '../../controllers/api/subscriptions/documents/documentsGet';
import { useEffect } from 'react';
import { usePaneActions } from '../panes/usePaneActions';
import { useQueryParam_pdfViewer_documentId } from '../../controllers/useGlobalQueryParams';

/** Closes the PDF viewer when switching to a different case. This is to avoid inconsistent states
 * of the UI, i.e. that the entriesList shows a folder from one case, and the pdfViewer shows a
 * PDF file from another case, leading to UI issues when the user then triggers a case-related
 * action, e.g. download a tag instances report for the current case. */
export function ClosePdfViewerOnCaseIdChangeListener() {
  const paneActions = usePaneActions();
  const { id: currentCaseId } = useCurrentCase();
  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(1);
  const { data: document } = useDocumentsGet(pdfViewer_documentId);

  useEffect(() => {
    if (!pdfViewer_documentId) return;
    if (!document) return;

    if (document.path.case_id !== currentCaseId) {
      /* Only close pdfViewer variant 1. Variant 2 should never be touched automatically. */
      paneActions.closePane(PaneKeys.pdfViewer);
    }
  }, [currentCaseId]);

  return null;
}
