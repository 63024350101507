import React, { useMemo } from 'react';
import {
  useQueryParam_assistant_directoryId,
  useQueryParam_assistant_documentId,
} from '../../../../controllers/useGlobalQueryParams';

import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useTranslation } from 'react-i18next';

export default function TagInstancePredictionsPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const { t } = useTranslation();
  const isEveryone = useIsEveryone();

  const [assistant_directoryId] = useQueryParam_assistant_directoryId();
  const [assistant_documentId] = useQueryParam_assistant_documentId();

  const { data: directory } = useDirectoriesGet(assistant_directoryId);
  const { data: document } = useDocumentsGet(assistant_documentId);

  const assistantScopeSuffix = useMemo(() => {
    if (assistant_documentId && document)
      return t('assistantPane.reviewing', { name: document.path.entry_name });
    if (assistant_directoryId && directory)
      return t('assistantPane.reviewing', {
        name: directory.path?.entry_name || directory.path?.project_name,
      });
    return '';
  }, [
    assistant_documentId,
    document?.path.entry_name,
    directory?.path?.entry_name,
    directory?.path?.project_name,
    assistant_directoryId,
  ]);

  const icon = assistant_documentId ? (
    <EntryFileTypeIcon entryMimetype={document?.path.entry_mimetype} />
  ) : assistant_directoryId ? (
    <FolderOpenIcon />
  ) : (
    <></>
  );

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={icon}
      title={assistantScopeSuffix}
      paneKey={PaneKeys.assistantResults}
      readonly={isEveryone}
    />
  );
}
