import Codefy from '../../../../codefy';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { executeAction } from '../actionHelpers';

export const directoriesCreate = (data: {
  directory_id: Codefy.Objects.Directory['id'];
  name: string;
  case: boolean;
}) =>
  executeAction<Codefy.Objects.Directory>({
    config: { url: '/api/v1/directories/create', method: 'POST' },
    data,
    queryKeysToRefresh: [ENTRIES_LIST_KEY_TYPE],
  });
