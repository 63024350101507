import * as MaterialColors from '@material-ui/core/colors';

import { Box, makeStyles } from '@material-ui/core';

import Codefy from '../../../../codefy';
import { GenericNestableMenuEntry } from '../../../menus/genericNestableMenuEntry';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  color: {
    cursor: 'pointer',
    margin: '2px',
    border: '',

    '&:hover': {
      border: '2px solid #00336666',
    },
  },
  colorSelected: {
    border: '2px solid #003366',
    '&:hover': {
      border: '2px solid #00336666',
    },
  },
}));

export default function useTagColorsMenuEntries({
  currentColor,
  onSelectColor,
}: {
  currentColor?: Codefy.Objects.Tag['color'];
  onSelectColor: (newColor: Codefy.Objects.Tag['color']) => void;
}): GenericNestableMenuEntry['menuEntries'] {
  const classes = useStyles();

  /** Create HTML colors from the Material design palette */
  const colors = Object.values(MaterialColors)
    .map((color) => {
      if ('100' in color && '200' in color) {
        return [color['100'], color['200']];
      }
      return undefined;
    })
    .filter((c) => c)
    .flat();

  return [
    {
      key: 'color-wall',
      text: (
        <Box mt={-2}>
          {colors.map((color, index) => (
            <span key={color}>
              {index % 8 === 0 && <br />}
              <span key={color} onClick={() => onSelectColor(color)}>
                <svg
                  width="30"
                  height="30"
                  className={clsx(classes.color, currentColor === color && classes.colorSelected)}>
                  <rect width="30" height="30" style={{ fill: color }} />
                </svg>
              </span>
            </span>
          ))}
        </Box>
      ),
    },
  ];
}
