import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { passwordRules } from './validation';
import { useTranslation } from 'react-i18next';

type Props = {
  password?: string;
};

const PasswordHelperText = ({ password }: Props) => {
  const { t } = useTranslation();
  return (
    <FormHelperText>
      {password ? (
        <div>
          <div>{t('authScreen.passValMessage')}:</div>
          <ul>
            {passwordRules.map((rule, index) =>
              // @ts-ignore
              rule.regex.test(password) ? null : <li key={index}>{rule.text}</li>,
            )}
          </ul>
        </div>
      ) : (
        <div>{t('authScreen.confirmFailMessage')}</div>
      )}
    </FormHelperText>
  );
};

export default PasswordHelperText;
