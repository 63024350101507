import queryString from 'query-string';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

// TODO: Write tests
export const useUpdateUrlParams = () => {
  const history = useHistory();

  const fn = useCallback(
    (params: any, updateType: 'pushIn' | 'replaceIn') => {
      if (!history?.location.search) return;

      const currentSearch = queryString.parse(history.location.search);
      const newSearch = queryString.stringify({ ...currentSearch, ...params });
      switch (updateType) {
        case 'pushIn':
          history.push({ search: newSearch });
          return;
        case 'replaceIn':
          history.replace({ search: newSearch });
      }
    },
    [history?.location?.search],
  );

  return fn;
};
