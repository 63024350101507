import {
  useQueryParam_assistant_directoryId,
  useQueryParam_assistant_documentId,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import { PaneKeys } from '../paneKeys';
import { usePaneActions } from '../../usePaneActions';

export default function useSetAssistantParams({
  scope,
  id,
  project_id,
}: {
  scope: 'directory' | 'document';
  id?: number;
  project_id?: Codefy.Objects.Project['id'];
}) {
  const paneActions = usePaneActions();

  const [assistant_directoryId, set_assistant_directoryId] = useQueryParam_assistant_directoryId();
  const [assistant_documentId, set_assistant_documentId] = useQueryParam_assistant_documentId();

  const onSetSearchScopeDirectory = () => {
    if (!id || !project_id) return;
    assistant_documentId && set_assistant_documentId(undefined, 'pushIn');
    set_assistant_directoryId(id, 'pushIn');
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.assistant,
      params: { assistant_projectId: project_id },
    });
  };

  const onSetSearchScopeDocument = () => {
    if (!id || !project_id) return;
    set_assistant_documentId(id, 'pushIn');
    assistant_directoryId && set_assistant_directoryId(undefined, 'pushIn');
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.assistant,
      params: { assistant_projectId: project_id },
    });
  };

  switch (scope) {
    case 'directory':
      return onSetSearchScopeDirectory;
    case 'document':
      return onSetSearchScopeDocument;
  }
}
