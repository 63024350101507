import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';
import { useQuery } from 'react-query';

export const ENTRIES_GET_KEY_TYPE = 'entriesGet';

export const entriesGetKey = (
  id?: Codefy.Objects.Entry['id'],
): [string, Codefy.Objects.Entry['id'] | undefined] => [ENTRIES_GET_KEY_TYPE, id];

export const useEntriesGet = (entry_id?: Codefy.Objects.Entry['id'] | string) => {
  if (typeof entry_id === 'string') {
    entry_id = parseInt(entry_id);
  }
  return useQuery(
    entriesGetKey(entry_id),
    async (key: string, entry_id?: Codefy.Objects.Entry['id']) => {
      if (!entry_id) return undefined;
      const { data } = await axios.get('/api/v1/entries/get', {
        params: { entry_id },
        paramsSerializer: fastApiParamsSerializer,
      });
      return data as Codefy.Objects.Entry;
    },
  );
};
