import EditExportTextModal from '../panes/paneTypes/annotation/editExportTextModal';
import EditExportTextTemplatesModal from '../panes/paneTypes/tag/editExportTextTemplatesModal';
import React from 'react';

export enum ModalKeys {
  'editExportText' = 'editExportText',
  'editExportTextTemplates' = 'editExportTextTemplates',
}

export const modalTypes: {
  [key in ModalKeys]: React.ReactElement;
} = {
  editExportText: <EditExportTextModal />,
  editExportTextTemplates: <EditExportTextTemplatesModal />,
};
