import React, { useCallback } from 'react';
import {
  useUserSetting_projects_descending,
  useUserSetting_projects_filterByOwner,
  useUserSetting_projects_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import Codefy from '../../../../codefy';
import { ListRange } from 'react-virtuoso';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import { ProjectsListEntry } from './projectsListItem';
import { Virtuoso } from 'react-virtuoso';
import { makeStyles } from '@material-ui/core';
import { useProjectsList } from '../../../../controllers/api/subscriptions/projects/projectsList';
import { useQueryParam_projectsList_name } from '../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  virtuoso: { width: '100%', height: '100%' },
}));

export default function ProjectsListContent() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [projectsList_name] = useQueryParam_projectsList_name();
  const [projectsList_descending] = useUserSetting_projects_descending();
  const [projectsList_orderBy] = useUserSetting_projects_orderBy();
  const [projectsList_filterByOwner] = useUserSetting_projects_filterByOwner();

  const { data: projectsPages, isLoading, fetchMore, canFetchMore } = useProjectsList({
    name: projectsList_name,
    order_by: projectsList_orderBy,
    descending: projectsList_descending,
  });

  const projectsList =
    projectsPages?.reduce((acc: Codefy.Objects.Project[], cur) => {
      for (const project of cur.projects) {
        // If filtering is set, only add matching
        if (projectsList_filterByOwner && projectsList_filterByOwner == project.created_by?.email) {
          acc.push(project);
        }
        // If filtering is not set, add all
        if (!projectsList_filterByOwner) {
          acc.push(project);
        }
      }
      return acc;
    }, []) || [];

  const renderProject = (index: number) => {
    if (!projectsList[index]) return <span></span>;
    return <ProjectsListEntry project={projectsList[index]} />;
  };

  /** Triggered when the user scrolls in the list */
  const onRangeChanged = useCallback(
    (range: ListRange) => {
      /** If user didn't scroll far enough yet */
      if (range.endIndex < projectsList.length - 50) return;

      /** If there is nothing more to load */
      if (!canFetchMore) return;

      fetchMore();
    },
    [projectsList.length, canFetchMore],
  );

  if (isLoading && projectsList.length === 0) {
    return <PaneContentLoading />;
  }

  if (projectsList.length === 0) return <PaneContentEmpty text={t('projectsList.noProjects')} />;

  return (
    <Virtuoso
      className={classes.virtuoso}
      overscan={50}
      totalCount={projectsList.length || 0}
      itemContent={renderProject}
      rangeChanged={onRangeChanged}
    />
  );
}
