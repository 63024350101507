import { BOX_SHADOWS, useGlobalStyles } from '../../globalThemeSettings';
import { Box, Dialog, DialogProps, Typography } from '@material-ui/core';
import React, { Children, ReactNode } from 'react';

export function GenericDialog({
  title,
  children,
  ...rest
}: DialogProps & { title: string; children: ReactNode }) {
  const globalClasses = useGlobalStyles();

  return (
    <Dialog
      {...rest}
      fullWidth
      disableRestoreFocus
      PaperProps={{ style: { boxShadow: BOX_SHADOWS.medium } }}
      BackdropProps={{ style: { opacity: 0.5, backgroundColor: 'white' } }}
      /* Before setting this to >0 make sure that states are kept during the entire duration of the
      component, otherwise when closing the dialog, the text saying "Create case" might change to
      "Create folder" while the fade out transition is going on (just a few hundred milliseconds,
      but it might look "weird" to the user) */
      transitionDuration={0}>
      <Box m={2} ml={3} mr={3}>
        <Box mb={2}>
          <Typography className={globalClasses.subheading}>{title}</Typography>
        </Box>
        {Children.map(children, (child, index) => {
          if (index === 0) return child;
          return (
            <>
              <Box mt={1.5} mb={1.5}>
                <div className={globalClasses.visibleDivider} />
              </Box>
              {child}
            </>
          );
        })}
      </Box>
    </Dialog>
  );
}
