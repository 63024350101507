import { TAGLISTS_GET_KEY_TYPE } from '../../../subscriptions/taglists/taglistsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsList';
import Codefy from '../../../../../codefy';
import { executeAction } from '../../actionHelpers';

export const tagsDescription = (data: {
  tag_id: Codefy.Objects.Tag['id'];
  description: Codefy.Objects.Tag['description'];
}) =>
  executeAction<Codefy.Objects.Tag>({
    config: { url: '/api/v1/taglists/tags/description', method: 'POST' },
    data,
    queryKeysToRefresh: [TAGLISTS_GET_KEY_TYPE, TAGS_LIST_KEY_TYPE],
  });
