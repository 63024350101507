import Codefy from '../../../../codefy';
import { DIRECTORIES_GET_KEY_TYPE } from '../../subscriptions/directories/directoriesGet';
import { DIRECTORIES_PARENTS_KEY_TYPE } from '../../subscriptions/directories/directoriesParents';
import { DOCUMENTS_GET_KEY_TYPE } from '../../subscriptions/documents/documentsGet';
import { ENTRIES_GET_KEY_TYPE } from '../../subscriptions/entries/entriesGet';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { TAGLISTS_GET_KEY_TYPE } from '../../subscriptions/taglists/taglistsGet';
import { executeAction } from '../actionHelpers';

export const entriesRename = (data: {
  entry_id: Codefy.Objects.Entry['id'];
  name: Codefy.Objects.Entry['name'];
}) =>
  executeAction<Codefy.Objects.Entry>({
    config: { url: '/api/v1/entries/rename', method: 'POST' },
    data,
    queryKeysToRefresh: [
      ENTRIES_LIST_KEY_TYPE,
      ENTRIES_GET_KEY_TYPE,
      PROJECTS_LIST_KEY_TYPE,
      TAGLISTS_GET_KEY_TYPE,
      DOCUMENTS_GET_KEY_TYPE,
      DIRECTORIES_GET_KEY_TYPE,
      DIRECTORIES_PARENTS_KEY_TYPE,
    ],
  });
