import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';

import AnnotationAreaPreview from './annotationAreaPreview';
import AnnotationComments from './comments';
import AnnotationContextMenuWrapper from './annotationContextMenuWrapper';
import Codefy from '../../../../codefy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import { fastApiParamsSerializer } from '../../../../controllers/api/subscriptionHelpers';
import moment from 'moment';
import useHover from '../../../../hooks/useHover';
import { usePaneActions } from '../../usePaneActions';
import { useQueryParam_pdfViewer_highlightAnnotationWithId } from '../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  annotation: {
    cursor: 'pointer',
    width: '100%',
  },
}));

/** The URL that is sent the user to when he clicks on the annotation link. Also used for sharing,
 * the sharing forwarder takes the annotation id and forwards the user to the result of this
 * function here */
export const getAnnotationLinkUrl = (annotation: Codefy.Objects.Annotation): string => {
  const params = {
    panes: ['pdfViewer', 'annotation'],
    pdfViewer_page: annotation.boxes[0].page,
    pdfViewer_documentId: annotation.path.document_id,
    pdfViewer_highlightAnnotationWithId: annotation.id,
    annotation_id: annotation.id,
  };
  const linkUrl = '/?' + fastApiParamsSerializer(params);
  return linkUrl;
};
export default function Annotation({
  annotation,
}: {
  annotation: Codefy.Objects.Annotation;
  /** If the annotation part of a list and is preceded by an annotation from the same document? */
  groupContinuation: boolean;
}) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const paneActions = usePaneActions();
  const [hoverRef, isHovered] = useHover();

  const [pdfViewer_highlightAnnotationWithId] = useQueryParam_pdfViewer_highlightAnnotationWithId(
    1,
  );

  const onOpenAnnotationLeft = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: annotation.path.document_id,
        pdfViewer_boxes: JSON.stringify(annotation.boxes),
        pdfViewer_page: annotation.boxes[0].page,
        pdfViewer_highlightAnnotationWithId: annotation.id,
      },
      reset: true,
    });
  };

  return (
    <AnnotationContextMenuWrapper annotation={annotation}>
      <div ref={hoverRef}>
        <ListItem dense button selected={pdfViewer_highlightAnnotationWithId === annotation.id}>
          <Box width="100%">
            <Grid onClick={onOpenAnnotationLeft} className={classes.annotation}>
              <ListItemText
                className={globalClasses.heading}
                primary={
                  <Typography variant="body2">
                    <b>{annotation.path.directory_name || annotation.path.project_name}</b>
                    <Grid
                      item
                      className={
                        isHovered ? globalClasses.floatRightVisible : globalClasses.floatRightHidden
                      }>
                      <AnnotationContextMenuWrapper annotation={annotation} openOnLeftClick>
                        <IconButton edge="end" aria-label="delete" size="small">
                          <MoreVertIcon />
                        </IconButton>
                      </AnnotationContextMenuWrapper>
                    </Grid>
                  </Typography>
                }
              />

              <ListItemText
                className={globalClasses.heading}
                primary={
                  <Typography variant="body2">
                    <b>{annotation.path.entry_name}</b>{' '}
                    <span className={globalClasses.textLight}>
                      › {t('searchResult.page')} {annotation.boxes[0].page}
                    </span>
                  </Typography>
                }
              />
            </Grid>
          </Box>
        </ListItem>
        <ListItem divider dense>
          <Grid>
            <Grid item md={12}>
              {annotation.area ? (
                <AnnotationAreaPreview annotation={annotation} onClick={onOpenAnnotationLeft} />
              ) : (
                <Typography
                  variant="body2"
                  align="justify"
                  className={globalClasses.text}
                  dangerouslySetInnerHTML={{
                    __html:
                      annotation.text_snippet?.replaceAll(
                        'class="hla"',
                        `style="background-color:${
                          annotation.tag_instances?.[0]?.tag_color || COLORS.defaultTagColor
                        };"`,
                      ) || '',
                  }}
                />
              )}
            </Grid>

            <Grid item md={12}>
              <ListItemText
                secondary={
                  <span className={globalClasses.textLight}>
                    created {moment(annotation.created_at).fromNow()} by{' '}
                    {annotation.created_by.email}
                  </span>
                }
              />
            </Grid>

            <Grid item md={12}>
              <AnnotationComments annotation={annotation} />
            </Grid>
          </Grid>
        </ListItem>
      </div>
    </AnnotationContextMenuWrapper>
  );
}
