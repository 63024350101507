import { Box, IconButton, Popover } from '@material-ui/core';
import {
  bindContextMenu,
  bindMenu,
  bindPopper,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import Codefy from '../../../../codefy';
import CopyToClipboard from 'react-copy-to-clipboard';
import DeleteIcon from '@material-ui/icons/Delete';
import { FaFileExport } from 'react-icons/fa';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ModalKeys } from '../../../old_modals/modalTypes';
import { PaneKeys } from '../paneKeys';
import PdfViewerLeftIcon from '../../../icons/pdfViewerLeft';
import PdfViewerRightAddIcon from '../../../icons/pdfViewerRightAdd';
import PdfViewerRightIcon from '../../../icons/pdfViewerRight';
import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import TagLabelsEditor from '../../../tagLabels/tagLabelsEditor';
import { annotationsDelete } from '../../../../controllers/api/actions/annotations/annotationsDelete';
import getAnnotationShareUrl from './getAnnotationShareUrl';
import { toast } from 'react-toastify';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import { useIsPaneOpen } from '../../../../controllers/useGlobalQueryParams';
import useModalActions from '../../../old_modals/useModalActions';
import { usePaneActions } from '../../usePaneActions';
import { useTranslation } from 'react-i18next';

/** Whatever is inside this wrapper will open a annotation context menu (rename, delete, ...) when
 * right-clicked */
export default function AnnotationContextMenuWrapper({
  annotation,
  children,
  style,
  openOnLeftClick,
}: {
  annotation?: Codefy.Objects.Annotation;
  children: React.ReactChild | React.ReactChildren;
  style?: React.CSSProperties;
  openOnLeftClick?: boolean;
}) {
  const { t } = useTranslation();
  const modalActions = useModalActions();
  const paneActions = usePaneActions();

  const taglistFromTemplateEnabled = useFeatureFlag('taglist_from_template');
  const isPdfViewerOpen = useIsPaneOpen(PaneKeys.pdfViewer);
  const isPdfViewer2Open = useIsPaneOpen(PaneKeys.pdfViewer2);

  // TODO: Fix this hack
  //@ts-ignore
  const isEveryone = window.isEveryone;

  const contextMenuState = usePopupState({ variant: 'popover', popupId: 'annotationContextMenu' });
  const [contextState, setContextState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  const tagPopupState = usePopupState({ variant: 'popper', popupId: 'tagPopup' });

  if (!annotation) return <>{children}</>;

  const onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    contextMenuState.close();
  };

  const onOpenEditExportTextModal = () => {
    if (annotation.tag_instances?.length === 1) {
      modalActions.openModal({
        modal: ModalKeys.editExportText,
        params: { editExportText_itemId: annotation.tag_instances[0].id },
      });
    } else {
      // TODO: We could make it work for more tags, too.
      toast.error('Sorry, the annotation must only have exactly one tag to edit the export text');
    }
  };

  /** The share URL that gets copied into the clipboard when the user clicks on the share button */
  const shareUrl = getAnnotationShareUrl(annotation);

  /** Show popup that the share url has been successfully copied to the clipboard */
  const onCopyShareUrlToClipboardSuccess = () => {
    toast.success(t('annotations.copyLinkSuccess'));
  };

  const onDeleteAnnotation = () => {
    annotationsDelete({ annotation_id: annotation.id });
  };

  const menuAnchorPosition =
    contextState.mouseY !== null && contextState.mouseX !== null
      ? { top: contextState.mouseY, left: contextState.mouseX }
      : undefined;

  const onClose = () => {
    tagPopupState.setOpen(false);
    contextMenuState.close();
  };

  const onOpenAnnotationLeft = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: annotation.path.document_id,
        pdfViewer_boxes: JSON.stringify(annotation.boxes),
        pdfViewer_page: annotation.boxes[0].page,
        pdfViewer_highlightAnnotationWithId: annotation.id,
      },
      reset: true,
    });
  };

  const onOpenAnnotationRight = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer2,
      params: {
        pdfViewer2_documentId: annotation.path.document_id,
        pdfViewer2_boxes: JSON.stringify(annotation.boxes),
        pdfViewer2_page: annotation.boxes[0].page,
        pdfViewer2_highlightAnnotationWithId: annotation.id,
      },
      reset: true,
    });
  };

  const menu = (
    <Menu
      {...bindMenu(contextMenuState)}
      anchorReference="anchorPosition"
      anchorPosition={menuAnchorPosition}
      onClick={onMenuClick}>
      {/* <MenuItem {...bindToggle(tagPopupState)}>
        <ListItemIcon>
          <IconButton size="small">
            <LabelOutlinedIcon />
          </IconButton>
        </ListItemIcon>
        {annotation.tag_instances && annotation.tag_instances.length > 0
          ? t('annotationContextMenuWrapper.editTagLabels')
          : t('annotationContextMenuWrapper.addLabel')}
      </MenuItem> */}
      {isPdfViewerOpen && !isPdfViewer2Open && (
        <MenuItem onClick={onOpenAnnotationRight}>
          <ListItemIcon>
            <IconButton size="small" data-e2e-id="share-annotation">
              <PdfViewerRightAddIcon />
            </IconButton>
          </ListItemIcon>
          {t('pdfViewer2.openRightAdd')}
        </MenuItem>
      )}

      {isPdfViewerOpen && isPdfViewer2Open && (
        <MenuItem onClick={onOpenAnnotationLeft}>
          <ListItemIcon>
            <IconButton size="small" data-e2e-id="share-annotation">
              <PdfViewerLeftIcon />
            </IconButton>
          </ListItemIcon>
          {t('pdfViewer2.openLeft')}
        </MenuItem>
      )}

      {isPdfViewerOpen && isPdfViewer2Open && (
        <MenuItem onClick={onOpenAnnotationRight}>
          <ListItemIcon>
            <IconButton size="small" data-e2e-id="share-annotation">
              <PdfViewerRightIcon />
            </IconButton>
          </ListItemIcon>
          {t('pdfViewer2.openRight')}
        </MenuItem>
      )}

      {taglistFromTemplateEnabled && (
        <MenuItem onClick={onOpenEditExportTextModal}>
          <ListItemIcon>
            <IconButton size="small">
              <FaFileExport
                fontSize="small"
                style={{ marginLeft: '4px', marginBottom: '-3px' }}
                size={18}
              />
            </IconButton>
          </ListItemIcon>
          {t('annotationContextMenuWrapper.setExportText')}
        </MenuItem>
      )}

      <CopyToClipboard text={shareUrl} onCopy={onCopyShareUrlToClipboardSuccess}>
        <MenuItem>
          <ListItemIcon>
            <IconButton size="small" data-e2e-id="share-annotation">
              <ShareIcon />
            </IconButton>
          </ListItemIcon>
          {t('annotationContextMenuWrapper.copyShareLink')}
        </MenuItem>
      </CopyToClipboard>

      <MenuItem
        data-e2e-id="context-menu-delete-annotation"
        onClick={onDeleteAnnotation}
        disabled={isEveryone}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        {t('projectsList.contextMenu.delete')}
      </MenuItem>
    </Menu>
  );
  return (
    <div
      {...bindContextMenu(contextMenuState)}
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (!contextMenuState.isOpen) {
          setContextState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          });
        }
        bindContextMenu(contextMenuState).onContextMenu(event);
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!openOnLeftClick) return;

        event.stopPropagation();
        event.preventDefault();
        if (!contextMenuState.isOpen) {
          setContextState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          });
        }
        contextMenuState.open();
      }}
      style={style || {}}>
      <Popover {...bindPopper(tagPopupState)} disablePortal>
        <Box m={2} zIndex={30000}>
          <TagLabelsEditor
            source="annotationContextMenuWrapper"
            taglistType="annotation"
            onClickOutside={onClose}
            onClose={onClose}
            project_id={annotation.path.project_id}
            annotation_id={annotation.id}
          />
        </Box>
      </Popover>
      {children}
      {menu}
    </div>
  );
}
