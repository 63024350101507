import { connect } from '@giantmachines/redux-websocket';
import store from '../store';
import { throttle } from 'throttle-debounce';

/** Connects to our API websocket to listen to notifications. */
const connectToNotificationsWebsocket = () => {
  const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  /** Window location host contains the port, so host will be localhost:3000 in dev and
  will be proxied in development, while not proxying in production. */
  const host = window.location.host;
  const apiWebsocketEndpoint = '/api/v1/notifications/listen';
  const webSocketUrl = `${protocolPrefix}//${host}${apiWebsocketEndpoint}`;
  store.dispatch(connect(webSocketUrl));
};

/** Throttle by default so prevent trying to reconnect dozens of times per second */
export default throttle(5000, connectToNotificationsWebsocket);
