import { AnyAction, Dispatch } from 'redux';

import connectToNotificationsWebsocket from './connectToNotificationsWebsocket';

let reconnectTimeout: NodeJS.Timeout | undefined;

/** Listens for cache invalidate notifications and invalidates the relevant caches so
 * that they are refreshed. */
const errorHandler = () => (next: Dispatch) => (action: AnyAction): AnyAction => {
  switch (action?.type) {
    case 'REDUX_WEBSOCKET::CLOSED':
    case 'REDUX_WEBSOCKET::ERROR':
      if (reconnectTimeout === undefined) {
        reconnectTimeout = setTimeout(() => {
          connectToNotificationsWebsocket();
          reconnectTimeout = undefined;
        }, 5000);
      }
      break;
    case 'REDUX_WEBSOCKET::OPEN':
    case 'REDUX_WEBSOCKET::RECONNECTED':
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
  }

  return next(action);
};

export default errorHandler;
