import {
  Badge,
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import {
  useQueryParam_assistantResults_tagId,
  useQueryParam_panes,
  useQueryParam_tag_tagId,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import CodefyIcon from '../../../appLogo/codefyIcon';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenericContextMenu from '../../../menus/genericContextMenu';
import IconButton from '@material-ui/core/IconButton';
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import TagLabels from '../../../tagLabels/tagLabelsRenderer';
import { TaglistVariants } from '../paneTypes';
import clsx from 'clsx';
import useHover from '../../../../hooks/useHover';
import { useLocalStorage } from '@rehooks/local-storage';
import { usePaneActions } from '../../usePaneActions';
import useTagMenuEntries from '../tag/useTagMenuEntries';

export function TagListTag({
  project_id,
  tag,
  variant,
  hideButtons,
}: {
  project_id?: Codefy.Objects.Project['id'];
  tag: Codefy.Objects.Tag;
  variant: TaglistVariants;
  hideButtons?: boolean;
}) {
  const globalClasses = useGlobalStyles();
  const tagMenuEntries = useTagMenuEntries({ project_id, tag, full: true, variant });
  const [panes] = useQueryParam_panes();
  const paneActions = usePaneActions();
  const [tag_tagId] = useQueryParam_tag_tagId({ taglistType: tag.taglist_type });
  const [assistantResults_tagId] = useQueryParam_assistantResults_tagId();
  const [hoverRef, isHovered] = useHover();
  const [collapsedTags, setCollapsedTags] = useLocalStorage<number[]>('collapsed_tags');

  const tagIsCollapsed = collapsedTags?.includes(tag.id);
  const toggleTagCollapsed: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (tagIsCollapsed) {
      setCollapsedTags(collapsedTags?.filter((tagId) => tagId !== tag.id) || []);
    } else {
      setCollapsedTags([...(collapsedTags || []), tag.id]);
    }
  };

  const onClick = () => {
    switch (variant) {
      case TaglistVariants.TAGLIST:
        if (tag.taglist_type === 'annotation') {
          paneActions.addOrUpdatePane({
            paneKey: PaneKeys.annotationTaglist,
            params: {
              annotationTaglist_id: tag.taglist_id,
            },
          });
          paneActions.addOrUpdatePane({
            paneKey: PaneKeys.annotationTag,
            params: {
              annotationTag_tagId: tag.id,
            },
          });
          //paneActions.closePane(PaneKeys.annotationsList);
        } else if (tag.taglist_type === 'entry') {
          paneActions.addOrUpdatePane({
            paneKey: PaneKeys.entryTaglist,
            params: {
              entryTaglist_id: tag.taglist_id,
            },
          });
          paneActions.addOrUpdatePane({
            paneKey: PaneKeys.entryTag,
            params: {
              entryTag_tagId: tag.id,
            },
          });
          //paneActions.closePane(PaneKeys.annotationsList);
        }

        break;
      case TaglistVariants.ASSISTANT:
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.assistant,
          params: { assistant_taglistId: tag.taglist_id },
        });
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.assistantResults,
          params: { assistantResults_tagId: tag.id },
        });

        break;
    }
  };

  return (
    <>
      <GenericContextMenu key={tag.id} menuEntries={tagMenuEntries}>
        <Box borderLeft={`5px solid ${tag.color || COLORS.defaultTagColor}`}>
          <ListItem
            button
            divider
            disabled={variant === TaglistVariants.ASSISTANT && tag.num_tag_instances === 0}
            selected={
              variant === TaglistVariants.TAGLIST
                ? tag.id === tag_tagId &&
                  (panes.includes(PaneKeys.annotationTag) || panes.includes(PaneKeys.entryTag))
                : tag.id === assistantResults_tagId && panes.includes(PaneKeys.assistantResults)
            }
            key={tag.id}
            onClick={onClick}
            innerRef={hoverRef}>
            <ListItemIcon className={clsx(globalClasses.narrowListItemIcon)}>
              {variant === TaglistVariants.TAGLIST ? (
                <Badge
                  badgeContent={variant === TaglistVariants.TAGLIST && tag.num_tag_instances}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                  {tag.taglist_type === 'annotation' && <LabelOutlinedIcon />}
                  {tag.taglist_type === 'entry' && <LabelIcon />}
                </Badge>
              ) : (
                <CodefyIcon />
              )}
            </ListItemIcon>

            <ListItemText
              primary={
                <span>
                  <Box pr={7}>
                    <Grid container alignItems="center" spacing={2}>
                      {tag?.cell && (
                        <Grid item>
                          <span className={globalClasses.textLight}>{tag?.cell}</span>
                        </Grid>
                      )}
                      <Grid item className={globalClasses.listEntry}>
                        {tag.name}
                        {tag.annotation_tag_instances && tag.annotation_tag_instances.length > 0 && (
                          <>
                            <br />
                            <TagLabels
                              tagInstances={tag.annotation_tag_instances}
                              icon="tag"
                              lineBreaks={false}
                              fullWidth
                              taglistType="entry"
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  {tagMenuEntries.length > 0 && !hideButtons && (
                    <ListItemSecondaryAction>
                      <Grid container wrap="nowrap" onClick={(event) => event.stopPropagation}>
                        {tag.childTags && tag.childTags.length > 0 && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            onClick={toggleTagCollapsed}>
                            {tagIsCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                          </IconButton>
                        )}
                        <GenericContextMenu
                          key={tag.id}
                          menuEntries={tagMenuEntries}
                          openOnLeftClick>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            style={isHovered ? {} : { opacity: 0 }}>
                            <MoreVertIcon />
                          </IconButton>
                        </GenericContextMenu>
                      </Grid>
                    </ListItemSecondaryAction>
                  )}
                </span>
              }
            />
          </ListItem>
        </Box>
      </GenericContextMenu>
      {tag.childTags && !tagIsCollapsed && (
        <Box ml={2}>
          {tag.childTags.map((childTag) => (
            <TagListTag
              key={childTag.id}
              project_id={project_id}
              tag={childTag}
              variant={variant}
              hideButtons={hideButtons}
            />
          ))}
        </Box>
      )}
    </>
  );
}
