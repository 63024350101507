import { StringParam, useQueryParam } from 'use-query-params';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import NoAuthScreenWrapper from './noAuthScreenWrapper';
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { getErrorText } from '../../controllers/api/actions/actionHelpers';
import { isAxiosError } from './axiosHelper';
import useAxios from '@use-hooks/axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBlock: {
      margin: theme.spacing(4, 0),
    },
  }),
);

/** A screen allowing the user to confirm email after registration */

const ConfirmRegister = (): JSX.Element => {
  const classes = useStyles();
  const [token] = useQueryParam('token', StringParam);
  const { t } = useTranslation();

  const { response, loading, error } = useAxios({
    url: '/api/v1/users/confirm_register',
    method: 'GET',
    options: {
      params: { token },
    },
    trigger: token,
  });
  return (
    <NoAuthScreenWrapper title={t('confirmRegister.emailConfirmation')}>
      <div className={classes.textBlock}>
        {loading && <Skeleton variant="text" />}
        {!token && (
          <Typography color="error" variant="body1" align="center">
            {t('confirmRegister.invalidTokenText')}
          </Typography>
        )}
        {isAxiosError(error) && (
          <Typography color="error" variant="body1" align="center">
            {getErrorText(error?.response?.data) || error?.name}
          </Typography>
        )}
        {response && (
          <Typography
            variant="body1"
            align="center"
            dangerouslySetInnerHTML={{
              __html: t('confirmRegister.successConfirmationText', {
                email: response?.data?.email,
              }),
            }}
          />
        )}
      </div>
      {!loading && token && (
        <Grid container justify="center">
          <Grid item>
            <Link style={{ textDecoration: 'none' }} href="/">
              <Button variant="contained" color="primary">
                {t('confirmRegister.signIn')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
    </NoAuthScreenWrapper>
  );
};

export default ConfirmRegister;
