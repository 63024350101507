import { Reducer, combineReducers } from 'redux';

import Codefy from '../codefy';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { initialState } from './store';

const search: Reducer<Codefy.State['search'], Codefy.API.Search.Action> = (
  state = initialState['search'],
  action,
) => {
  switch (action.type) {
    case 'setSearch':
      if (action.open !== undefined) {
        state.open = action.open;
      }
      if (action.query !== undefined) {
        state.query = action.query;
      }
      if (action.searchbarArrowKeyPosition !== undefined) {
        state.searchbarArrowKeyPosition = action.searchbarArrowKeyPosition;
      }
      return state;
    default:
      return state;
  }
};

const uploads: Reducer<
  Codefy.State['uploads'],
  | Codefy.API.Documents.Upload.Actions.AddUploads
  | Codefy.API.Documents.Upload.Actions.UpdateUploadStatus
  | Codefy.API.Documents.Upload.Actions.ResetUploadBatch
> = (state = initialState['uploads'] || [], action) => {
  switch (action.type) {
    case 'AddUploads':
      return [...state, ...action.uploads];

    case 'UpdateUploadStatus':
      return [
        ...state.map((upload) => {
          if (action.fileIds.includes(upload.fileId)) {
            upload.status = action.status;
          }
          return upload;
        }),
      ];

    case 'ResetUploadBatch':
      return state.filter((upload) => upload.upload_batch_uuid !== action.upload_batch_uuid);

    default:
      return state;
  }
};

const isPreparingUploads: Reducer<
  Codefy.State['isPreparingUploads'],
  Codefy.API.Documents.Upload.Actions.SetPreparingUploads
> = (state = initialState['isPreparingUploads'] || false, action) => {
  switch (action.type) {
    case 'SetPreparingUploads':
      return action.preparing;

    default:
      return state;
  }
};

const user: Reducer<
  Codefy.State['user'],
  | Codefy.API.Users.Get.Action
  | Codefy.API.Users.Logout.Action
  | Codefy.API.Users.ChangeSettings.Action
> = (state = initialState['user'] || null, action) => {
  switch (action.type) {
    case 'UserGet':
      return action.user;

    case 'UserLogout':
      return null;

    case 'ChangeSettings':
      return action.user;

    default:
      return state;
  }
};

const userLoadingCompleted: Reducer<
  Codefy.State['userLoadingCompleted'],
  Codefy.API.Users.LoadingCompleted.Action
> = (state = initialState['userLoadingCompleted'] || null, action) => {
  switch (action.type) {
    case 'UserLoadingCompleted':
      return true;

    default:
      return state;
  }
};

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    search,
    uploads,
    isPreparingUploads,
    user,
    userLoadingCompleted,
  });

export default createRootReducer;
