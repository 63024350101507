// Source: https://gist.github.com/gragland/a32d08580b7e0604ff02cb069826ca2f
//
// Usage
// function App() {
//   const [hoverRef, isHovered] = useHover();

//   return (
//     <div ref={hoverRef}>
//       {isHovered ? '😁' : '☹️'}
//     </div>
//   );
// }

import { MutableRefObject, useCallback, useRef, useState } from 'react';

import { useDebounce } from 'use-debounce';

/** Detect whether the mouse is hovering an element. The hook returns a ref and a
 * boolean value indicating whether the element with that ref is currently being
 * hovered. Just add the returned ref to any element whose hover state you want to
 * monitor. */
const useHover = (): [(node: any) => void, true | ''] => {
  const [value, setValue] = useState(false);
  /* We need to add a debounce here for some reason, otherwise icons that are changing
  based on whether something is hovered or not might be flackering */
  const [debouncedValue] = useDebounce(value, 1);

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  // Keep track of the last node passed to callbackRef
  // so we can remove its event listeners.
  const ref: MutableRefObject<Node | undefined> = useRef();

  // Use a callback ref instead of useEffect so that event listeners
  // get changed in the case that the returned ref gets added to
  // a different element later. With useEffect, changes to ref.current
  // wouldn't cause a rerender and thus the effect would run again.
  const callbackRef = useCallback(
    (node) => {
      if (ref.current) {
        ref.current.removeEventListener('mouseover', handleMouseOver);
        ref.current.removeEventListener('mouseout', handleMouseOut);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener('mouseover', handleMouseOver);
        ref.current.addEventListener('mouseout', handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut],
  );
  return [callbackRef, !!debouncedValue || ''];
};

export default useHover;
