import { Box, Button, FormControlLabel, Switch, TextField } from '@material-ui/core';
import React, { useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import Codefy from '../../../../codefy';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { PaneMenuButtonProps } from '../../paneTitleBar';
import ShareIcon from '@material-ui/icons/Share';
import { SharedWithUser } from './sharedWithUser';
import { projectsShare } from '../../../../controllers/api/actions/projects/projectsShare';
import { toast } from 'react-toastify';
import { useProjectsGet } from '../../../../controllers/api/subscriptions/projects/projectsGet';
import { useQueryParam_projectShare_projectId } from '../../../../controllers/useGlobalQueryParams';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUsersList } from '../../../../controllers/api/subscriptions/users/usersList';

export default function ProjectShareSettings() {
  const [projectShare_projectId] = useQueryParam_projectShare_projectId();

  const user = useSelector((state: Codefy.State) => state.user);
  const { data: project } = useProjectsGet(projectShare_projectId);
  const { t } = useTranslation();

  const [shareWithEmail, setShareWithEmail] = useState<string>('');
  const [shareWithWrite, setShareWithWrite] = useState<boolean>(true);
  const [searchEmail, setSearchEmail] = useState<string>();
  const { latestData: users } = useUsersList({ email: searchEmail, limit: 5 });
  const autocompleteOptions = !users
    ? []
    : /** Remove users the project is already shared with */
      users.users.filter(
        (user) =>
          !project?.permissions.map((permission) => permission.user.email).includes(user.email),
      );

  const onShare = () => {
    if (shareWithEmail === user?.email) {
      toast.error(t('projectShareSettings.cannotShareWithYourself'));
    }
    if (projectShare_projectId && shareWithEmail) {
      projectsShare({
        project_ids: [projectShare_projectId],
        email: shareWithEmail,
        write: shareWithWrite,
      });
      setShareWithEmail('');
      setSearchEmail('');
    }
  };

  const onKeyDown = (event: any) => {
    /** ENTER key */
    if (event.keyCode === 13) {
      onShare();
    }
  };

  return (
    <>
      <>
        <Box m={2}>
          <Autocomplete
            size="small"
            id="share-with"
            freeSolo
            style={{ minWidth: '300px' }}
            options={autocompleteOptions}
            getOptionLabel={(user) => (typeof user === 'object' ? user.email : user)}
            value={shareWithEmail}
            onChange={(event, value, reason) => {
              if (reason === 'select-option' && value) {
                if (typeof value === 'object') {
                  setShareWithEmail(value.email);
                } else {
                  setShareWithEmail(value);
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
                inputProps={{ ...params.inputProps, autocomplete: 'new-password' }}
                variant="standard"
                placeholder={t('projectShareSettings.shareWith')}
                size="small"
                value={shareWithEmail}
                fullWidth
                onKeyDown={onKeyDown}
                onChange={(event) => {
                  setSearchEmail(event.target.value);
                  setShareWithEmail(event.target.value);
                }}
              />
            )}
          />
          {shareWithEmail && (
            <ListItem key={'share-with-buttons'}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    size="small"
                    checked={shareWithWrite}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      setShareWithWrite(checked)
                    }
                    name={'share-with-write'}
                  />
                }
                label={t('annotations.comments.mentionGiveWriteAccess')}
              />
              <Button
                {...PaneMenuButtonProps}
                variant="outlined"
                size="small"
                onClick={onShare}
                startIcon={<ShareIcon />}>
                {t('projects.share')}
              </Button>
            </ListItem>
          )}
        </Box>
      </>

      <List dense disablePadding>
        {project?.permissions
          .filter(
            (permission) =>
              permission.user.id !== user?.id && permission.user.email.toLowerCase() !== 'everyone',
          )
          .map((permission) => (
            <SharedWithUser key={permission.user.id} permission={permission} project={project} />
          ))}
      </List>
    </>
  );
}
