import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import Codefy from '../../../codefy';
import CommentIcon from '@material-ui/icons/Comment';
import { GenericDialog } from '../genericDialog';
import PaneContentLoading from '../../panes/paneContentLoading';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ShortTextIcon from '@material-ui/icons/ShortText';
import moment from 'moment';
import { taglistsExportPrettyWord } from '../../../controllers/api/actions/taglists/taglistsExportPrettyWord';
import { useBetween } from 'use-between';
import { useDirectoriesGet } from '../../../controllers/api/subscriptions/directories/directoriesGet';
import { useGlobalStyles } from '../../../globalThemeSettings';
import useLocalStorage from '@rehooks/local-storage';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type DialogExportPrettyWordStateType = {
  taglist_id: Codefy.Objects.Taglist['id'];
  case_id: Codefy.Objects.Directory['id'];
};

const useDialogExportPrettyWordState = () => useState<DialogExportPrettyWordStateType>();

const useSharedDialogExportPrettyWordState = () => useBetween(useDialogExportPrettyWordState);

export default function ExportPrettyWordDialog() {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const [state, setState] = useSharedDialogExportPrettyWordState();

  const userEmail = useSelector((state: Codefy.State) => state.user?.email);

  const [projectTitle, setProjectTitle] = useLocalStorage<string>(
    `export-pretty-word-project-title-taglist-${state?.taglist_id}-case-${state?.case_id}`,
  );

  const [projectID, setProjectID] = useLocalStorage<string>(
    `export-pretty-word-project-id-taglist-${state?.taglist_id}-case-${state?.case_id}`,
  );

  const [createdBy, setCreatedBy] = useLocalStorage<string>(`export-pretty-word-created-by`);
  const [createdAt, setCreatedAt] = useState<string>(moment().format('DD.MM.YYYY, HH:mm'));
  const [summary, setSummary] = useLocalStorage<string>(
    `export-pretty-word-summary-taglist-${state?.taglist_id}-case-${state?.case_id}`,
  );

  const [includeOperationsTags, setIncludeOperationsTags] = useLocalStorage<boolean>(
    'export-pretty-word-include-operations-tags',
    true,
  );

  const [includeTagDescriptions, setIncludeTagDescriptions] = useLocalStorage<boolean>(
    'export-pretty-word-include-tag-descriptions',
    true,
  );

  const [includeTaggedContent, setIncludeTaggedContent] = useLocalStorage<boolean>(
    'export-pretty-word-include-tag-content',
    true,
  );

  const [includeComments, setIncludeComments] = useLocalStorage<boolean>(
    'export-pretty-word-include-comments',
    true,
  );

  const [isExporting, setIsExporting] = useState<boolean>(false);

  const { data: directory } = useDirectoriesGet(state?.case_id);

  if (!state || !directory) return null;

  const onClose = () => {
    setState(undefined);
  };

  const onExport = async () => {
    setIsExporting(true);
    await taglistsExportPrettyWord({
      taglist_id: state?.taglist_id,
      case_id: state?.case_id,
      projectTitle: projectTitle || directory.path?.entry_name || '',
      projectID: projectID || '',
      createdAt,
      createdBy: createdBy || userEmail || '',
      summary: summary || '',
      includeOperationsTags,
      includeTagDescriptions,
      includeTaggedContent,
      includeComments,
    });
    setIsExporting(false);
  };

  const textFieldProps = { fullWidth: true, InputProps: { disableUnderline: true } };

  return (
    <GenericDialog
      title={t('exportDialog.downloadReport')}
      onClose={onClose}
      open={!!state}
      fullWidth>
      <Box>
        <Grid container alignItems="center" alignContent="center">
          <Box mr={1}>
            <ShortTextIcon />
          </Box>
          <Typography className={globalClasses.textPrimary}>
            <Box mb={0.65}>{t('exportDialog.projectInformation')}</Box>
          </Typography>
        </Grid>
        <Box m={1} ml={4}>
          <TextField
            placeholder={t('exportDialog.enterProjectTitle')}
            {...textFieldProps}
            value={projectTitle || directory.path?.entry_name || ''}
            onChange={(event) => setProjectTitle(event.target.value)}
          />
        </Box>
        <Box m={1} ml={4}>
          <TextField
            placeholder={t('exportDialog.enterProjectID')}
            {...textFieldProps}
            value={projectID || ''}
            onChange={(event) => setProjectID(event.target.value)}
          />
        </Box>
      </Box>
      <Box>
        <Grid container alignItems="center" alignContent="center">
          <Box mr={1}>
            <PersonIcon />
          </Box>
          <Typography className={globalClasses.textPrimary}>
            <Box mb={0.65}>{t('exportDialog.generatedBy')}</Box>
          </Typography>
        </Grid>
        <Box m={1} ml={4}>
          <TextField
            placeholder={t('exportDialog.insertName')}
            {...textFieldProps}
            value={createdBy || userEmail || ''}
            onChange={(event) => setCreatedBy(event.target.value)}
          />
        </Box>
      </Box>
      <Box>
        <Grid container alignItems="center" alignContent="center">
          <Box mr={1}>
            <QueryBuilderIcon />
          </Box>
          <Typography className={globalClasses.textPrimary}>
            <Box mb={0.65}>{t('exportDialog.date')}</Box>
          </Typography>
        </Grid>
        <Box m={1} ml={4}>
          <TextField
            placeholder={t('exportDialog.insertDate')}
            {...textFieldProps}
            value={createdAt}
            onChange={(event) => setCreatedAt(event.target.value)}
          />
        </Box>
      </Box>
      <Box>
        <Grid container alignItems="center" alignContent="center">
          <Box mr={1}>
            <CommentIcon />
          </Box>
          <Typography className={globalClasses.textPrimary}>
            <Box mb={0.65}>{t('exportDialog.addSummary')}</Box>
          </Typography>
        </Grid>
        <Box m={1} ml={4}>
          <TextField
            placeholder={t('exportDialog.insertText')}
            multiline
            value={summary}
            onChange={(event) => setSummary(event.target.value)}
            rows={7}
            {...textFieldProps}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box>
        <Grid container alignItems="center" alignContent="center">
          <Box mr={1}>
            <PlaylistAddIcon />
          </Box>
          <Typography className={globalClasses.textPrimary}>
            <Box mb={0.65}>{t('exportDialog.includeInReport')}</Box>
          </Typography>
        </Grid>
        <Box m={1} ml={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!includeOperationsTags}
                onChange={(event) => setIncludeOperationsTags(event.target.checked)}
              />
            }
            label={
              <Typography className={globalClasses.textPrimary}>
                {t('exportDialog.operationsTags')}
              </Typography>
            }
            color="primary"
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!includeTagDescriptions}
                onChange={(event) => setIncludeTagDescriptions(event.target.checked)}
              />
            }
            label={
              <Typography className={globalClasses.textPrimary}>
                {t('exportDialog.tagDescriptions')}
              </Typography>
            }
            color="primary"
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!includeTaggedContent}
                onChange={(event) => setIncludeTaggedContent(event.target.checked)}
              />
            }
            label={
              <Typography className={globalClasses.textPrimary}>
                {t('exportDialog.taggedContent')}
              </Typography>
            }
            color="primary"
          />
          <br />
          <FormControlLabel
            disabled={!includeTaggedContent}
            control={
              <Checkbox
                color="primary"
                checked={!!includeTaggedContent && !!includeComments}
                onChange={(event) => setIncludeComments(event.target.checked)}
              />
            }
            label={
              <Typography className={globalClasses.textPrimary}>
                {t('exportDialog.comments')}
              </Typography>
            }
            color="primary"
          />
        </Box>

        <DialogActions>
          <Button
            disabled={isExporting}
            size="small"
            onClick={onExport}
            color="primary"
            className={globalClasses.dialogButton}>
            {t('exportDialog.download')}
          </Button>
        </DialogActions>
        <div style={{ height: '5px' }}>{isExporting && <PaneContentLoading />}</div>
      </Box>
    </GenericDialog>
  );
}

export const useOpenExportPrettyWordDialog = () => {
  const [, setState] = useSharedDialogExportPrettyWordState();
  return (state: DialogExportPrettyWordStateType) => setState(state);
};
