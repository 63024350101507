import Codefy from '../../../../codefy';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { executeAction } from '../actionHelpers';

export const entriesDuplicate = (data: { entry_id: Codefy.Objects.Entry['id'] }) =>
  executeAction<Codefy.Objects.Entry[]>({
    config: { url: '/api/v1/entries/duplicate', method: 'POST' },
    data,
    queryKeysToRefresh: [ENTRIES_LIST_KEY_TYPE, PROJECTS_LIST_KEY_TYPE],
  });
