import {
  DEFAULT_PDF_SCALE,
  useUserSetting_pdf_scale,
} from '../../../../controllers/api/subscriptions/users/userSettings';
import React, { useCallback, useMemo } from 'react';
import {
  useQueryParam_pdfViewer_areaSelection,
  useQueryParam_pdfViewer_documentId,
  useQueryParam_pdfViewer_page,
} from '../../../../controllers/useGlobalQueryParams';

import { BiScreenshot } from 'react-icons/bi';
import { BsCursorText } from 'react-icons/bs';
import { COLORS } from '../../../../globalThemeSettings';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import PdfViewerPaneTitleBarCurrentPage from './pdfViewerPaneTitleBarCurrentPage';
import { PdfViewerVariantType } from './pdfViewer';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { documentsDownload } from '../../../../controllers/api/actions/documents/documentsDownload';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenRenameEntryDialog } from '../../../dialogs/rename/renameEntryDialog';
import useSetAssistantParams from '../assistant/useSetAssistantParams';
import useSetSearchParams from '../../../navbar/searchBar/useSetSearchParams';
import { useTranslation } from 'react-i18next';

export default function PdfViewerPaneTitleBar({
  pdfViewerVariant,
  onlyShowPart,
}: {
  pdfViewerVariant: PdfViewerVariantType;
  onlyShowPart?: 'icon' | 'title';
}) {
  const { t } = useTranslation();
  const isEveryone = useIsEveryone();

  const [scale, setScale] = useUserSetting_pdf_scale();

  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(pdfViewerVariant);
  const [pdfViewer_page] = useQueryParam_pdfViewer_page(pdfViewerVariant);
  const [, set_pdfViewer_areaSelection] = useQueryParam_pdfViewer_areaSelection(pdfViewerVariant);

  const openRenameEntryDialog = useOpenRenameEntryDialog();

  const { data: document, isLoading: documentIsLoading, error: documentError } = useDocumentsGet(
    pdfViewer_documentId,
  );

  const onSetSearchScopeDocument = useSetSearchParams('document', pdfViewer_documentId);
  const onSetAssistantScopeDocument = useSetAssistantParams({
    scope: 'document',
    id: pdfViewer_documentId,
    project_id: document?.path.project_id,
  });

  const onDownloadDocument = useCallback(() => {
    if (!document) return;
    documentsDownload(document);
  }, [document?.id]);

  const userHasWriteAccess = document?.path?.entry_write;

  const menuEntries = useMemo(
    () => [
      {
        key: 'zoomOut',
        text: 'Zoom out',
        icon: <ZoomOutIcon />,
        onClick: () => {
          setScale((scale || DEFAULT_PDF_SCALE) - 0.1);
        },
      },
      {
        key: 'zoomIn',
        text: 'Zoom in',
        icon: <ZoomInIcon />,
        onClick: () => {
          setScale((scale || DEFAULT_PDF_SCALE) + 0.1);
        },
      },
      {
        key: 'resetZoom',
        text: 'Reset zoom',
        icon: <RotateLeftIcon />,
        onClick: () => {
          setScale(DEFAULT_PDF_SCALE);
        },
      },
      {
        key: 'divider1',
        text: '',
        divider: true,
      },
      {
        key: 'page',
        text: 'Page',
        inactive: true,
        icon: <PdfViewerPaneTitleBarCurrentPage pdfViewerVariant={pdfViewerVariant} />,
      },
      {
        key: 'divider2',
        text: '',
        divider: true,
      },
      {
        key: 'download',
        text: t('pdfViewerPaneTitleBar.download'),
        onClick: onDownloadDocument,
        icon: <GetAppIcon />,
      },
      {
        key: 'divider3',
        text: '',
        divider: true,
      },
      {
        key: 'selection-text',
        text: t('pdfViewerPaneTitleBar.textSelection'),
        icon: <BsCursorText color={COLORS.primary} />,
        onClick: () => set_pdfViewer_areaSelection(false),
      },
      {
        key: 'selection-area',
        text: t('pdfViewerPaneTitleBar.areaSelection'),
        icon: <BiScreenshot color={COLORS.primary} />,
        onClick: () => set_pdfViewer_areaSelection(true),
      },
      {
        key: 'divider3',
        text: '',
        divider: true,
      },
    ],
    [document?.id, scale, pdfViewer_page],
  );

  const icon = useMemo(
    () =>
      document?.path?.entry_mimetype && (
        <EntryFileTypeIcon entryMimetype={document.path.entry_mimetype} size={20} />
      ),
    [document?.path.entry_mimetype],
  );

  if (onlyShowPart === 'icon' && icon && document?.path.entry_mimetype)
    return (
      <EntryFileTypeIcon
        entryMimetype={document?.path.entry_mimetype}
        size={20}
        style={{ marginLeft: '3px' }}
      />
    );

  const title = (document && document.path?.entry_name) || '';

  if (onlyShowPart === 'title' && title) return <span>{title}</span>;

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={icon}
      title={title}
      titleCopyable
      onSearchCallback={onSetSearchScopeDocument}
      onAssistantCallback={onSetAssistantScopeDocument}
      onRenameCallback={openRenameEntryDialog({ entryId: document?.path.entry_id })}
      paneKey={pdfViewerVariant === 2 ? PaneKeys.pdfViewer2 : PaneKeys.pdfViewer}
      menuEntries={menuEntries}
      loading={documentIsLoading}
      error={documentError}
      readonly={isEveryone || !userHasWriteAccess}
    />
  );
}
