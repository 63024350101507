import {
  PdfPageIndexContext,
  PdfPagePageHeightContext,
  PdfPagePageWidthContext,
  RECTANGLE_HIGHLIGHT_MARGIN_BOTTOM,
} from '../../pdfViewer';
import React, { useContext } from 'react';

import Codefy from '../../../../../../codefy';
import stringify from 'fast-stringify';

export function AnnotationRectangleHighlight({
  annotation,
}: {
  annotation: Codefy.Objects.Annotation;
}) {
  const pageIndex = useContext(PdfPageIndexContext);
  const pageWidth = useContext(PdfPagePageWidthContext);
  const pageHeight = useContext(PdfPagePageHeightContext);
  const boxes = annotation.boxes.filter((box) => box.page === pageIndex + 1);

  return (
    <>
      {boxes.map((box) => (
        <div
          key={annotation.id + '-' + stringify(box)}
          style={{
            position: 'absolute',
            top: box.ymin * pageHeight + RECTANGLE_HIGHLIGHT_MARGIN_BOTTOM,
            left: box.xmin * pageWidth,
            width: (box.xmax - box.xmin) * pageWidth,
            height: (box.ymax - box.ymin) * pageHeight,
            zIndex: 400,
            borderBottom: annotation.area ? undefined : '3px solid #003366',
            backgroundColor: annotation.area ? '#00336666' : undefined,
          }}
        />
      ))}
    </>
  );
}
