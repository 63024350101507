import { PdfViewerVariantType } from './pdfViewer';
import { useEffect } from 'react';
import { useQueryParam_pdfViewer_page } from '../../../../controllers/useGlobalQueryParams';

export function usePdfCurrentPageSaver({
  pdfViewerVariant,
  currentPageIndex,
}: {
  pdfViewerVariant: PdfViewerVariantType;
  currentPageIndex: number;
}) {
  const [, set_pdfViewer_page] = useQueryParam_pdfViewer_page(pdfViewerVariant);
  useEffect(() => {
    set_pdfViewer_page(currentPageIndex + 1);
  }, [currentPageIndex]);
}
