import Codefy from '../../../../codefy';
import { DocumentStatus } from '../../../../controllers/api/actions/documents/documentsUpload';
import { UploadBatchesListBatchesReturnType } from '../../../../controllers/api/subscriptions/uploadBatches/uploadBatchesListBatches';
import groupBy from 'just-group-by';

export function patchUploadBatchesListBatches(
  data: UploadBatchesListBatchesReturnType,
  uploads: Codefy.State['uploads'],
): UploadBatchesListBatchesReturnType {
  if (!data) return data;

  data.localBatches = [];

  let localBatches: Codefy.Objects.UploadBatch[] = Object.entries(
    groupBy(uploads, (upload) => upload.upload_batch_uuid),
  ).map(([upload_batch_uuid, localBatchUploads]) => {
    return {
      upload_batch_uuid,
      created_at: localBatchUploads[0].created_at,
      num_documents_unprocessed: 0,
      num_documents_processed: 0,
      num_documents_failed: 0,
      num_documents: localBatchUploads.length,
      num_documents_uploading: localBatchUploads.filter(
        (upload) => upload.status === DocumentStatus.uploading,
      ).length,
      num_documents_storing: localBatchUploads.filter(
        (upload) => upload.status === DocumentStatus.storing,
      ).length,
      num_documents_uploaded: localBatchUploads.filter(
        (upload) => upload.status === DocumentStatus.uploaded,
      ).length,
      num_documents_uploading_cancelled: localBatchUploads.filter(
        (upload) => upload.status === DocumentStatus.uploading_cancelled,
      ).length,
      num_documents_error_upload_failed: localBatchUploads.filter(
        (upload) => upload.status === DocumentStatus.error_upload_failed,
      ).length,
      num_documents_error_format_not_supported: localBatchUploads.filter(
        (upload) => upload.status === DocumentStatus.error_format_not_supported,
      ).length,
      num_documents_error_quota_exceeded: localBatchUploads.filter(
        (upload) => upload.status === DocumentStatus.error_quota_exceeded,
      ).length,
    };
  });

  localBatches.sort((a, b) => b.created_at.localeCompare(a.created_at));

  data.upload_batches = data.upload_batches.map((uploadBatch) => {
    localBatches = localBatches.map((localBatch) => {
      if (localBatch.upload_batch_uuid === uploadBatch.upload_batch_uuid) {
        const mergedBatch = {
          upload_batch_uuid: uploadBatch.upload_batch_uuid,
          created_at: uploadBatch.created_at,
          num_documents_uploading: localBatch.num_documents_uploading,
          num_documents_storing: Math.max(
            0,
            (localBatch.num_documents_storing || 0) -
              (uploadBatch.num_documents_unprocessed +
                uploadBatch.num_documents_processed +
                uploadBatch.num_documents_failed),
          ),
          num_documents_uploading_cancelled: localBatch.num_documents_uploading_cancelled,
          num_documents_uploaded: localBatch.num_documents_uploaded,
          num_documents_error_upload_failed: localBatch.num_documents_error_upload_failed,
          num_documents_error_format_not_supported:
            localBatch.num_documents_error_format_not_supported,
          num_documents_error_quota_exceeded: localBatch.num_documents_error_quota_exceeded,
          num_documents_unprocessed: uploadBatch.num_documents_unprocessed,
          num_documents_processed: uploadBatch.num_documents_processed,
          num_documents_failed: uploadBatch.num_documents_failed,
          num_documents: localBatch.num_documents,
        };

        uploadBatch = mergedBatch;
        localBatch = mergedBatch;

        /* When the browser has just uploaded a document, but has not yet refreshed the GET cache, there
        might be a discrepancy between the total number of documents in the batch and the sum of all
        statuses. We thus increase the uploading count to fill this gap and all numbers add up again.
        This situation usually just persists for a few seconds. */
        let difference = localBatch?.num_documents || 0;
        for (const [key, value] of Object.entries(localBatch)) {
          if (key.startsWith('num_') && typeof value === 'number') {
            if (key !== 'num_documents') {
              difference = difference - value;
            }
          }
        }
        if (difference !== 0) {
          localBatch.num_documents_storing = (mergedBatch.num_documents_storing || 0) + difference;
          // if (uploadBatch.upload_batch_uuid === localBatch.upload_batch_uuid) {
          //   uploadBatch.num_documents_uploading = localBatch.num_documents_uploading;
          // }
        }
      }

      return localBatch;
    });
    if (!uploadBatch.num_documents) {
      uploadBatch.num_documents = 0;
      for (const [key, value] of Object.entries(uploadBatch)) {
        if (key.startsWith('num_') && typeof value === 'number') {
          uploadBatch.num_documents = uploadBatch.num_documents + value;
        }
      }
    }

    return uploadBatch;
  });

  data.patched_upload_batches = data.upload_batches.filter(
    (uploadBatch) =>
      !localBatches
        .map((localBatch) => localBatch.upload_batch_uuid)
        .includes(uploadBatch.upload_batch_uuid),
  );

  data.localBatches = localBatches;

  return data;
}
