import 'draft-js/dist/Draft.css';

import React, { ChangeEvent, useEffect, useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Codefy from '../../../../codefy';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { documentsUpload } from '../../../../controllers/api/actions/documents/documentsUpload';
import escapeHtml from 'escape-html';
import pasteStyle from './pasteStyle';
import { stateToHTML } from 'draft-js-export-html';
import { toast } from 'react-toastify';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

export const getWordsFromText = (str: string) => {
  return str.replace(/\n/g, ' ').replace(/(([^\s]+\s\s*){7})(.*)/, '$1');
};

const toolbarVisibleStyle = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: '80vh',
    },
    inputContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

/** Allows the user to paste text instead of uploading a file */
export default function PasteTextDialog(props: {
  open: boolean;
  directory_id: Codefy.Objects.Project['id'];
  onCloseCallback: () => void;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSave = async (html: string, title: string) => {
    const file = new File([html], `${title}.html`, { type: 'text/html' });
    dispatch(documentsUpload([file], props.directory_id));
  };

  const classes = useStyles();

  const { data: directory } = useDirectoriesGet(props.directory_id);

  const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
  const [titleValue, setTitleValue] = useState('');
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const editorPlainText = editorValue.getEditorState().getCurrentContent().getPlainText();
  const onChange = (value: EditorValue) => {
    setIsValueChanged(true);
    setEditorValue(value);
    if (!isTitleChanged) {
      const plainText = value.getEditorState().getCurrentContent().getPlainText();
      const trimmedText = getWordsFromText(plainText);
      setTitleValue(trimmedText);
    }
  };

  const toolbarConfig = {
    display: [
      'INLINE_STYLE_BUTTONS',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS',
    ],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  };
  const clearFormState = () => {
    setIsTitleChanged(false);
    setEditorValue(RichTextEditor.createEmptyValue());
    setTitleValue('');
  };

  useEffect(() => {
    if (!props.open) {
      clearFormState();
    }
  }, [props.open]);

  const handlePasteClick = () => {
    const html = stateToHTML(editorValue.getEditorState().getCurrentContent());

    onSave(
      `<!DOCTYPE html>
      <html>
      <head>
          <style>${pasteStyle}</style>
      </head>
      <body>
          <h1>${escapeHtml(titleValue)}</h1>
            ${html}
      </body>
      </html>
      `,
      titleValue,
    );
    toast.success(
      t('pasteTextDialog.successPopup', { name: titleValue, postProcess: 'markdown-jsx' }),
    );
    if (createAnother) {
      clearFormState();
    } else {
      props.onCloseCallback();
    }
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTitleValue(value);
    setIsTitleChanged(!!value);
  };

  const rootStyle = { border: '1px solid #003366', background: '#00336608' };
  const editorStyle = { minHeight: '186px' };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth={false}
      open={props.open}
      style={{
        zIndex: 10000,
      }}
      onBackdropClick={props.onCloseCallback}
      PaperProps={{ className: isValueChanged ? classes.paper : '' }}>
      <DialogTitle id="responsive-dialog-title">
        {t('pasteTextDialog.pasteTextToProject', {
          project: directory?.path?.entry_name || directory?.path?.project_name || '',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('pasteTextDialog.help')}</DialogContentText>
        <div className={classes.inputContainer}>
          <TextField
            data-e2e-id="paste-text-title"
            id="standard-basic"
            label={t('pasteTextDialog.title')}
            fullWidth
            variant="outlined"
            size="small"
            value={titleValue}
            onChange={handleTitleChange}
          />
        </div>
        <RichTextEditor
          webDriverTestID="paste-text-editor"
          toolbarStyle={toolbarVisibleStyle}
          autoFocus
          placeholder={`${t('pasteTextDialog.pasteTextHere')}...`}
          value={editorValue}
          onChange={onChange}
          rootStyle={rootStyle}
          editorStyle={editorStyle}
          //@ts-ignore
          toolbarConfig={toolbarConfig}
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={createAnother}
              onChange={(event) => {
                const checked = event.target.checked;
                setCreateAnother(checked);
              }}
              name="createAnother"
              color="primary"
            />
          }
          label={t('pasteTextDialog.createAnother')}
        />
        <Button
          data-e2e-id="paste-text-save-btn"
          onClick={handlePasteClick}
          color="primary"
          variant="contained"
          disabled={!editorPlainText.trim() || !titleValue.trim()}>
          {t('pasteTextDialog.create')}
        </Button>
        <Button onClick={props.onCloseCallback}>{t('pasteTextDialog.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
