import {
  ArrayParam,
  BooleanParam,
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from 'use-query-params';
import { TaglistVariants, paneTypes } from '../components/panes/paneTypes/paneTypes';

import Codefy from '../codefy';
import { ModalKeys } from '../components/old_modals/modalTypes';
import { PaneKeys } from '../components/panes/paneTypes/paneKeys';
import { PdfViewerVariantType } from '../components/panes/paneTypes/pdfViewer/pdfViewer';
import { SearchSearchRequestParams } from './api/subscriptions/search/searchSearch';
import { useSelector } from 'react-redux';

export enum GLOBAL_QUERY_PARAMS {
  'panes' = 'panes',
  'focusedPane' = 'focusedPane',
  'panesDimensions' = 'panesDimensions',
  'modal' = 'modal',
  'annotation_id' = 'annotation_id',
  'annotationsList_descending' = 'annotationsList_descending',
  'annotationsList_directoryId' = 'annotationsList_directoryId',
  'annotationsList_documentId' = 'annotationsList_documentId',
  'annotationsList_excludeWithTagInstances' = 'annotationsList_excludeWithTagInstances',
  'annotationsList_orderBy' = 'annotationsList_orderBy',
  'annotationsList_selectedText' = 'annotationsList_selectedText',
  'annotationsList_type' = 'annotationsList_type',
  'annotationTag_tagId' = 'annotationTag_tagId',
  'annotationTaglist_id' = 'annotationTaglist_id',
  'assistant_directoryId' = 'assistant_directoryId',
  'assistant_documentId' = 'assistant_documentId',
  'assistant_projectId' = 'assistant_projectId',
  'assistant_taglistId' = 'assistant_taglistId',
  'assistantResults_tagId' = 'assistantResults_tagId',
  'editExportText_itemId' = 'editExportText_itemId',
  'editExportTextTemplates_tagId' = 'editExportTextTemplates_tagId',
  'entriesList_directoryId' = 'entriesList_directoryId',
  'entriesList_offset' = 'entriesList_offset',
  'entriesList_openDirectories' = 'entriesList_openDirectories',
  'entryTag_tagId' = 'entryTag_tagId',
  'entryTaglist_id' = 'entryTaglist_id',
  'pdfViewer_boxes' = 'pdfViewer_boxes',
  'pdfViewer_documentId' = 'pdfViewer_documentId',
  'pdfViewer_highlightAnnotationWithId' = 'pdfViewer_highlightAnnotationWithId',
  'pdfViewer_page' = 'pdfViewer_page',
  'pdfViewer_areaSelection' = 'pdfViewer_areaSelection',
  'pdfViewer2_boxes' = 'pdfViewer2_boxes',
  'pdfViewer2_documentId' = 'pdfViewer2_documentId',
  'pdfViewer2_highlightAnnotationWithId' = 'pdfViewer2_highlightAnnotationWithId',
  'pdfViewer2_page' = 'pdfViewer2_page',
  'pdfViewer2_areaSelection' = 'pdfViewer2_areaSelection',
  'projectShare_projectId' = 'projectShare_projectId',
  'projectsList_name' = 'projectsList_name',
  'projectsList_offset' = 'projectsList_offset',
  'search_attributes' = 'search_attributes',
  'search_directoryId' = 'search_directoryId',
  'search_documentId' = 'search_documentId',
  'search_headings' = 'search_headings',
  'search_isAutocomplete' = 'search_isAutocomplete',
  'search_language' = 'search_language',
  'search_maxCreatedAt' = 'search_maxCreatedAt',
  'search_maxNumPages' = 'search_maxNumPages',
  'search_method' = 'search_method',
  'search_minCreatedAt' = 'search_minCreatedAt',
  'search_minNumPages' = 'search_minNumPages',
  'search_orderBy' = 'search_orderBy',
  'search_page' = 'search_page',
  'search_partId' = 'search_partId',
  'search_partTypes' = 'search_partTypes',
  'search_query' = 'search_query',
  'search_recursive' = 'search_recursive',
  'search_selectedResultPartId' = 'search_selectedResultPartId',
  'searchBar_directoryId' = 'searchBar_directoryId',
  'searchBar_documentId' = 'searchBar_documentId',
  'sessionsPlayer_sessionKey' = 'sessionsPlayer_sessionKey',
  'uploadBatch_uuid' = 'uploadBatch_uuid',
  'uploadBatch_status' = 'uploadBatch_status',
  'nextUrl' = 'nextUrl',
}

export function useQueryParam_panes() {
  // eslint-disable-next-line prefer-const
  let [panes, set_panes] = useQueryParam(GLOBAL_QUERY_PARAMS.panes, ArrayParam);
  const userFeatureFlags = useSelector((state: Codefy.State) => state.user?.feature_flags);

  /** Hide panes that the user is missing the requires feature flags for */
  panes = panes?.filter((paneKey) => {
    const pane = paneTypes.find((p) => p.key === paneKey);

    /** When in doubt, default to showing a pane. These should be edge cases. */
    if (!pane || !userFeatureFlags) return true;

    /** If the pane needs no feature flags, show it */
    if (!pane.needsFeatureFlag) return true;

    /** Otherwise, only show it if the user has the feature flag enabled */
    return userFeatureFlags[pane.needsFeatureFlag];
  });

  return [panes as PaneKeys[], set_panes] as const;
}

export function useIsPaneOpen(paneKey: PaneKeys) {
  const [panes] = useQueryParam_panes();
  return panes.includes(paneKey);
}

export function useQueryParam_focusedPane() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.focusedPane, StringParam);
}

export function useQueryParam_panesDimensions() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.panesDimensions, ArrayParam);
}

export function useQueryParam_modal() {
  const [modal, set_modal] = useQueryParam(GLOBAL_QUERY_PARAMS.modal, StringParam);
  return [modal as ModalKeys, set_modal] as const;
}

export function useQueryParam_annotation_id() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.annotation_id, NumberParam);
}

export function useQueryParam_annotationsList_descending() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.annotationsList_descending, BooleanParam);
}

export function useQueryParam_annotationsList_directoryId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.annotationsList_directoryId, NumberParam);
}

export function useQueryParam_annotationsList_documentId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.annotationsList_documentId, NumberParam);
}

export function useQueryParam_annotationsList_orderBy() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.annotationsList_orderBy, StringParam);
}

export function useQueryParam_annotationsList_selectedText() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.annotationsList_selectedText, StringParam);
}

export function useQueryParam_annotationsList_type() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.annotationsList_type, StringParam);
}

export function useQueryParam_annotationsList_excludeWithTagInstances() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.annotationsList_excludeWithTagInstances, NumberParam);
}

export function useQueryParam_assistant_projectId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.assistant_projectId, NumberParam);
}

export function useQueryParam_assistant_directoryId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.assistant_directoryId, NumberParam);
}

export function useQueryParam_assistant_documentId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.assistant_documentId, NumberParam);
}

export function useQueryParam_assistantResults_tagId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.assistantResults_tagId, NumberParam);
}

export function useQueryParam_tag_tagId({
  taglistType,
}: {
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  return useQueryParam(
    taglistType === 'annotation'
      ? GLOBAL_QUERY_PARAMS.annotationTag_tagId
      : GLOBAL_QUERY_PARAMS.entryTag_tagId,
    NumberParam,
  );
}

export function useQueryParam_taglist_id({
  variant,
  taglistType,
}: {
  variant: TaglistVariants;
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  return useQueryParam(
    variant === TaglistVariants.TAGLIST
      ? taglistType === 'annotation'
        ? GLOBAL_QUERY_PARAMS.annotationTaglist_id
        : GLOBAL_QUERY_PARAMS.entryTaglist_id
      : GLOBAL_QUERY_PARAMS.assistant_taglistId,
    NumberParam,
  );
}

export function useQueryParam_editExportText_itemId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.editExportText_itemId, NumberParam);
}

export function useQueryParam_editExportTextTemplates_tagId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.editExportTextTemplates_tagId, NumberParam);
}

export function useQueryParam_entriesList_directoryId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.entriesList_directoryId, NumberParam);
}

export function useQueryParam_entriesList_offset() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.entriesList_offset, NumberParam);
}

export function useQueryParam_pdfViewer_boxes(variant: PdfViewerVariantType) {
  return useQueryParam(
    variant === 2 ? GLOBAL_QUERY_PARAMS.pdfViewer2_boxes : GLOBAL_QUERY_PARAMS.pdfViewer_boxes,
    JsonParam,
  );
}

export function useQueryParam_pdfViewer_documentId(variant: PdfViewerVariantType) {
  return useQueryParam(
    variant === 2
      ? GLOBAL_QUERY_PARAMS.pdfViewer2_documentId
      : GLOBAL_QUERY_PARAMS.pdfViewer_documentId,
    NumberParam,
  );
}

export function useQueryParam_pdfViewer_highlightAnnotationWithId(variant: PdfViewerVariantType) {
  return useQueryParam(
    variant === 2
      ? GLOBAL_QUERY_PARAMS.pdfViewer2_highlightAnnotationWithId
      : GLOBAL_QUERY_PARAMS.pdfViewer_highlightAnnotationWithId,
    NumberParam,
  );
}

export function useQueryParam_pdfViewer_page(variant: PdfViewerVariantType) {
  return useQueryParam(
    variant === 2 ? GLOBAL_QUERY_PARAMS.pdfViewer2_page : GLOBAL_QUERY_PARAMS.pdfViewer_page,
    NumberParam,
  );
}

export function useQueryParam_pdfViewer_areaSelection(variant: PdfViewerVariantType) {
  return useQueryParam(
    variant === 2
      ? GLOBAL_QUERY_PARAMS.pdfViewer2_areaSelection
      : GLOBAL_QUERY_PARAMS.pdfViewer_areaSelection,
    BooleanParam,
  );
}

export function useQueryParam_projectShare_projectId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.projectShare_projectId, NumberParam);
}

export function useQueryParam_projectsList_name() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.projectsList_name, StringParam);
}

export function useQueryParam_projectsList_offset() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.projectsList_offset, NumberParam);
}

export function useQueryParam_search_attributes() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_attributes, JsonParam);
}

export function useQueryParam_search_directoryId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_directoryId, NumberParam);
}

export function useQueryParam_search_documentId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_documentId, NumberParam);
}

export function useQueryParam_search_headings() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_headings, ArrayParam);
}

export function useQueryParam_search_language() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_language, StringParam);
}

export function useQueryParam_search_maxCreatedAt() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_maxCreatedAt, StringParam);
}

export function useQueryParam_search_maxNumPages() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_maxNumPages, NumberParam);
}

export function useQueryParam_search_method() {
  const [search_method, set_search_method] = useQueryParam(
    GLOBAL_QUERY_PARAMS.search_method,
    StringParam,
  );
  return [search_method as SearchSearchRequestParams['method'], set_search_method] as const;
}

export function useQueryParam_search_minCreatedAt() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_minCreatedAt, StringParam);
}

export function useQueryParam_search_minNumPages() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_minNumPages, NumberParam);
}

export function useQueryParam_search_orderBy() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_orderBy, StringParam);
}

export function useQueryParam_search_page() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_page, NumberParam);
}

export function useQueryParam_search_partId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_partId, NumberParam);
}

export function useQueryParam_search_partTypes() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_partTypes, ArrayParam);
}

export function useQueryParam_search_query() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_query, StringParam);
}

export function useQueryParam_search_recursive() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_recursive, BooleanParam);
}

export function useQueryParam_search_selectedResultPartId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_selectedResultPartId, NumberParam);
}

export function useQueryParam_search_isAutocomplete() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.search_isAutocomplete, NumberParam);
}

export function useQueryParam_searchBar_directoryId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.searchBar_directoryId, NumberParam);
}

export function useQueryParam_searchBar_documentId() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.searchBar_documentId, NumberParam);
}

export function useQueryParam_sessionsPlayer_sessionKey() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.sessionsPlayer_sessionKey, StringParam);
}

export function useQueryParam_uploadBatch_uuid() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.uploadBatch_uuid, StringParam);
}

export function useQueryParam_uploadBatch_status() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.uploadBatch_status, NumberParam);
}

export function useQueryParam_nextUrl() {
  return useQueryParam(GLOBAL_QUERY_PARAMS.nextUrl, StringParam);
}
