import React, { useState } from 'react';

import Codefy from '../../../codefy';
import GenericDeleteDialog from '../genericDeleteDialog';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import { annotationsDelete } from '../../../controllers/api/actions/annotations/annotationsDelete';
import { useBetween } from 'use-between';
import { usePaneActions } from '../../panes/usePaneActions';
import { useTranslation } from 'react-i18next';

type DialogDeleteAnnotationStateType = { annotationId?: Codefy.Objects.Annotation['id'] };

const useDialogDeleteAnnotationState = () => useState<DialogDeleteAnnotationStateType>();

const useSharedDialogDeleteAnnotationState = () => useBetween(useDialogDeleteAnnotationState);

export const DeleteAnnotationDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogDeleteAnnotationState();

  const paneActions = usePaneActions();

  const onClose = () => setState(undefined);

  const onDelete = async () => {
    if (!state?.annotationId) return;
    annotationsDelete({ annotation_id: state?.annotationId });
    paneActions.closePane(PaneKeys.annotation);
  };

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted deletions. */
  if (!state) return null;

  return (
    <GenericDeleteDialog
      open={!!state}
      title={t('deleteDialog.deleteAnnotation')}
      onDelete={onDelete}
      onClose={onClose}
    />
  );
};

export const useOpenDeleteAnnotationDialog = () => {
  const [, setState] = useSharedDialogDeleteAnnotationState();
  return (state: DialogDeleteAnnotationStateType) => () => setState(state);
};
