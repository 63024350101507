import Codefy from '../../codefy';
import { IconButton } from '@material-ui/core';
import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import html2canvas from 'html2canvas';
import { useDirectoriesGet } from '../../controllers/api/subscriptions/directories/directoriesGet';
import { useOpenCreatePublicationDialog } from '../dialogs/create/createPublicationDialog';
import { useQueryParam_entriesList_directoryId } from '../../controllers/useGlobalQueryParams';
import { useSelector } from 'react-redux';

function dataUrlToBlob(dataUrl: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataUrl.split(',')[1]);

  // separate out the mime component
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export default function CreatePublicationButton() {
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();
  const { data: directory } = useDirectoriesGet(entriesList_directoryId);
  const project_id = directory?.path?.project_id;
  const project_name = directory?.path?.project_name;

  const userEmail = useSelector((state: Codefy.State) => state.user?.email);
  const openCreatePublicationDialog = useOpenCreatePublicationDialog();

  const onCreatePublication = async () => {
    if (!project_id || !project_name) return;

    const canvas = await html2canvas(document.body);
    const dataUrl = canvas.toDataURL();
    const blob = dataUrlToBlob(dataUrl);

    openCreatePublicationDialog({
      project_id,
      project_name,
      target_url: window.location.pathname + window.location.search,
      image_blob: blob,
      image_data_url: dataUrl,
    });
  };

  if (!project_id) return null;

  return (
    <IconButton
      disabled={!userEmail}
      aria-label="share"
      edge="end"
      color="inherit"
      onClick={onCreatePublication}>
      <ShareIcon />
    </IconButton>
  );
}
