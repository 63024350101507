import Codefy from '../../../../codefy';
import { QueryKey } from 'react-query';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

type UploadBatchesListBatchesRequestParams = {
  upload_batch_uuid?: Codefy.Objects.UploadBatch['upload_batch_uuid'];
  offset?: number;
  limit?: number;
};

export type UploadBatchesListBatchesReturnType = {
  localBatches: Codefy.Objects.UploadBatch[];
  patched_upload_batches: Codefy.Objects.UploadBatch[];
  upload_batches: Codefy.Objects.UploadBatch[];
  num_upload_batches: number;
  offset: number;
};

export const UPLOAD_BATCHES_LIST_KEY_TYPE = 'uploadBatchesListBatches';

export const uploadBatchesListBatchesKey = (
  params: UploadBatchesListBatchesRequestParams,
): QueryKey => [UPLOAD_BATCHES_LIST_KEY_TYPE, params];

export const useUploadBatchesListBatches = (params: UploadBatchesListBatchesRequestParams) =>
  useCreateInfiniteQuery<UploadBatchesListBatchesReturnType>(
    uploadBatchesListBatchesKey(params),
    params.limit || 100,
    '/api/v1/upload_batches/list',
    'GET',
    params,
    'upload_batches',
    true,
    false,
  );
