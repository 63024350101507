import Codefy from '../../../../../../codefy';
import { useCreateInfiniteQuery } from '../../../../subscriptionHelpers';

export const TAG_INSTANCE_PREDICTIONS_LIST_URL = '/api/v1/taglists/tags/instances/predict_trigram';

export const TAG_INSTANCE_PREDICTIONS_LIST_KEY_TYPE = 'tagInstancesPredictions';

export type TagInstancePredictionsListParams = {
  tag_id?: Codefy.Objects.Tag['id'];
  document_ids?: Codefy.Objects.Document['id'][];
  directory_ids?: Codefy.Objects.Directory['id'][];
  // FIXME adapt to final API parameters
  window_size?: number;
  min_confidence?: number;
};

export type TagInstancePredictionsListReturnType = {
  tag_instance_predictions: Codefy.Objects.SearchResult[];
  num_tag_instance_predictions: number;
  offset: number;
};

export const tagInstancePredictionsListKey = (
  params: TagInstancePredictionsListParams,
): [string, TagInstancePredictionsListParams] => [TAG_INSTANCE_PREDICTIONS_LIST_KEY_TYPE, params];

export const useTagInstancePredictionsList = (params: TagInstancePredictionsListParams) =>
  useCreateInfiniteQuery<TagInstancePredictionsListReturnType>(
    tagInstancePredictionsListKey(params),
    10,
    TAG_INSTANCE_PREDICTIONS_LIST_URL,
    'GET',
    params,
    'tag_instance_predictions',
    false,
    false,
  );
