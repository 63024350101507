import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import AppLogo from '../appLogo/AppLogo';
import Box from '@material-ui/core/Box';
import Copyright from './copyright';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import login_background from './login_background.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formBox: {
      padding: theme.spacing(6, 5, 4),
      backgroundColor: 'white',
    },
    textBlock: {
      margin: theme.spacing(4, 0),
    },
    backgroundImage: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url("${login_background}")`,
      backgroundSize: 'cover',
      zIndex: -999,
    },
  }),
);

type Props = {
  title?: string | JSX.Element;
};

const NoAuthScreenWrapper: React.FC<Props> = ({ children, title }): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}>
        <Grid item xs={12} sm={9} lg={4}>
          <Box
            border={1}
            borderRadius={8}
            borderColor="#00000033"
            className={classes.formBox}
            minWidth={440}>
            <div className={classes.paper}>
              <Box mt={3} mb={8}>
                <AppLogo id="register-or-login-logo" width="150px" height="50px" />
              </Box>
              <Typography component="h3" variant="h5">
                {title}
              </Typography>
            </div>
            {children}
            <Box mt={8}>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <div className={classes.backgroundImage} />
    </>
  );
};

export default NoAuthScreenWrapper;
