import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { commonTextFieldProps, useStyles } from '../index';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { handleActionError } from '../../../../../controllers/api/actions/actionHelpers';
import { isEmailValid } from '../../../../auth/validation';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userChangeEmail } from '../../../../../controllers/api/actions/user/userChangeEmail';

// eslint-disable-next-line react/display-name
export const EmailSettingContent = forwardRef(
  (
    props: {
      email: string;
      setSaveDisabled: any;
      setLoading: any;
    },
    ref: any,
  ): JSX.Element => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const [email, setEmail] = useState(props.email);
    const [showPleaseConfirmMessage, setShowPleaseConfirmMessage] = useState<boolean>(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    // The component instance (ref) gets extended
    // with a function returned from the callback
    // passed as the second argument
    useImperativeHandle(ref, () => ({
      saveData() {
        props.setLoading(ref, true);
        // submit data after password input
        dispatch(
          userChangeEmail({
            email: email,
            password: password,
          }),
        )
          // @ts-ignore
          .then(() => {
            setShowPleaseConfirmMessage(true);

            props.setLoading(ref, false);
          }) // @ts-ignore
          .catch((error: any) => {
            props.setLoading(ref, false);
            handleActionError(error?.response?.data);
          });
      },
    }));

    useEffect(() => {
      // enable save button after password has been entered
      // and the email address is valid and is not the same
      if (isEmailValid(email) && password !== '' && email !== props.email) {
        props.setSaveDisabled(ref, false);
      } else {
        props.setSaveDisabled(ref, true);
      }
    });

    useEffect(() => {
      if (showPleaseConfirmMessage) {
        props.setSaveDisabled(ref, true);
      }
    });

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
      event.preventDefault();
    };

    return (
      <React.Fragment>
        {showPleaseConfirmMessage ? (
          <Typography color="primary">
            <b>{t('account.pleaseConfirm', { email })}</b>
          </Typography>
        ) : (
          <div>
            <Typography className={classes.secondaryHeading} variant="caption">
              {t('account.emailDescription')}
            </Typography>

            <Typography variant="caption">
              <TextField
                {...commonTextFieldProps}
                id="email"
                label={t('authScreen.email')}
                name="email"
                variant="standard"
                size="small"
                autoComplete="email"
                autoFocus
                error={email !== '' && !isEmailValid(email)}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Typography>

            <TextField
              {...commonTextFieldProps}
              label={t('account.password')}
              name="password"
              size="small"
              variant="standard"
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
      </React.Fragment>
    );
  },
);
