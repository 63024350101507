/** helper to calculate distance from point to box and distance between two points using Pythagorean theorem */

import Codefy from '../../../../../codefy';
type Point = {
  x: number;
  y: number;
};
export const rectDistanceToPoint = (point: Point, rect: Codefy.Objects.LayoutPart) => {
  let dx = 0;
  let dy = 0;

  if (point.x < rect.xmin) {
    dx = rect.xmin - point.x;
  } else if (point.x > rect.xmax) {
    dx = rect.xmax - point.x;
  }

  if (point.y < rect.ymin) {
    dy = rect.ymin - point.y;
  } else if (point.y > rect.ymax) {
    dy = rect.ymax - point.y;
  }

  return Math.sqrt(dx * dx + dy * dy);
};

export const pointDistanceToPoint = (point1: Point, point2: Point) => {
  const dx = point1.x - point2.x;
  const dy = point1.y - point2.y;

  return Math.sqrt(dx * dx + dy * dy);
};
