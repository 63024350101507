import React, { useEffect, useState } from 'react';

import Codefy from '../../codefy';
import { GenericNestableMenuEntry } from './genericNestableMenuEntry';
import { GenericNestableMenuItem } from './genericNestableMenuItem';
import { Menu, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { componentTypeStyles } from '../../globalThemeSettings';

export default function GenericMenuButton({
  button,
  menuEntries,
  title,
  hideMenuEntriesGroupTitle,
}: {
  button: React.ReactElement;
  menuEntries: GenericNestableMenuEntry[];
  title?: string | React.ReactElement;
  hideMenuEntriesGroupTitle?: boolean;
}) {
  const [menuPosition, setMenuPosition] = useState<any>(null);

  /** Hack to hide the menu after the user has selected files to upload */
  const uploadsTotal = useSelector((state: Codefy.State) => state.uploads.length);
  useEffect(() => {
    setMenuPosition(null);
  }, [uploadsTotal]);
  /** End of hack */

  const handleRightClick = (event: React.MouseEvent) => {
    if (menuPosition || menuEntries.length === 0) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleItemClick = () => {
    setMenuPosition(null);
  };

  return (
    <span onClick={handleRightClick}>
      {button}
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}>
        {title && !hideMenuEntriesGroupTitle && (
          <GenericNestableMenuItem
            key={'groupTitle'}
            menuItem={{
              key: 'groupTitle',
              text: (
                <Typography
                  style={{
                    ...componentTypeStyles.subheading,
                    ...componentTypeStyles.unselectable,
                  }}>
                  {title}
                </Typography>
              ),
            }}
            parentMenuOpen={!!menuPosition}
            itemClickedCallback={handleItemClick}
          />
        )}
        {menuEntries.map((menuItem) => (
          <GenericNestableMenuItem
            key={menuItem.key}
            menuItem={menuItem}
            parentMenuOpen={!!menuPosition}
            itemClickedCallback={handleItemClick}
          />
        ))}
      </Menu>
    </span>
  );
}
