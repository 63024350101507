import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../subscriptions/annotations/annotationsList';

import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import Codefy from '../../../../codefy';
import { executeAction } from '../actionHelpers';
import { TAGS_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagsList';

export const annotationsDelete = async (data: { annotation_id: Codefy.Objects.Annotation['id'] }) =>
  executeAction<Codefy.Objects.Annotation>({
    config: { url: '/api/v1/annotations/delete', method: 'POST' },
    data,
    queryKeysToRefresh: [
      ANNOTATIONS_LIST_KEY_TYPE,
      ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
      TAG_INSTANCES_LIST_KEY_TYPE,
      TAGS_LIST_KEY_TYPE,
    ],
  });
