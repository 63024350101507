import { QueryKey, useQuery } from 'react-query';
import { fastApiParamsSerializer, useCreateInfiniteQuery } from '../../subscriptionHelpers';

import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';

export type AnnotationsListRequestParams = {
  document_ids?: Codefy.Objects.Document['id'][];
  directory_ids?: Codefy.Objects.Directory['id'][];
  tag_ids?: Codefy.Objects.Tag['id'][];
  offset?: number;
  limit?: number;
  pages?: number[];
  order_by?: 'created_at' | 'created_by' | 'text' | 'name_page';
  descending?: boolean;
  text_snippet_len?: number;
  type?: 'selected_text' | 'search_result';
  selected_text?: string;
  comment_text?: string;
};

export type AnnotationsListReturnType = {
  annotations: Codefy.Objects.Annotation[];
  num_annotations: number;
};

export const ANNOTATIONS_LIST_URL = '/api/v1/annotations/list';

export const ANNOTATIONS_LIST_INFINITE_KEY_TYPE = 'annotationsListInfinite';

export const annotationsListInfiniteKey = (params: AnnotationsListRequestParams): QueryKey => [
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  params,
];

export const useAnnotationsListInfinite = (params: AnnotationsListRequestParams) =>
  useCreateInfiniteQuery<AnnotationsListReturnType>(
    annotationsListInfiniteKey(params),
    100,
    ANNOTATIONS_LIST_URL,
    'GET',
    { ...params, types: params.type ? [params.type] : undefined, type: undefined },
    'annotations',
  );

export const ANNOTATIONS_LIST_KEY_TYPE = 'annotationsList';

export const annotationsListKey = (params: AnnotationsListRequestParams): QueryKey => [
  ANNOTATIONS_LIST_KEY_TYPE,
  params,
];

export const useAnnotationsList = (params: AnnotationsListRequestParams) =>
  useQuery(
    annotationsListKey(params),
    async () => {
      const { data } = await axios.get(ANNOTATIONS_LIST_URL, {
        params: { ...params, types: params.type ? [params.type] : undefined, type: undefined },
        paramsSerializer: fastApiParamsSerializer,
      });
      return data as AnnotationsListReturnType;
    },
    /* For some reason this needs to be explicitely set here and setting it globally is
     not enough */
    { refetchOnWindowFocus: false },
  );
