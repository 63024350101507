import { createStyles, makeStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
import React from 'react';
// TODO: Update with necessary legal information as soon as we launch.
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    copyright: {
      textDecoration: 'none',
      opacity: 0.5,
      '&:hover': { cursor: 'pointer', textDecoration: 'none' },
    },
  }),
);

/** Displays the copyright information banner in the bottom */
const Copyright = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Typography variant="body1" color="primary" align="center">
      <Link color="inherit" href="https://codefy.de/impressum" className={classes.copyright}>
        <small>
          {t('copyright.madeIn')} | {t('copyright.impressum')}
        </small>
      </Link>{' '}
      <Link
        color="inherit"
        href="https://codefy.de/datenschutz-fuer-dienste"
        className={classes.copyright}>
        <small>| {t('copyright.privacyPolicy')}</small>
      </Link>
    </Typography>
  );
};

export default Copyright;
