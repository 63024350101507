import {
  DocumentStatus,
  cancelUploadBatchUpload,
} from '../../../controllers/api/actions/documents/documentsUpload';
import React, { useState } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import Codefy from '../../../codefy';
import GenericDeleteDialog from '../genericDeleteDialog';
import { useBetween } from 'use-between';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type DialogCancelUploadBatchUploadsStateType = {
  upload_batch_uuid?: Codefy.Objects.UploadBatch['upload_batch_uuid'];
};

const useDialogCancelUploadBatchUploadsState = () =>
  useState<DialogCancelUploadBatchUploadsStateType>();

const useSharedDialogCancelUploadBatchUploadsState = () =>
  useBetween(useDialogCancelUploadBatchUploadsState);

export const CancelUploadBatchUploadsDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogCancelUploadBatchUploadsState();

  const uploads = useSelector((s: Codefy.State) =>
    s.uploads.filter(
      (upload) =>
        upload.upload_batch_uuid === state?.upload_batch_uuid &&
        upload.status === DocumentStatus.uploading,
    ),
  );

  const onClose = () => setState(undefined);

  const onDelete = () => {
    if (!state?.upload_batch_uuid) return;
    cancelUploadBatchUpload(state.upload_batch_uuid);
  };

  if (!uploads?.length) return null;

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted deletions. */
  if (!state) return null;

  return (
    <GenericDeleteDialog
      open={!!state}
      icon={<CancelIcon />}
      title={t('uploadBatch.cancelUploads')}
      content={
        <span>
          {t('uploadBatch.uploadsWillBeCancelled', {
            count: uploads.length,
          })}
        </span>
      }
      onDelete={onDelete}
      onClose={onClose}
      deleteButtonText={t('uploadBatch.cancelUploads')}
    />
  );
};

export const useOpenCancelUploadBatchUploadsDialog = () => {
  const [, setState] = useSharedDialogCancelUploadBatchUploadsState();
  return (state: DialogCancelUploadBatchUploadsStateType) => () => setState(state);
};
