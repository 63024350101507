import Codefy from '../../../../../../codefy';
import { axios } from '@use-hooks/axios';
import { useQuery } from 'react-query';

export const TAG_INSTANCES_GET_URL = '/api/v1/taglists/tags/instances/get';

export const TAG_INSTANCES_GET_KEY_TYPE = 'tagInstancesGet';

export const tagInstancesGetKey = (
  id?: Codefy.Objects.Taglist['id'],
): [string, Codefy.Objects.Taglist['id'] | undefined] => [TAG_INSTANCES_GET_KEY_TYPE, id];

export const useTagInstancesGet = (tag_instances_id?: Codefy.Objects.TagInstance['id']) => {
  return useQuery(
    tagInstancesGetKey(tag_instances_id),
    async (key: string, tag_instances_id?: Codefy.Objects.TagInstance['id']) => {
      if (!tag_instances_id) return undefined;
      const { data } = await axios.get(TAG_INSTANCES_GET_URL, {
        params: { tag_instances_id: tag_instances_id },
      });
      return data as Codefy.Objects.TagInstance;
    },
  );
};
