import {
  DocumentIdContext,
  PDF_PAGE_MARGIN_BOTTOM_PERCENTAGE_OF_PAGE_HEIGHT,
  PdfPageIndexContext,
  PdfPagePageHeightContext,
  PdfPagePageWidthContext,
  PdfViewerVariantType,
  getPdfPageElementId,
} from './pdfViewer';
import React, { useContext, useMemo } from 'react';

import PaneContentLoading from '../../paneContentLoading';
import { PdfPageAnnotationLayer } from './pdfPageAnnotationLayer/pdfPageAnnotationLayer';
import PdfPageAreaSelectionLayer from './pdfPageAreaSelectionLayer/pdfPageAreaSelectionLayer';
import { PdfPageHighlightBoxes } from './pdfPageHighlightBoxes';
import { Page as PdfPageRenderer } from 'react-pdf';
import { PdfPageSelectionLayer } from './pdfPageTextSelectionLayer/pdfPageSelectionLayer';
import { useQueryParam_pdfViewer_areaSelection } from '../../../../controllers/useGlobalQueryParams';

export const PDF_PAGE_CLASS_NAME = 'pdf-page';

export function PdfPage({
  pdfViewerVariant,
  visible,
}: {
  pdfViewerVariant: PdfViewerVariantType;
  visible: boolean;
}) {
  const documentId = useContext(DocumentIdContext);
  const pageIndex = useContext(PdfPageIndexContext);
  const pageWidth = useContext(PdfPagePageWidthContext);
  const pageHeight = useContext(PdfPagePageHeightContext);

  const [pdfViewer_areaSelection] = useQueryParam_pdfViewer_areaSelection(pdfViewerVariant);

  const style: React.CSSProperties = useMemo(
    () => ({
      cursor: pdfViewer_areaSelection ? 'crosshair' : 'text',
      width: pageWidth,
      height: pageHeight,
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: pageHeight * PDF_PAGE_MARGIN_BOTTOM_PERCENTAGE_OF_PAGE_HEIGHT,
    }),
    [pageWidth, pageHeight, pdfViewer_areaSelection],
  );

  return (
    <div
      id={getPdfPageElementId(pdfViewerVariant, documentId, pageIndex)}
      className={PDF_PAGE_CLASS_NAME}
      style={style}>
      {visible && (
        <PdfPageRenderer
          pageIndex={pageIndex}
          width={pageWidth}
          height={pageHeight}
          renderTextLayer={false}
          loading={PaneContentLoading}>
          {pdfViewer_areaSelection ? (
            <PdfPageAreaSelectionLayer pdfViewerVariant={pdfViewerVariant} />
          ) : (
            <PdfPageSelectionLayer />
          )}
          <PdfPageHighlightBoxes pdfViewerVariant={pdfViewerVariant} />
          <PdfPageAnnotationLayer />
        </PdfPageRenderer>
      )}
    </div>
  );
}
