import { Box, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ListRange, Virtuoso } from 'react-virtuoso';
import React, { useCallback } from 'react';
import {
  useQueryParam_uploadBatch_status,
  useQueryParam_uploadBatch_uuid,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import { DocumentStatus } from '../../../../controllers/api/actions/documents/documentsUpload';
import { EntriesListItem } from '../entriesList/entriesListItem';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUploadBatchEntriesList } from '../../../../controllers/api/subscriptions/uploadBatches/uploadBatchesListBatchEntries';

export function UploadBatchEntriesRenderer({
  uploadBatch_uuid,
  uploadBatch_status,
}: {
  uploadBatch_uuid: string;
  uploadBatch_status: Codefy.Objects.Document['status'];
}) {
  const { t } = useTranslation();

  const {
    data: uploadBatchEntriesPages,
    fetchMore,
    canFetchMore,
    isFetchedAfterMount,
  } = useUploadBatchEntriesList({
    upload_batch_uuid: uploadBatch_uuid,
    document_processing_status: uploadBatch_status,
  });

  const entriesList =
    uploadBatchEntriesPages?.reduce((acc: Codefy.Objects.Entry[], cur) => {
      for (const entry of cur.entries) {
        acc.push(entry);
      }
      return acc;
    }, []) || [];

  const renderDocument = (index: number) => {
    if (!entriesList[index]) return <span></span>;
    return <EntriesListItem entry={entriesList[index]} />;
  };

  /** Triggered when the user scrolls in the list */
  const onRangeChanged = useCallback(
    (range: ListRange) => {
      /** If user didn't scroll far enough yet */
      if (range.endIndex < entriesList.length - 50) return;

      /** If there is nothing more to load */
      if (!canFetchMore) return;

      fetchMore();
    },
    [entriesList.length, canFetchMore],
  );

  if (!isFetchedAfterMount) return <PaneContentLoading />;

  if (entriesList.length === 0) {
    switch (uploadBatch_status) {
      case DocumentStatus.unprocessed:
        return <PaneContentEmpty text={t('uploadBatch.paneContentEmpty.unprocessed')} />;
      case DocumentStatus.processed:
        return <PaneContentEmpty text={t('uploadBatch.paneContentEmpty.processed')} />;
    }
  }

  return (
    <Box height="100%" width="100%">
      {uploadBatch_status === DocumentStatus.unprocessed && <PaneContentLoading />}
      <Virtuoso
        totalCount={entriesList?.length || 0}
        itemContent={renderDocument}
        rangeChanged={onRangeChanged}
      />
    </Box>
  );
}

export default function UploadBatch() {
  const { t } = useTranslation();
  const [uploadBatch_uuid] = useQueryParam_uploadBatch_uuid();
  const [uploadBatch_status] = useQueryParam_uploadBatch_status();

  const globalClasses = useGlobalStyles();

  const uploads = useSelector((state: Codefy.State) => state.uploads).filter(
    (upload) =>
      upload.status === uploadBatch_status && upload.upload_batch_uuid === uploadBatch_uuid,
  );

  /* Render data from the server */
  if (uploadBatch_uuid && uploadBatch_status !== undefined && uploadBatch_status <= 2) {
    return (
      <UploadBatchEntriesRenderer
        uploadBatch_uuid={uploadBatch_uuid}
        uploadBatch_status={uploadBatch_status}
      />
    );
  }

  /* Render data from the user's browser */

  if (uploads.length === 0) {
    switch (uploadBatch_status) {
      case DocumentStatus.uploading:
        return <PaneContentEmpty text={t('uploadBatch.paneContentEmpty.uploading')} />;
      case DocumentStatus.storing:
        return <PaneContentEmpty text={t('uploadBatch.paneContentEmpty.storing')} />;
      case DocumentStatus.uploading_cancelled:
        return <PaneContentEmpty text={t('uploadBatch.paneContentEmpty.uploading_cancelled')} />;
    }
  }

  const renderUpload = (index: number) => {
    if (!uploads[index])
      /* Prevent React Virtuoso from crashing */
      return <span style={{ height: '1px', width: '1px' }}></span>;
    const upload = uploads[index];
    return (
      <div key={upload.fileId}>
        <ListItem divider>
          <ListItemIcon className={globalClasses.narrowListItemIcon}>
            <EntryFileTypeIcon
              size={22}
              entryMimetype={upload.fileType as Codefy.Objects.EntryMimeType}
            />
          </ListItemIcon>
          <ListItemText primary={upload.fileName} />
        </ListItem>
      </div>
    );
  };

  return (
    <Box height="100%" width="100%">
      {uploadBatch_status === DocumentStatus.uploading && <PaneContentLoading />}
      <Virtuoso totalCount={uploads.length} itemContent={renderUpload} />
    </Box>
  );
}
