import { PaneKeys } from '../../components/panes/paneTypes/paneKeys';

/** IDs for certain UI elements that we want to be able to refer to from the headless browser tests */
export enum E2E {
  account_open_account_pane_button,
  account_logout_button,
  account_change_language_select,
  account_save_button,
  projectsList_createProject_button,
  dialog_generic_create_input,
  dialog_generic_delete_confirm,
}

/** What part of a pane one wants to refer to */
type PaneScope = 'entirePane' | 'titleBar' | 'closeButton' | 'content';

/* Generate an ID for a part of a pane so that tests can refer to these pane UI elements very
precisely */
export const getPaneE2eId = (paneKey: PaneKeys, scope: PaneScope) => {
  switch (scope) {
    case 'entirePane':
      return `pane-${paneKey}`;
    case 'titleBar':
      return `pane-${paneKey}-titleBar`;
    case 'closeButton':
      return `pane-${paneKey}-closeButton`;
    case 'content':
      return `pane-${paneKey}-content`;
  }
};

/** Helper function so that test files can be written more consisely. */
export const getPane = (paneKey: PaneKeys, scope: PaneScope) => {
  const querySelector = `[data-e2e-id="${getPaneE2eId(paneKey, scope)}"]`;
  return cy.get(querySelector);
};

/** Helper function so that test files can be written more consisely. */
export const getE2e = (dataE2eId: E2E) => {
  const querySelector = `[data-e2e-id="${dataE2eId}"]`;
  return cy.get(querySelector);
};
