import { Container, Draggable } from 'react-smooth-dnd';
import { DIRECTORY_DROP_GROUP_NAME, DropPayload } from './directoryDropWrapper';
import React, { useCallback } from 'react';

import Codefy from '../../../../codefy';

/** Allows dragging entries */
export default function EntryMoveDragWrapper({
  entry,
  children,
}: {
  entry?: Codefy.Objects.Entry;
  children: React.ReactChild | React.ReactChild[];
}) {
  const getChildPayload = useCallback((): DropPayload => ({ type: 'moveEntry', data: entry }), [
    entry?.id,
  ]);
  const shouldAcceptDrop = useCallback(() => false, []);

  return (
    <Container
      getChildPayload={getChildPayload}
      groupName={DIRECTORY_DROP_GROUP_NAME}
      behaviour="drop-zone"
      shouldAcceptDrop={shouldAcceptDrop}>
      <Draggable>{children}</Draggable>
    </Container>
  );
}
