import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';
import { usePaginatedQuery } from 'react-query';

export type UsersListParams = {
  email?: string;
  project?: boolean;
  offset?: number;
  limit?: number;
};

export type UsersListResponse = { users: Codefy.Objects.User[]; num_users: number };

export const USERS_LIST_KEY_TYPE = 'usersList';

export const usersListKey = (params: UsersListParams): [string, Record<string, any>] => [
  USERS_LIST_KEY_TYPE,
  params,
];

export const useUsersList = (params: UsersListParams) =>
  usePaginatedQuery(usersListKey(params), async () => {
    const { data } = await axios.get('/api/v1/users/list', { params });
    return data as UsersListResponse;
  });
