/* eslint-disable */

/** custom implementation of minBy similar to lodash */

const getTag = (value: any) => {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return value.toString();
};
const isSymbol = (value: any) => {
  const type = typeof value;
  return (
    type == 'symbol' || (type === 'object' && value != null && getTag(value) == '[object Symbol]')
  );
};

const minBy = (array: any[], iteratee: any) => {
  let result;
  if (array == null) {
    return result;
  }
  let computed;
  for (const value of array) {
    const current = iteratee(value);

    if (
      current != null &&
      (computed === undefined ? current === current && !isSymbol(current) : current < computed)
    ) {
      computed = current;
      result = value;
    }
  }
  return result;
};

export default minBy;
