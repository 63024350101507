import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
  AnnotationsListReturnType,
} from '../../subscriptions/annotations/annotationsList';

import Codefy from '../../../../codefy';
import { TAGS_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { executeAction } from '../actionHelpers';
import { queryCache } from 'react-query';

// TODO: This is not really optimized, causing lots of unnecessary refreshes
export const ANNOTATIONS_CREATE_QUERY_KEYS_TO_REFRESH = [
  ANNOTATIONS_LIST_KEY_TYPE,
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  TAG_INSTANCES_LIST_KEY_TYPE,
  TAGS_LIST_KEY_TYPE,
];

export const annotationsCreate = async (data: {
  document_id: Codefy.Objects.Document['id'];
  boxes: Codefy.Objects.Box[];
  area?: Codefy.Objects.Annotation['area'];
  type: Codefy.Objects.Annotation['type'];
  tag_id: Codefy.Objects.Tag['id'];
  /** Where did the user create the tag instance from? Used for metrics. */
  source: string;
}) => {
  if (data.boxes.length === 0) return undefined;

  const result = await executeAction<Codefy.Objects.Annotation>({
    config: { url: '/api/v1/annotations/create', method: 'POST' },
    data,
    queryKeysToRefresh: ANNOTATIONS_CREATE_QUERY_KEYS_TO_REFRESH,
  });

  if (!result) return;

  /** Use the response to manually mutate the local cache for maximum performance */
  for (const query of queryCache.getQueries(ANNOTATIONS_LIST_KEY_TYPE)) {
    const data = queryCache.getQueryData(query.queryKey) as AnnotationsListReturnType;
    if (!data) continue;
    /* We just add it to all pages since when rendering we filter by page again, so it will not be
displayed twice */
    query.setData({
      ...data,
      annotations: [...data.annotations, result],
    });
  }

  return result;
};
