import {
  useQueryParam_searchBar_directoryId,
  useQueryParam_searchBar_documentId,
  useQueryParam_search_attributes,
  useQueryParam_search_directoryId,
  useQueryParam_search_documentId,
  useQueryParam_search_isAutocomplete,
  useQueryParam_search_language,
  useQueryParam_search_method,
  useQueryParam_search_query,
  useQueryParam_search_recursive,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import { SearchSearchRequestParams } from '../../../../controllers/api/subscriptions/search/searchSearch';
import { useSelector } from 'react-redux';

/** A simple hook that maps search params from the URL (e.g. "search_minNumPages") to API endpoints
 * (e.g. "min_num_pages") */
export default function useSearchParams({
  variant,
}: {
  /* Should the hook provide the query as it is typed in the search bar, or only after starting the
search (e.g. by pressing Enter) */
  variant?: 'searchBar' | 'searchPane';
}) {
  const [search_directoryId] = useQueryParam_search_directoryId();
  const [search_documentId] = useQueryParam_search_documentId();
  const [searchBar_directoryId] = useQueryParam_searchBar_directoryId();
  const [searchBar_documentId] = useQueryParam_searchBar_documentId();
  const [search_query] = useQueryParam_search_query();
  const [search_language] = useQueryParam_search_language();
  const [search_attributes] = useQueryParam_search_attributes();
  const [search_recursive] = useQueryParam_search_recursive();
  const [search_isAutocomplete] = useQueryParam_search_isAutocomplete();
  const [search_method] = useQueryParam_search_method();

  const searchQueryCurrentlyBeingTyped = useSelector((state: Codefy.State) => state.search.query);

  const params: SearchSearchRequestParams = {
    query: variant === 'searchBar' ? searchQueryCurrentlyBeingTyped : search_query || '',
    method: search_method || 'insta',
  };

  switch (variant) {
    case 'searchBar':
      if (searchBar_documentId) {
        params['document_ids'] = [searchBar_documentId];
      } else if (searchBar_directoryId) {
        params['directory_ids'] = [searchBar_directoryId];
        params['recursive'] = search_recursive ?? true;
      }
      break;
    case 'searchPane':
      if (search_documentId) {
        params['document_ids'] = [search_documentId];
      } else if (search_directoryId) {
        params['directory_ids'] = [search_directoryId];
        params['recursive'] = search_recursive ?? true;
      }
      break;
  }

  params['attributes'] = search_attributes;
  params['languages'] = search_language
    ? [search_language as Codefy.Objects.LanguageType]
    : undefined;

  params['autocomplete'] = search_isAutocomplete === 1;

  return params;
}
