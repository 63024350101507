import Codefy from '../../../../codefy';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

type ProjectsListParams = {
  starred?: Codefy.Objects.Project['starred'];
  offset?: number;
  limit?: number;
  order_by?: string;
  descending?: boolean;
  name?: string;
};

type ProjectsListReturnType = {
  projects: Codefy.Objects.Project[];
  num_projects: number;
};

export const PROJECTS_LIST_KEY_TYPE = 'projectsList';

export const projectsListKey = (params: ProjectsListParams): [string, {} | undefined] => [
  PROJECTS_LIST_KEY_TYPE,
  params,
];

export const useProjectsList = (params: ProjectsListParams) =>
  useCreateInfiniteQuery<ProjectsListReturnType>(
    projectsListKey(params),
    100,
    '/api/v1/projects/list',
    'GET',
    params,
    'projects',
  );
