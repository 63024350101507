import { COLORS } from '../../../../../globalThemeSettings';
import Codefy from '../../../../../codefy';
import { TAGLISTS_GET_KEY_TYPE } from '../../../subscriptions/taglists/taglistsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsList';
import { executeAction } from '../../actionHelpers';

export type TagsCreateRequestType = {
  taglist_id: Codefy.Objects.Taglist['id'];
  name: string;
  before_pos?: string;
  color?: string;
  parent_tag_id?: Codefy.Objects.Tag['id'];
  description?: Codefy.Objects.Tag['description'];
};

export const tagsCreate = (data: TagsCreateRequestType) =>
  executeAction<Codefy.Objects.Tag>({
    config: { url: '/api/v1/taglists/tags/create', method: 'POST' },
    data: { ...data, color: data.color || COLORS.defaultTagColor },
    queryKeysToRefresh: [TAGLISTS_GET_KEY_TYPE, TAGS_LIST_KEY_TYPE],
  });
