import {
  useQueryParam_searchBar_directoryId,
  useQueryParam_searchBar_documentId,
} from '../../../controllers/useGlobalQueryParams';

import { focusSearchBarInput } from './setSearchBarInputValue';

export default function useSetSearchParams(
  scope: 'everywhere' | 'directory' | 'document',
  id?: number,
) {
  const [searchBar_directoryId, set_searchBar_directoryId] = useQueryParam_searchBar_directoryId();
  const [searchBar_documentId, set_searchBar_documentId] = useQueryParam_searchBar_documentId();

  const onSetSearchScopeEverywhere = () => {
    searchBar_directoryId && set_searchBar_directoryId(undefined, 'pushIn');
    searchBar_documentId && set_searchBar_documentId(undefined, 'pushIn');
    focusSearchBarInput();
  };

  const onSetSearchScopeDirectory = () => {
    if (!id) return;
    searchBar_documentId && set_searchBar_documentId(undefined, 'pushIn');
    set_searchBar_directoryId(id, 'pushIn');
    focusSearchBarInput();
  };

  const onSetSearchScopeDocument = () => {
    if (!id) return;
    set_searchBar_documentId(id, 'pushIn');
    searchBar_directoryId && set_searchBar_directoryId(undefined, 'pushIn');
    focusSearchBarInput();
  };

  switch (scope) {
    case 'everywhere':
      return onSetSearchScopeEverywhere;
    case 'directory':
      return onSetSearchScopeDirectory;
    case 'document':
      return onSetSearchScopeDocument;
  }
}
