import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../../subscriptions/annotations/annotationsList';

import { TAGLISTS_GET_KEY_TYPE } from '../../../subscriptions/taglists/taglistsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsList';
import { ANNOTATIONS_GET_KEY_TYPE } from '../../../subscriptions/annotations/annotationsGet';
import { TAG_INSTANCES_GET_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import Codefy from '../../../../../codefy';
import { executeAction } from '../../actionHelpers';
import { ENTRIES_LIST_KEY_TYPE } from '../../../subscriptions/entries/entriesList';

export const tagsDelete = (data: { tag_id: Codefy.Objects.Tag['id'] }) =>
  executeAction<Codefy.Objects.Tag>({
    config: { url: '/api/v1/taglists/tags/delete', method: 'POST' },
    data,
    queryKeysToRefresh: [
      TAGLISTS_GET_KEY_TYPE,
      TAGS_LIST_KEY_TYPE,
      TAG_INSTANCES_GET_KEY_TYPE,
      TAG_INSTANCES_LIST_KEY_TYPE,
      ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
      ANNOTATIONS_LIST_KEY_TYPE,
      ANNOTATIONS_GET_KEY_TYPE,
      ENTRIES_LIST_KEY_TYPE,
    ],
  });
