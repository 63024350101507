import './styles.css';

import { COLORS, useGlobalStyles } from '../../../../../globalThemeSettings';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SelectionHelper, { VISIBLE_SELECTION_CLASS_NAME } from './selectionHelper';

import AnnotationContextMenuWrapper from '../../annotation/annotationContextMenuWrapper';
import Codefy from '../../../../../codefy';
import { PaneKeys } from '../../paneKeys';
import SelectionMenu from '../../../../menus/selectionMenu';
import stringify from 'fast-stringify';
import { useAnnotationsList } from '../../../../../controllers/api/subscriptions/annotations/annotationsList';
import { useDocumentsGet } from '../../../../../controllers/api/subscriptions/documents/documentsGet';
import useKeyPress from '../../../../../hooks/useKeyPress';
import { usePaneActions } from '../../../usePaneActions';

const contextState = {
  mouseX: 0,
  mouseY: 0,
};

const clearSelection = () => {
  const allSelections = document.querySelectorAll(
    `.page .selectionLayer .${VISIBLE_SELECTION_CLASS_NAME}`,
  );
  allSelections.forEach((sel) => sel.classList.remove(VISIBLE_SELECTION_CLASS_NAME));
};

/** Component renders selection boxes and handles custom selection using mouse event.
 * Shows context menu on selection finished. Also support ctrl+c key to copy selected text to
 * clipboard */
const SelectionLayer = ({
  uniqueId,
  wrapperClassName,
  boxes,
  documentId,
  renderSelectionBoxes,
  renderAnnotationBoxes,
  source,
}: {
  uniqueId: string;
  wrapperClassName: string;
  boxes: Codefy.Objects.LayoutPart[];
  documentId: Codefy.Objects.Document['id'];
  renderSelectionBoxes?: boolean;
  // TODO: Is there a better way?
  /** Used to hide annotation boxes from "lower" UI elements when the "higher" searchDropdown is
   * open (otherwise the annotation boxes from the lower UI elements, like the search results list,
   * would show for some reason). */
  renderAnnotationBoxes: boolean;
  /** Where is the user accessing this component from? Used for metrics. */
  source: string;
}) => {
  const paneActions = usePaneActions();

  const globalClasses = useGlobalStyles();

  const [selectionHelper] = useState(new SelectionHelper());
  const shiftPressed = useKeyPress(16);
  selectionHelper.uniqueId = uniqueId;
  const [selectionText, setSelectionText] = useState<string | null>(null);

  const pages = boxes.reduce((acc: number[], cur) => {
    if (!acc.includes(cur.page)) {
      acc.push(cur.page);
    }
    return acc;
  }, []);

  const { data: annotations } = useAnnotationsList({
    document_ids: [documentId],
    pages,
  });

  const { data: _document } = useDocumentsGet(documentId);

  const onMouseMove = useCallback(
    (e: Event) => {
      if (!selectionHelper.selectionStartElement) {
        return null;
      }

      SelectionHelper.clearSelection();
      const { x, y } = selectionHelper.getRelativeCoordinates(e);
      selectionHelper.selectionEndElement = selectionHelper.getClosestElement(x, y);
      selectionHelper.lastSelectionPosition.x = x;
      selectionHelper.lastSelectionPosition.y = y;
      const list = selectionHelper.getSelectedElementsList();
      selectionHelper.selectedBoxes = list;
      list.forEach((l) => {
        const innerSelector =
          '#' + uniqueId + ' .selection[data-order-number="' + l.orderNumber.toString() + '"]';
        const el = document.querySelector(innerSelector);
        if (el) {
          el.classList.add(VISIBLE_SELECTION_CLASS_NAME);
        }
      });
    },
    [selectionHelper, uniqueId],
  );
  const onMouseDown = useCallback(
    (e: Event) => {
      SelectionHelper.clearSelection();
      selectionHelper.selectedBoxes = [];
      const { x, y } = selectionHelper.getRelativeCoordinates(e);
      selectionHelper.selectionStartElement = selectionHelper.getClosestElement(x, y);
      selectionHelper.startSelectionPosition.x = x;
      selectionHelper.startSelectionPosition.y = y;
    },
    [selectionHelper],
  );

  const onDocumentMouseMove = useCallback(
    (e: MouseEvent) => {
      /* If open already, don't move */
      if (selectionText) {
        return;
      }
      contextState.mouseX = e.clientX;
      contextState.mouseY = e.clientY;
    },
    [selectionText],
  );

  const onCopy = useCallback(
    (event: ClipboardEvent) => {
      if (event?.clipboardData && selectionText) {
        event.clipboardData.setData('text/plain', selectionText);
      }
      event.preventDefault();
    },
    [selectionText],
  );

  useEffect(() => {
    document.addEventListener('mousemove', onDocumentMouseMove);
    return () => {
      document.removeEventListener('mousemove', onDocumentMouseMove);
    };
  }, [onDocumentMouseMove]);

  useEffect(() => {
    document.addEventListener('copy', onCopy);
    return () => {
      document.removeEventListener('copy', onCopy);
    };
  }, [onCopy]);

  useEffect(() => {
    const selector = '#' + uniqueId + ' .' + wrapperClassName;
    const element = document.querySelector(selector);
    if (element) {
      element.addEventListener('mousedown', onMouseDown);
      element.addEventListener('mousemove', onMouseMove);
      /** add double click event to select entire word */
      element.addEventListener('dblclick', (e) => {
        const { target } = e;
        const selectionElement = target as HTMLElement;
        /** make sure that current element is selection box */
        if (selectionElement.classList.contains('selection')) {
          clearSelection();
          selectionElement.classList.add(VISIBLE_SELECTION_CLASS_NAME);
          setSelectionText(selectionElement.getAttribute('data-text-content'));
        }
      });

      document.addEventListener('mouseup', () => {
        const list = selectionHelper.getSelectedElementsList();
        const text = list.map((l) => l.text).join('');
        selectionHelper.selectionStartElement = null;
        selectionHelper.selectionEndElement = null;
        if (text) {
          setSelectionText(text.replace(/\s\s/g, ' '));
        }
      });
    }
  }, [uniqueId, selectionHelper, onMouseMove, onMouseDown, wrapperClassName]);

  const selectionBoxes = useMemo(() => {
    if (renderSelectionBoxes && boxes.length) {
      selectionHelper.boxesList = boxes;
      return boxes.map((box) => {
        return (
          <span
            style={{
              position: 'absolute',
              left: box.xmin + 'px',
              top: box.ymin + 'px',
              width: box.xmax - box.xmin + 'px',
              height: box.ymax - box.ymin + 'px',
              cursor: 'text',
              /** Make sure the selections are always above the annotations, so that you can select over
               * highlighted text */
              zIndex: 2000,
            }}
            key={uniqueId + box.orderNumber}
            data-text-content={box.text}
            data-order-number={box.orderNumber}
            className="selection"
          />
        );
      });
    }
    return <></>;
  }, [stringify(boxes), renderSelectionBoxes]);

  const annotationBoxes = useMemo(() => {
    if (!renderAnnotationBoxes) return [];

    const annotationBoxes =
      annotations?.annotations?.reduce(
        (acc: { annotation: Codefy.Objects.Annotation; box: Codefy.Objects.Box }[], cur) => {
          for (const box of cur?.boxes) {
            acc.push({ box, annotation: cur });
          }
          return acc;
        },
        [],
      ) || [];

    const annotatedBoxes = [];

    const tolerance = 0.002;

    const isContained = (box: Codefy.Objects.Box, annotationBox: Codefy.Objects.Box) => {
      if (box.page !== annotationBox.page) return false;

      return (
        annotationBox.xmin - tolerance <= box.xmin &&
        annotationBox.xmax + tolerance >= box.xmax &&
        annotationBox.ymin - tolerance <= box.ymin &&
        annotationBox.ymax + tolerance >= box.ymax
      );
    };

    for (const box of boxes) {
      for (const annotationBox of annotationBoxes) {
        if (isContained(box.originalData, annotationBox.box)) {
          annotatedBoxes.push(
            <AnnotationContextMenuWrapper
              annotation={annotationBox.annotation}
              key={annotationBox.annotation.id}>
              <span
                onClick={() =>
                  paneActions.addOrUpdatePane({
                    paneKey: PaneKeys.annotation,
                    params: { annotation_id: annotationBox.annotation.id },
                  })
                }
                style={{
                  position: 'absolute',
                  left: box.xmin + 'px',
                  top: box.ymin + 'px',
                  width: box.xmax - box.xmin + 'px',
                  height: box.ymax - box.ymin + 'px',
                  zIndex: 1000,
                  backgroundColor:
                    annotationBox.annotation.tag_instances?.[0]?.tag_color ||
                    COLORS.defaultTagColor,
                }}
                //key={index}
                data-text-content={box.text}
                data-order-number={box.orderNumber}
                className={globalClasses.annotationBox}
              />
            </AnnotationContextMenuWrapper>,
          );
        }
      }
    }
    return annotatedBoxes;
  }, [stringify(boxes), annotations?.num_annotations, renderAnnotationBoxes]);

  const handleContextMenuClose = () => {
    /* Allow powerusers to quickly press several actions without having to re-select */
    if (shiftPressed) {
      return;
    }
    setSelectionText(null);
    SelectionHelper.clearSelection();
    selectionHelper.selectedBoxes = [];
  };

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      {ref.current && selectionHelper.selectedBoxes.length > 0 && selectionText && (
        <SelectionMenu
          source={source}
          menuAnchorElement={ref.current}
          handleContextMenuClose={handleContextMenuClose}
          selectedText={selectionText}
          selectedBoxes={selectionHelper.selectedBoxes.map((box) => ({
            page: box.page,
            xmin: box.originalData.xmin,
            xmax: box.originalData.xmax,
            ymin: box.originalData.ymin,
            ymax: box.originalData.ymax,
          }))}
          _document={_document}
        />
      )}
      <div className="selectionLayer">{selectionBoxes}</div>
      <div className="annotationsLayer">{annotationBoxes}</div>
    </div>
  );
};
export default SelectionLayer;
