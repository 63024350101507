import { COLORS } from '../../globalThemeSettings';
import React from 'react';

/** Icon used for entry taglists */
export default function EntryTaglistIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path fill="none" d="M0,0H24V24H0ZM0,0H24V24H0Z" />
          <path
            fill={COLORS.primary}
            d="M13,15H6v2h7Zm4-4H6v2H17Zm0-4H6V9H17ZM5,7H3V9H5ZM5,17V15H3v2Zm0-6H3v2H5Z"
          />
          <path
            fill={COLORS.primary}
            d="M22.62,16.65l-.8-1.4a.21.21,0,0,0-.25-.09l-1,.4a3.07,3.07,0,0,0-.68-.39l-.15-1.06a.22.22,0,0,0-.22-.16H17.92a.18.18,0,0,0-.19.18l-.15,1.06a3.52,3.52,0,0,0-.68.39l-1-.41a.2.2,0,0,0-.25.1l-.8,1.4a.2.2,0,0,0,.05.26l.85.66c0,.13,0,.26,0,.38s0,.28,0,.39l-.85.66a.2.2,0,0,0-.05.26l.8,1.4a.21.21,0,0,0,.25.1l1-.41a3.07,3.07,0,0,0,.68.39l.15,1.06a.18.18,0,0,0,.19.18h1.61a.18.18,0,0,0,.19-.18l.15-1.06a3.45,3.45,0,0,0,.67-.39l1,.41a.19.19,0,0,0,.24-.1l.81-1.4a.2.2,0,0,0-.05-.26l-.84-.66a2.53,2.53,0,0,0,0-.38,2.38,2.38,0,0,0,0-.39l.86-.66A.26.26,0,0,0,22.62,16.65Zm-3.9,2.66a1.34,1.34,0,0,1,0-2.67A1.35,1.35,0,0,1,20.06,18,1.33,1.33,0,0,1,18.72,19.31Z"
          />
        </g>
      </g>
    </svg>
  );
}
