import { bindContextMenu, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';

import Codefy from '../../../../codefy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import { fastApiParamsSerializer } from '../../../../controllers/api/subscriptionHelpers';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenDeleteProjectDialog } from '../../../dialogs/delete/deleteProjectDialog';
import { useOpenRenameProjectDialog } from '../../../dialogs/rename/renameProjectDialog';
import { useOpenUnshareProjectDialog } from '../../../dialogs/delete/unshareProjectDialog';
import { usePaneActions } from '../../usePaneActions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Whatever is inside this wrapper will open a project context menu (rename, delete, ...) when
 * right-clicked */
export default function ProjectContextMenuWrapper({
  project,
  children,
  openOnLeftClick,
}: {
  project?: Codefy.Objects.Project;
  children: React.ReactChild | React.ReactChildren;
  /** Used when we are wrapping a button that is supposed to open the context menu when clicked
   * (with the left mouse button) */
  openOnLeftClick?: boolean;
}) {
  const { t } = useTranslation();
  const paneActions = usePaneActions();
  const isEveryone = useIsEveryone();

  const openRenameProjectDialog = useOpenRenameProjectDialog();
  const openDeleteProjectDialog = useOpenDeleteProjectDialog();
  const openUnshareProjectDialog = useOpenUnshareProjectDialog();

  const userEmail = useSelector((state: Codefy.State) => state.user?.email) ?? 'everyone';

  const userHasWriteAccess =
    userEmail != 'everyone' &&
    project?.permissions
      .filter((permission) => permission.user.email == userEmail)
      .map((permission) => permission.write || [false])[0] == true;

  const severalUsersHaveAccess =
    (project?.permissions?.map((permission) => permission.user.email).length || 1) > 1;

  const userIsOwner = project?.created_by?.email === userEmail;

  const contextMenuState = usePopupState({ variant: 'popover', popupId: 'projectContextMenu' });
  const [contextState, setContextState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  if (!project) return <>{children}</>;

  const onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    contextMenuState.close();
  };

  const onOpenProject = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.entriesList,
      params: { entriesList_directoryId: project.directory_id },
      reset: true,
    });
  };

  const onOpenProjectShareSettings = async () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.projectShareSettings,
      params: { projectShare_projectId: project.id },
      reset: true,
    });
  };

  // 2021-02-11 Disabled for now
  // const onPublishProject = async () => {
  //   projectsShare({ project_ids: [project.id], email: 'everyone', write: false });
  // };

  // 2021-02-11 Disabled for now
  // const onUnpublishProjectEveryone = async () => {
  //   if (window.confirm(t('projectContextMenuWrapper.unpublishConfirm'))) {
  //     projectsUnshare({ project_ids: [project.id], email: 'everyone' });
  //   }
  // };

  // 2021-05-21 Disabled for now
  // const onDuplicateProject = async () => {
  //   await projectsDuplicate({ project_id: project.id, name: project.name });
  // };

  const onDownloadProject = () => {
    const downloadUrl =
      '/api/v1/projects/download?' + fastApiParamsSerializer({ project_id: project.id });
    window.location.assign(downloadUrl);
  };

  const menuAnchorPosition =
    contextState.mouseY !== null && contextState.mouseX !== null
      ? { top: contextState.mouseY, left: contextState.mouseX }
      : undefined;

  const menu = (
    <Menu
      {...bindMenu(contextMenuState)}
      anchorReference="anchorPosition"
      anchorPosition={menuAnchorPosition}
      onClick={onMenuClick}>
      <MenuItem onClick={onOpenProject}>
        <ListItemIcon>
          <IconButton size="small">
            <OpenInNewIcon />
          </IconButton>
        </ListItemIcon>
        {t('projectsList.open')}
      </MenuItem>
      <MenuItem
        data-e2e-id="project-contextmenu-rename"
        onClick={openRenameProjectDialog({ projectId: project.id })}
        disabled={isEveryone || !userHasWriteAccess}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        {t('projectsList.contextMenu.rename')}
      </MenuItem>

      <MenuItem onClick={onOpenProjectShareSettings} disabled={isEveryone || !userHasWriteAccess}>
        <ListItemIcon>
          <IconButton size="small">
            <ShareIcon />
          </IconButton>
        </ListItemIcon>
        {t('projectContextMenuWrapper.share')}
      </MenuItem>

      {/* 2021-02-11 Disabled for now */}
      {/* {project.permissions.map((permission) => permission.user.email).includes('everyone') ? (
            <MenuItem
              onClick={onUnpublishProjectEveryone}
              disabled={isEveryone || !userHasWriteAccess}>
              <ListItemIcon>
                <IconButton size="small">
                  <PublicIcon />
                </IconButton>
              </ListItemIcon>
              {t('projectContextMenuWrapper.unpublish')}
            </MenuItem>
          ) : (
            <MenuItem onClick={onPublishProject} disabled={isEveryone || !userHasWriteAccess}>
              <ListItemIcon>
                <IconButton size="small">
                  <PublicIcon />
                </IconButton>
              </ListItemIcon>
              {t('projectContextMenuWrapper.publish')}
            </MenuItem>
          )} */}

      {/* 2021-05-21 Disabled for now */}
      {/* <MenuItem
        data-e2e-id="project-contextmenu-duplicate"
        onClick={onDuplicateProject}
        disabled={isEveryone || !userHasWriteAccess}>
        <ListItemIcon>
          <FileCopyIcon />
        </ListItemIcon>
        {t('projectsList.contextMenu.duplicate')}
      </MenuItem> */}

      <MenuItem data-e2e-id="project-contextmenu-download" onClick={onDownloadProject}>
        <ListItemIcon>
          <GetAppIcon />
        </ListItemIcon>
        {t('projectsList.contextMenu.download')}
      </MenuItem>
      <MenuItem
        data-e2e-id="context-menu-delete-project"
        onClick={
          !severalUsersHaveAccess
            ? openDeleteProjectDialog({ projectId: project.id })
            : userIsOwner
            ? openDeleteProjectDialog({ projectId: project.id })
            : userHasWriteAccess
            ? openDeleteProjectDialog({ projectId: project.id })
            : openUnshareProjectDialog({ projectId: project.id, email: userEmail })
        }>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        {!severalUsersHaveAccess
          ? t('projectContextMenuWrapper.delete')
          : userIsOwner
          ? t('projectContextMenuWrapper.deleteForEveryone')
          : t('projectContextMenuWrapper.removeFromMyWorkspaces')}
      </MenuItem>
    </Menu>
  );
  return (
    <div
      {...bindContextMenu(contextMenuState)}
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setContextState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        bindContextMenu(contextMenuState).onContextMenu(event);
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!openOnLeftClick) return;

        event.stopPropagation();
        event.preventDefault();
        setContextState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        contextMenuState.open();
      }}>
      {children}
      {menu}
    </div>
  );
}
