import Codefy from '../../../../codefy';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';

export const taglistsExportPrettyExcel = async (params: {
  taglist_id: Codefy.Objects.Taglist['id'];
  directory_id?: Codefy.Objects.Directory['id'];
  document_id?: Codefy.Objects.Document['id'];
}) => {
  const a = document.createElement('a');
  a.href =
    '/api/v1/taglists/export_taglists_as_pretty_excel' + '?' + fastApiParamsSerializer(params);
  a.click();
};
