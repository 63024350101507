import axios, { AxiosResponse } from 'axios';
import i18n, { i18n_3_to_2_converter } from '../../../../i18n/i18n';

import Codefy from '../../../../codefy';
import { ThunkDispatch } from 'redux-thunk';

/** This would normally be a subscription since it's a GET request, but the user
 * currently cannot change, so we leave it here for now. */

export const userGet = () => {
  return async (
    dispatch: ThunkDispatch<
      Codefy.State,
      undefined,
      Codefy.API.Users.Get.Action | Codefy.API.Users.LoadingCompleted.Action
    >,
  ) => {
    try {
      const config: Codefy.API.Users.Get.RequestConfig = {
        url: '/api/v1/users/get',
        method: 'GET',
      };

      type Response = Codefy.API.Users.Get.Response;
      const response: AxiosResponse<Response> = await axios(config);

      i18n.changeLanguage(i18n_3_to_2_converter(response.data.language));

      if (!response.data.email) {
        window.location.href = '/login';
      }

      dispatch({
        type: 'UserGet',
        user: response.data,
      });

      dispatch({
        type: 'UserLoadingCompleted',
      });
    } catch (error) {
      /* No error message here since we are testing if we are authorized */
      dispatch({
        type: 'UserLoadingCompleted',
      });
    }
  };
};
