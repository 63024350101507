import {
  useUserSetting_tag_descending,
  useUserSetting_tag_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import Brightness1Icon from '@material-ui/icons/Brightness1';
import { COLORS } from '../../../../globalThemeSettings';
import Codefy from '../../../../codefy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SortIcon from '@material-ui/icons/Sort';
import StorageIcon from '@material-ui/icons/Storage';
import TagLabelsEditor from '../../../tagLabels/tagLabelsEditor';
import { TaglistVariants } from '../paneTypes';
import { tagsColor } from '../../../../controllers/api/actions/taglists/tags/tagsColor';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenDeleteTagDialog } from '../../../dialogs/delete/deleteTagDialog';
import { useOpenEditTagDialog } from '../../../dialogs/edit/editTagDialog';
import { usePaneActions } from '../../usePaneActions';
import { useProjectsGet } from '../../../../controllers/api/subscriptions/projects/projectsGet';
import useTagColorsMenuEntries from './useTagColorsMenuEntries';
import { useTranslation } from 'react-i18next';

export default function useTagMenuEntries({
  project_id,
  tag,
  full,
  variant,
}: {
  project_id?: Codefy.Objects.Project['id'];
  tag?: Codefy.Objects.Tag;
  /** Should all options be shown or just a minimal subset? */
  full?: boolean;
  variant: TaglistVariants;
}) {
  const { t } = useTranslation();
  //const modalActions = useModalActions();
  const paneActions = usePaneActions();
  const isEveryone = useIsEveryone();

  const { id: currentCaseId } = useCurrentCase();

  const { data: project } = useProjectsGet(project_id);
  //const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId();
  const [tag_descending, set_tag_descending] = useUserSetting_tag_descending();
  const [tag_orderBy, set_tag_orderBy] = useUserSetting_tag_orderBy();

  //const openEditTagCellDialog = useOpenEditTagCellDialog();
  const openEditTagDialog = useOpenEditTagDialog();
  const openDeleteTagDialog = useOpenDeleteTagDialog();

  const tagColorMenuEntries = useTagColorsMenuEntries({
    currentColor: tag?.color,
    onSelectColor: (color) => {
      if (tag?.id) {
        tagsColor({ tag_id: tag.id, color });
      }
    },
  });

  if (!tag || !project?.id) return [];

  // const onTagExportTextTemplates = () => {
  //   if (!tag.id || !tag.taglist_id) return;
  //   modalActions.openModal({
  //     modal: ModalKeys.editExportTextTemplates,
  //     params: {
  //       editExportTextTemplates_tagId: tag.id,
  //     },
  //   });
  // };

  // const onExportMarkdownDocument = () => {
  //   if (!pdfViewer_documentId) return;
  //   taglistsExportMarkdown({
  //     taglist_id: tag.taglist_id,
  //     tag_id: tag.id,
  //     document_id: pdfViewer_documentId,
  //   });
  // };

  // const onExportMarkdownAll = () => {
  //   taglistsExportMarkdown({ taglist_id: tag.taglist_id, tag_id: tag.id });
  // };

  const onViewTrainingData = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.entriesList,
      params: { entriesList_directoryId: project?.directory_id },
    });
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.annotationTag,
      params: {
        annotationTag_tagId: tag.id,
      },
    });
  };

  if (variant === TaglistVariants.ASSISTANT) {
    return [
      {
        key: 'viewTrainingData',
        text: t('useTagMenuEntries.viewTrainingData'),
        icon: <StorageIcon />,
        onClick: onViewTrainingData,
        disabled: tag.taglist_type !== 'annotation',
      },
    ];
  }

  return [
    {
      key: 'tagLabelsEditor',
      text: (
        <TagLabelsEditor
          taglistType="entry"
          project_id={project?.id}
          annotation_tag={tag}
          source="tagList"
        />
      ),
      disabled: tag.taglist_type === 'entry' || !currentCaseId || !full,
    },
    {
      key: 'tagLabelsEditorDivider',
      divider: true,
      disabled: tag.taglist_type === 'entry' || !currentCaseId || !full,
    },
    {
      key: 'edit',
      text: t('useTagMenuEntries.edit'),
      icon: <EditIcon />,
      onClick: openEditTagDialog({ tagId: tag.id }),
    },
    {
      key: 'color',
      text: t('useTagMenuEntries.changeColor'),
      icon: <Brightness1Icon style={{ color: tag.color || COLORS.defaultTagColor }} />,
      menuEntries: tagColorMenuEntries,
    },
    // {
    //   key: 'cell',
    //   text: tag.cell
    //     ? t('useTagMenuEntries.changeLinkedExcelCell')
    //     : t('useTagMenuEntries.linkToExcelCell'),
    //   icon: <GridOnIcon />,
    //   onClick: openEditTagCellDialog({ tagId: tag.id }),
    //   disabled: !full || isEveryone || !taglist.template_name || taglist.type !== 'annotation',
    // },
    // {
    //   key: 'exportTextTemplates',
    //   text: tag.export_text_templates
    //     ? t('useTagMenuEntries.editExistingExportTextTemplates')
    //     : t('useTagMenuEntries.addExportTextTemplates'),
    //   icon: <PlaylistAddCheckIcon />,
    //   onClick: onTagExportTextTemplates,
    //   disabled: !full || isEveryone || !taglist.template_name || taglist.type !== 'annotation',
    // },
    {
      key: 'sort',
      text: t('useTagMenuEntries.sortBy'),
      icon: <SortIcon />,
      disabled: full,
      menuEntries: [
        {
          key: 'sortName',
          text: t('useTagMenuEntries.name'),
          icon: <SortByAlphaIcon />,
          selected: tag_orderBy === 'name',
          menuEntries: [
            {
              key: 'sortNameAscending',
              text: t('useTagMenuEntries.AZName'),
              selected: !tag_descending && tag_orderBy === 'name',
              onClick: async () => {
                await set_tag_descending(false);
                await set_tag_orderBy('name');
              },
            },
            {
              key: 'sortNameDescending',
              text: t('useTagMenuEntries.ZAName'),
              selected: tag_descending && tag_orderBy === 'name',
              onClick: async () => {
                await set_tag_descending(true);
                await set_tag_orderBy('name');
              },
            },
          ],
        },
        {
          key: 'sortCreationDate',
          text: t('useTagMenuEntries.creationDate'),
          icon: <ScheduleIcon />,
          selected: tag_orderBy === 'created_at',
          menuEntries: [
            {
              key: 'sortNameAscending',
              text: t('useTagMenuEntries.oldestFirst'),
              selected: !tag_descending && tag_orderBy === 'created_at',
              onClick: async () => {
                await set_tag_descending(false);
                await set_tag_orderBy('created_at');
              },
            },
            {
              key: 'sortNameDescending',
              text: t('useTagMenuEntries.newestFirst'),
              selected: tag_descending && tag_orderBy === 'created_at',
              onClick: async () => {
                await set_tag_descending(true);
                await set_tag_orderBy('created_at');
              },
            },
          ],
        },
      ],
    },
    // {
    //   key: 'export',
    //   text: 'Export',
    //   icon: <PublishIcon />,
    //   disabled: !full || taglist.type !== 'annotation' || tag.num_tag_instances === 0,
    //   menuEntries: [
    //     {
    //       key: 'exportMarkdown',
    //       text: t('useTagMenuEntries.exportAsMarkdownFile'),
    //       icon: (
    //         <FaMarkdown
    //           fontSize="small"
    //           style={{ marginLeft: '4px', marginBottom: '-3px' }}
    //           size={18}
    //         />
    //       ),
    //       menuEntries: [
    //         {
    //           key: 'exportDocument',
    //           text: t('useTagMenuEntries.currentDocument'),
    //           onClick: onExportMarkdownDocument,
    //           disabled: !pdfViewer_documentId,
    //         },
    //         {
    //           key: 'exportDirectoryAsOne',
    //           text: t('useTagMenuEntries.all'),
    //           onClick: onExportMarkdownAll,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      key: 'delete',
      text: t('useTagMenuEntries.delete'),
      icon: <DeleteIcon />,
      disabled: !full || isEveryone,
      onClick: openDeleteTagDialog({ tagId: tag.id }),
    },
  ];
}
