import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import Codefy from '../../../codefy';
import EntryFileTypeIcon from '../../panes/paneTypes/entriesList/entryFileTypeIcon';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GenericDeleteDialog from '../genericDeleteDialog';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import { entriesDelete } from '../../../controllers/api/actions/entries/entriesDelete';
import { useBetween } from 'use-between';
import { useEntriesGet } from '../../../controllers/api/subscriptions/entries/entriesGet';
import { usePaneActions } from '../../panes/usePaneActions';
import { useQueryParam_pdfViewer_documentId } from '../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

type DialogDeleteEntryStateType = { entryId?: Codefy.Objects.Entry['id'] };

const useDialogDeleteEntryState = () => useState<DialogDeleteEntryStateType>();

const useSharedDialogDeleteEntryState = () => useBetween(useDialogDeleteEntryState);

export const DeleteEntryDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogDeleteEntryState();

  const { data: entry } = useEntriesGet(state?.entryId);

  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(1);
  const [pdfViewer2_documentId] = useQueryParam_pdfViewer_documentId(2);

  const paneActions = usePaneActions();

  const onClose = () => setState(undefined);

  const onDelete = () => {
    if (!state?.entryId) return;
    entriesDelete({ entry_ids: [state.entryId] });
    if (entry?.document && pdfViewer_documentId === entry.document.id) {
      paneActions.closePane(PaneKeys.pdfViewer);
    }
    if (entry?.document && pdfViewer2_documentId === entry.document.id) {
      paneActions.closePane(PaneKeys.pdfViewer2);
    }
  };

  if (!entry?.name) return null;

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted deletions. */
  if (!state) return null;

  const icon = (() => {
    switch (entry.mimetype) {
      case 'inode/directory':
        return <FolderOpenIcon />;
      case 'inode/case':
        return <EntryFileTypeIcon entryMimetype={entry.mimetype} size={22} />;
      default:
        return (
          <Box ml={'1px'}>
            <EntryFileTypeIcon entryMimetype={entry.mimetype} size={22} />
          </Box>
        );
    }
  })();

  return (
    <GenericDeleteDialog
      open={!!state}
      icon={icon}
      title={t('deleteDialog.deleteEntry')}
      content={<span>{entry.name}</span>}
      onDelete={onDelete}
      onClose={onClose}
    />
  );
};

export const useOpenDeleteEntryDialog = () => {
  const [, setState] = useSharedDialogDeleteEntryState();
  return (state: DialogDeleteEntryStateType) => () => setState(state);
};
