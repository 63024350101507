import { useDispatch, useSelector } from 'react-redux';

import Codefy from '../../../../codefy';
import { userChangeSettings } from '../../actions/user/userChangeSettings';

/** Helper function that gets the user setting from the redux store and sends changes to the backend */
const useUserSetting = <T>(setting: string, defaultValue?: T) => {
  const dispatch = useDispatch();

  const value = useSelector(
    (state: Codefy.State) => state.user?.settings?.[setting] ?? defaultValue,
  ) as T | undefined;

  const setValue = (newValue: T | undefined) =>
    dispatch(userChangeSettings({ [setting]: newValue }));

  return [value, setValue] as const;
};

export const useUserSetting_taglist_descending = () => useUserSetting('taglist_descending', false);

export const useUserSetting_taglist_orderBy = () => useUserSetting('taglist_orderBy', 'name');

export const useUserSetting_taglist_showPredictions = () =>
  useUserSetting('taglist_showPredictions', true);

export const useUserSetting_tag_descending = () => useUserSetting('tag_descending', true);

export const useUserSetting_tag_orderBy = () => useUserSetting('tag_orderBy', 'created_at');

export const useUserSetting_entries_descending = () => useUserSetting('entries_descending', false);

export const useUserSetting_entries_filterByMimetype = () =>
  useUserSetting<Codefy.Objects.EntryMimeType[]>('entries_filterByMimetype');

export const useUserSetting_entries_orderBy = () => useUserSetting('entries_orderBy', 'created_at');

export const useUserSetting_projects_descending = () => useUserSetting('projects_descending', true);

export const useUserSetting_projects_filterByOwner = () =>
  useUserSetting<string>('projects_filterByOwner');

export const useUserSetting_projects_orderBy = () =>
  useUserSetting('projects_orderBy', 'created_at');

export const useUserSetting_tutorials_closed = () => useUserSetting('tutorials_closed', false);

export const DEFAULT_PDF_SCALE = 0.9;

export const useUserSetting_pdf_scale = () => useUserSetting('pdf_scale', DEFAULT_PDF_SCALE);
