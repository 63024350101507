import {
  PdfPageIndexContext,
  PdfPagePageHeightContext,
  PdfPagePageWidthContext,
} from '../../pdfViewer';
import React, { useContext } from 'react';

import { COLORS } from '../../../../../../globalThemeSettings';
import Codefy from '../../../../../../codefy';
import { Fade } from '@material-ui/core';
import stringify from 'fast-stringify';

export function AnnotationRectangle({ annotation }: { annotation: Codefy.Objects.Annotation }) {
  const pageIndex = useContext(PdfPageIndexContext);
  const pageWidth = useContext(PdfPagePageWidthContext);
  const pageHeight = useContext(PdfPagePageHeightContext);
  const boxes = annotation.boxesRenderRectangle?.filter((box) => box.page === pageIndex + 1);

  return (
    <>
      {boxes?.map((box) => {
        const style: React.CSSProperties = {
          position: 'absolute',
          top: box.ymin * pageHeight,
          left: box.xmin * pageWidth,
          width: (box.xmax - box.xmin) * pageWidth,
          height: (box.ymax - box.ymin) * pageHeight,
          mixBlendMode: 'darken',
          zIndex: 400,
        };
        const color = annotation.tag_instances?.[0]?.tag_color || COLORS.defaultTagColor;
        if (annotation.area) {
          style.border = `3px solid ${color}`;
        } else {
          style.backgroundColor = color;
        }
        return (
          <Fade in key={annotation.id + '-' + stringify(box)}>
            <div style={style} />
          </Fade>
        );
      })}
    </>
  );
}
