import { Box, Button, FilledInputProps, Grid, TextField, Typography } from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../globalThemeSettings';
import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Autocomplete } from '@material-ui/lab';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Codefy from '../../codefy';
import GenericContextMenu from '../menus/genericContextMenu';
import { GenericDialog } from './genericDialog';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import SortIcon from '@material-ui/icons/Sort';
import { TagsCreateRequestType } from '../../controllers/api/actions/taglists/tags/tagsCreate';
import useTagColorsMenuEntries from '../panes/paneTypes/tag/useTagColorsMenuEntries';
import { useTranslation } from 'react-i18next';

export type GenericTagDialogOnDone = (props: {
  name: TagsCreateRequestType['name'];
  color?: TagsCreateRequestType['color'];
  description?: TagsCreateRequestType['description'];
  parent_tag_id?: TagsCreateRequestType['parent_tag_id'];
}) => void;

export const GenericTagDialog = ({
  taglistType,
  currentTag,
  onDone,
  onClose,
  possibleParents,
}: {
  taglistType: Codefy.Objects.Taglist['type'];
  currentTag?: Codefy.Objects.Tag;
  onDone: GenericTagDialogOnDone;
  onClose: () => void;
  possibleParents: Codefy.Objects.Tag[];
}) => {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const [name, setName] = useState(currentTag?.name);
  const [parentTagId, setParentTagId] = useState(currentTag?.parent);
  const [description, setDescription] = useState(currentTag?.description);

  const [color, setColor] = useState(currentTag?.color);
  const tagColorsMenuEntries = useTagColorsMenuEntries({
    currentColor: color,
    onSelectColor: setColor,
  });

  const onSubmit = () => {
    if (!name) return;
    onDone({ name, parent_tag_id: parentTagId, description, color });
    onClose();
  };

  const onChangeName: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) =>
    setName(event.target.value);

  const onChangeDescription: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) =>
    setDescription(event.target.value);

  const onKeyDownName: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const NameTextFieldInputProps: Partial<FilledInputProps> = {
    startAdornment: (
      <Box mr={1} mb={-0.5}>
        {taglistType === 'annotation' ? <LabelOutlinedIcon /> : <LabelIcon />}
      </Box>
    ),
    endAdornment: (
      <Box ml={1}>
        <Button
          onClick={onSubmit}
          disabled={!name}
          color="primary"
          className={globalClasses.dialogButton}>
          {currentTag ? t('tagDialog.save') : t('tagDialog.create')}
        </Button>
      </Box>
    ),
    disableUnderline: true,
  };

  return (
    <GenericDialog
      title={currentTag ? t('tagDialog.editTag') : t('tagDialog.createNewTag')}
      onClose={onClose}
      open>
      <TextField
        placeholder={t('tagDialog.enterName')}
        value={name}
        onChange={onChangeName}
        onKeyDown={onKeyDownName}
        fullWidth
        autoFocus
        InputProps={NameTextFieldInputProps}
      />
      <Box>
        <Grid container alignItems="center" alignContent="center">
          <Box mr={1}>
            <SortIcon />
          </Box>
          <Typography className={globalClasses.textPrimary}>
            <Box mb={0.65}>{t('tagDialog.arrangeTagUnder')}</Box>
          </Typography>
        </Grid>
        <Box mt={1} ml={4}>
          <Autocomplete<Codefy.Objects.Tag['id']>
            fullWidth
            autoSelect
            blurOnSelect
            options={[0, ...possibleParents.map((t) => t.id)]}
            onChange={(event, newValue) => {
              setParentTagId(newValue || undefined);
            }}
            renderOption={(option) => {
              if (option === 0) return <i>{t('tagDialog.defaultLevel')}</i>;
              return possibleParents.find((parent) => parent.id === option)?.name || '';
            }}
            getOptionLabel={(option) => {
              return possibleParents.find((parent) => parent.id === option)?.name || '';
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                variant="standard"
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                style={{ maxHeight: '35px', backgroundColor: 'none' }}
                placeholder={t('tagDialog.defaultLevel')}
              />
            )}
            style={{ maxHeight: '35px', backgroundColor: 'none' }}
            value={parentTagId || 0}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <GenericContextMenu openOnLeftClick menuEntries={tagColorsMenuEntries || []}>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            style={{ cursor: 'pointer' }}
            wrap="nowrap">
            <Box mr={1}>
              <Brightness1Icon style={{ color: color || COLORS.defaultTagColor }} />
            </Box>
            <Typography className={globalClasses.textPrimary} style={{ width: '100%' }}>
              <Box mb={0.65}>
                {t('tagDialog.changeColor')}
                <span style={{ float: 'right' }}>
                  <ArrowRightIcon />
                </span>
              </Box>
            </Typography>
          </Grid>
        </GenericContextMenu>
      </Box>
      <Box>
        <Grid container alignItems="flex-start" alignContent="center">
          <Box mr={1} mb={0.5}>
            <InsertCommentIcon />
          </Box>
          <Typography className={globalClasses.textPrimary}>
            <Box mb={0.65}>{t('tagDialog.addNote')}</Box>
          </Typography>
        </Grid>
        <Box ml={4}>
          <TextField
            fullWidth
            multiline
            rows={7}
            placeholder={t('tagDialog.enterText')}
            variant="standard"
            InputProps={{ disableUnderline: true }}
            onChange={onChangeDescription}
            value={description || ''}
          />
        </Box>
      </Box>
    </GenericDialog>
  );
};
