import { Typography } from '@material-ui/core';
import React from 'react';
import { componentTypeStyles } from '../../globalThemeSettings';
import { GenericNestableMenuEntry } from './genericNestableMenuEntry';

/** Helper function that allows us to quickly create group titles to insert into menus */
export default function createGenericNestableMenuGroupTitle({
  groupTitle,
  topDivider,
  disabled,
}: {
  groupTitle: string;
  topDivider?: boolean;
  disabled?: boolean;
}): GenericNestableMenuEntry[] {
  if (disabled) return [];

  const entries = [];

  if (topDivider) {
    entries.push({ divider: true, key: groupTitle + '-groupTitleDivider' });
  }

  entries.push({
    key: groupTitle + '-groupTitle',
    text: (
      <Typography
        style={{
          ...componentTypeStyles.subheading,
          ...componentTypeStyles.unselectable,
        }}>
        {groupTitle}
      </Typography>
    ),
  });

  return entries;
}
