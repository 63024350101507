import * as rrweb from 'rrweb';

import { eventWithTime } from 'rrweb/typings/types';
import { sessionsStoreEventsChunk } from '../controllers/api/actions/sessions/sessionsStoreEventsChunk';
import { useEffect } from 'react';
import useFeatureFlag from './useFeatureFlag';
import { useInterval } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

export let session_buffer: eventWithTime[] = [];

/** A UUID of the current session to be able to group together event chunks. Note that this session
 * key should not be used anywhere else, e.g. not as the upload_batch UUID for uploading documents,
 * because otherwise it would be possible to unmask which user was associated with this recording
 * session by comparing with the upload_batch UUIDs, which are attached to a document and thus a
 * user. */
export const SESSION_KEY: string = uuidv4();
export const STORE_CHUNK_AFTER_SECONDS = 10;

/** Privacy-preserving session recorder */
export default function useSessionRecorder() {
  const sessionsEnabled = useFeatureFlag('sessions');

  const initializeRecorder = () => {
    /* Don't record if feature flag disabled */
    if (!sessionsEnabled) return;

    const stop = rrweb.record({
      sampling: {
        scroll: 200,
      },
      emit(event) {
        session_buffer.push(event);
      },
    });
    return stop;
  };

  const storeChunk = () => {
    /* If nothing has happened */
    if (session_buffer.length === 0) return;

    const events_chunk = session_buffer;
    session_buffer = [];

    sessionsStoreEventsChunk({
      session_key: SESSION_KEY,
      events_chunk,
    });
  };

  useEffect(initializeRecorder, []);
  useInterval(storeChunk, STORE_CHUNK_AFTER_SECONDS * 1000);
}
