import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';
import { useQuery } from 'react-query';

export type AnnotationsGetParams = { annotation_id?: Codefy.Objects.Annotation['id'] };

export type AnnotationsGetResponse = Codefy.Objects.Annotation;

export const ANNOTATIONS_GET_KEY_TYPE = 'annotationsGet';

export const annotationsGetKey = (params: AnnotationsGetParams): [string, Record<string, any>] => [
  ANNOTATIONS_GET_KEY_TYPE,
  params,
];

export const useAnnotationsGet = (params: AnnotationsGetParams) =>
  useQuery(annotationsGetKey(params), async () => {
    if (!params.annotation_id) return undefined;
    const { data } = await axios.get('/api/v1/annotations/get', {
      params,
    });
    return data as AnnotationsGetResponse;
  });
