import { GenericTagDialog, GenericTagDialogOnDone } from '../genericTagDialog';
import React, { useState } from 'react';

import Codefy from '../../../codefy';
import getTagsHierarchy from '../../panes/paneTypes/tag/getTagsHierarchy';
import { tagsColor } from '../../../controllers/api/actions/taglists/tags/tagsColor';
import { tagsDescription } from '../../../controllers/api/actions/taglists/tags/tagsDescription';
import { tagsParent } from '../../../controllers/api/actions/taglists/tags/tagsParent';
import { tagsRename } from '../../../controllers/api/actions/taglists/tags/tagsRename';
import { useBetween } from 'use-between';
import { useTaglistsGet } from '../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../controllers/api/subscriptions/taglists/tags/tagsGet';

type DialogEditTagStateType = {
  tagId?: Codefy.Objects.Tag['id'];
};

const useDialogEditTagState = () => useState<DialogEditTagStateType>();

const useSharedDialogEditTagState = () => useBetween(useDialogEditTagState);

const getTagDescendantIds = (tag: Codefy.Objects.Tag): Codefy.Objects.Tag['id'][] => {
  const descendantIds: Codefy.Objects.Tag['id'][] = [];

  const processChildTag = (childTag: Codefy.Objects.Tag) => {
    descendantIds.push(childTag.id);
    childTag.childTags?.forEach(processChildTag);
  };

  tag.childTags?.forEach(processChildTag);

  return descendantIds;
};

const findTagInHierarchy = (
  tagHierarchy: Codefy.Objects.Tag[],
  tagId: Codefy.Objects.Tag['id'],
): Codefy.Objects.Tag | null => {
  const processTag = (tag: Codefy.Objects.Tag): Codefy.Objects.Tag | null => {
    if (tag.id === tagId) return tag;
    const tags = tag.childTags?.map(processTag);
    return tags?.find((t) => !!t) || null;
  };

  for (const tag of tagHierarchy) {
    const result = processTag(tag);
    if (result) return result;
  }

  return null;
};

export const EditTagDialog = () => {
  const [state, setState] = useSharedDialogEditTagState();

  const { data: tag, isFetching } = useTagsGet(state?.tagId);
  const { data: taglist } = useTaglistsGet({ taglist_id: tag?.taglist_id });

  const onClose = () => setState(undefined);

  const onEdit: GenericTagDialogOnDone = ({ color, description, name, parent_tag_id }) => {
    const tag_id = state?.tagId;
    if (!tag_id) return;
    if (color !== tag?.color) tagsColor({ tag_id, color });
    if (description !== tag?.description) tagsDescription({ tag_id, description });
    if (name !== tag?.name) tagsRename({ tag_id, name });
    if (parent_tag_id !== tag?.parent) tagsParent({ tag_id, parent_tag_id });
  };

  if (!taglist || !tag) return null;

  /* Make sure to break the cache of the GenericTagDialog when the user edits the same tag again */
  if (isFetching) return null;

  // TODO: Not too readable... is there a simpler way?
  const tagHierarchy = getTagsHierarchy(taglist.tags);
  const currentTagInHierarchy = findTagInHierarchy(tagHierarchy, tag.id);
  let possibleParents: Codefy.Objects.Tag[] = [];
  if (currentTagInHierarchy) {
    const tagDescendantIds = getTagDescendantIds(currentTagInHierarchy);
    possibleParents = taglist.tags.filter(
      (possibleParent) =>
        !tagDescendantIds.includes(possibleParent.id) && tag.id !== possibleParent.id,
    );
  }

  return (
    <GenericTagDialog
      taglistType={taglist.type}
      onDone={onEdit}
      onClose={onClose}
      possibleParents={possibleParents}
      currentTag={tag}
    />
  );
};

export const useOpenEditTagDialog = () => {
  const [, setState] = useSharedDialogEditTagState();
  return (state: DialogEditTagStateType) => () => setState(state);
};
