import {
  DocumentIdContext,
  PdfPageIndexContext,
  PdfPagePageHeightContext,
  PdfPagePageWidthContext,
} from '../pdfViewer';
import React, { useContext } from 'react';

import SelectableWord from './selectableWord';
import { SelectionContext } from './selectionArea';
import { useLayoutsGet } from '../../../../../controllers/api/subscriptions/parts/layoutsGet';

export function PdfPageSelectionLayer() {
  const documentId = useContext(DocumentIdContext);
  const pageIndex = useContext(PdfPageIndexContext);
  const pageWidth = useContext(PdfPagePageWidthContext);
  const pageHeight = useContext(PdfPagePageHeightContext);
  const selectionAreaId = useContext(SelectionContext);
  const { data: layouts } = useLayoutsGet({ document_id: documentId, page: pageIndex + 1 });

  const pad = (input: number | string): string => input.toString().padStart(6, '0');

  /* Note that the layouts must arrive from the backend in a sorted fashion, otherwise the selection
    will be broken sometimes, making it difficult to debug. Sorting should be done by ymin first,
    then by xmin. */

  return (
    <>
      {layouts?.map((layout, ix) => {
        const key = `${pad(selectionAreaId)}-${pad(documentId)}-${pad(pageIndex + 1)}-${pad(ix)}`;
        return (
          <SelectableWord
            key={key}
            box={layout}
            index={key}
            text={layout.text}
            top={layout.ymin * pageHeight}
            left={layout.xmin * pageWidth}
            width={(layout.xmax - layout.xmin) * pageWidth}
            height={(layout.ymax - layout.ymin) * pageHeight}
          />
        );
      })}
    </>
  );
}
