import Codefy from '../../../../codefy';
import { axios } from '@use-hooks/axios';
import { useQuery } from 'react-query';

export const DOCUMENTS_GET_KEY_TYPE = 'documentsGet';

export const documentsGetKey = (
  id?: Codefy.Objects.Document['id'],
): [string, Codefy.Objects.Document['id'] | undefined] => [DOCUMENTS_GET_KEY_TYPE, id];

export const useDocumentsGet = (document_id?: Codefy.Objects.Document['id'] | string) => {
  if (typeof document_id === 'string') {
    document_id = parseInt(document_id);
  }
  return useQuery(
    documentsGetKey(document_id),
    async (key: string, document_id?: Codefy.Objects.Document['id']) => {
      if (!document_id) return undefined;
      const { data } = await axios.get('/api/v1/documents/get', { params: { document_id } });
      const document = data as Codefy.Objects.Document;

      return document as Codefy.Objects.Document;
    },
  );
};
