import LanguageDetector from 'i18next-browser-languagedetector';
import deu from './translations/deu.json';
import eng from './translations/eng.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//@ts-ignore
import markdownJsx from 'i18next-markdown-jsx-plugin';

const FALLBACK_LANGUAGE = 'eng';

/** Converts a 2-letter country code into a three letter country code for supported
 * languages, otherwise defaults to the fallback language */
export const i18n_2_to_3_converter = (lang: string) => {
  if (lang.length === 3) {
    if (['eng', 'deu'].includes(lang)) {
      return lang;
    } else {
      return FALLBACK_LANGUAGE;
    }
  }

  switch (lang.slice(0, 2)) {
    case 'en':
      return 'eng';
    case 'de':
      return 'deu';
    default:
      return FALLBACK_LANGUAGE;
  }
};

export const i18n_3_to_2_converter = (lng: string) => {
  return lng.substr(0, 2);
};

const resources = {
  en: eng,
  de: deu,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(markdownJsx)
  .init({
    /* We cannot just postprocess everything automatically with markdown, because some components
need to receive plain text. Therefore, we rather manually indicate in the `t` functions when some
string needs to be postprocessed with markdown */
    resources,
    fallbackLng: i18n_3_to_2_converter(FALLBACK_LANGUAGE),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator'],
    },
  });

export default i18n;
