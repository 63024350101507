import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../../../subscriptions/annotations/annotationsList';

import { ANNOTATIONS_GET_KEY_TYPE } from '../../../../subscriptions/annotations/annotationsGet';
import Codefy from '../../../../../../codefy';
import { ENTRIES_GET_KEY_TYPE } from '../../../../subscriptions/entries/entriesGet';
import { ENTRIES_LIST_KEY_TYPE } from '../../../../subscriptions/entries/entriesList';
import { TAGLISTS_GET_KEY_TYPE } from '../../../../subscriptions/taglists/taglistsGet';
import { TAGS_GET_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_GET_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { executeAction } from '../../../actionHelpers';

export const tagInstancesDelete = (data: { tag_instance_id: Codefy.Objects.TagInstance['id'] }) =>
  executeAction<Codefy.Objects.TagInstance>({
    config: { url: '/api/v1/taglists/tags/instances/delete', method: 'POST' },
    data,
    queryKeysToRefresh: [
      TAGLISTS_GET_KEY_TYPE,
      ENTRIES_LIST_KEY_TYPE,
      ENTRIES_GET_KEY_TYPE,
      TAG_INSTANCES_GET_KEY_TYPE,
      TAG_INSTANCES_LIST_KEY_TYPE,
      TAGS_LIST_KEY_TYPE,
      TAGS_GET_KEY_TYPE,
      ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
      ANNOTATIONS_LIST_KEY_TYPE,
      ANNOTATIONS_GET_KEY_TYPE,
    ],
  });
