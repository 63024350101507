import {
  AnnotationsListRequestParams,
  useAnnotationsListInfinite,
} from '../../../../controllers/api/subscriptions/annotations/annotationsList';
import { ListRange, Virtuoso } from 'react-virtuoso';
import React, { useMemo } from 'react';
import {
  useQueryParam_annotationsList_descending,
  useQueryParam_annotationsList_excludeWithTagInstances,
  useQueryParam_annotationsList_orderBy,
  useQueryParam_annotationsList_selectedText,
} from '../../../../controllers/useGlobalQueryParams';

import Annotation from '../annotation/annotation';
import Codefy from '../../../../codefy';

export const DEFAULT_SORTED_BY = 'created_at';
export const DEFAULT_DESCENDING = true;

// TODO: Add ability to act on multiple items

export default function AnnotationsListContent() {
  const [annotationsList_selectedText] = useQueryParam_annotationsList_selectedText();
  const [annotationsList_orderBy] = useQueryParam_annotationsList_orderBy();
  const [annotationsList_descending] = useQueryParam_annotationsList_descending();
  const [
    annotationsList_excludeWithTagInstances,
  ] = useQueryParam_annotationsList_excludeWithTagInstances();

  const { data: annotationsPages, canFetchMore, fetchMore } = useAnnotationsListInfinite({
    //directory_ids: currentCaseId ? [currentCaseId] : undefined,
    selected_text: annotationsList_selectedText,
    order_by:
      (annotationsList_orderBy as AnnotationsListRequestParams['order_by']) ?? DEFAULT_SORTED_BY,
    descending: annotationsList_descending ?? DEFAULT_DESCENDING,
  });

  const annotationsList =
    annotationsPages?.reduce((acc: Codefy.Objects.Annotation[], cur) => {
      for (const annotation of cur.annotations) {
        if (
          annotationsList_excludeWithTagInstances &&
          annotation.tag_instances &&
          annotation.tag_instances.length > 0
        ) {
          continue;
        } else {
          acc.push(annotation);
        }
      }
      return acc;
    }, []) || [];

  const renderAnnotation = (index: number) => {
    if (!annotationsList || !annotationsList[index]) return <span></span>;
    const groupContinuation = !!(
      annotationsList[index].path.entry_id === annotationsList[index - 1]?.path.entry_id
    );
    return <Annotation annotation={annotationsList[index]} groupContinuation={groupContinuation} />;
  };

  /** Triggered when the user scrolls in the list */
  const onRangeChanged = (range: ListRange) => {
    /** If user didn't scroll far enough yet */
    if (range.endIndex < annotationsList.length - 50) return;

    /** If there is nothing more to load */
    if (!canFetchMore) return;

    fetchMore();
  };

  const style = useMemo(() => ({ width: '100%', height: '100%' }), []);

  if (!annotationsList) return null;

  return (
    <Virtuoso
      style={style}
      overscan={500}
      totalCount={annotationsList.length || 0}
      itemContent={renderAnnotation}
      rangeChanged={onRangeChanged}
    />
  );
}
