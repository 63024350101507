import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TutorialsPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const { t } = useTranslation();

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={<HelpOutlineIcon />}
      title={t('tutorials.tutorials')}
      paneKey={PaneKeys.tutorials}
    />
  );
}
