import { GLOBAL_QUERY_PARAMS } from '../../controllers/useGlobalQueryParams';
import { ModalKeys } from './modalTypes';
import { PaneKeys } from '../panes/paneTypes/paneKeys';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useUpdateUrlParams } from '../../hooks/useUpdateUrlParams';

export default function useModalActions() {
  const history = useHistory();
  const updateUrlParams = useUpdateUrlParams();

  /** Returns the params object necessary to remove all params for a pane. This should ALWAYS be the
   * first parameter when spreading, i.e. {...getParamsToResetPane(pane), ...otherParams}, because
   * otherwise we will overwrite the other params! */
  const getParamsToResetPane = (
    pane: PaneKeys | ModalKeys,
  ): { [key in GLOBAL_QUERY_PARAMS]?: undefined } => {
    const resetParams: { [key: string]: undefined } = {};

    const urlParams = queryString.parse(history.location.search);

    Object.keys(urlParams).forEach((key) => {
      if (key.startsWith(pane + '_')) {
        resetParams[key] = undefined;
      }
    });

    return resetParams;
  };

  const openModal = ({
    modal,
    params,
    reset,
  }: {
    modal: ModalKeys;
    params?: { [key in GLOBAL_QUERY_PARAMS]?: any };
    reset?: boolean;
  }) => {
    if (reset) params = { ...getParamsToResetPane(modal), ...params };
    updateUrlParams({ modal, ...params }, 'replaceIn');
  };

  return { openModal };
}
