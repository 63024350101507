// TODO @jakub add documentation

import { createStyles, makeStyles } from '@material-ui/core';

import { AnnotationComment } from './AnnotationComment';
import Codefy from '../../../../../codefy';
import { CreateAnnotationComment } from './CreateAnnotationComment';
import React from 'react';

type AnnotationCommentsStyleProps = { isExpanded?: boolean; isHovered?: boolean };

export const useStyles = makeStyles(() =>
  createStyles({
    commentsRoot: {
      marginTop: '-10px',
      textAlign: 'left',
      wordBreak: 'break-all',
    },
    commentRoot: {
      paddingTop: '10px',
    },
    commentCreatedAt: {
      opacity: 0.5,
    },
    commentDeleteIcon: {
      float: 'right',
      opacity: 0.5,
    },
    commentDeleteIconInactive: {
      float: 'right',
      opacity: 0,
    },
    createRoot: { paddingTop: '15px' },
    createRootInactive: {
      paddingTop: '15px',
      filter: 'grayscale(100%)',
    },
    createAvatar: {
      textAlign: 'center',
      marginTop: '2px',
      marginLeft: '7px',
    },
    createAvatarInactive: {
      textAlign: 'center',
      marginTop: '2px',
      marginLeft: '7px',
      opacity: 0.5,
    },
    createTextField: {
      background: '#00000000',
    },
    createTextFieldInactive: {
      background: '#00000000',
    },
    /* This is necessary to render text with line breaks, otherwise it's all one looong
       line */
    text: {
      whiteSpace: 'pre-line',
    },
    cancelButton: {
      opacity: 0.7,
    },
    mentionIcon: {
      marginBottom: '-5px',
    },
    mentionEmail: {
      cursor: 'text',
    },
    mentionFormGroup: {
      marginLeft: '15px',
    },
  }),
);

export default function AnnotationComments({
  annotation,
}: {
  annotation: Codefy.Objects.Annotation;
}) {
  const classes = useStyles();

  return (
    <div className={classes.commentsRoot} onClick={(event) => event.stopPropagation()}>
      {annotation.comments.map((annotationComment) => (
        <AnnotationComment annotationComment={annotationComment} key={annotationComment.id} />
      ))}
      <CreateAnnotationComment annotation={annotation} />
    </div>
  );
}
