import {
  DEFAULT_PDF_SCALE,
  useUserSetting_pdf_scale,
} from '../../../../controllers/api/subscriptions/users/userSettings';
import React, { useEffect } from 'react';

import { usePrevious } from 'react-use';

/** When the user changes the scale (zooms in or out), make sure the document stays at the same
 * position it was before. */
export function usePdfScrollSyncAfterScaleChange({
  pdfViewerContainerRef,
}: {
  pdfViewerContainerRef: React.RefObject<HTMLDivElement>;
}) {
  const [userSetting_pdf_scale] = useUserSetting_pdf_scale();
  const scale = userSetting_pdf_scale || DEFAULT_PDF_SCALE;

  /** Necessary because the `useEffect` hook can only access the current value of `scale`. */
  const previousScale = usePrevious(scale);

  useEffect(() => {
    if (!pdfViewerContainerRef.current || !scale || !previousScale) return;
    pdfViewerContainerRef.current.scrollTop *= scale / previousScale;
  }, [scale]);
}
