import { Grid, IconButton, ListItem, ListItemText, Typography } from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import Codefy from '../../../../codefy';
//@ts-ignore
import Delay from 'react-delay';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaneKeys } from '../paneKeys';
import SearchResultContextMenuWrapper from './searchResultContextMenuWrapper';
import SelectionLayer from './selection/selectionLayer';
import { documentsDownload } from '../../../../controllers/api/actions/documents/documentsDownload';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import useHover from '../../../../hooks/useHover';
import { usePaneActions } from '../../usePaneActions';
import { useQueryParam_search_selectedResultPartId } from '../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

export default function SearchResultsItem({
  searchResult,
  isInstasearchResult,
  uniqueId,
  renderAnnotationBoxes,
  source,
}: {
  searchResult: Codefy.Objects.SearchResult;
  isInstasearchResult: boolean;
  uniqueId: string;
  groupContinuation?: boolean;
  renderSelectionLayer?: boolean;
  renderAnnotationBoxes: boolean;
  /** Where is the user accessing this component from? Used for metrics. */
  source: string;
}) {
  const globalClasses = useGlobalStyles();
  const typographyRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search_selectedResultPartId] = useQueryParam_search_selectedResultPartId();

  const [hoverRef, isHovered] = useHover();
  const [mouseHasBeenDown, setMouseHasBeenDown] = useState(false);

  const onMouseDown = () => {
    setMouseHasBeenDown(true);
  };

  const paneActions = usePaneActions();

  const onClick = () => {
    if (isInstasearchResult) {
      dispatch({ type: 'setSearch', open: false });
    }

    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: searchResult.document.id,
        pdfViewer_page: searchResult.highlight.boxes[0].page,
        pdfViewer_boxes: JSON.stringify(searchResult.highlight.boxes),
        search_selectedResultPartId: searchResult.part_id,
      },
    });
  };

  const onDownloadDocument = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (!searchResult.document) return;
    documentsDownload(searchResult.document);
  };

  const renderSelectionBoxes = isHovered || mouseHasBeenDown;
  const panesViewport = document.querySelector('#panesViewport');

  const selectionBoxes = useMemo(() => {
    const selector = '#' + uniqueId + ' .searchResultPart';
    const elements = document.querySelectorAll(selector);
    const boxes: Codefy.Objects.LayoutPart[] = [];
    if (elements.length) {
      elements.forEach((el: any, index) => {
        const { width, height } = el.getBoundingClientRect();
        const orderNumber = parseFloat(`1.${index.toString().padStart(7, '0')}`);
        try {
          const originalData = JSON.parse(el.getAttribute('data-snippet'));
          boxes.push({
            xmin: el.offsetLeft,
            ymin: el.offsetTop,
            xmax: el.offsetLeft + width,
            ymax: el.offsetTop + height,
            orderNumber,
            page: parseInt(el.getAttribute('data-page-number')),
            virtual: false,
            text: el.innerText,
            originalData,
            distance: 0,
            uniqueId,
          });
        } catch {
          // ignore
        }
      });
    }
    return boxes;
    // eslint-disable-next-line
  }, [
    uniqueId,
    /* We need to recalculate the selectionBoxes positions when the user resizes the window,
    otherwise it will be totally misaligned */
    panesViewport?.scrollWidth,
    typographyRef?.current?.scrollHeight,
    renderSelectionBoxes,
  ]);

  return (
    <SearchResultContextMenuWrapper searchResult={searchResult}>
      <Box mt={1} mb={1}>
        <div ref={hoverRef}>
          <ListItem dense button selected={search_selectedResultPartId === searchResult.part_id}>
            <Box width="100%">
              <Grid item md={12} onClick={onClick}>
                <ListItemText
                  primary={
                    <Typography variant="body2" className={globalClasses.heading}>
                      <Box width="100%">
                        {searchResult.document.path?.directory_name ||
                          searchResult.document.path?.project_name}

                        <Grid
                          className={
                            isHovered
                              ? globalClasses.floatRightVisible
                              : globalClasses.floatRightHidden
                          }>
                          <SearchResultContextMenuWrapper
                            searchResult={searchResult}
                            openOnLeftClick>
                            <Grid container zeroMinWidth>
                              <Grid item>
                                <IconButton size="small" onClick={onDownloadDocument}>
                                  <GetAppIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton edge="end" aria-label="delete" size="small">
                                  <MoreVertIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </SearchResultContextMenuWrapper>
                        </Grid>
                      </Box>
                      {searchResult.document.path?.entry_name}{' '}
                      <span className={globalClasses.textLight}>
                        › {t('searchResult.page')} {searchResult.highlight.boxes[0].page}
                      </span>
                    </Typography>
                  }
                />
              </Grid>
            </Box>
          </ListItem>

          <ListItem divider dense id={uniqueId}>
            <Box>
              <Grid>
                <Grid item md={12}>
                  <div
                    style={{ position: 'relative' }}
                    className="textLayer"
                    onMouseDown={onMouseDown}>
                    <Typography
                      variant="body2"
                      align="justify"
                      ref={typographyRef}
                      className={globalClasses.text}
                      dangerouslySetInnerHTML={{
                        __html: searchResult.text_snippet
                          .map(({ html, ...rest }) => {
                            return `<span data-page-number="${
                              rest.page
                            }" data-snippet='${JSON.stringify(
                              rest,
                            )}' class="searchResultPart">${html.replace(
                              /* For parts that contain newlines */ '\n',
                              '<br/><br/>',
                            )}</span>`;
                          })
                          .join(''),
                      }}
                    />
                    {/** Delayed so we don't DDOS the backend with annotations/list requests */}
                    <Delay wait={500}>
                      <SelectionLayer
                        source={source}
                        documentId={searchResult.document.id}
                        boxes={selectionBoxes}
                        uniqueId={uniqueId}
                        wrapperClassName="textLayer"
                        renderSelectionBoxes={renderSelectionBoxes}
                        renderAnnotationBoxes={renderAnnotationBoxes}
                      />
                    </Delay>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </ListItem>
        </div>
      </Box>
    </SearchResultContextMenuWrapper>
  );
}
