import React, { useState } from 'react';

import FolderIcon from '@material-ui/icons/Folder';
import GenericCreateDialog from './../genericCreateDialog';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import { projectsCreate } from '../../../controllers/api/actions/projects/projectsCreate';
import { useBetween } from 'use-between';
import { usePaneActions } from '../../panes/usePaneActions';
import { useTranslation } from 'react-i18next';

type DialogCreateProjectStateType = {};

const useDialogCreateProjectState = () => useState<DialogCreateProjectStateType>();

const useSharedDialogCreateProjectState = () => useBetween(useDialogCreateProjectState);

export const CreateProjectDialog = () => {
  const { t } = useTranslation();
  const paneActions = usePaneActions();

  const [state, setState] = useSharedDialogCreateProjectState();

  const onClose = () => setState(undefined);

  const onCreate = async (name: string) => {
    if (!name) return;

    const project = await projectsCreate({ name });
    if (project && project.id) {
      /** Switch to the newly created project */
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.entriesList,
        params: { entriesList_directoryId: project.directory_id },
        reset: true,
      });
    }
  };

  return (
    <GenericCreateDialog
      open={!!state}
      title={t('createDialog.newWorkspace')}
      icon={<FolderIcon />}
      onClose={onClose}
      onCreate={onCreate}
    />
  );
};

export const useOpenCreateProjectDialog = () => {
  const [, setState] = useSharedDialogCreateProjectState();
  return () => setState({});
};
