import Codefy from '../../../../../../codefy';
import { PdfPagePageHeightContext } from '../../pdfViewer';
import { TAG_LABEL_HEIGHT } from '../../../../../../globalThemeSettings';
import { useContext } from 'react';

/** Calculate where a tagLabel for a given annotation should be placed so that there are no overlaps. */
export default function useAnnotationPatchTagLabelPositions(
  annotations?: Codefy.Objects.Annotation[],
): Codefy.Objects.Annotation[] {
  const pageHeight = useContext(PdfPagePageHeightContext);
  const tagLabelYSize = TAG_LABEL_HEIGHT / pageHeight;

  if (!annotations) return [];

  /* The sorting order is essential for the minimumNextYmin to be calculated correctly! */
  annotations = annotations.sort(
    (a, b) => a.boxes?.[0].ymin - b.boxes?.[0].ymin || a.boxes?.[0].xmin - b.boxes?.[0].xmin,
  );

  /** The highest position (= minimum ymin) a tagLabel could be placed at. The next tagLabel might
   * be placed lower (= higher ymin) than that (this will happen if the next annotation is much
   * lower in the text), but this is the uppermost position. */
  let minimumNextYmin = 0;

  annotations = annotations.map((annotation) => {
    let tagLabelYmin = annotation.boxes[0].ymin;

    while (tagLabelYmin < minimumNextYmin) {
      /* Move it down a bit and try again. We just use a fraction of the height of the tagLabel. */
      tagLabelYmin += tagLabelYSize / 5;
    }

    /* Store in the annotation object */
    annotation.tagLabelYmin = tagLabelYmin;

    /* Calculate the next minimum ymin position, taking into account the height of a tagLabel and
    that an annotation might have several labels */
    minimumNextYmin = tagLabelYmin + tagLabelYSize * (annotation.tag_instances?.length || 1);

    return annotation;
  });

  return annotations;
}
