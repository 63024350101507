import {
  useQueryParam_annotationsList_descending,
  useQueryParam_annotationsList_directoryId,
  useQueryParam_annotationsList_documentId,
  useQueryParam_annotationsList_orderBy,
} from '../../../../controllers/useGlobalQueryParams';

import { DEFAULT_DESCENDING } from './annotationsList';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SortIcon from '@material-ui/icons/Sort';
import StarIcon from '@material-ui/icons/Star';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';

export default function AnnotationsListPaneTitleBar({
  onlyShowPart,
}: {
  onlyShowPart?: 'icon' | 'title';
}) {
  const { t } = useTranslation();
  const [annotationsList_documentId] = useQueryParam_annotationsList_documentId();
  const [annotationsList_directoryId] = useQueryParam_annotationsList_directoryId();
  const [
    annotationsList_orderBy,
    set_annotationsList_orderBy,
  ] = useQueryParam_annotationsList_orderBy();
  const [
    annotationsList_descending,
    set_annotationsList_descending,
  ] = useQueryParam_annotationsList_descending();

  const sortMenuState = usePopupState({ variant: 'popover', popupId: 'sortMenu' });

  const { data: document } = useDocumentsGet(annotationsList_documentId);
  const { data: directory } = useDirectoriesGet(annotationsList_directoryId);

  const orderByHumanReadable = {
    text: t('annotationsListPaneTitleBar.text'),
    created_at: t('annotationsListPaneTitleBar.creationDate'),
  };

  const orderByIcons: any = {
    text: <SortByAlphaIcon />,
    created_at: <ScheduleIcon />,
  } as const;

  const descending = annotationsList_descending ?? DEFAULT_DESCENDING;

  const titleSuffix = document
    ? t('annotationsListPaneTitleBar.inDocument', { name: document.path?.entry_name })
    : directory
    ? t('annotationsListPaneTitleBar.inFolder', {
        name: directory?.path?.entry_name || directory.path?.project_name,
      })
    : '';

  return (
    <PaneTitleBar
      onlyShowPart={onlyShowPart}
      icon={<StarIcon />}
      title={`${t('annotationsListPaneTitleBar.highlights')} ${titleSuffix}`}
      paneKey={PaneKeys.annotationsList}
      menuEntries={[
        {
          key: 'sort',
          text: t('annotationsListPaneTitleBar.sortBy'),
          icon: <SortIcon />,
          menuEntries: Object.entries(orderByHumanReadable).map(([accessor, humanReadable]) => ({
            key: accessor,
            text: humanReadable,
            icon: orderByIcons[accessor],
            selected: annotationsList_orderBy === accessor,
            onClick: () => {
              sortMenuState.close();
              if (annotationsList_orderBy === accessor) {
                set_annotationsList_descending(!descending);
              } else {
                set_annotationsList_orderBy(accessor);
              }
            },
          })),
        },
      ]}
    />
  );
}
