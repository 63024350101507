import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiDataSerializer } from '../../subscriptionHelpers';

export const userChangePassword = (data: Codefy.API.Users.ChangePassword.RequestData) => {
  return async () => {
    const config: Codefy.API.Users.ChangePassword.RequestConfig = {
      url: '/api/v1/users/change_password',
      method: 'POST',
      data: fastApiDataSerializer(data),
    };

    return await axios(config);
  };
};
