import { Box, Divider, IconButton, Popover, Typography } from '@material-ui/core';
import React, { useMemo, useRef } from 'react';
import { bindContextMenu, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';

import CachedIcon from '@material-ui/icons/Cached';
import Codefy from '../../../../codefy';
import CodefyIcon from '../../../appLogo/codefyIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectoryUploadWrapper from './directoryFileUploadWrapper';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import { PaneKeys } from '../paneKeys';
import PdfViewerLeftIcon from '../../../icons/pdfViewerLeft';
import PdfViewerRightAddIcon from '../../../icons/pdfViewerRightAdd';
import PdfViewerRightIcon from '../../../icons/pdfViewerRight';
import TagLabelsEditor from '../../../tagLabels/tagLabelsEditor';
import { directoriesConvert } from '../../../../controllers/api/actions/directories/directoriesConvert';
import { documentsDownload } from '../../../../controllers/api/actions/documents/documentsDownload';
import { entriesDuplicate } from '../../../../controllers/api/actions/entries/entriesDuplicate';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useIsPaneOpen } from '../../../../controllers/useGlobalQueryParams';
import { useOpenDeleteEntryDialog } from '../../../dialogs/delete/deleteEntryDialog';
import { useOpenRenameEntryDialog } from '../../../dialogs/rename/renameEntryDialog';
import { usePaneActions } from '../../usePaneActions';
import { useTranslation } from 'react-i18next';

// TODO: Add translations

/** Whatever is inside this wrapper will open a document context menu (rename, delete, ...) when
 * right-clicked */
export default function EntryContextMenuWrapper({
  entry,
  children,
  openOnLeftClick,
  hideOpenInParentDirectory,
}: {
  entry?: Codefy.Objects.Entry;
  children: React.ReactChild | React.ReactChildren;
  /** Used when we are wrapping a button that is supposed to open the context menu when clicked
   * (with the left mouse button) */
  openOnLeftClick?: boolean;
  hideOpenInParentDirectory?: boolean;
}) {
  const { t } = useTranslation();
  const paneActions = usePaneActions();
  const isEveryone = useIsEveryone();

  const globalClasses = useGlobalStyles();

  const operationsTagsEnabled = useFeatureFlag('operations_tags');

  const contextMenuState = usePopupState({ variant: 'popover', popupId: 'entryContextMenu' });

  const isPdfViewerOpen = useIsPaneOpen(PaneKeys.pdfViewer);
  const isPdfViewer2Open = useIsPaneOpen(PaneKeys.pdfViewer2);

  const ref = useRef();

  const openDeleteEntryDialog = useOpenDeleteEntryDialog();
  const openRenameEntryDialog = useOpenRenameEntryDialog();

  const onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    contextMenuState.close();
  };

  const [contextState, setContextState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  const onOpenAssistantForDirectory = () => {
    if (!entry?.directory) return;

    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.entriesList,
      params: {
        entriesList_directoryId: entry.directory.id,
      },
    });

    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.assistant,
      params: {
        assistant_directoryId: entry.directory.id,
        assistant_documentId: undefined,
        assistant_projectId: entry.directory.path?.project_id,
      },
    });
  };

  const onOpenAssistantForDocument = () => {
    if (!entry?.document) return;

    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.assistant,
      params: {
        assistant_documentId: entry.document.id,
        assistant_directoryId: undefined,
        assistant_projectId: entry.document.path.project_id,
      },
    });
  };

  const onOpenDocumentLeft = () => {
    if (!entry?.document) return;
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: entry.document.id,
        pdfViewer_page: 1,
      },
      reset: true,
    });
  };

  const onOpenDocumentRight = () => {
    if (!entry?.document) return;
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer2,
      params: {
        pdfViewer2_documentId: entry.document.id,
        pdfViewer2_page: 1,
      },
      reset: true,
    });
  };

  /** Directories currently cannot be duplicated */
  const onDuplicateDocument = () => {
    if (!entry?.id || !entry?.document?.path?.directory_id) return;

    entriesDuplicate({ entry_id: entry.id });
  };

  const onDownloadDocument = async () => {
    if (!entry?.document) return;
    documentsDownload(entry.document);
  };

  const onConvert = async () => {
    if (!entry?.directory) return;
    directoriesConvert({ directory_id: entry.directory.id });
  };

  const onOpenParentDirectory = () => {
    if (!entry?.path?.directory_id) return;
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.entriesList,
      params: { entriesList_directoryId: entry.path.directory_id },
    });
  };

  const menuAnchorPosition =
    contextState.mouseY !== null && contextState.mouseX !== null
      ? { top: contextState.mouseY, left: contextState.mouseX }
      : undefined;

  /** Not exactly the same as isEveryone because users can star entries even without write access */
  const userHasWriteAccess = entry?.path?.directory_write; // TODO: Might need to be .entry_write ?

  const divProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > = useMemo(
    () => ({
      ...bindContextMenu(contextMenuState),
      onContextMenu: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (!contextMenuState.isOpen) {
          setContextState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          });
        }

        contextMenuState.open(ref.current);
      },
      onClick: (event: React.MouseEvent<HTMLDivElement>) => {
        if (!openOnLeftClick || contextMenuState.isOpen) return;

        event.stopPropagation();
        event.preventDefault();
        if (!contextMenuState.isOpen) {
          setContextState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          });
        }

        contextMenuState.open(ref.current);
      },
    }),
    [contextMenuState.isOpen],
  );

  const menu = (
    /* Popover instead of Menu is used because Menu comes with a lot of automatic behaviour that is
    incompatible with our custom functionality, esp the TagLabelsEditor */
    <Popover
      {...bindMenu(contextMenuState)}
      onClose={contextMenuState.close}
      anchorReference="anchorPosition"
      anchorPosition={menuAnchorPosition}
      onClick={onMenuClick}>
      <Box mt={1} mb={1}>
        {entry?.path?.project_id && operationsTagsEnabled && (
          <TagLabelsEditor
            taglistType="entry"
            project_id={entry?.path?.project_id}
            entry={entry}
            onClose={contextMenuState.close}
            source="entryContextMenuWrapper"
          />
        )}

        {entry?.directory && (
          <>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>

            <Box m={2} mt={0} mb={1}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.review')}
              </Typography>
            </Box>
            <MenuItem onClick={onOpenAssistantForDirectory}>
              <ListItemIcon>
                <IconButton size="small">
                  <CodefyIcon />
                </IconButton>
              </ListItemIcon>
              {t('useTaglistMenuEntries.showAnalysis')}
            </MenuItem>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
          </>
        )}

        {entry?.document && (
          <>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>

            <Box m={2} mt={0} mb={1}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.review')}
              </Typography>
            </Box>
            <MenuItem onClick={onOpenAssistantForDocument}>
              <ListItemIcon>
                <IconButton size="small">
                  <CodefyIcon />
                </IconButton>
              </ListItemIcon>
              {t('useTaglistMenuEntries.showAnalysis')}
            </MenuItem>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
          </>
        )}

        {!hideOpenInParentDirectory && (
          <>
            <MenuItem
              data-e2e-id="entry-contextmenu-openParentDirectory"
              onClick={onOpenParentDirectory}>
              <ListItemIcon>
                <FolderOpenIcon />
              </ListItemIcon>
              {t('entriesList.contextMenu.openParentDirectory')}
            </MenuItem>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
          </>
        )}

        {entry?.document && isPdfViewerOpen && !isPdfViewer2Open && (
          <>
            <Box m={2} mt={0} mb={1}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.view')}
              </Typography>
            </Box>
            <MenuItem
              data-e2e-id="entry-contextmenu-openInSecondaryViewer"
              onClick={onOpenDocumentRight}>
              <ListItemIcon>
                <PdfViewerRightAddIcon />
              </ListItemIcon>
              {t('pdfViewer2.openRightAdd')}
            </MenuItem>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
          </>
        )}

        {entry?.document && isPdfViewerOpen && isPdfViewer2Open && (
          <>
            <Box m={2} mt={0} mb={1}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.view')}
              </Typography>
            </Box>
            <MenuItem
              data-e2e-id="entry-contextmenu-openInPrimaryViewer"
              onClick={onOpenDocumentLeft}>
              <ListItemIcon>
                <PdfViewerLeftIcon />
              </ListItemIcon>
              {t('pdfViewer2.openLeft')}
            </MenuItem>
            <MenuItem
              data-e2e-id="entry-contextmenu-openInSecondaryViewer"
              onClick={onOpenDocumentRight}>
              <ListItemIcon>
                <PdfViewerRightIcon />
              </ListItemIcon>
              {t('pdfViewer2.openRight')}
            </MenuItem>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
          </>
        )}

        <Box m={2} mt={0} mb={1}>
          <Typography className={globalClasses.subheading}>
            {t('entriesList.contextMenu.edit')}
          </Typography>
        </Box>

        <MenuItem
          data-e2e-id="entry-contextmenu-rename"
          onClick={openRenameEntryDialog({ entryId: entry?.id })}
          disabled={isEveryone || !userHasWriteAccess}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          {t('entriesList.contextMenu.rename')}
        </MenuItem>

        {entry?.document && (
          <MenuItem
            data-e2e-id="entry-contextmenu-duplicate"
            onClick={onDuplicateDocument}
            disabled={isEveryone || !userHasWriteAccess}>
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            {t('entriesList.contextMenu.duplicate')}
          </MenuItem>
        )}

        {entry?.directory && entry?.mimetype == 'inode/case' && (
          <MenuItem
            data-e2e-id="entry-contextmenu-convert-to-directory"
            onClick={onConvert}
            disabled={isEveryone || !userHasWriteAccess}>
            <ListItemIcon>
              <CachedIcon />
            </ListItemIcon>
            {t('entriesList.contextMenu.convertToDirectory')}
          </MenuItem>
        )}

        {entry?.directory && entry?.mimetype == 'inode/directory' && (
          <MenuItem
            data-e2e-id="entry-contextmenu-convert-to-case"
            onClick={onConvert}
            disabled={isEveryone || !userHasWriteAccess}>
            <ListItemIcon>
              <CachedIcon />
            </ListItemIcon>
            {t('entriesList.contextMenu.convertToCase')}
          </MenuItem>
        )}

        {entry?.document && (
          <MenuItem data-e2e-id="entry-contextmenu-download" onClick={onDownloadDocument}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            {t('entriesList.contextMenu.download')}
          </MenuItem>
        )}

        <MenuItem
          data-e2e-id="context-menu-delete-entry"
          onClick={openDeleteEntryDialog({ entryId: entry?.id })}
          disabled={isEveryone || !userHasWriteAccess}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          {t('entriesList.contextMenu.delete')}
        </MenuItem>
      </Box>
    </Popover>
  );

  if (!entry) return <>{children}</>;

  if (entry.directory) {
    return (
      <DirectoryUploadWrapper directory_id={entry.directory.id}>
        <div {...divProps}>
          {children}
          {menu}
        </div>
      </DirectoryUploadWrapper>
    );
  }

  return (
    <div {...divProps} ref={ref.current}>
      {menu}
      <div id="children">{children}</div>
    </div>
  );
}
