import { Box, Grid, ListItem, Typography, makeStyles } from '@material-ui/core';

import Annotation from '../annotation/annotation';
import Codefy from '../../../../codefy';
import { EntriesListItem } from '../entriesList/entriesListItem';
import { FaFileExport } from 'react-icons/fa';
import { ModalKeys } from '../../../old_modals/modalTypes';
import React from 'react';
import { TagListTag } from '../taglist/tagListTag';
import { TaglistVariants } from '../paneTypes';
import useModalActions from '../../../old_modals/useModalActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  annotation: {
    cursor: 'pointer',
    width: '100%',
  },
  tagInstanceCompleted: { backgroundColor: '#a8f79855' },
  exportTextStyle: { cursor: 'pointer', backgroundColor: '#00000000' },
}));

export default function TagInstance({
  tag,
  tagInstance,
  project_id,
}: {
  tag: Codefy.Objects.Tag;
  tagInstance: Codefy.Objects.TagInstance;
  project_id?: Codefy.Objects.Project['id'];
}) {
  if (!tag.cell) {
    return (
      <Grid>
        <TagInstanceObject tagInstance={tagInstance} project_id={project_id} />
      </Grid>
    );
  }

  return (
    <ListItem dense disableGutters>
      <Grid container>
        {tag.cell && (
          <Grid item md={12}>
            <Box ml={2}>
              <TagInstanceExportText
                tagInstance={tagInstance}
                defaultText={
                  tagInstance.type === 'annotation' ? tagInstance.annotation.selected_text : ''
                }
              />
            </Box>
          </Grid>
        )}
        <Grid item md={12}>
          <TagInstanceObject tagInstance={tagInstance} project_id={project_id} />
        </Grid>
      </Grid>
    </ListItem>
  );
}

function TagInstanceObject({
  tagInstance,
  project_id,
}: {
  tagInstance: Codefy.Objects.TagInstance;
  project_id?: Codefy.Objects.Project['id'];
}) {
  switch (tagInstance.type) {
    case 'annotation':
      return (
        <Annotation
          annotation={tagInstance.annotation}
          key={tagInstance.id}
          groupContinuation={false}
        />
      );
    case 'entry':
      return <EntriesListItem entry={tagInstance.entry} />;

    case 'annotation_tag':
      return (
        <TagListTag
          project_id={project_id}
          tag={tagInstance.annotation_tag}
          variant={TaglistVariants.TAGLIST}
        />
      );

    default:
      return <span />;
  }
}

function TagInstanceExportText({
  tagInstance,
  defaultText,
}: {
  tagInstance: Codefy.Objects.TagInstance;
  defaultText?: string;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const modalActions = useModalActions();

  const onClickExportText = () => {
    modalActions.openModal({
      modal: ModalKeys.editExportText,
      params: { editExportText_itemId: tagInstance.id },
    });
  };

  return (
    <Grid item md={12} onClick={onClickExportText} className={classes.exportTextStyle}>
      <Box p={1}>
        <Typography variant="body2">
          <FaFileExport
            fontSize="small"
            style={{ marginRight: '4px', marginBottom: '-3px' }}
            size={18}
          />
          {t('tagInstance.exportedAs')}: <b>{tagInstance.export_text || defaultText || ''}</b>
        </Typography>
      </Box>
    </Grid>
  );
}
