import Codefy from '../../../../codefy';
import { ENTRIES_DELETE_QUERY_KEYS_TO_REFRESH } from '../entries/entriesDelete';
import { cancelUploadBatchUpload } from '../documents/documentsUpload';
import { executeAction } from '../actionHelpers';
import store from '../../../store';

export const uploadBatchesDelete = async (data: {
  upload_batch_uuid: Codefy.Objects.UploadBatch['upload_batch_uuid'];
}) => {
  cancelUploadBatchUpload(data.upload_batch_uuid);

  store.dispatch({ type: 'ResetUploadBatch', upload_batch_uuid: data.upload_batch_uuid });

  await executeAction({
    config: { url: '/api/v1/upload_batches/delete', method: 'POST' },
    data,
    queryKeysToRefresh: ENTRIES_DELETE_QUERY_KEYS_TO_REFRESH,
  });

  /* Because some time might pass between stopping uploads and the last upload reaching the server */
  setTimeout(
    () =>
      executeAction({
        config: { url: '/api/v1/upload_batches/delete', method: 'POST' },
        data,
        queryKeysToRefresh: ENTRIES_DELETE_QUERY_KEYS_TO_REFRESH,
        showToastOnError: false,
      }),
    3000,
  );
};
