import {
  PdfPageIndexContext,
  PdfPagePageHeightContext,
  PdfPagePageWidthContext,
  PdfViewerVariantType,
  RECTANGLE_HIGHLIGHT_MARGIN_BOTTOM,
} from './pdfViewer';
import React, { useContext, useRef } from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Codefy from '../../../../codefy';
import useOnClickOutside from 'use-onclickoutside';
import { useQueryParam_pdfViewer_boxes } from '../../../../controllers/useGlobalQueryParams';

export const PDF_PAGE_HIGHLIGHT_BOXES_ID = 'highlightBoxes';

export function PdfPageHighlightBoxes({
  pdfViewerVariant,
}: {
  pdfViewerVariant: PdfViewerVariantType;
}) {
  const pageIndex = useContext(PdfPageIndexContext);
  const pageWidth = useContext(PdfPagePageWidthContext);
  const pageHeight = useContext(PdfPagePageHeightContext);
  const [highlightBoxes, setHighlightBoxes] = useQueryParam_pdfViewer_boxes(pdfViewerVariant);

  const boxes = (highlightBoxes as Codefy.Objects.Box[] | undefined)?.filter(
    (box) => box.page === pageIndex + 1,
  );

  /* When the user clicks somewhere else, hide the highlight */
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setHighlightBoxes(undefined));

  if (!boxes) return null;

  return (
    <div ref={ref}>
      {boxes.length > 0 && (
        <div
          style={{
            position: 'absolute',
            left: '96.5%',
            top: boxes[0]?.ymin * pageHeight,
            width: pageWidth * 0.03,
            height: pageWidth * 0.03,
          }}>
          <ArrowBackIcon style={{ width: '100%', height: '100%' }} />
        </div>
      )}
      {boxes.map((box) => (
        <div
          key={`box-${box.page}-${box.xmax}-${box.xmin}-${box.ymax}-${box.ymin}`}
          id={PDF_PAGE_HIGHLIGHT_BOXES_ID}
          style={{
            position: 'absolute',
            top: box.ymin * pageHeight + RECTANGLE_HIGHLIGHT_MARGIN_BOTTOM,
            left: box.xmin * pageWidth,
            width: (box.xmax - box.xmin) * pageWidth,
            height: (box.ymax - box.ymin) * pageHeight,
            borderBottom: '3px solid #003366',
          }}
        />
      ))}
    </div>
  );
}
