import { LinearProgress, createStyles, makeStyles } from '@material-ui/core';

import NoAuthScreenWrapper from '../../components/auth/noAuthScreenWrapper';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    loadingProgressBar: { color: '#003366', width: '100%', marginBottom: '20px' },
  }),
);

/** Shown while the user cookie is being loaded */
export default function Loading() {
  const classes = useStyles();

  return (
    <NoAuthScreenWrapper>
      <LinearProgress color="primary" className={classes.loadingProgressBar} />
    </NoAuthScreenWrapper>
  );
}
