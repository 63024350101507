import React, { useContext, useMemo } from 'react';

import Codefy from '../../../../../codefy';
import { SelectionContext } from './selectionArea';
import stringify from 'fast-stringify';

export const SELECTABLE_WORD_SELECTED_CLASSNAME = 'selectableWord-selected';
export const DATA_SELECTABLE_WORD_ID_ATTRIBUTE_NAME = 'data-selectable-word-id';
export const DATA_SELECTABLE_WORD_TEXT_ATTRIBUTE_NAME = 'data-selectable-word-text';
export const DATA_SELECTABLE_WORD_BOX_ATTRIBUTE_NAME = 'data-selectable-word-box';
export const DATA_SELECTABLE_WORD_SELECTED_ATTRIBUTE_NAME = 'data-selectable-word-selected';

export default function SelectableWord({
  top,
  left,
  width,
  height,
  box,
  index,
  text,
  renderText,
}: {
  top: number;
  left: number;
  width: number;
  height: number;
  box: Codefy.Objects.Box;
  index: string;
  text: string;
  renderText?: boolean;
}) {
  const selectionAreaId = useContext(SelectionContext);

  const id = useMemo(() => `${selectionAreaId}-${index}`, []);

  const boxString = useMemo(
    () =>
      stringify({
        page: box.page,
        xmin: box.xmin,
        xmax: box.xmax,
        ymin: box.ymin,
        ymax: box.ymax,
      }),
    [],
  );

  const style: React.CSSProperties = useMemo(
    () => ({
      cursor: 'text',
      mixBlendMode: 'darken',
      zIndex: 500,
      userSelect: 'none',
      position: 'absolute',
      top,
      left,
      width,
      height,
    }),
    [top, left, width, height],
  );

  return (
    <span
      className={selectionAreaId}
      {...{
        [DATA_SELECTABLE_WORD_ID_ATTRIBUTE_NAME]: id,
        [DATA_SELECTABLE_WORD_TEXT_ATTRIBUTE_NAME]: text,
        [DATA_SELECTABLE_WORD_BOX_ATTRIBUTE_NAME]: boxString,
      }}
      style={style}>
      {renderText && text}
    </span>
  );
}
