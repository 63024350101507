import Codefy from '../../../../codefy';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { executeAction } from '../actionHelpers';

export const projectsCreate = (data: { name?: Codefy.Objects.Project['name'] }) =>
  executeAction<Codefy.Objects.Project>({
    config: { url: '/api/v1/projects/create', method: 'POST' },
    data,
    queryKeysToRefresh: [PROJECTS_LIST_KEY_TYPE],
  });
