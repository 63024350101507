import { Box } from '@material-ui/core';
import React from 'react';
import { SearchSearchRequestParams } from '../../../../controllers/api/subscriptions/search/searchSearch';
import clsx from 'clsx';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useSearchAutocorrect } from '../../../../controllers/api/subscriptions/search/searchAutocorrect';
import { useTranslation } from 'react-i18next';

export default function SearchNoResults({
  searchParams,
  onClickCorrection,
}: {
  searchParams: SearchSearchRequestParams;
  onClickCorrection: (correction: string) => void;
}) {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const { data: autocorrect } = useSearchAutocorrect(searchParams);

  return (
    <div>
      <Box m={3}>
        {t('searchBar.noResults')}.
        {autocorrect?.result && autocorrect.result !== searchParams.query && (
          <>
            {' '}
            {t('searchBar.didYouMean')}:{' '}
            <span
              className={clsx(globalClasses.link, globalClasses.underlineOnHover)}
              onClick={() => onClickCorrection(autocorrect?.result)}>
              {autocorrect?.result}
            </span>
          </>
        )}
      </Box>
    </div>
  );
}
