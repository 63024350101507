import '@material/react-linear-progress/dist/linear-progress.css';

import React, { useCallback, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Codefy from '../codefy';
import Div100vh from 'react-div-100vh';
import Loading from '../controllers/routes/loading';
import Routes from '../controllers/routes/routes';
import connectToNotificationsWebsocket from '../controllers/websocket/connectToNotificationsWebsocket';
import { userGet } from '../controllers/api/actions/user/userGet';

export const SESSION_COCKIE_KEY = 'session';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    searchDropdownAnchor: {
      position: 'absolute',
      top: '0px',
      left: '0px',
    },

    content: {
      flexGrow: 0,
      width: `100vw`,
      position: 'relative',
      overflowY: 'auto',
      height: '100%',
    },
    div100vh: {
      display: 'flex',
      flexDirection: 'column',
    },
    modal: {},
  }),
);

/** The basic logic of our app, mainly a wrapper for many different things, such as
 * theme, browser history and url parameter handling. Also the logic gate that shows
 * logged in users the actual app and everyone else a log in/register screen. */
const AppLayout = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userId = useSelector((state: Codefy.State) => state.user?.id);
  const userLoadingCompleted = useSelector((state: Codefy.State) => state.userLoadingCompleted);
  const searchOpen = useSelector((state: Codefy.State) => state.search.open);

  /* After the user opens the app for the first time, we do an API call to get his
   * user information. This call just needs to be run once, as long as the user loggedIn
   * behaviour or the session cookie have not changed. */
  useEffect(() => {
    dispatch(userGet());
  }, [dispatch]);

  /* Establishes the connection to our backend API websocket to recieve live push
   * notifications. The connection is established only once the user has authenticated
   * because the React development server crashes when a websocket connection returns
   * an error in Chrome. */
  useEffect(() => {
    if (userId && userLoadingCompleted) connectToNotificationsWebsocket();
  }, [userId && userLoadingCompleted]);

  const onMainClick = useCallback(() => {
    if (searchOpen) dispatch({ type: 'setSearch', open: false });
  }, [searchOpen]);

  if (!userLoadingCompleted) return <Loading />;

  return (
    <div className={classes.root}>
      <div id="search-dropdown-anchor" className={classes.searchDropdownAnchor} />

      {/* The Div100vh is used to prevent scrolling issues on some mobile screens */}
      <Div100vh className={classes.div100vh}>
        <main id="MAIN" className={classes.content} onClick={onMainClick}>
          <Routes />
        </main>
      </Div100vh>
    </div>
  );
};

export default AppLayout;
