import Codefy from '../../../../codefy';

/** A short and pretty url that the user can share with others that redirect to the annotation.
 * Looks like this: https://test.codefy.de/share/annotation/123 */
export default function getAnnotationShareUrl(annotation: Codefy.Objects.Annotation): string {
  /** The server part of the URL, where the frontend is running, e.g. https://test.codefy.de/ */
  const baseUrl = window.location.protocol + '//' + window.location.host;
  /** The final url that the user can share with others */
  const shareUrl = baseUrl + '/share/annotation/' + annotation.id;
  return shareUrl;
}
