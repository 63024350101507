import React, { forwardRef, useImperativeHandle, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { E2E } from '../../../../testing/common/data-e2e-ids';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './index';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/display-name
export const GenericSetting = forwardRef(
  (
    props: {
      id: number;
      type: string;
      text: string;
      icon: JSX.Element;
      content: JSX.Element;
      contentRef: React.RefObject<any>;
      noSave?: boolean;
    },
    ref: any,
  ): JSX.Element => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { id, type, text, icon, content, contentRef, noSave } = props;

    const [expanded, setExpanded] = useState<boolean>(false);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);

    // The component instance (ref) gets extended
    // with a function returned from the callback
    // passed as the second argument
    useImperativeHandle(ref, () => ({
      setExpand(expand: boolean) {
        setExpanded(expand);
      },
      setSaveDisabled(disabled: boolean) {
        setSaveDisabled(disabled);
      },
      setLoading(loading: boolean) {
        setLoading(loading);
      },
    }));

    const contentId = `panel${id}a-content`;
    const headerId = `panel${id}a-header`;

    return (
      <div className={classes.panel}>
        <Accordion
          defaultExpanded={false}
          expanded={expanded}
          onChange={(_, expand) => {
            setExpanded(expand);
            setSaveDisabled(expand);
          }}
          TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={contentId} id={headerId}>
            <div className={classes.column}>
              <Typography className={classes.heading} variant="caption" color="primary">
                <Grid
                  container
                  justify="flex-start"
                  direction="row"
                  alignItems="center"
                  spacing={2}>
                  <Grid item>{icon}</Grid>
                  <Grid item>{type}</Grid>
                </Grid>
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading} variant="caption">
                {text}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>{content}</AccordionDetails>

          {!noSave && (
            <AccordionActions>
              <Button
                data-e2e-id={E2E.account_save_button}
                variant="contained"
                color="primary"
                startIcon={<SaveIcon style={{ color: 'white' }} fontSize="small" />}
                disableElevation
                disabled={saveDisabled}
                onClick={() => contentRef.current.saveData()}>
                {t('account.save')}
              </Button>
            </AccordionActions>
          )}
        </Accordion>
        <Backdrop classes={{ root: classes.backdrop }} style={{ opacity: 0.5 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  },
);
