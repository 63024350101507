import React, { useRef } from 'react';
import { bindContextMenu, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';

import Codefy from '../../../../codefy';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { projectsUnshare } from '../../../../controllers/api/actions/projects/projectsUnshare';
import { useTranslation } from 'react-i18next';

/** Whatever is inside this wrapper will open a user permission context menu (rename, delete, ...)
when right-clicked */
export default function SharedWithUserContextMenuWrapper({
  permission,
  project,
  children,
  openOnLeftClick,
}: {
  permission?: Codefy.Objects.ProjectPermission;
  project?: Codefy.Objects.Project;
  children: React.ReactChild | React.ReactChildren;
  openOnLeftClick?: boolean;
}) {
  const { t } = useTranslation();

  const ref = useRef();

  const contextMenuState = usePopupState({ variant: 'popover', popupId: 'permissionContextMenu' });
  const [contextState, setContextState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  if (!permission || !project) return <>{children}</>;

  const onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!contextMenuState.isOpen) {
      if (openOnLeftClick) {
        setContextState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        contextMenuState.open(ref.current);
        return;
      }
    } else {
      contextMenuState.close();
    }
  };

  const onDeletePermission = async () => {
    projectsUnshare({ project_ids: [project.id], email: permission?.user.email });
  };

  const menuAnchorPosition =
    contextState.mouseY !== null && contextState.mouseX !== null
      ? { top: contextState.mouseY, left: contextState.mouseX }
      : undefined;

  const menu = (
    <Menu
      {...bindMenu(contextMenuState)}
      anchorReference="anchorPosition"
      anchorPosition={menuAnchorPosition}
      onClick={onMenuClick}>
      <MenuItem
        data-e2e-id="context-menu-delete-permission"
        onClick={onDeletePermission}
        /** One cannot remove the access of the owner of a project */
        disabled={permission.user.id === project.created_by?.id}>
        {t('projectsList.contextMenu.removeAccess')}
      </MenuItem>
    </Menu>
  );
  return (
    <div
      ref={ref.current}
      {...bindContextMenu(contextMenuState)}
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setContextState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        bindContextMenu(contextMenuState).onContextMenu(event);
      }}
      onClick={onMenuClick}>
      {children}
      {menu}
    </div>
  );
}
