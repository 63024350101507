import React, { useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import NoAuthScreenWrapper from './noAuthScreenWrapper';
import PasswordHelperText from './passwordHelperText';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { TextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { getErrorText } from '../../controllers/api/actions/actionHelpers';
import { isAxiosError } from './axiosHelper';
import { isPasswordValid } from './validation';
import useAxios from '@use-hooks/axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBlock: {
      margin: theme.spacing(4, 0),
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
      overflow: 'hidden',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    bottomButtons: {
      paddingTop: theme.spacing(3),
    },
  }),
);

/** A screen allowing the user to set new password */

const ConfirmResetPassword = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [formStep, setFormStep] = useState(1);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [checkPassword, setCheckPassword] = React.useState(false);
  const [token] = useQueryParam('token', StringParam);
  const formData = new FormData();
  formData.append('token', token as string);
  formData.append('password', password);
  const { response, loading, error, reFetch } = useAxios({
    url: '/api/v1/users/confirm_reset_password',
    method: 'POST',
    options: {
      data: formData,
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  /** Are all fields filled out? */

  let canExecuteNextOrSubmit = false;
  let submitButtonTitle = t('authScreen.next');
  if (formStep === 1 && password) {
    canExecuteNextOrSubmit = true;
    canExecuteNextOrSubmit = checkPassword ? isPasswordValid(password) : true;
  }
  if (formStep === 2) {
    canExecuteNextOrSubmit = !!token && password === passwordConfirmation;
    submitButtonTitle = t('confirmResetPassword.changePassword');
  }

  /** Runs the set new password action */
  const executeNextOrSubmit = () => {
    if (formStep === 1 && !checkPassword) {
      setCheckPassword(true);
      canExecuteNextOrSubmit = isPasswordValid(password);
    }

    if (!canExecuteNextOrSubmit) return;

    if (formStep === 1) {
      setFormStep(formStep + 1);
    } else {
      reFetch();
    }
  };

  /** Some common props for the <TextField> components to avoid duplicate code */
  const commonTextFieldProps: TextFieldProps = {
    variant: 'outlined',
    margin: 'normal',
    required: true,
    fullWidth: true,
  };

  return (
    <NoAuthScreenWrapper title={t('confirmResetPassword.setNewPassword')}>
      {loading && (
        <div className={classes.textBlock}>
          <Skeleton variant="text" />
        </div>
      )}
      {!token && (
        <div className={classes.textBlock}>
          <Typography color="error" variant="body1" align="center">
            {t('confirmResetPassword.invalidTokenText')}
          </Typography>
        </div>
      )}
      {isAxiosError(error) && (
        <div className={classes.textBlock}>
          <Typography color="error" variant="body1" align="center">
            {getErrorText(error?.response?.data) || error?.name}
          </Typography>
        </div>
      )}
      {response && (
        <div className={classes.textBlock}>
          <Typography variant="body1" align="center">
            {t('confirmResetPassword.successConfirmationText')}
          </Typography>
        </div>
      )}

      {!loading && !response && token && (
        <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
          {formStep === 1 && (
            <Slide in direction="right">
              <TextField
                {...commonTextFieldProps}
                label={t('authScreen.password')}
                name="password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                autoFocus
                error={checkPassword ? !isPasswordValid(password) : false}
                helperText={
                  (checkPassword ? !isPasswordValid(password) : false) && (
                    <PasswordHelperText password={password} />
                  )
                }
                variant="outlined"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Slide>
          )}
          {formStep === 2 && (
            <Slide in direction="right">
              <TextField
                {...commonTextFieldProps}
                label={t('authScreen.confirmPassword')}
                name="confirm-password"
                type="password"
                id="confirm-password"
                autoFocus
                value={passwordConfirmation}
                error={
                  password !== '' &&
                  passwordConfirmation !== '' &&
                  password !== passwordConfirmation
                }
                onChange={(event) => setPasswordConfirmation(event.target.value)}
              />
            </Slide>
          )}
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              {formStep > 1 && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    setFormStep(formStep - 1);
                  }}>
                  {t('authScreen.back')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submit}
                disabled={!canExecuteNextOrSubmit}
                onClick={executeNextOrSubmit}>
                {submitButtonTitle}
              </Button>
            </Grid>
          </Grid>
          <Grid container className={classes.bottomButtons} justify="flex-end">
            <Grid item>
              <Link style={{ textDecoration: 'none' }} component={RouterLink} to="/">
                {t('authScreen.existingAccount')}
              </Link>
            </Grid>
          </Grid>
        </form>
      )}

      {response && (
        <Grid container justify="center">
          <Grid item>
            <Link style={{ textDecoration: 'none' }} href="/">
              <Button variant="contained" color="primary">
                {t('confirmRegister.signIn')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
    </NoAuthScreenWrapper>
  );
};

export default ConfirmResetPassword;
